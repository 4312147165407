import { useCallback, useEffect, useState } from 'react';


function useIsWindowVisible() {

    const [isVisible, setIsVisible] = useState(true);

    const handleVisibilityChange = useCallback(() => {
        setIsVisible(document.visibilityState !== 'hidden');
    }, []);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return isVisible;
}

export default useIsWindowVisible;