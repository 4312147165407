import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { ReactComponent as PlusIcon } from 'images/plus.svg';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import useAppState from 'lib/hooks/useAppState.js';
import AccountSectionHeader from '../common/AccountSectionHeader.js';
import { becomesIconButtonResponsive } from '../../../theme/mixins.js';
import AddClientDialog from './AddClientDialog.js';

const useStyles = makeStyles(theme => ({
    cta: {
        height: 48,
        width: 178,
        ...becomesIconButtonResponsive(theme)
    }
}));

function ClientsHeader() {

    const classes = useStyles();
    const { state } = useAppState();
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleAddClient = e => {
        e.preventDefault();
        setDialogOpen(true);
    };

    return <>
        <AccountSectionHeader title="Clients" rightSide={state.userData && state.userData.is_admin &&
            <Button
                onClick={handleAddClient}
                className={classes.cta}
                size="small"
                variant="contained"
                startIcon={<PlusIcon/>}
            >
            <Hidden smDown>Add Client</Hidden></Button>} hasFilter />
            <AddClientDialog open={dialogOpen} setOpen={setDialogOpen} />
        </>
}

export default ClientsHeader;
