import palette from './palette.js';

const globalStyles = {
    '@global': {
        html: {
            height: '100%'
        },

        body: {
            height: '100%'
        },

        '#root': {
            background: 'linear-gradient(180deg, #C5EDE9 0%, #FEF3E1 100%)',
            minHeight: '100%',
            backgroundAttachment: 'fixed',
        },

        '.intercom-lightweight-app-launcher': {
            bottom: '40px!important'
        },

        a: {
            color: palette.primary.main,
            textDecoration: 'none'
        },

        'button': {
            fontFamily: "'Neutrif Studio', sans-serif"
        },

        '@keyframes shakeX': {
            'from, to': {
                transform: 'translate3d(0, 0, 0)'
            },

            '15%, 45%': {
                transform: 'translate3d(-7px, 0, 0)'
            },

            '30%, 60%': {
                transform: 'translate3d(7px, 0, 0)'
            }
        },



        '@keyframes newTableRow': {
            '0%': {
                backgroundColor: palette.accent.marketMint[400]
            },

            '100%': {
                backgroundColor: 'rgba(255,255,255,0)'
            }
        },

        '@keyframes rotation': {
            'from': {
                transform: 'rotate(0deg)'
            },

            'to': {
                transform: 'rotate(359deg)'
            }
        },

        '@keyframes toastProgress': {
            '0%': {
                transformOrigin: 'left center',
                transform: 'scale(0)',
            },

            '40%': {
                transform: 'scale(0)',
            },

            '100%': {
                transform: 'scale(1)',
            }
        },
        '@keyframes startOver': {
            '100%': {
                maxWidth: 330,
            },
        }
    }
};

export default globalStyles;
