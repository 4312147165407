import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, Switch, makeStyles, Typography, Divider, Tooltip } from "@material-ui/core";
import classNames from "classnames";
import { ReactComponent as PlusIcon } from 'images/plusCircle.svg';
import { ReactComponent as TrashIcon } from 'images/trash.svg';
import NumberInputGroup from "../../common/NumberInputGroup";
import AddressSelect from "./AddressSelect";
import AddressCreateForm from "../../AddressesPage/AddressCreateForm";
import { formatPrice } from "lib/functions";
import { ReactComponent as InfoIcon } from 'images/info.svg';
import counterCard from 'images/counter-card.png';
import checkingCopies from 'images/checking-copies.png'
import { ReactComponent as Free } from 'images/free.svg';

const useStyles = makeStyles(theme => ({
    wrap: {
        padding: '16px 24px',

        [theme.breakpoints.down('xs')]: {
            padding: '0 0 16px 0',
        }
    },
    addonTable: {
        border: `1px solid ${theme.palette.neutral.paper}`,
        borderRadius: 5,
        margin: '16px 0',
        overflow: 'hidden',
    },
    addonTableHeader: {
        minHeight: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        borderBottom: `1px solid ${theme.palette.neutral.paper}`,
        background: theme.palette.neutral.paper,
        fontSize: 13,
        lineHeight: '15px',
        color: theme.palette.neutral[900],

        [theme.breakpoints.down('xs')]: {
            '& $left': {
                width: 107,
            }
        }
    },
    addonActiveTableHeader: {
        backgroundColor: theme.palette.primary[400],

        '& $price': {
            color: 'white',
        }
    },
    left: {
        display: 'flex',
        alignContent: 'center',
        width: 190,
        marginRight: 16,
        fontSize: 15,

        '& > *': {
            display: 'flex',
            alignItems: 'center',
        },

        '& > *:nth-child(2)': {
            marginLeft: 16,
            marginRight: 16,
        },

        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            fontSize: 11,
            marginRight: 8,

            '& > *:nth-child(2)': {
                marginLeft: 8,
                marginRight: 8,
            },
        }
    },
    right: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignContent: 'center',

        '& > *': {
            display: 'flex',
            alignItems: 'center',
        },

        [theme.breakpoints.down('xs')]: {

        }
    },
    description: {
        fontSize: 15,

        [theme.breakpoints.down('xs')]: {
            fontSize: 13,
        }
    },
    price: {
        color: theme.palette.neutral[800],
        whiteSpace: 'nowrap',
        fontSize: 13,
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        height: 30
    },
    subtotalWrap: {
        width: 110,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: 16,
    },
    subtotal: {
        color: theme.palette.common.offWhite,
        fontWeight: 'bold',
    },
    addonTableRow: {
        background: theme.palette.primary[100],
        borderBottom: `1px solid ${theme.palette.primary[200]}`,
        color: theme.palette.primary[700],
        minHeight: 48,
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
    },
    designRow: {
        display: 'flex',
        fontSize: 15,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 10px',
        width: '100%',
    },
    designServiceText: {
        fontSize: 13,
        maxWidth: '80%'
    },
    select: {
        flex: 1,

        '& .MuiInputBase-root': {
            minWidth: '100%',
            border: '1px solid red',
        }
    },
    addonTableFooter: {
        background: theme.palette.neutral[100],
        minHeight: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 10px',
        color: theme.palette.neutral[700],
    },
    addonActiveTableFooter: {
        backgroundColor: theme.palette.primary[100],
        color: theme.palette.neutral[900],
    },
    addonTableActive: {
        borderColor: theme.palette.primary[400],

        '& $addonTableHeader': {
            borderBottom: `1px solid ${theme.palette.primary[400]}`,
            background: theme.palette.primary[400],
            color: theme.palette.primary[900],
        },

        '& $price': {
            color: theme.palette.common.offWhite,
            opacity: 1,
        },

        '& $addonTableFooter': {
            background: theme.palette.primary[100],
        },
    },
    addRowBtn: {
        '& .MuiButton-label': {
            fontSize: 13,
            textTransform: 'lowercase',
            fontWeight: 'normal'
        },

        '&:not(:first-child)': {
            marginLeft: 16,
        }
    },
    formWrap: {
        padding: 16,
    },
    formTitle: {
        color: theme.palette.neutral[900],
        fontSize: 21,
    },
    addonWrapper: {
        border: `1px solid ${theme.palette.neutral["paper"]}`,
        borderRadius: 5
    },
    addonActiveWrapper: {
        border: `1px solid ${theme.palette.primary[400]}`,
        borderRadius: 5
    },
    infoIcon: {
        '& path': {
            stroke: theme.palette.neutral[100]
        }
    },
    imgWrap: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: 12,

        '& img': {
            width: 80,
            height: 80,
            marginRight: 16,
        },
        '& p': {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '21px',
        }
    },
    designServiceLink: {
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    freeIconWrap: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 5,
        'svg': {
            height: 30
        }
    },
    hideOnMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}))

export default function OrderExtraAddonsSelect({ addons, selected, setSelected, initAddons=null, cartErrors, designServiceLink='', hideDeleteAll=false }) {
    const classes = useStyles();

    const [addressFormOpen, setAddressFormOpen] = useState(null);

    useEffect(() => {
        if (initAddons) {
            const selected = initAddons();
            return setSelected(selected);
        }
        // init selected array
        let array = [...addons].filter(addon => addon.is_available);

        array = array.map(addon => {
            let items = [];
            if (addon.qty) {
                items.push({ qty: addon.qty, address: '' })
            }

            return {
                ...addon,
                items
            }
        })

        setSelected(array);
    }, [])

    const calcSubtotal = addon => {
        if (!addon.items || !addon.items.length || addon.items.length === 0) return 0

        let subtotal = 0;
        addon.items.forEach(item => {
            subtotal += addon.price * (!!item?.is_free ? 0 : item.qty);
        });

        return subtotal;
    }

    const calcQty = addon => {
        if (!addon.items || !addon.items.length || addon.items.length === 0) return 0

        let qty = 0;
        addon.items.forEach(item => {
            qty += !!item?.is_free ? 0 : item.qty;
        });

        return qty;
    }

    const handleAddRow = (addonIndex) => {
        let addons = Array.from(selected);
        addons[addonIndex].items.push({ qty: 1, address_id: null });
        setSelected(addons)
    }

    const handleRemoveRow = (addonIndex, itemIndex) => {
        let addons = Array.from(selected);
        addons[addonIndex].items.splice(itemIndex, 1);
        setSelected([])
        setSelected(addons);
    }

    const handleDeleteAll = (addonIndex) => {
        let addons = Array.from(selected);
        addons[addonIndex].items = [];
        setSelected(addons)
    }

    const handleQtyChange = (addonIndex, itemIndex, val) => {
        let addons = Array.from(selected);
        addons[addonIndex].items[itemIndex].qty = val
        setSelected(addons);
    }

    const handleAddressChange = (addonIndex, itemIndex, addressId) => {
        let addons = Array.from(selected);
        addons[addonIndex].items[itemIndex].address_id = addressId
        setSelected(addons);
    }

    const handleSwitchChange = (evt, addonIndex) => {
        let addons = Array.from(selected);
        addons[addonIndex].items = [{qty: evt.target.checked ? 1 : 0}]
        setSelected(addons);
    }

    const handleAddressAdded = (address, {addonIndex, itemIndex}) => {
        let addons = Array.from(selected);
        addons[addonIndex].items[itemIndex].address_id = address.id;
        setSelected(addons);
        setAddressFormOpen(null);
    }

    const handleCancelAddingAddress = () => {
        setAddressFormOpen(null);
    }

    const CheckingCopiesTitle = () => {
        return (
            <div className={classes.imgWrap}>
                <img src={checkingCopies}/>
                <Typography>
                    Buy up to 20 additional copies of the magazine featuring your ad and have them shipped anywhere!
                </Typography>
            </div>
        )
    }

    const CounterCardTitle = () => {
        return (
            <div className={classes.imgWrap}>
                <img width={100} height={100} src={counterCard}/>
                <Typography>
                Extend your ad’s exposure with a custom easel sign. The first one is free!
                </Typography>
            </div>
        )
    }

    const ToolTipTitle = ({addon}) => {
        if (addon.type === "checkcopy") {
            return <CheckingCopiesTitle />
        } else if (addon.type === "countercard") {
            return <CounterCardTitle/>
        }
        return ''
    }

    return <Box className={classes.wrap}>
        {selected.map((addon, addonIndex) => {

            const isChecked = !!(addon.items && addon.items[0] && addon.items[0].qty > 0);
            const hasFreeItems = addon.items.some(i => i.is_free);

            return (
                <Box key={addonIndex}>
                    {addon.type === 'design' &&
                    <Box className={classNames(classes.addonWrapper, isChecked && classes.addonActiveWrapper)}>
                        <Box className={classNames(classes.addonTableHeader, isChecked && classes.addonActiveTableHeader)}>
                            <Box className={classes.left}>
                                <Box className={classes.description}>{addon.description}</Box>
                            </Box>
                            <Box className={classes.right}>
                                <Box className={classes.price}>{formatPrice(addon.price)}</Box>
                            </Box>
                        </Box>
                        <Box className={classNames(classes.designRow, classes.addonTableFooter, isChecked && classes.addonActiveTableFooter)}>
                            <Box className={classes.designServiceText}>Work with an Addy designer to <a className={classes.designServiceLink} target="_blank" href={designServiceLink}>create your ad</a>.
                                The design is yours to reuse as much as you'd like!
                            </Box>
                            <Switch
                                color="primary"
                                checked={isChecked}
                                onChange={(e) => { handleSwitchChange(e, addonIndex) }} />
                          </Box>
                      </Box>
                    }
                    {addon.type !== 'design' && (
                        <Box className={classNames(classes.addonTable, (calcQty(addon) > 0 || hasFreeItems) ? classes.addonTableActive : '')}>
                            <Box className={classes.addonTableHeader}>
                                <Box className={classes.left}>
                                    <Box className={classes.description}>{addon.description}</Box>
                                </Box>
                                <Box className={classes.right}>

                                    <Box className={classes.price}>
                                        {hasFreeItems &&
                                            <span className={classes.freeIconWrap}>
                                                2x<Free/> {calcQty(addon) ? '+' : ''}
                                            </span>
                                        }
                                        {calcQty(addon) > 0 &&
                                            <span>{calcQty(addon) ? `${calcQty(addon)}x ` : ''}{addon.price_formatted}</span>
                                        }
                                    </Box>
                                    {calcQty(addon) > 0  && <span className={classes.subtotalWrap}>
                                        <span className={classes.subtotalText}>subtotal</span>
                                        <span className={classes.subtotal}>{formatPrice(calcSubtotal(addon))}</span>
                                    </span>}
                                    <Tooltip className={classes.tooltip} title={<ToolTipTitle addon={addon}/>} placement="left-end" arrow>
                                        <InfoIcon className={isChecked ? classes.infoIcon: ''} />
                                    </Tooltip>
                              </Box>
                            </Box>
                            {addon.items.map((item, itemIndex) => {
                               return (
                                <Box className={classes.addonTableRow} key={itemIndex}>
                                    <Box className={classes.left}>
                                        <span className={classes.hideOnMobile}>ship</span>
                                        <NumberInputGroup value={item.qty} onChange={(val) => { handleQtyChange(addonIndex, itemIndex, val) }} disabled={!!item.is_free} disableAdd={!addon.is_available}/>
                                        <span>to</span>
                                    </Box>
                                    <Box className={classes.right}>
                                        <AddressSelect
                                          className={classes.select}
                                          value={item.address_id || ""}
                                          onChange={ (addressId) => { handleAddressChange(addonIndex, itemIndex, addressId) } }
                                          setAddressFormOpen={() => { setAddressFormOpen({ addonIndex, itemIndex }) }}
                                        />
                                        <IconButton color="primary" aria-label="upload picture" component="span" disabled={!item.is_deleteable} onClick={() => { handleRemoveRow(addonIndex, itemIndex) }}>
                                            <TrashIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                              )
                            })}
                                <Box className={classes.addonTableFooter}>
                                    <Button
                                        onClick={() => {handleAddRow(addonIndex)}}
                                        className={classes.addRowBtn}
                                        size="small"
                                        startIcon={<PlusIcon/>}
                                    >
                                        {calcQty(addon) > 0 ? `add another shipping address` : `add ${addon?.description?.toLowerCase()}`}
                                    </Button>
                                    {calcQty(addon) > 0 && !hideDeleteAll && (
                                    <Button
                                      onClick={() => {handleDeleteAll(addonIndex)}}
                                      className={classes.addRowBtn}
                                      size="small"
                                    >
                                        delete all
                                    </Button>
                                )}
                                </Box>
                                {addressFormOpen && addressFormOpen.addonIndex === addonIndex && (
                                    <Box className={classes.formWrap}>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography className={classes.formTitle}>New Shipping Address</Typography>
                                            <Button onClick={handleCancelAddingAddress} className={classes.addRowBtn}>cancel</Button>
                                        </Box>
                                        <Divider />
                                        <AddressCreateForm onAddressCreated={ (address) => { handleAddressAdded(address, addressFormOpen) } } />
                                    </Box>
                                )}
                      </Box>
                    )}
                </Box>
            )
          }
        )}
    </Box>
}
