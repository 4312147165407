import Box from '@material-ui/core/Box';
import CommonHeader from '../../../elements/common/CommonHeader.js';
import PaymentCreateCardForm from '../../../elements/PaymentMethodsPage/PaymentCreateCardForm.js';
import BackArrow from '../../../elements/common/BackArrow.js';
import StripeProvider from '../../../elements/form/StripeProvider.js';

function PaymentMethodCreateCard() {
    return <Box>
        <CommonHeader heading="New Credit Card"/>
        <BackArrow path="/account/paymentmethods"/>
        <StripeProvider>
            <PaymentCreateCardForm/>
        </StripeProvider>
    </Box>;
}

export default PaymentMethodCreateCard;