import React from 'react';
import { Page } from 'components/elements/common';
import Card from '@material-ui/core/Card';
import MainMenu from './MainSidebar';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';


// import AccountIndex from 'components/pages/dashboard/AccountIndex.js';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    content: {
        flex: '1 1 auto',
        margin: '24px 8px',
        padding: 0,

        [theme.breakpoints.up('sm')]: {
            margin: 24
        },

        [theme.breakpoints.up('lg')]: {
            margin: '48px 32px 12px 0'
        },

        '& $tabsPanel': {
            paddingTop: 0,
        }
    },
    mainMenuWrapper: {
        display: 'none',
        maxWidth: 234,
        width: '100%',

        [theme.breakpoints.up('lg')]: {
            display: 'flex'
        },

        [theme.breakpoints.up('xl')]: {
            maxWidth: 308
        }
    },
    tabsPanel: {}
}));

function OrderLayout({children}) {

    const classes = useStyles();

    return (
        <Page className={classes.root}>
            <Box className={classes.mainMenuWrapper}>
                <MainMenu/>
            </Box>
            <Card className={classes.content}>
                { children }
            </Card>
        </Page>
    );
}

export default OrderLayout;