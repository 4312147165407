import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%'
    },
    rowCanceled: {
        opacity: .3
    },
    thumb: {
        flex: '0 0 48px',
        height: 48,
        overflow: 'hidden',
        borderRadius: 5,
        marginRight: 12,

        '& img': {
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    text: {
        flex: 1
    },
    id: {
        display: 'block',
        color: theme.palette.neutral[800],
        fontSize: 11,
        lineHeight: '13px'
    },
    title: {
        display: 'block',
        color: theme.palette.neutral[900],
        fontSize: 13,
        lineHeight: '18px',
        fontWeight: 'bold'
    },
    description: {
        display: 'block',
        color: theme.palette.neutral[800],
        fontSize: 11,
        lineHeight: '13px'
    },
    cancel: {
        margin: 'auto 0 auto auto',
        borderRadius: 14,
        border: `1px solid ${theme.palette.common.base}`,
        color: theme.palette.common.base,
        fontSize: 13,
        lineHeight: '18px',
        padding: '4px 8px',
        backgroundColor: 'transparent'
    }
}));

function OrdersAutocompleteResult({ option }) {

    const classes = useStyles();

    return <Box className={classNames(
        classes.root,
        option.is_canceled ? classes.rowCanceled : ''
    )}>
        <Box className={classes.thumb}>
            <img src={option.image_thumbnail} alt="thumb"/>
        </Box>
        <Box className={classes.text}>
            <span className={classes.id}>#{option.id}</span>
            <span className={classes.title}>{option.title}</span>
            <span className={classes.description}>{option.summary_line_1}</span>
        </Box>
        {option.is_canceled ? <div className={classes.cancel}>Canceled</div> : ''}
    </Box>;
}

export default OrdersAutocompleteResult;