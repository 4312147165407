import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { ReactComponent as CompanyIcon } from 'images/company.svg';
import AvatarContainer from 'components/elements/common/AvatarContainer.js';
import AddressForm from 'components/elements/CompanyPage/AddressForm.js';
import useAppState from 'lib/hooks/useAppState.js';
import BillingForm from 'components/elements/CompanyPage/BillingForm.js';
import AdDueNotificationsForm from 'components/elements/CompanyPage/AdDueNotificationsForm.js';
import useData from '../../../lib/hooks/useData.js';

const useStyles = makeStyles(() => ({
    container: {},
    wrap: {
      maxWidth: 580,
    },
}));

export default function CompanyPage() {
    const { state } = useAppState();
    const classes = useStyles();
    const isAgency = state.userData.is_agency;
    const companyId = state.myCompany?.data?.id;
    const materialReminder = state.myCompany?.data?.material_reminder;

    useData({
        queries: ['getMyCompany'],
        setLoadingState: true
    });

    return (
        <Box className={classes.container}>
            <AvatarContainer
                icon={<CompanyIcon/>}
                src={state.myCompany.data.avatar}
                uploadUrl={`/company/${state.myCompany.data.id}/avatar`}
            />
            <Divider/>
            <AddressForm data={state.myCompany.data} />
            <Divider/>
            <BillingForm data={state.myCompany.data} />
            <Divider/>
            {!isAgency && (
                <AdDueNotificationsForm
                   companyId={companyId}
                   materialReminders={materialReminder}
                />
            )}
        </Box>
    );
}
