import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

import ZipcodeInfo from './ZipcodeInfo'

const useStyles = makeStyles(theme => ({
    statistics: {
        marginBottom: 16,
    },
    statisticsLoading: {
        opacity: 0.5
    },
}))

export default function Statistics({ quote }) {
    const classes = useStyles()

    return (
        <div className={classNames(classes.statistics, quote.isFetching && classes.statisticsLoading)}>
            <ZipcodeInfo
                demographics={quote.result?.demographics}
                zipcodeTitle={quote.result ? `${quote.result.total_zipcodes} Zips` : ''}
                variant="lg"
            />
        </div>
    )
}
