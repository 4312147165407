import React from 'react'
import useAppState from 'lib/hooks/useAppState';
import useLinkHandler from 'lib/hooks/useLinkHandler';
import { Box, makeStyles } from '@material-ui/core';
import FilesMoreMenu from './FilesMoreMenu';
import FilesFavoriteButton from './FilesFavoriteButton';
import MediaTypeIcon from '../common/MediaTypeIcon';
import classNames from 'classnames';
import VideoFileThumbnail from './VideoFileThumbnail';

const useStyles = makeStyles(theme => ({
    grid: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
            marginRight: 8,
        }
    },

    gridItem: {
        width: 'calc(50% - 8px)',
        height: 256,
        marginBottom: 24,
        
        [theme.breakpoints.up('sm')]: {
            width: 188,
            height: 281,
            marginRight: 16,
        }
    },
    
    fileCard: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        borderRadius: 8,
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        backgroundColor: theme.palette.neutral[300],
        backgroundImage: `linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.2) 95%)`,

        '&:hover': {
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15), 0px 1px 8px rgba(0, 0, 0, 0.18)',
        },
    },

    cardThumbnailWrap: {
        height: 210,

        [theme.breakpoints.up('sm')]: {
            height: 235,
        }
    },

    cardImageWrap: {
        height: '100%',
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    },

    copyWrap: {
        height: 'calc(100% - 210px)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.common.white,

        [theme.breakpoints.up('sm')]: {
            height: 'calc(100% - 235px)',
        }
    },

    filename: {
        maxWidth: 'calc(100% - 45px)',
        maxHeight: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: 12,
        cursor: 'pointer'
    },

    favoriteButtonWrap: {
        position: 'absolute',
        top: 8,
        left: 8,
        width: 28,
        height: 28,
        zIndex: 100,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
    },

    mediaTypeWrap: {
        position: 'absolute',
        top: 8,
        right: 8,
        width: 28,
        height: 28,
        zIndex: 100,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
    },

    selectedItem: {
        border: `2px solid ${theme.palette.primary.main}`,
        boxShadow: 'none !important',
    }
}))

export default function FilesGrid({onItemClick, selectedItem}) {
    const classes = useStyles();
    const { state, dispatch } = useAppState();
    const { getLinkAnchorProps } = useLinkHandler();

    const files = state.files.data ?? [];

    return (
        <Box className={classes.grid}>
            {files.map((file, index) => 
                <Box className={classes.gridItem} key={index}>
                    <Box className={classNames(classes.fileCard, selectedItem && selectedItem.id === file.id ? classes.selectedItem : '')} onClick={() => onItemClick(file)}>
                        <Box className={classes.favoriteButtonWrap}>
                            <FilesFavoriteButton file={file} type="button" />
                        </Box>
                        <Box className={classes.mediaTypeWrap}>
                            <MediaTypeIcon mediaType={file.media_type} iconType={file.media_icon} iconOnly size={28} />
                        </Box>
                        <Box className={classes.cardThumbnailWrap}>
                            {file.media_type === 'TV'
                                ? <VideoFileThumbnail file={file} />
                                : <Box className={classes.cardImageWrap} style={{ backgroundImage: `url(${file.thumbnail})`}}></Box>
                            }
                        </Box>
                        <Box className={classes.copyWrap}>
                            <a className={classes.filename} {...getLinkAnchorProps({ url: `/file/${file.id}` })}>{file.filename}</a>
                            <Box>
                                <FilesMoreMenu file={file} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    )
}
