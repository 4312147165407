import React, { useEffect } from 'react';
import { useParams } from "react-router";
import { Box, Button, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import OrderDetails from "../../elements/OrderPage/OrderDetails";
import OrderCharts from "../../elements/OrderPage/OrderCharts";
import OrderSidebar from "../../elements/OrderPage/OrderSidebar/OrderSidebar";
import OrderTimeline from "../../elements/OrderPage/OrderTimeline";
import OrderDesignHelp from "../../elements/OrderPage/OrderDesignHelp";
import BackArrow from "components/elements/common/BackArrow";
import useData from "lib/hooks/useData";
import OrderFileSpecs from '../../elements/OrderPage/OrderFileSpecs.js';
import useLinkHandler from "../../../lib/hooks/useLinkHandler";
import { getBackButtonProps } from '../../../lib/functions';
import SkeletonOrderSidebar from '../../elements/OrderPage/OrderSidebar/SkeletonOrderSidebar.js';
import SkeletonOrderMain from '../../elements/OrderPage/SkeletonOrderMain.js';

const useStyles = makeStyles(theme => ({
    wrap: {
        display: 'grid',
        height: '100%',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto auto auto auto 1fr',
        gridTemplateAreas: `
            \'back\'
            \'details\'
            \'designHelp\'
            \'receipt\'
            \'timeline\' 
        `,

        [theme.breakpoints.up('tb')]: {
            gridTemplateColumns: 'calc(100% - 290px) 290px',
            gridTemplateRows: 'auto auto auto 1fr',
            gridTemplateAreas: `
                \'back receipt\'
                \'details receipt\'
                \'designHelp receipt\'
                \'timeline receipt\'
            `
        },

        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'calc(100% - 360px) 360px'
        },

        [theme.breakpoints.up('xl')]: {
            gridTemplateColumns: 'calc(100% - 450px) 450px'
        }
    },

    inner: {
        padding: '0 32px',

        [theme.breakpoints.down('md')]: {
            padding: '0 24px'
        },

        [theme.breakpoints.down('sm')]: {
            padding: '0 16px'
        }
    },
    back: {
        gridArea: 'back',
        paddingTop: 8,

        [theme.breakpoints.up('lg')]: {
            paddingTop: 16
        },

        [theme.breakpoints.up('lg')]: {
            paddingTop: 24
        }
    },
    details: {
        gridArea: 'details',
        marginTop: 20
    },
    timeline: {
        gridArea: 'timeline'
    },
    designHelp: {
        gridArea: 'designHelp'
    },
    receipt: {
        gridArea: 'receipt',
        [theme.breakpoints.up('tb')]: {
            borderLeft: `1px solid ${theme.palette.neutral[400]}`
        }
    },
    reOrderBtn: {
        height: 36,
        width: 104,
        backgroundColor: theme.palette.primary.main,

        '& .MuiButton-label': {
            fontSize: 15
        }
    },
    headerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.neutral[300]}`,

        '& .MuiBox-root': {
            borderBottom: 0,
            marginBottom: 0
        }
    }
}));

export default function OrderPage() {
    const { id, action } = useParams();

    let shouldDuplicate = action === 'pickup' ? true : false;

    const classes = useStyles();
    const { getLinkButtonProps } = useLinkHandler();
    let history = useHistory();
    const from = history.location.state?.from;

    const state = useData({
        queries: [['getOrder', [id]], ['getTimeline', [id]]],
        cbError: () => {
            // TODO: 404
        }
    });

    useEffect(() => {
        //TODO replace this with custom scrollTo from functions
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    const orderLoaded = !!state.orders.current;

    return <Box className={classes.wrap}>
        <Box className={classNames(classes.inner, classes.back)}>
            <div className={classes.headerWrapper}>
                <BackArrow
                    {...getBackButtonProps(from)}
                />
                {orderLoaded ?
                    <Button
                        variant="contained"
                        className={classes.reOrderBtn}
                        {...getLinkButtonProps({ url: state.orders.current.reorder_url })}
                    >
                        Re-order
                    </Button> : ''}
            </div>
        </Box>
        <Box className={classNames(classes.inner, classes.details)}>
            {orderLoaded ? <>
                <OrderDetails order={state.orders.current} />
                {state.orders?.current?.is_canceled ?
                    '' :
                    <>
                        {state.orders?.current?.has_digital_metrics && (
                            <OrderCharts order={state.orders.current} id={id} />
                        )}
                        {!state.orders?.current?.has_digital_metrics && (
                            <OrderFileSpecs order={state.orders.current} duplicate={shouldDuplicate} />
                        )}
                    </>}
            </> :
                <SkeletonOrderMain />}
        </Box>
        <Box className={classNames(classes.receipt)}>
            {orderLoaded
                ? <OrderSidebar order={state.orders.current} />
                : <SkeletonOrderSidebar />
            }
        </Box>
        {orderLoaded && !state.orders?.current?.has_digital_metrics && (
            state.orders?.current?.is_canceled ?
                '' :
                <Box className={classNames(classes.inner, classes.designHelp)}>
                    <OrderDesignHelp order={state.orders.current} />
                </Box>
        )}
        {orderLoaded &&
            <Box className={classNames(classes.inner, classes.timeline)}>
                <OrderTimeline isCanceled={state.orders?.current?.is_canceled} data={state.timeline.data} />
            </Box>}
    </Box>;
}
