import Box from '@material-ui/core/Box';
import DataTable from '../common/DataTable.js';
import useAppState from '../../../lib/hooks/useAppState.js';
import { ReactComponent as CardAmexIcon } from '../../../images/cardAmex.svg';
import { ReactComponent as CardVisaIcon } from '../../../images/cardVisa.svg';
import { ReactComponent as CardJCBIcon } from '../../../images/cardJCB.svg';
import { ReactComponent as CardDiscoverIcon } from '../../../images/cardDiscover.svg';
import { makeStyles } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns';
import Chip from '@material-ui/core/Chip';
import PaymentTableTitle from './PaymentTableTitle.js';
import MoreMenu from '../common/MoreMenu.js';
import { objContainsStr } from '../../../lib/functions.js';
import NoDataFound from '../common/NoDataFound.js';

const useStyles = makeStyles(theme => ({
    name: {
        color: theme.palette.primary.main
    },
    logoCell: {
        width: 39,
        paddingRight: 0
    },
    actionsCell: {
        width: 44,
        padding: 0
    },
    defaultCell: {
        width: 77,
        paddingRight: 0,
        paddingTop: 10,
        paddingBottom: 10
    }
}));

const cardLogos = {
    'American Express': CardAmexIcon,
    'Visa': CardVisaIcon,
    'JCB': CardJCBIcon,
    'Discover': CardDiscoverIcon
};

const headings = [
    { label: 'Name', id: 'logo' },
    { id: 'name' },
    { label: 'Expiration date', id: 'expiry_date' },
    { label: 'Date added', id: 'date_added' },
    { id: 'default' },
    { id: 'actions' }
];

function PaymentDataTableCards({ onSetDefault, onDelete, onEditNickname }) {

    const classes = useStyles();
    const { state } = useAppState();

    const cellPropsGenerator = (heading, row) => {
        switch (heading.id) {
            case 'logo':
                return { className: classes.logoCell };
            case 'actions':
                return { className: classes.actionsCell };
            case 'default':
                return { className: classes.defaultCell };
            default:
                return {};
        }
    };

    const cellRenderer = (heading, row) => {
        switch (heading.id) {
            case 'logo':
                if (cardLogos[row.brand]) {
                    const SvgComp = cardLogos[row.brand];
                    return <SvgComp/>;
                }
                return '';
            case 'name':
                return <span className={classes.name}>{row.name} {row.number}</span>;
            case 'expiry_date':
                return format(parseISO(row.cc_expire), 'MM/yyyy');
            case 'date_added':
                return !row.created ? '--' : format(parseISO(row.created), 'MM/dd/yyyy');
            case 'default':
                if (row.is_default) return <Chip label="default"/>;
                else return '';
            case 'actions':
                const items = [
                    {
                        label: 'Make default', onClick: (e, row) => {
                            onSetDefault(e, row.id);
                        }, disabled: !!row.is_default
                    },
                    {
                        label: 'Edit nickname', onClick: (e, row) => {
                            onEditNickname(e, row);
                        }
                    },
                    {
                        label: 'Delete', onClick: (e, row) => {
                            onDelete(e, row.id, row.type);
                        }, disabled: row.is_deletable
                    }
                ];
                return <MoreMenu id="payment-menu" items={items} row={row}/>;
            default:
                return '';
        }
    };

    const filterFunction = rows => {
        if (!state.temporary.filter) return rows;
        return rows.filter(row => objContainsStr(row, ['number', 'name', 'brand'], state.temporary.filter));
    };

    return <Box>
        <PaymentTableTitle>Credit Cards</PaymentTableTitle>
        {!state.paymentMethods.cards || state.paymentMethods.cards.length === 0 ?
            (state.paymentMethods.isLoaded ?
                <NoDataFound>No cards in this account.</NoDataFound> : '') :
            <DataTable
                breakpointPaddings={{
                    xsUp: 16,
                    smUp: 24,
                    mdUp: 32
                }}
                headings={headings}
                rows={state.paymentMethods.cards}
                filterFunction={filterFunction}
                cellPropsGenerator={cellPropsGenerator}
                cellRenderer={cellRenderer}
            />}
    </Box>;
}

export default PaymentDataTableCards;
