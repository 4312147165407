import React from 'react';
import {Box, Divider, IconButton, Popover, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import mediaTypeDigitalOOH from 'images/mediaTypeDigitalOOH.svg';
import mediaTypeDirectMail from 'images/mediaTypeDirectMail.svg';
import mediaTypeMagazine from 'images/mediaTypeMagazine.svg';
import mediaTypeGoogle from 'images/mediaTypeGoogle.svg';
import mediaTypeGt from 'images/mediaTypeGt.svg';
import mediaTypeTV from 'images/mediaTypeTV.svg';
import defaultIcon from 'images/file.svg';
import {ReactComponent as DeleteIcon} from 'images/trash.svg';
import {ReactComponent as AlertIcon} from 'images/alert.svg';
import theme from 'theme/theme';

import {format, isBefore, isSameDay} from 'date-fns';
import {useParams} from "react-router";
import DataController from 'lib/controllers/DataController';
import OrderExtraAddonsSelect from 'components/elements/OrderPage/OrderSidebar/OrderExtraAddonsSelect';
import CheckoutOrderDetails from './CheckoutOrderDetails';
import CheckoutCTA from './CheckoutCTA';
import {useSnackbar} from "notistack";
import ItemSummary from './ItemSummary';
import CheckoutDateSelect from './CheckoutDateSelect';

const tvDesignServiceLink = 'https://www.addy.co/info/tv-commercial-design-on-addy';
const designServiceLink = 'https://www.addy.co/info/ad-design-on-addy';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 60,
    padding: '10px 24px',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      padding: '10px 16px',
    },
  },
  image: {
    display: 'block',
    width: 100,
    height: 100,
    borderRadius: 5,
    objectFit: 'cover',

    [theme.breakpoints.down('md')]: {
      width: 80,
      height: 80,
    },
  },
  icon: {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    flex: '0 0 30px',
    justifyContent: 'center',
    height: 30,
    width: 30,
    marginBottom: '8px',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '83px',
    [theme.breakpoints.down('sm')]: {
      '& p': {
        fontSize: 13,
        lineHeight: '18px',
      }
    },
  },
  priceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '27px',
    [theme.breakpoints.down('sm')]: {
      '& p': {
        fontSize: 15,
      }
    },
  },
  priceCaption: {
    fontSize: 15
  },
  priceTitle: {
    fontWeight: 800,
    fontSize: 15
  },
  iconTitle: {
    color: '#569992'
  },
  totalRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 15,
    color: theme.palette.neutral[900],
    borderBottom: `1px solid ${theme.palette.neutral[300]}`,
    minHeight: 48,
    margin: '-16px 24px',
    [theme.breakpoints.down('sm')]: {
      margin: '-16px 0',
      padding: '0 16px'
    },
  },
  wrapper: {
    margin: 0,
    padding: '0 24px'
  },
  divider: {
    margin: 0
  },
  itemWrapper: {
    display: 'flex',

  },
  itemFirst: {
    minHeight: 48,
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      order: 0
    },
  },
  errorElWrapper: {
    display: 'relative'
  },
  alertIconWrapper: {
    position: 'absolute',
    top: -15,
    left: -15
  },
  errorsPopoverWrapper: {
    backgroundColor: theme.palette.primary[500],
    maxWidth: 300,
    color: theme.palette.common.offWhite,
    padding: 10,
    fontSize: 15,
    lineHeight: '22px',

    '& ul': {
      paddingLeft: 20
    }
  },
  itemSecond: {
    minHeight: 48,
    [theme.breakpoints.down('md')]: {
      order: 1
    },
  },
  itemThird: {
    display: 'flex',
    width: '50%',
    padding: '0px 10px',
    marginLeft: 'auto',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      order: 2,
      padding: 0,
      marginTop: 20,
      width: '100%',
    },
  },
  dateWrapper: {
    color: theme.palette.neutral[900],
    fontWeight: 400,
    lineHeight: '27px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      '& p': {
        fontSize: 15,
        lineHeight: '18px',
      }
    },
  },
  dateText: {
    fontWeight: 800,
    fontSize: 15
  },
  popover: {
    pointerEvents: 'none',
  },
}));

const itemMeta = {
  printmag: {
    title: 'Magazine',
    color: theme.palette.accent.customerCoral[400],
    src: mediaTypeMagazine,
    inputLabel: 'Issue',
    format: 'MMM yyyy'
  },
  tv: {
    title: 'TV',
    color: theme.palette.accent.TV[500],
    src: mediaTypeTV,
    inputLabel: 'Start date',
    view: ['date'],
    format: 'MM/dd/yyyy'
  },
  eddm: {
    title: 'Direct Mail',
    color: theme.palette.accent.subscriberSunshine[500],
    src: mediaTypeDirectMail,
    view: ['date'],
    inputLabel: 'Delivery week of',
    format: 'MM/dd/yyyy',
  },
  dooh: {
    title: 'DOOH',
    color: theme.palette.active.main,
    src: mediaTypeDigitalOOH,
    inputLabel: 'Start date',
    format: 'MM/dd/yyyy',
    view: ['date'],
  },
  gt: {
    title: 'Online',
    color: theme.palette.accent.online[500],
    src: mediaTypeGt,
    inputLabel: 'Start date',
    format: 'MM/dd/yyyy',
    view: ['date'],
  },
  google: {
    title: 'Online',
    color: theme.palette.accent.buyerBlue[500],
    src: mediaTypeGoogle,
    inputLabel: 'Start date',
    format: 'MM/dd/yyyy',
    view: ['date'],
  },
  default: {
    title: '',
    color: theme.palette.accent.publicPink[500],
    src: defaultIcon,
    inputLabel: 'Issue',
    format: 'MM/dd/yyyy',
    view: ['date'],
  }
}

function CheckoutItem({
                        item,
                        updateItem,
                        hideAddon = true,
                        disableUpdate = false,
                        handleAddonChange,
                        showAddonPricing,
                        showCTA,
                        isInvoicePayment,
                        deleteCartItem,
                        disableDelete,
                        cartErrors,
                        dateErrors
                      }) {
  const classes = useStyles();
  const {cartId} = useParams();
  const iconInfo = itemMeta[item?.icon || "default"];
  const {enqueueSnackbar} = useSnackbar();

  const formatDate = (date, formatter = iconInfo.format) =>
    format(new Date(date), formatter);

  const getSelectedDate = () => {
    let startDate = (item?.date_start ?? item.issue)?.replaceAll("-", "/").split(" ")[0];
    return startDate.split("/").length > 2 ? startDate : `${startDate}/01`
  };
  const [selectedDate, setSelectedDate] = React.useState(
    new Date(getSelectedDate())
  );
  const [loading, setLoading] = React.useState(true);
  const [itemDates, setItemDates] = React.useState([]);
  const [dateLimit, setDateLimit] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [errAnchorEl, setErrAnchorEl] = React.useState(null);
  const [keyDates, setKeyDates] = React.useState({});

  const initAddons = () =>
    item.available_addons.map((availableAddon) => {
      if (!availableAddon.items) availableAddon.items = [];
      const items = [];
      item.addons.map((selectedAddon) => {
        if (selectedAddon.type === availableAddon.type) {
          items.push({
            ...selectedAddon,
          });
        }
      });
      availableAddon.items = items;
      return availableAddon;
    });

  const [selectedAddons, setSelectedAddons] = React.useState([]);

  React.useEffect(() => {
    !hideAddon &&
    handleAddonChange(cartId, item.id, item.addons, selectedAddons);
  }, [selectedAddons]);

  React.useEffect(() => {
    const selected = initAddons();
    setSelectedAddons(selected);
  }, [item]);

  React.useEffect(() => {
    updateKeyDates(selectedDate);
  }, [selectedDate]);

  const subTotal = item?.receipt_lines.filter(i => i.is_total)[0].value;

  const handleClick = (event) => {
    getDates(cartId, item.id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApplyClick = () => {
    setAnchorEl(null);
    updateItem(item.id, formatDate(selectedDate, "yyyy-MM-dd"));
  }

  const setDateLimits = (dates) => {
    const limit = {
      minDate: new Date(dates[0]?.date_start?.replaceAll("-", "/")),
      maxDate: new Date(dates[dates.length - 1]?.date_start?.replaceAll("-", "/")),
    };
    setDateLimit(limit);
    const date = item.media_type === 'printmag' ? item.issue : item?.date_start?.replaceAll("-", "/");
    const initialDate = new Date(date);
    if (isBefore(initialDate, limit.minDate)) {
      setSelectedDate(limit.minDate);
    } else {
      setSelectedDate(initialDate);
    }
  };

  const getDates = async (cartId, itemId) => {
    try {
      setLoading(true);
      const res = await DataController.getCartItemDates(cartId, itemId);
      if (res?.success) {
        setItemDates(res.data);
        setDateLimits(res.data);
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
      }
    } catch (e) {
      //handle error
    } finally {
      setLoading(false);
    }
  };

  const updateKeyDates = (date) => {
    const itemDate = itemDates.find((itemDate) => {
      const newDate = itemDate?.date_start?.replaceAll("-", "/");
      return isSameDay(date, new Date(newDate));
    });
    setKeyDates(itemDate ?? {});
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDeleteItem = () => {
    deleteCartItem(cartId, item.id)
  };

  const getDesignServiceLink = () => {
    return item.media_type === 'tv' ? tvDesignServiceLink : designServiceLink;
  };

  const errorEl = dateErrors[item.id] || cartErrors[item.id];
  const open = Boolean(errAnchorEl);
  const id = open ? 'date-errors-popover' : undefined;

  return (
    <Box>
      <Box className={classes.root}>
        <Box className={classes.itemFirst}>
          <Box className={classes.errorElWrapper}>
            <img className={classes.image} src={item.image} alt=""/>
            {!!errorEl && (
              <Box
                className={classes.alertIconWrapper}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={(e) => setErrAnchorEl(e.currentTarget)}
                onMouseLeave={() => setErrAnchorEl(null)}
              >
                <AlertIcon/>
              </Box>
            )}
            <Popover
              id={id}
              className={classes.popover}
              open={open}
              anchorEl={errAnchorEl}
              onClose={() => setErrAnchorEl(null)}
              anchorOrigin={{vertical: 'center', horizontal: 'right'}}
              transformOrigin={{vertical: 'top', horizontal: 'left'}}
              disableRestoreFocus
            >
              <Box className={classes.errorsPopoverWrapper}>
                <Box>{`This order has ${errorEl?.length} error`}{errorEl?.length > 1 && 's'}:</Box>
                <ul>
                  {errorEl?.map((el, key) => {
                    return (
                      <li key={key}>{el}</li>
                    )
                  })}
                </ul>
              </Box>
            </Popover>
          </Box>
          <ItemSummary item={item}/>
        </Box>
        <Box className={classes.itemThird}>
          {!disableUpdate && selectedDate &&
            <CheckoutDateSelect
              handleClick={handleClick}
              iconInfo={iconInfo}
              anchorEl={anchorEl}
              handleClose={handleClose}
              disableUpdate={disableUpdate}
              item={item}
              selectedDate={selectedDate}
              dateLimit={dateLimit}
              loading={loading}
              handleDateChange={handleDateChange}
              keyDates={keyDates}
              itemDates={itemDates}
              handleApplyClick={handleApplyClick}
            />
          }
          {disableUpdate &&
            <Box className={classes.dateWrapper}>
              <Typography>{iconInfo.inputLabel}</Typography>
              <Typography className={classes.dateText}>{formatDate(selectedDate)}</Typography>
            </Box>
          }
          <Box className={classes.priceWrapper}>
            <Typography className={classes.priceCaption}>{"Media price"}</Typography>
            <Typography className={classes.priceTitle}>
              {item?.price_formatted}
            </Typography>
          </Box>
          <Box className={classes.iconWrapper}>
            <img className={classes.icon} src={iconInfo.src} alt=""/>
            <Typography style={{color: iconInfo.color}}>
              {iconInfo.title}
            </Typography>
          </Box>
        </Box>
        {!disableDelete &&
          <Box className={classes.itemSecond}>
            <IconButton onClick={handleDeleteItem}>
              <DeleteIcon/>
            </IconButton>
          </Box>
        }
      </Box>
      {!hideAddon && (
        <Box>
          <OrderExtraAddonsSelect
            style={{'.MuiBox-root': {padding: 0}}}
            addons={item.available_addons}
            selected={selectedAddons}
            setSelected={setSelectedAddons}
            initAddons={initAddons}
            cartErrors={cartErrors}
            designServiceLink={getDesignServiceLink()}
            hideDeleteAll={true}
          />
          <Box className={classes.totalRow} px={1}>
            <span>sub total</span>
            <span>{subTotal}</span>
          </Box>
        </Box>
      )}
      <Box className={classes.wrapper}>
        {hideAddon && <Divider className={classes.divider}/>}
        {showAddonPricing && <CheckoutOrderDetails item={item}/>}
        {showCTA && (
          <CheckoutCTA
            order={item?.order}
            isInvoicePayment={isInvoicePayment}
          />
        )}
      </Box>
    </Box>
  );
}

export default CheckoutItem;
