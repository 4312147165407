import { ReactComponent as OhmGuyIcon } from 'images/ohm-guy.svg';
import { ReactComponent as PleaseWaitGraphic } from 'images/pleaseWait.svg';
import { Box, makeStyles, Typography, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    wrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 16,
    },
    image: {
        width: 260,
        height: 'auto',
        marginTop: 33,
        marginBottom: 33,
    },
    description: {
        marginTop: 16,
        marginBottom: 16,
    },
}))

export default function OrderExtraAddonsProcessing() {

    const classes = useStyles();

    return <Box className={classes.wrap}>
        <OhmGuyIcon className={classes.image} />
        <PleaseWaitGraphic />
        <Typography className={classes.description}>While we process your payment.</Typography>
        <CircularProgress color="secondary" />
    </Box>
}