import React from 'react';
import OrdersList from '../../../elements/OrdersPage/OrdersList.js';

function OrdersUpcomingPage() {
    return (
        <OrdersList status="open" title="Upcoming"/>
    );
}

export default OrdersUpcomingPage;
