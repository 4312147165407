import React, { useRef, useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { ReactComponent as PlayIcon } from 'images/play.svg';

const useStyles = makeStyles(theme => ({
    videoThumbWrap: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    playerWrap: {
        position: 'relative',
        paddingBottom: '56.25%',
        width: '100%'
    },

    videoElement: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        maxWidth: '100%',
        maxHeight: '100%'
    },

    progress: {
        position: 'absolute',
        width: 'calc(100% - 38px)',
        height: 4,
        left: 16,
        bottom: 20,
        background: 'rgba(255, 255, 255, 0.33)',
    },

    progressThumb: {
        position: 'absolute',
        width: 12,
        height: 12,
        backgroundColor: theme.palette.common.white,
        borderRadius: 12,
        top: -4,
        transform: 'translateX(-4px)',
        left: 'var(--ellapsed)',
        boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.12), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 3px 5px rgba(0, 0, 0, 0.2)',
        zIndex: 2,
    },

    ellapsed: {
        position: 'absolute',
        left: 0,
        top: 0,
        height: 4,
        background: theme.palette.common.white,
        width: 'var(--ellapsed)',
        zIndex: 1,
    },

    playIcon: {
        position: 'absolute',
        zIndex: 1,
        left: 10,
        bottom: 32
    }
}))

export default function VideoFileThumbnail({ file }) {
    const classes = useStyles();
    const videoRef = useRef();
    const [ellapsed, setEllapsed] = useState('50%');

    useEffect(() => {
        const setEllapsedTime = () => {
            const ellapsed = videoRef.current.currentTime / videoRef.current.duration;
            const ellapsedPercentage = Math.round(ellapsed * 100) + '%';
            setEllapsed(ellapsedPercentage);   
        }

        if (videoRef.current) {
            videoRef.current.addEventListener('timeupdate', setEllapsedTime);
        }
        
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('timeupdate', setEllapsedTime);
            }
        }
    }, [videoRef])
    

    const playVideo = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }

    const stopAndRewindVideo = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    }

    return (
        <Box 
            className={classes.videoThumbWrap}
            onMouseEnter={playVideo}
            onMouseLeave={stopAndRewindVideo}
        >
            <Box className={classes.playerWrap}>
                <video 
                    ref={videoRef}
                    muted
                    className={classes.videoElement}
                >
                    <source src={file.url} type="video/mp4" />
                </video>
                <Box className={classes.controls}>
                    <PlayIcon className={classes.playIcon} />
                    <Box className={classes.progress} style={{ '--ellapsed': ellapsed }}>
                        <Box className={classes.progressThumb}></Box>
                        <Box className={classes.ellapsed}></Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
