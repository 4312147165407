import { Box, Button, makeStyles } from "@material-ui/core";
import OrderSidebarRow from "./OrderSidebarRow";
import useLinkHandler from "../../../../lib/hooks/useLinkHandler";

const useStyles = makeStyles(theme => ({
    wrap: {
        padding: '24px 16px',

        [theme.breakpoints.up('xl')]: {
            padding: '24px 32px',
        },
    },
}))

export default function OrderSidebarTotals({ order }) {

    const classes = useStyles();
    const { getLinkButtonProps } = useLinkHandler();

    return <Box className={classes.wrap}>
        {order.totals.map((row, index) => (
            <OrderSidebarRow row={row} key={index} />
        ))}
        {!order.is_canceled && !order.is_paid &&
            <Box>
                <Button
                    variant="outlined"
                    fullWidth
                    {...getLinkButtonProps({url: order.invoice.invoice_uri})}
                >Pay now</Button>
            </Box>
        }
    </Box>
}
