import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import NeutrifStudioRegularTtf from '../../../assets/fonts/Neutrif-Studio-Regular.ttf';
import { stripeKey } from '../../../lib/config.js';

const stripePromise = loadStripe(stripeKey);

function StripeProvider({ children }) {
    return <Elements stripe={stripePromise}
                     options={{
                         fonts: [{
                             src: `url(${NeutrifStudioRegularTtf})`,
                             family: 'Neutrif Studio',
                             style: 'normal'
                         }]
                     }}>
        {children}
    </Elements>;
}

export default StripeProvider;