export const becomesIconButtonResponsive = theme => ({
    [theme.breakpoints.down('sm')]: {
        width: 72,
        minWidth: 0,

        '& .MuiButton-endIcon': {
            display: 'none'
        },

        '& svg, & .MuiButton-startIcon': {
            margin: 0
        }
    }
});