import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { actions } from 'store/store.js';
import { formatErrorObj } from 'lib/functions';
import DataController from 'lib/controllers/DataController.js';
import FieldGroup from 'components/elements/form/FieldGroup';
import useAppState from '../../../lib/hooks/useAppState.js';
import useFormControl from '../../../lib/hooks/useFormControl.js';
import { useSnackbar } from "notistack";

const defaultForm = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    id: ''
};

export default function TeamEditForm({ teamMember }) {

    const { enqueueSnackbar } = useSnackbar();

    const { dispatch } = useAppState();

    const {
        getFieldProps,
        setErrors,
        formData,
        renderSubmitButton,
        setIsWorking
    } = useFormControl({
        defaultForm,
        loadValues: teamMember,
        SubmitButtonProps: {
            label: 'Save'
        }
    });

    const handleProfileSubmit = (e) => {
        e.preventDefault();

        setErrors({});
        setIsWorking(true);

        DataController
            .postTeamMember(formData)
            .then(({ data, errors, message, success }) => {
                if (success) {
                    dispatch({
                        type: actions.UPDATE_ROW,
                        payload: {
                            location: 'myTeam.contacts',
                            data: data
                        }
                    });
                    enqueueSnackbar('Team member updated successfully!');
                } else {
                    if (Object.keys(errors).length > 0) {
                        const errorObject = formatErrorObj(errors);
                        setErrors(errorObject);
                    } else {
                        enqueueSnackbar(message, {
                            variant: 'error',
                        });
                    }
                }
                setIsWorking(false);
            });
    };

    return (
        <form
            autoComplete="off"
            noValidate
            onSubmit={handleProfileSubmit}
        >
            <FieldGroup>
                <TextField
                    label="First name"
                    required
                    {...getFieldProps('firstname')}
                />
                <TextField
                    label="Last name"
                    required
                    {...getFieldProps('lastname')}
                />
            </FieldGroup>

            <FieldGroup>
                <TextField
                    label="Email"
                    required
                    disabled
                    {...getFieldProps('email')}
                />
                <TextField
                    label="Phone"
                    {...getFieldProps('phone')}
                />
            </FieldGroup>

            <Box mt={3}>
                {renderSubmitButton}
            </Box>

        </form>
    );
}
