import CustomDialog from '../common/CustomDialog.js';
import FieldGroup from '../form/FieldGroup.js';
import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect } from 'react';
import DataController from '../../../lib/controllers/DataController.js';
import { actions } from '../../../store/store.js';
import useAppState from '../../../lib/hooks/useAppState.js';
import { useSnackbar } from "notistack";

function PaymentMethodNicknameDialog({ openRow, setOpenRow }) {
    
    const { dispatch } = useAppState();
    const { enqueueSnackbar } = useSnackbar();
    const [nick, setNick] = useState('');

    const handleClose = e => {
        e && e.preventDefault();
        setOpenRow(null);
    };

    const handleUpdate = e => {
        DataController.paymentMethodEdit(openRow.id, { nickname: nick }).then(res => {
            console.log('[paymentMethodEdit]', res);
            if (res && res.success) {
                dispatch({
                    type: actions.UPDATE_ROW,
                    payload: {
                        location: openRow.type === 'stripe' ? 'paymentMethods.cards' : 'paymentMethods.banks',
                        data: res.data
                    }
                });
                enqueueSnackbar('Payment method nickname updated');
            } else {
                enqueueSnackbar(res.message || 'Could not update payment method nickname.', {
                    variant: 'error',
                });
            }
        });
        window.setTimeout(() => {
            setNick('');
        }, 300);
        handleClose(e);
    };

    const handleChange = e => {
        setNick(e.target.value);
    };

    useEffect(() => {
        if (!!openRow) {
            setNick(openRow.cc_nickname || '');
        }
    }, [openRow]);

    return <CustomDialog
        title="Edit Nickname"
        maxWidth="xs"
        onClose={handleClose}
        hasXClose
        disableBackdropClick
        actions={[
            {
                label: 'Cancel',
                fullWidth: false,
                variant: 'contained',
                color: 'secondary',
                style: { marginRight: '20px' },
                onClick: {handleClose}
            },
            { 
                label: 'Update', 
                onClick: handleUpdate, 
                fullWidth: false 
            }
        ]}
        isOpen={!!openRow}>
        <FieldGroup>
            <TextField value={nick}
                       onChange={handleChange}
                       label="Nickname"
                       autoFocus/>
        </FieldGroup>
    </CustomDialog>;
}

export default PaymentMethodNicknameDialog;