import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as ChevronIcon } from 'images/chevron-down.svg';
import { ReactComponent as ImageIcon } from 'images/imageIcon.svg';
import { IconButton, Typography } from '@material-ui/core';
import FileSummary from './FileSummary';

const useStyles = makeStyles(theme => ({
    wrap: props => ({
        width: props.open ? 250 : 48,
        background: theme.palette.accent.marketMint[100],
        border: `1px solid ${theme.palette.neutral[400]}`,
        transition: 'width .5s ease-in-out',
        overflowX: 'hidden',
        marginRight: -16,
        color: theme.palette.neutral[800],

        display: 'none',

        [theme.breakpoints.up('tb')]: {
            marginRight: -24,
            display: 'block'
        },

        [theme.breakpoints.up('md')]: {
            marginRight: -32
        },
    }),

    toggle: {
        height: 48,
    },

    toggleBtn: {
        width: 48,
        height: 48,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '&:hover': {
            textDecoration: 'none',
        }
    },

    chevron: props => ({
        transform: props.open ? 'rotate(-90deg)' : 'rotate(450deg)',
        transition: 'transform .5s ease-in-out',

        '& path': {
            stroke: theme.palette.primary.main,
        }
    }),

    content: props => ({
        width: 250,
        opacity: props.open ? 1 : 0,
        transform: props.open ? 'translateX(0)' : 'translateX(50px)',
        transition: 'all .5s ease-in-out',
        padding: '0 12px'
    }),

    noImageBox: {
        height: 226,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.white
    },

    imageIcon: {
        marginBottom: 30,
    },

    title: {
        fontSize: 15,
        fontWeight: 800,
        color: theme.palette.neutral[900],
        marginBottom: 16
    },
}))

export default function FilesSidebar({open, setOpen, selectedFile}) {
    const classes = useStyles({open});

    return (
        <Box className={classes.wrap}>
            <Box className={classes.toggle}>
                <IconButton className={classes.toggleBtn} onClick={() => setOpen(val => !val)}><ChevronIcon className={classes.chevron} /></IconButton>
            </Box>
            <Box className={classes.content}>
                {!selectedFile 
                    ? <Box>
                        <Box className={classes.noImageBox}>
                            <ImageIcon className={classes.imageIcon} />
                            <div>Select an ad file to preview</div>
                        </Box>
                        <Divider />
                        <Typography className={classes.title}>File properties</Typography>
                        <div style={{textAlign: 'center'}}>no file selected</div>
                        <Divider />
                    </Box> 
                    : <FileSummary selectedFile={selectedFile} />
                }
            </Box>
        </Box>
    )
}
