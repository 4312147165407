import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme/theme.js';
import LoginPage from 'components/pages/LoginPage';
import { StateProvider } from './store/store.js';
import { Footer, MainNavBar } from 'components/layout';
import NotFoundPage from 'components/pages/NotFoundPage.js';
import TestPage from './components/pages/TestPage.js';
import InvoicePage from './components/pages/InvoicePage.js';
import AccountIndex from 'components/pages/dashboard/AccountIndex.js';
import useGlobalStyles from './lib/hooks/useGlobalStyles.js';
import useAppState from './lib/hooks/useAppState.js';
import Dashboard from './components/layout/Dashboard.js';
import InvoiceLayout from './components/layout/InvoiceLayout.js';
import Container from '@material-ui/core/Container';
import SnackbarWrapper from 'components/layout/SnackbarWrapper.js';
import ClientsPage from 'components/pages/dashboard/ClientsPage.js';
import ClientEditPage from 'components/pages/dashboard/clients/ClientEditPage.js';
import BillingPage from 'components/pages/dashboard/BillingPage.js';
import CheckoutPage from 'components/pages/dashboard/CheckoutPage.js';
import OrderLayout from 'components/layout/OrderLayout.js';
import OrderPage from 'components/pages/dashboard/OrderPage.js';
import OrdersAllPage from './components/pages/dashboard/orders/OrdersAllPage.js';
import OrdersUpcomingPage from './components/pages/dashboard/orders/OrdersUpcomingPage.js';
import OrdersInProcessPage from './components/pages/dashboard/orders/OrdersInProcessPage.js';
import OrdersHistoryPage from './components/pages/dashboard/orders/OrdersHistoryPage.js';
import OrdersCanceledPage from './components/pages/dashboard/orders/OrdersCanceledPage.js';
import CustomEventHandler from './components/elements/functional/CustomEventHandler.js';
import ThrottledModal from './components/elements/common/ThrottledModal.js';
import HashListener from './components/elements/functional/HashListener.js';
import IntercomBoot from './components/elements/functional/IntercomBoot.js';
import { INTERCOM_APP_ID } from './lib/config';
import { useHistory } from 'react-router-dom';
import TeamPage from 'components/pages/dashboard/TeamPage.js';
import TeamInvitePage from 'components/pages/dashboard/team/TeamInvitePage.js';
import TeamEditPage from 'components/pages/dashboard/team/TeamEditPage.js';
import actions from 'store/actions';
import LoadHotjar from './components/elements/functional/LoadHotjar';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { LocalizationProvider } from '@material-ui/pickers';
import FilesPage from 'components/pages/dashboard/FilesPage.js';
import FilePage from 'components/pages/dashboard/FilePage.js';
import ABDMPage from 'components/pages/ABDMPage.js';

const Logout = () => {
    const { dispatch } = useAppState();
    dispatch({
        type: actions.LOGOUT
    });
    return null;
};

const ProtectedRoute = (props) => {
    const history = useHistory();
    const { state } = useAppState();
    const authenticated = !!state.token;

    if (!authenticated) {
        window.setTimeout(() => {
            history.push(`/login?goto=${props.location.pathname}`);
        }, 1);
    }

    return <Route
        {...props}
    />;
};

export default function App() {
    useGlobalStyles();

    return (
        <Router>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
                <IntercomProvider appId={INTERCOM_APP_ID}>
                    <StateProvider>
                        <LoadHotjar />
                        <IntercomBoot/>
                        <ThemeProvider theme={theme}>
                            <CssBaseline/>
                            <CustomEventHandler/>
                            <HashListener/>
                            <ThrottledModal/>
                            <MainNavBar/>
                            <SnackbarWrapper>
                                <Container component="main" id="app-root">
                                    <Switch>
                                        <Route exact path="/">
                                            <Redirect to="/login"/>
                                        </Route>

                                        <Route path="/login" exact>
                                            <LoginPage/>
                                        </Route>

                                        <Route path="/logout" exact>
                                            <Logout/>
                                        </Route>

                                        <Route path="/password-reset" exact>
                                            <LoginPage/>
                                        </Route>

                                        <Route path="/test" exact>
                                            <TestPage/>
                                        </Route>

                                        <Route path="/abdm" exact>
                                            <ABDMPage/>
                                        </Route>

                                        <Route path="/invoice/view/:cartId/:orderId" exact>
                                            <InvoiceLayout>
                                                <InvoicePage/>
                                            </InvoiceLayout>
                                        </Route>

                                        <Route exact path="/checkout/:cartId">
                                            <CheckoutPage/>
                                        </Route>

                                        <ProtectedRoute path="/account">
                                            <Dashboard>
                                                <AccountIndex/>
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/clients/:id/edit">
                                            <Dashboard>
                                                <ClientEditPage/>
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/clients">
                                            <Dashboard>
                                                <ClientsPage/>
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/billing">
                                            <Dashboard>
                                                <BillingPage/>
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/order/:id/:action?">
                                            <OrderLayout>
                                                <OrderPage/>
                                            </OrderLayout>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/orders/all">
                                            <Dashboard>
                                                <OrdersAllPage/>
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/orders">
                                            <Dashboard>
                                                <OrdersAllPage/>
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/orders/upcoming">
                                            <Dashboard>
                                                <OrdersUpcomingPage/>
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/orders/inprocess">
                                            <Dashboard>
                                                <OrdersInProcessPage/>
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/orders/history">
                                            <Dashboard>
                                                <OrdersHistoryPage/>
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/orders/canceled">
                                            <Dashboard>
                                                <OrdersCanceledPage/>
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/team">
                                            <Dashboard>
                                                <TeamPage/>
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/team/invite">
                                            <Dashboard>
                                                <TeamInvitePage/>
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/team/:id/edit">
                                            <Dashboard>
                                                <TeamEditPage/>
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/files">
                                            <Dashboard>
                                                <FilesPage />
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <ProtectedRoute exact path="/file/:id">
                                            <Dashboard>
                                                <FilePage />
                                            </Dashboard>
                                        </ProtectedRoute>

                                        <Route>
                                            <NotFoundPage/>
                                        </Route>
                                    </Switch>
                                </Container>
                            </SnackbarWrapper>

                            <Footer/>

                        </ThemeProvider>
                    </StateProvider>
                </IntercomProvider>
            </LocalizationProvider>
        </Router>
    );
}
