import { ReactComponent as CopyClipboard } from 'images/copyClipboard.svg';
import { ReactComponent as CopyClipboardDone } from 'images/copyClipboardDone.svg';
import React, { useState } from 'react';
import { copyTextToClipboard } from '../../../lib/functions.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-flex',
        alignItems: 'center',

        '& svg': {
            marginRight: 20
        }
    }
}));

function CopyRowButton({ text }) {

    const classes = useStyles();
    const [copied, setCopied] = useState(false);

    const handleClick = e => {
        e.preventDefault();
        const res = copyTextToClipboard(text);

        if (res) {
            setCopied(true);
            window.setTimeout(() => {
                setCopied(false);
            }, 5000);
        }
    };

    return <a href="#!" onClick={handleClick} className={classes.root}>
        {copied ?
            <><CopyClipboardDone/>copied to clipboard</> : <>
                <CopyClipboard/>click to copy</>}
    </a>;
}

export default CopyRowButton;