import React, { useState, useEffect } from "react";
import { Dialog, Divider, DialogActions, DialogContent, DialogTitle, Typography, Button,  } from "@material-ui/core";
import LoadingButton from "components/elements/common/LoadingButton";
import useData from "lib/hooks/useData";
import MultiSelectList from "components/elements/common/MultiSelectList";
import DataController from "lib/controllers/DataController";
import useAppState from "lib/hooks/useAppState";
import actions from "store/actions";
import { useSnackbar } from "notistack";

export default function OrderAddonsAddressDialog({open, handleClose, addon }) {
    const [loading, setLoading] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState([]);

    const { dispatch } = useAppState();

    const { enqueueSnackbar } = useSnackbar();

    const state = useData({
        queries: ['getMyAddresses'],
        setLoadingState: true 
    });

    useEffect(() => {
        if (addon && addon.addresses && addon.addresses.length && addon.addresses.length > 0) {
            console.log(addon.addresses[0].id)
            setSelectedAddress([ addon.addresses[0].id ])
        }
    }, [addon])

    const handleUpdateAddress = async (e) => {

        e.preventDefault();
        setLoading(true);

        if (!selectedAddress || !selectedAddress[0]) return

        DataController
            .updatePurchasedAddon(addon.id, { address: selectedAddress[0] })
            .then(({ data, errors, message, success }) => {
                if (success) {
                    dispatch({
                        type: actions.SET_CURRENT_ORDER,
                        payload: data
                    });
                    enqueueSnackbar('Address updated successfully!');
                    handleClose();
                    setLoading(false);
                } else {
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                    setLoading(false);
                }
            })
    };

    const itemText = item => {
        return `${item.street} ${item.city}, ${item.state} ${item.zip}`
    }

    return (
        <Dialog
            onClose={() => {
                handleClose();
            }}
            open={open}
        >
            <DialogTitle disableTypography>
                <Typography variant="h4">Edit Delivery Address</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <MultiSelectList
                    items={state.addresses.addresses}
                    itemsLoaded={state.addresses.isLoaded}
                    noItemsText={'No addresses found.'}
                    selected={selectedAddress}
                    setSelected={setSelectedAddress}
                    itemTextFormater={item => itemText(item)}
                    allowMultiselect={false}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={false}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    color="default"
                    type="submit"
                    disabled={false}
                    onClick={handleUpdateAddress}
                    loading={loading}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}