import React, {useState, useEffect} from 'react';
import useQueryParams from 'lib/hooks/useQueryParams';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Divider } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AccountSectionHeader from 'components/elements/common/AccountSectionHeader';
import useData from 'lib/hooks/useData';
import FilesFilter from 'components/elements/FilesPage/FilesFilter';
import Pagination from '@material-ui/lab/Pagination';
import SkeletonTable from 'components/elements/common/SkeletonTable';
import FilesTable from 'components/elements/FilesPage/FilesTable';
import FilesSidebar from 'components/elements/FilesPage/FilesSidebar';
import FilesGrid from 'components/elements/FilesPage/FilesGrid';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
    pagination: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        padding: '12px 0',

        [theme.breakpoints.up('sm')]: {
            padding: '24px 0'
        }
    },

    tableWrapper: {
        width: '100%',

        [theme.breakpoints.up('tb')]: {
            width: 'calc(100% - 250px)',
            flex: 1,
        }
    }
}))

export default function FilesPage() {
    const classes = useStyles();
    const history = useHistory();
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const { replaceQueryParams, queryParams } = useQueryParams();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [filters, setFilters] = useState({
        media_type: queryParams.media_type || '',
        favorite: queryParams.favorite || '',
        sort: queryParams.sort || 'last_used',
        page: queryParams.page || 1,
        prevent: false
    });

    const [view, setView] = useState();

    const [selectedFile, setSelectedFile] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(true);

    useEffect(() => {
      const storedView = localStorage.getItem('ad_files_view');
      setView(storedView ?? 'list');
    }, [])

    const state = useData({
        queries: [['getFiles', [filters.media_type, filters.favorite, filters.sort, filters.page]]],
        setLoadingState: true,
        prevent: filters.prevent,
        cbAfter: () => {
            setSkeletonLoading(false);
            replaceQueryParams(filters);
        },
        cbBefore: () => {
            setSkeletonLoading(true);
        }
    });

    const handlePageChange = (e, value) => {
        setFilters(({ media_type, favorite, sort }) => ({
            media_type,
            favorite,
            sort,
            page: value,
            prevent: false
        }));
    };

    console.log(state.files.meta)

    const onItemClick = (row, e) => {
        const windowWidth = window.innerWidth;

        if (windowWidth > 768) {
            setSelectedFile(row);
            setSidebarOpen(true);
        } else {
            let path = e.nativeEvent.composedPath();

            for (let i = 0; i < path.length; i++) {
                const el = path[i];

                if (el.getAttribute) {
                    if (el.getAttribute('data-redirect-on-click')) {
                        history.push(`/file/${row.id}`);
                    }
                }
            }
        }
    }

    return (
        <Box>
            <AccountSectionHeader title="Ad Files" />
            <Divider />

            {skeletonLoading 
                ?   <SkeletonTable/> 
                :   <>  
                        <Box mb={4}>
                            <FilesFilter 
                                filters={filters}
                                setFilters={setFilters} 
                                view={view}
                                setView={setView}
                            />
                        </Box>
                        <Box display="flex">
                            {view === 'list' &&
                                <Box className={classes.tableWrapper}>
                                    <FilesTable onRowClick={(e, row) => onItemClick(row, e)} selectedRow={selectedFile} />
                                </Box>
                            }
                            {view === 'grid' &&
                                <Box flex={1} width="100%">
                                    <FilesGrid onItemClick={(item) => onItemClick(item)} selectedItem={selectedFile} /> 
                                </Box>
                            }
                            <FilesSidebar 
                                open={sidebarOpen} 
                                setOpen={setSidebarOpen} 
                                selectedFile={selectedFile} 
                            />
                        </Box>
                        {state.files?.meta?.count && state.files?.meta?.count > 0 
                            ?   <Box className={classes.pagination}>
                                    <Pagination
                                        boundaryCount={isSmallScreen ? 1 : undefined}
                                        count={state.files?.meta?.total_pages || 1}
                                        onChange={handlePageChange}
                                        page={state.files?.meta?.page || 1}
                                        siblingCount={isSmallScreen ? 0 : undefined}
                                    />
                                </Box> 
                            : ''
                        }
                    </>
            }

            
        </Box>
    )
}
