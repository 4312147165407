import React from 'react';
import OrdersList from '../../../elements/OrdersPage/OrdersList.js';

function OrdersCanceledPage() {
    return (
        <OrdersList status="canceled" title="Canceled Orders"/>
    );
}

export default OrdersCanceledPage;
