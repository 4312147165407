import React, { useState, useRef } from "react";
import { Box, Chip, makeStyles, Typography } from "@material-ui/core";
import theme from "theme/theme";

const useStyles = makeStyles((theme) => ({
    wrap: {
        border: `1px solid ${theme.palette.neutral[400]}`,
        borderRadius: 10,
        padding: '8px 12px',
        marginBottom: 24,
        minHeight: 154,
        fontSize: 15,
        position: 'relative',
        backgroundColor: theme.palette.common.offWhite,

        '&:hover': {
            cursor: 'text',
        }
    },

    inner: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        justifyContent: 'start',
        alignItems: 'flex-start',
    },

    chip: {
        marginRight: 8,
        marginBottom: 8,
        backgroundColor: theme.palette.primary.main,

        '& .MuiChip': {
            '&-label': {
                fontSize: 15,
            },
        },

        '& .MuiChip-deleteIcon': {
            fill: theme.palette.common.offWhite,
            transition: 'fill 0.1s ease-in-out',

            '&:hover': {
                fill: theme.palette.neutral[400],
            }
        }
    },

    input: {
        fontSize: 15,
        minWidth: 300,
        height: 28,
        lineHeight: 28,
        border: 'none',
        outline: 'none',
        backgroundImage: 'none !important',
        backgroundColor: theme.palette.common.offWhite,
    },

    error: {
        position: 'absolute',
        bottom: -18,
        left: 0,
        color: theme.palette.error.main,
        fontSize: 12,
    }
}));

export default function EmailTextarea({items, setItems}) {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [error, setError] = useState(null);
    const [focused, setFocused] = useState(false);
    const [deleteLast, setDeleteLast] = useState(false);
    const inputRef = useRef();

    const handleKeyDown = evt => {
        if (evt.key === 'Backspace') {
            if (value === '') {
                evt.preventDefault();
                if (deleteLast) {
                    setItems(items.slice(0, -1));
                    setDeleteLast(false);
                } else {
                    setDeleteLast(true);
                }
            }
        }
        else if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            tryAddingEmailToArray();
        }
        else {
            setDeleteLast(false)
        }
    };

    const tryAddingEmailToArray = () => {
        const email = value.trim();

        if (email && isValid(email)) {
            setItems([...items, email]);
            setValue('');
        }
    }

    const isValid = email => {
        let error = null;

        if (isInList(email)) {
            error = `"${email}" has already been added.`;
        }

        if (!isEmail(email)) {
            error = `"${email}" is not a valid email address.`;
        }

        if (error) {
            setError(error);
            return false;
        }

        return true;
    }

    const isInList = email => {
        return items.includes(email);
    }

    const isEmail = email => {
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    }

    const handleChange = evt => {
        setValue(evt.target.value);
        setError(null);
    };

    const handlePaste = evt => {
        evt.preventDefault();

        var paste = evt.clipboardData.getData("text");
        var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

        if (emails) {
            var toBeAdded = emails.filter(email => !isInList(email));
            setItems([...items, ...toBeAdded]);
        }
    };

    const handleDelete = email => {
        setDeleteLast(false);
        setItems(items.filter(item => item !== email))
    }

    const handleFocus = () => {
        setDeleteLast(false);
        if (inputRef.current) {
            inputRef.current.focus();
            setFocused(true);
        }
    }

    const handleBlur = () => {
        setFocused(false);
        tryAddingEmailToArray();
    }

    const borderColor = () => {
        if (error) return theme.palette.error.main;
        if (focused) return theme.palette.primary.main;
        return theme.palette.neutral[400]
    }

    return (
        <Box className={classes.wrap} onClick={handleFocus} style={{ borderColor: borderColor() }}>
            <Box className={classes.inner}>
                {items.map((email, index) => {return (
                    <Chip
                        key={email}
                        label={email}
                        onDelete={() => { handleDelete(email) }}
                        className={classes.chip}
                        style={{backgroundColor: index === items.length - 1 && deleteLast ? theme.palette.neutral[700] : ''}}
                    />
                )})}
                <input
                    type="text"
                    ref={inputRef}
                    value={value}
                    className={classes.input}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    onPaste={handlePaste}
                    onBlur={handleBlur}
                />
            </Box>
            {error &&
                <Typography className={classes.error}>{error}</Typography>
            }
        </Box>
    )
}
