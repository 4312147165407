import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import { ReactComponent as DownloadDark } from 'images/downloadDark.svg';
import useAppState from 'lib/hooks/useAppState.js';
import DataController from 'lib/controllers/DataController';
import DataTable from 'components/elements/common/DataTable';
import classNames from "classnames";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as DownIcon } from 'images/chevron-down-line.svg';

const useStyles = makeStyles(theme => ({
    button: {
        padding: 2,
        minWidth: 77,
        marginLeft: 2,
        border: `1px solid ${theme.palette.neutral[900]}`,

        '& span': {
            fontSize: 15
        }
    },
    buttonText: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    invoiceDownload: {
        borderWidth: 1,
        padding: '2px 10px',
        marginLeft: 10,
        minWidth: 77,

        '& span': {
            fontSize: 15
        },

        '&.Mui-disabled': {
            '& svg path': {
                stroke: `${theme.palette.neutral[600]} !important`
            }
        }
    },
    invoiceDownloadIcon: {
        '& path': {
            stroke: theme.palette.primary.main
        }
    },
    downIcon: {
        width: 18,
        marginLeft: 4,
        marginRight: '0px !important',
    },
    menu: {
        transform: 'translate(0%, 6%)',
        boxShadow: 'none !important',

        '& .MuiPopover-paper': {
            color: theme.palette.primary.main,
            boxShadow: 'none !important',
        },

        '& .MuiListItem-root': {
            minWidth: 196,
            boxShadow: 'none !important',
        }
    },
    menuItemContent: {
        display: 'flex',
        boxShadow: 'none !important',
    },
    iconButton: {
        padding: 2,
        border: `1px solid ${theme.palette.neutral[900]}`,
    },
    chiplabel: {
        borderColor: theme.palette.text.primary,

        '& span': {
            color: theme.palette.text.primary
        }
    },
    chipOutlined: {
        background: theme.palette.common.white,
        borderColor: theme.palette.accent.subscriberSunshine[500],

        '& span': {
            color: theme.palette.accent.subscriberSunshine[500]
        }
    },
    chipPrimary: {
        borderColor: theme.palette.accent.buyerBlue[600],

        '& span': {
            color: theme.palette.accent.buyerBlue[600]
        }
    },
    chipSecondary: {
        borderColor: theme.palette.accent.customerCoral[500],

        '& span': {
            color: theme.palette.accent.customerCoral[500]
        }
    },
    chipDisabled: {
        borderColor: theme.palette.neutral[600],

        '& span': {
            color: theme.palette.neutral[600]
        }
    },
    adDescription: {
        color: theme.palette.neutral[900],
        fontSize: 20,
        lineHeight: '25px',
        marginBottom: 4,
        fontWeight: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    adQty: {
        color: theme.palette.neutral[800],
        fontSize: 13,
        overflow: 'hidden',
        lineHeight: '18px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    adRun: {
        color: theme.palette.neutral[800],
        fontSize: 13,
        overflow: 'hidden',
        lineHeight: '18px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    invoiceNumberWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',

        '& div': {
            marginBottom: 5
        }
    },
    textCell: {
        maxWidth: 430,
        minWidth: 340
    },
    pdfUrlCell: {
        width: 330,
        whiteSpace: 'normal'
    },
    paymentDateCell: {
        textAlign: 'center'
    }
}));

const headings = [
    {
        id: 'id',
        label: 'Order',
        key: 'id'
    },
    {
        breakpoint: 'md',
        id: 'order_date',
        label: 'Date',
        key: 'order_date'
    },
    {
        breakpoint: 'sm',
        id: 'ad_description',
        label: 'Name',
        key: 'ad_description'
    },
    {
        breakpoint: 'md',
        id: 'due_date',
        label: 'Due',
        key: 'due_date'
    },
    {
        breakpoint: 'md',
        id: 'payment_date',
        label: 'Paid on',
        key: 'payment_date'
    },
    {
        id: 'total',
        label: 'Total',
        key: 'total',
    },
    {
        id: 'pdf_url',
        label: '',
        key: 'pdf_url'
    },
];

const statusMap = {
    Open: 'default',
    Overdue: 'secondary',
    Paid: 'primary'
};

function BillingTable({ }) {

    const classes = useStyles();

    const { state } = useAppState();
    const { enqueueSnackbar } = useSnackbar();

    const [activeMenuEl, setActiveMenuEl] = useState(null);
    const [activeMenuId, setActiveMenuId] = useState('');

    const handleOpenMenu = (e, id) => {
        e.preventDefault();
        setActiveMenuEl(e.currentTarget);
        setActiveMenuId(id);
    };

    const handleCloseMenu = (e) => {
        e && e.preventDefault();
        setActiveMenuEl(null);
        setActiveMenuId('');
    };

    const downloadPdf = (invoiceNumber, pdfDialogUrl) => {
        if (!invoiceNumber) {
            return;
        }

        const filename = pdfDialogUrl.split('/')[9];

        DataController
            .downloadPdf(pdfDialogUrl, invoiceNumber)
            .then((body) => body.blob())
            .then((blob) => URL.createObjectURL(blob))
            .then(href => {
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.href = href;
                a.style = 'display: none';
                a.download = `${filename}.pdf`;
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(href);
            })
            .catch(() => {
                enqueueSnackbar('Error while downloading', {
                    variant: 'error'
                });
            });
    };

    const renderCell = ({ key }, data) => {

        switch (key) {
            case 'id':
                return (
                    <div className={classes.invoiceNumberWrapper}>
                        <div>{`#${data.id}`}</div>
                        <Chip
                            classes={{
                                disabled: classes.chipDisabled,
                                label: classes.chiplabel,
                                outlined: classes.chipOutlined,
                                outlinedPrimary: classes.chipPrimary,
                                outlinedSecondary: classes.chipSecondary
                            }}
                            color={statusMap[data.status]}
                            disabled={data.status === 'Canceled'}
                            label={data.status ? data.status.toLowerCase() : ''}
                            variant="outlined"
                        />
                    </div>
                );
            case 'ad_description':
                return (
                    <Box maxWidth={340}>
                        <Typography className={classes.adDescription}>
                            {data.summary_title}
                        </Typography>
                        <Typography className={classes.adRun}>
                            Company: {data.company}
                        </Typography>
                        <Typography className={classes.adRun}>
                            {data.summary_line_1}
                        </Typography>
                        <Typography className={classes.adQty}>
                            {data.summary_line_2}
                        </Typography>
                    </Box>
                );
            case 'pdf_url':
                return (
                    <Box display='flex' justifyContent='flex-end'>
                        {
                            data.invoice_url && data.button_label ? (
                                <Button
                                    className={classes.button}
                                    href={data.invoice_url}
                                    target="_blank"
                                    variant={data.button_label === 'View' ? 'outlined' : 'contained'}
                                >
                                    {data.button_label}
                                </Button>
                            ) : null
                        }
                        {data.pdf_url_addy && data.pdf_button_label ?
                            <>
                                <Button
                                    className={classNames(classes.iconButton, classes.invoiceDownload)}
                                    variant='outlined'
                                    onClick={state.userData.is_agency ? (ev) => handleOpenMenu(ev, data.id, data) : () => downloadPdf(data.id, data.pdf_url_addy)}
                                >
                                    <DownloadDark className={classes.invoiceDownloadIcon} />
                                    <span className={classes.buttonText}>{data.pdf_button_label}</span>
                                    {state.userData.is_agency &&
                                        <>
                                            <DownIcon className={classes.downIcon} />
                                        </>
                                    }

                                </Button>
                                <Menu
                                    id={data.id}
                                    anchorEl={activeMenuEl}
                                    open={Boolean(activeMenuEl) && (!data || activeMenuId === data.id)}
                                    onClose={handleCloseMenu}
                                    className={classes.menu}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                                >
                                    <MenuItem onClick={() => downloadPdf(data.id, data.pdf_url_addy)}>Your Addy Invoice PDF</MenuItem>
                                    <MenuItem onClick={() => downloadPdf(data.id, data.pdf_url_agency)}>Client-Facing Gross Invoice PDF</MenuItem>
                                </Menu>
                            </> : null
                        }
                    </Box>
                );
            default:
                return data[key] || '-';
        }
    };

    const cellPropsGenerator = (heading, row) => {
        switch (heading.id) {
            case 'ad_description':
                return {
                    className: classNames(classes.textCell)
                };
            case 'pdf_url':
                return { className: classes.pdfUrlCell };
            case 'payment_date':
                return { className: classes.paymentDateCell };
        }
    };

    return (
        <>
            <DataTable
                breakpointPaddings={{
                    xsUp: 16,
                    smUp: 24,
                    mdUp: 32
                }}
                cellPropsGenerator={cellPropsGenerator}
                cellRenderer={renderCell}
                headings={headings}
                rows={state.billing.data}
                tooManyFilters={state.billing?.meta?.is_filtered === true && state.billing.data.length === 0}
            />
        </>
    );
}

export default BillingTable;
