import React from 'react';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import useAppState from 'lib/hooks/useAppState.js';
import DataTable from 'components/elements/common/DataTable';
import MediaTypeIcon from '../common/MediaTypeIcon';
import OrderThumbnail from './OrderThumbnail';
import Button from '@material-ui/core/Button';
import useLinkHandler from '../../../lib/hooks/useLinkHandler.js';
import OrderSummaryText from '../OrderPage/OrderSummaryText.js';
import {ReactComponent as UploadIcon} from "../../../images/btn-upload.svg";
import {ReactComponent as PaymentIcon} from "../../../images/btn-payment.svg";
import {ReactComponent as ReplaceIcon} from "../../../images/btn-replace.svg";
import {ReactComponent as InfoIcon} from "../../../images/btn-info.svg";
import {ReactComponent as CheckmarkIcon} from "../../../images/btn-checkmark.svg";

const useStyles = makeStyles(theme => ({
    logoCell: {
        maxWidth: 142,
        width: 142
    },
    canceledCell: {
        opacity: .3
    },
    textCell: {
        maxWidth: 330,
        minWidth: 240
    },
    mediaTypeCell: {
        textAlign: 'center'
    },
    nextUpCell: {
        maxWidth: 150,
        width: 150,
        whiteSpace: 'normal'
    },
    nextUpAdNotApproved: {
        color: theme.palette.error.main,
    },
    nextUpAdProcessing: {
        color: theme.palette.accent.subscriberSunshine[500],
    },
    textId: {
        color: theme.palette.neutral[800],
        fontSize: 11,
        lineHeight: '13px',
        marginBottom: 4
    },
    textTitle: {
        color: theme.palette.neutral[900],
        fontSize: 20,
        lineHeight: '25px',
        marginBottom: 4,
        whiteSpace: 'normal',
        fontWeight: 400,
    },
    textTitleBolder: {
        color: theme.palette.neutral[900],
        fontSize: 20,
        lineHeight: '25px',
        marginBottom: 4,
        whiteSpace: 'normal',
        fontWeight: 800,
    },
    price: {
        fontSize: 15,
        lineHeight: '20px',
        color: theme.palette.neutral[800]
    },
    defaultRow: {
        cursor: 'pointer',
        background: theme.palette.common.offWhite,

        '&:hover': {
            background: theme.palette.neutral[100]
        }
    },
    errorBorder: {
        borderLeft: `3px solid ${theme.palette.error.main}`,
        borderRight: `3px solid ${theme.palette.error.main}`,
        borderTop: `2px solid ${theme.palette.error.main}`,
        borderBottom: `2px solid ${theme.palette.error.main}`
    },
    defaultRowCanceled: {
        opacity: .3
    },
    cta: {
        minWidth: 146,
        float: 'right'
    },
    errorCta: {
        background: theme.palette.error.main,
    },
    errorNextUp: {
        color: theme.palette.error.main,
    },
    thumbWrap: {
        position: 'relative'
    },
    thumbDotNew: {
        display: 'block',
        position: 'absolute',
        left: -13,
        top: '50%',
        transform: 'translate(0,-50%)',
        width: 8,
        height: 8,
        borderRadius: '50%',
        background: theme.palette.accent.subscriberSunshine[500],

        [theme.breakpoints.up('sm')]: {
            left: -17
        },

        [theme.breakpoints.up('md')]: {
            left: -20
        }
    }
}));

let defaultHeadings = [
    {
        id: 'logo',
        label: 'Order',
        breakpoint: 'tb'
    },
    {
        id: 'text',
        label: ''
    },
    {
        id: 'media_type',
        label: 'Media type'
    },
    {
        id: 'base_price',
        label: 'Price',
        breakpoint: 'sm'
    },
    {
        id: 'next_up',
        label: 'Next up',
        breakpoint: 'sm'
    },
    {
        id: 'cta',
        label: '',
        breakpoint: 'lg'
    }
];

function OrdersTable({ isAgency, status }) {

    const classes = useStyles();
    const { state } = useAppState();
    const { history, getLinkButtonProps } = useLinkHandler();
    const location = useLocation();

    const headings = status === 'canceled' ? defaultHeadings.filter(el => el.id !== "next_up") : defaultHeadings;

    const cellRenderer = (heading, row) => {

        function getButtonIcon() {
            if (row.button_icon === 'upload') {
                return <UploadIcon />
            } else if (row.button_icon === 'replace') {
                return <ReplaceIcon />
            } else if (row.button_icon === 'payment') {
                return <PaymentIcon />
            } else if (row.button_icon === 'info') {
                return <InfoIcon />
            } else if (row.button_icon === 'checkmark') {
                return <CheckmarkIcon />
            }
        }

        switch (heading.id) {
            case 'logo':
                return <Box className={classes.thumbWrap}>
                    <OrderThumbnail isCanceled={row.is_canceled} src={row.image_thumbnail} />
                    {row.is_flagged && !row.is_canceled && <span className={classes.thumbDotNew} />}
                </Box>;
            case 'text':
                return <Box>
                    <Box className={classes.textId}>#{row.id}</Box>
                    <Box className={row.is_flagged ? classes.textTitleBolder : classes.textTitle}>{row.title}</Box>
                    <OrderSummaryText order={row} isAgency={isAgency} />
                </Box>;
            case 'media_type':
                return <MediaTypeIcon mediaType={row.media_type} iconType={row.icon_type} />;
            case 'base_price':
                return <Box className={classes.price}>{row.price_formatted}</Box>;
            case 'next_up':
                return <Box
                    className={classNames(
                        classes.nextUp,
                        row.nextup.step === "Processing ad" ? classes.nextUpAdProcessing : (row.nextup.step === 'Ad not approved' ? classes.nextUpAdNotApproved : ''),
                        row.nextup.type === "error" && classes.errorNextUp,
                    )}
                >
                    {row.nextup && row.nextup.step}{row.nextup.date && <strong> {row.nextup.date}</strong>}
                </Box>;
            case 'cta':
                return (
                    <Button
                        size="small"
                        variant={row.button_type === 'light' ? 'outlined' : 'contained'}
                        className={classNames(classes.cta, row.button_type === 'error' && classes.errorCta)}
                        startIcon={getButtonIcon()}
                        {...getLinkButtonProps({ url: row.button_cta, preventDefault: true })}>
                          {row.button_label}
                    </Button>
                );
            default:
                return '-';
        }
    };

    const cellPropsGenerator = (heading, row) => {
        switch (heading.id) {
            case 'logo':
                return {
                    className: classNames(
                        classes.logoCell,
                        row && row.is_canceled ? classes.canceledCell : ''
                    )
                };
            case 'text':
                return {
                    className: classNames(
                        classes.textCell,
                        row && row.is_canceled ? classes.canceledCell : ''
                    )
                };
            case 'media_type':
                return { className: classes.mediaTypeCell };
            case 'next_up':
                return { className: classes.nextUpCell };
        }
    };

    const rowPropsGenerator = row => {
        return {
            className: classNames(classes.defaultRow,  row.nextup.type === 'error' && classes.errorBorder)
        };
    };

    const handleClickRow = (e, row) => {
        history.push(`/order/${row.id}`, { from: location.pathname });
    };

    return (
      <DataTable
        breakpointPaddings={{
            xsUp: 16,
            smUp: 24,
            mdUp: 32
        }}
        headings={headings}
        rows={state.orders.data}
        rowPropsGenerator={rowPropsGenerator}
        cellPropsGenerator={cellPropsGenerator}
        cellRenderer={cellRenderer}
        onRowClick={handleClickRow}
        hideTableHeadInSm
        tooManyFilters={state.orders?.meta?.is_filtered === true && state.orders.data.length === 0}
      />
    );
}

export default OrdersTable;
