import React, { useState, useCallback } from 'react';
import { Box, makeStyles, Slider } from '@material-ui/core';
import Cropper from 'react-easy-crop';

const useStyles = makeStyles((theme) => ({
    imageEditor: {
        width: 300,
        height: 300,
        marginBottom: 20
    },
    cropperWrap: {
        width: '100%',
        height: '100%',
        position: 'relative',
        borderRadius: '50%',
        overflow: 'hidden',
        border: `2px solid ${theme.palette.neutral[900]}`
    },
    container: {},
    cropArea: {},
    media: {}
}));

export default function ImageEditor({ src, croppedAreaPixels, setCroppedAreaPixels, ...rest }) {

    const classes = useStyles();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [minZoom, setMinZoom] = useState(1);
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    return (
        <>
            <Box className={classes.imageEditor} {...rest}>
                <Box className={classes.cropperWrap}>
                    <Cropper
                        classes={{
                            containerClassName: classes.container,
                            mediaClassName: classes.media,
                            cropAreaClassName: classes.cropArea
                        }}
                        showGrid={false}
                        cropSize={{ width: 300, height: 300 }}
                        image={src}
                        crop={crop}
                        zoom={zoom}
                        minZoom={minZoom}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        cropShape="round"
                        onMediaLoaded={(mediaSize) => {
                            const zoomRatio = mediaSize.naturalWidth > mediaSize.naturalHeight ? (mediaSize.naturalWidth / mediaSize.naturalHeight) : (mediaSize.naturalHeight / mediaSize.naturalWidth);
                            setZoom(zoomRatio);
                            setMinZoom(zoomRatio);
                        }}
                    />
                </Box>
            </Box>
            <Slider
                value={zoom}
                min={minZoom}
                max={minZoom + 3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom)}/>
        </>
    );
}
