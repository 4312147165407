import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ReactComponent as MinusIcon } from '../../../images/minus.svg';
import { ReactComponent as PlusIcon } from '../../../images/plus.svg';
import PropTypes from 'prop-types';

function AccordionWrap({
                           id = 'accordion',
                           title,
                           children,
                           initialValue,
                           AccordionSummaryClass,
                           AccordionDetailsClass,
                           ...rest
                       }) {

    const [isExpanded, setIsExpanded] = useState(initialValue || false);

    const handleChange = (event, isExpanded) => {
        setIsExpanded(isExpanded);
    };

    return <Accordion expanded={isExpanded}
                      onChange={handleChange}
                      {...rest}>
        <AccordionSummary
            className={AccordionSummaryClass}
            aria-controls={`${id}-content`}
            id={`${id}-header`}
            expandIcon={isExpanded ? <MinusIcon/> : <PlusIcon/>}>
            {title}
        </AccordionSummary>
        <AccordionDetails className={AccordionDetailsClass}>
            {children}
        </AccordionDetails>
    </Accordion>;
}

AccordionWrap.propTypes = {
    id: PropTypes.string,
    initialValue: PropTypes.bool,
    title: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string
    ]).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export default AccordionWrap;