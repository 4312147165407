import CustomDialog from '../common/CustomDialog.js';
import FieldGroup from '../form/FieldGroup.js';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import DataController from '../../../lib/controllers/DataController.js';
import { actions } from '../../../store/store.js';
import useAppState from '../../../lib/hooks/useAppState.js';
import { useSnackbar } from "notistack";
import { useHistory } from 'react-router';

function AddClientDialog({ open, setOpen }) {
    
    const history = useHistory();
    const { dispatch } = useAppState();
    const { enqueueSnackbar } = useSnackbar();
    const [clientName, setClientName] = useState('');

    const handleClose = e => {
        e && e.preventDefault();
        setOpen(null);
    };

    const handleAdd = e => {
        DataController.createCompany({ name: clientName }).then(res => {
            console.log('[paymentMethodEdit]', res);
            if (res && res.success) {
                console.log(res.data)
                dispatch({
                    type: actions.UPDATE_ROW,
                    payload: {
                        location: 'myClients.clients',
                        data: res.data
                    }
                });
                enqueueSnackbar('Client added!');
                history.push(`/clients/${res.data.id}/edit`);
            } else {
                enqueueSnackbar(res.message || 'Could not update payment method nickname.', {
                    variant: 'error',
                });
            }
        });
        window.setTimeout(() => {
            setClientName('');
        }, 300);
        handleClose(e);
    };

    const handleChange = e => {
        setClientName(e.target.value);
    };

    return <CustomDialog
        title="Add Client"
        maxWidth="xs"
        onClose={handleClose}
        hasXClose
        disableBackdropClick
        actions={[
            {
                label: 'Cancel',
                fullWidth: false,
                variant: 'contained',
                color: 'secondary',
                style: { marginRight: '20px' },
                onClick: handleClose
            },
            { label: 'Add', 
                onClick: handleAdd, 
                fullWidth: false 
            }
        ]}
        isOpen={!!open}>
        <FieldGroup>
            <TextField value={clientName}
                       onChange={handleChange}
                       label="Client Name"
                       autoFocus/>
        </FieldGroup>
    </CustomDialog>;
}

export default AddClientDialog;