import ClientsHeader from "components/elements/ClientsPage/ClientsHeader";
import { Box, Avatar, makeStyles } from "@material-ui/core";
import React, { useState } from 'react';
import DataTable from '../../elements/common/DataTable.js';
import useAppState from '../../../lib/hooks/useAppState.js';
import useData from '../../../lib/hooks/useData.js';
import { objContainsStr } from '../../../lib/functions.js';
import MoreMenu from "components/elements/common/MoreMenu.js";
import { useHistory } from "react-router";
import DataController from "lib/controllers/DataController.js";
import actions from "store/actions.js";
import { useSnackbar } from "notistack";
// import UserRoleChip from "components/elements/TeamPage/UserRoleChip.js";
import NoDataFound from "components/elements/common/NoDataFound.js";
import SkeletonTable from '../../elements/common/SkeletonTable.js';

const useStyles = makeStyles(theme => ({
    name: {
        color: theme.palette.primary.main
    },
    avatarCell: {
        width: 44,
        paddingTop: 8,
        paddingBottom: 8
    },
    actionsCell: {
        width: 44,
        padding: 0
    },
    ownerCell: {
        width: 71,
        paddingRight: 0
    },
    totalSpend: {
        textAlign: 'right',
        width: 120
    },
    totalOrders: {
        textAlign: 'center',
        width: 120
    },
    dateColumn: {
        width: 120
    }
}));

const headings = [
    { id: 'avatar' },
    { label: 'Name', id: 'name' },
    { label: 'Date added', id: 'date_added' },
    { label: 'Total orders', id: 'total_orders' },
    { label: 'Total spend', id: 'total_spend' },
    // { id: 'owner' },
    { id: 'actions' }
];

export default function ClientsPage() {

    const classes = useStyles();
    const { state, dispatch } = useAppState();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const meta = state.myClients.meta;

    useData({
        queries: ['getMyClients', 'getMyCompany'],
        setLoadingState: true,
        cbAfter: () => {
            setSkeletonLoading(false);
        },
        cbBefore: () => {
            setSkeletonLoading(true);
        }
    });

    const cellRenderer = (heading, row) => {
        switch (heading.id) {
            case 'avatar':
                if (row.avatar) {
                    return <Avatar src={row.avatar} className={classes.avatar}/>;
                } else {
                    return <Avatar
                        className={classes.avatar}>{row.name && row.name.trim().substring(0, 1).toUpperCase()}</Avatar>;
                }
            case 'name':
                return <span className={classes.name}>{row.name}</span>;
            case 'total_orders':
                return row.total_orders;
            case 'date_added':
                return row.created;
            case 'total_spend':
                return `${row.total_spend}`;
            // case 'owner':
            //     return <UserRoleChip role={row.role} description={meta[row.role]}/>;
            case 'actions':
                if (row.is_editable) {
                    const items = [
                        {
                            label: 'Edit', onClick: (e, row) => {
                                onEdit(e, row);
                            }
                        },
                        {
                            label: 'Delete', onClick: (e, row) => {
                                onDelete(e, row.id);
                            }, disabled: row.is_deletable
                        }
                    ];
                    return <MoreMenu id="client-menu" items={items} row={row} loading={row.id === loading}/>;
                } else {
                    return '';
                }
            default:
                return '';
        }
    };

    const cellPropsGenerator = (heading, row) => {
        switch (heading.id) {
            case 'avatar':
                return { className: classes.avatarCell };
            case 'total_spend':
                return { className: classes.totalSpend };
            case 'total_orders':
                return { className: classes.totalOrders };
            case 'date_added':
                return { className: classes.dateColumn };
            case 'actions':
                return { className: classes.actionsCell };
            // case 'owner':
            //     return { className: classes.ownerCell };
            default:
                return {};
        }
    };

    const filterFunction = rows => {
        if (!state.temporary.filter) return rows;
        return rows.filter(row => objContainsStr(row, ['name'], state.temporary.filter));
    };

    const onEdit = (e, row) => {
        e.preventDefault();
        if (state.myClients.current && state.myClients.current.id !== row.id) {
            dispatch({
                type: actions.SET_CURRENT_CLIENT,
                payload: null
            });
        }
        history.push(`/clients/${row.id}/edit`);
    };

    const onDelete = (e, id) => {
        e.preventDefault();

        setLoading(id);

        DataController
            .deleteCompany(id)
            .then(({ data, success, error, message }) => {
                if (success) {
                    dispatch({
                        type: actions.UPDATE_ROW,
                        payload: {
                            deleteId: id,
                            location: 'myClients.clients'
                        }
                    });
                    enqueueSnackbar('Client deleted successfully.');
                } else {
                    enqueueSnackbar(message, {
                        variant: 'error'
                    });
                }
                setLoading(null);
            });
    };

    return (
        <Box>
            {skeletonLoading ?
                <SkeletonTable/> :
                <>
                    <ClientsHeader/>
                    <Box mt={8}>
                        {!state.myClients.clients || state.myClients.clients.length === 0 ?
                            (state.myClients.isLoaded ? <NoDataFound>No clients found.</NoDataFound> : '')
                                :  <DataTable
                                        breakpointPaddings={{
                                            xsUp: 16,
                                            smUp: 24,
                                            mdUp: 32
                                        }}
                                        headings={headings}
                                        rows={state.myClients.clients}
                                        filterFunction={filterFunction}
                                        cellPropsGenerator={cellPropsGenerator}
                                        cellRenderer={cellRenderer}
                                    />
                        }
                    </Box>
                </>}
        </Box>
    );
}
