import { ReactComponent as HighFive } from 'images/high_five.svg';
import { ReactComponent as AllSet } from 'images/all-set.svg';
import { Box, makeStyles, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
    wrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 16,
    },
    image: {
        width: 200,
        height: 'auto',
        marginTop: 33,
        marginBottom: 16,
    },
    description: {
        marginTop: 16,
        marginBottom: 16,
        fontSize: 20,
        color: theme.palette.neutral[900],
    },
}))

export default function OrderExtraAddonsSuccess({ handleClose, text }) {

    const classes = useStyles();

    const history = useHistory();

    const handleReturnToOrders = () => {
        // history.push('/orders/upcoming');
        handleClose();
    }

    return <Box className={classes.wrap}>
        <HighFive className={classes.image} />
        <AllSet />
        <Typography className={classes.description}>{text}</Typography>
        <Button
            variant="contained"
            onClick={handleReturnToOrders}
        >
            Return to Order
        </Button>
    </Box>
}
