import React from 'react';
import { makeStyles } from "@material-ui/core";
import { PieChart, Pie, Cell } from 'recharts';

const useStyles = makeStyles(theme => ({
  chartWrapper: {
      marginTop: 20
  },
  chartTitle: {
      fontSize: 20,
      lineHeight: '25px',
      alignItems: 'center',
      color: theme.palette.neutral[900],
      margin: '0 0 20px 10px',
  }
}))

export default function PieChartComponent({ data, title, colors, dataKey, nameKey }) {
    const classes = useStyles()

    return (
        <div className={classes.chartWrapper}>
            <div className={classes.chartTitle}>{title}</div>
            <PieChart width={250} height={250}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={125}
                    dataKey={dataKey}
                    nameKey={nameKey}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                </Pie>
            </PieChart>
        </div>
    )
}
