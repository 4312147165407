import React, { useState, useEffect, useCallback } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DataController from '../../../lib/controllers/DataController.js';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import { ReactComponent as AutocompleteMagnifier } from 'images/autocompleteMagnifier.svg';
import { ReactComponent as AutocompleteMagnifierOpen } from 'images/autocompleteMagnifierOpen.svg';
import { debounce } from '../../../lib/functions.js';
import OrdersAutocompleteResult from './OrdersAutocompleteResult.js';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 'auto',
        width: '100%',

        [theme.breakpoints.up('lg')]: {
            flex: '0 0 36%'
        }
    }
}));

function OrdersSearchQuery() {

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState('');
    const [options, setOptions] = useState([]);
    const history = useHistory();

    const handleChange = (e, val) => {
        setQuery(val);
    };

    const handleOpen = () => {
        setOpen(true);
        setLoading(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOptionClick = (e, option) => {
        e.preventDefault();
        history.push(`/order/${option.id}`);
    };

    const performSearch = (q) => {
        DataController.searchOrders(q).then(res => {
            setLoading(false);
            if (res.success) {
                setOptions(res.data);
            } else {
                setOptions([]);
            }
        });
    };

    const performSearchDebounce = useCallback(debounce(performSearch, 500), []);

    useEffect(() => {
        if (!open) return;
        performSearchDebounce(query);
    }, [query, open]);

    return <Autocomplete
        id="orders-search-query"
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        onInputChange={handleChange}
        inputValue={query}
        onChange={handleOptionClick}
        options={options}
        loading={loading}
        getOptionLabel={(option) => option.title}
        getOptionSelected={(option, value) => option.id === value.id}
        className={classes.root}
        filterOptions={options => options}
        filterSelectedOptions={false}
        forcePopupIcon={false}
        disableClearable={true}
        noOptionsText="No results found"
        renderOption={(option) => <OrdersAutocompleteResult option={option}/>}
        renderInput={(params) =>
            <TextField {...params}
                       label="Search"
                       InputLabelProps={{
                           ...params.InputLabelProps,
                           shrink: false
                       }}
                       InputProps={{
                           ...params.InputProps,
                           endAdornment: open ? <AutocompleteMagnifierOpen/> : <AutocompleteMagnifier/>
                       }}/>}
    />;
}

export default OrdersSearchQuery;