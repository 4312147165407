import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Dialog, IconButton, DialogTitle, DialogContent, DialogActions, makeStyles, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { ReactComponent as CloseDialogIcon } from 'images/closeDialog.svg';
import OrderDetailsHeader from '../OrderDetailsHeader';
import OrderExtraAddonsSelect from './OrderExtraAddonsSelect';
import OrderExtraAddonsPayment from './OrderExtraAddonsPayment';
import OrderExtraAddonsProcessing from './OrderExtraAddonsProcessing';
import OrderExtraAddonsSuccess from './OrderExtraAddonsSuccess';
import DataController from 'lib/controllers/DataController';
import actions from 'store/actions';
import useAppState from 'lib/hooks/useAppState';
import { ReactComponent as AlertIcon } from 'images/alertError.svg';
import { formatPrice } from 'lib/functions';

const useStyles = makeStyles(theme => ({
    xCloseButton: {
        marginLeft: 'auto',
        marginRight: 0,
        marginTop: 2
    },
    dialogTitleCloseButton: {
        display: 'flex',
        width: '100%',
        padding: 24,
    },
    title: {
        marginRight: 24,
        '& span': {
            fontSize: 21,
            lineHeight: '25px',

            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
            }
        }
    },
    dialog: {
        '& .MuiDialog-paper': {
            padding: 0,
            margin: 10
        },

        [theme.breakpoints.up('tb')]: {
            '& .MuiDialog-paper': {
                minWidth: 636,
            }
        },

        '& .MuiDialogActions-root': {
            padding: '8px 24px',
            background: theme.palette.neutral[200],
            boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.12), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 3px 5px rgba(0, 0, 0, 0.2)'
        }
    },
    inner: {
        width: '100%',
        marginLeft: -24,
    },
    cta: {
        background: 'linear-gradient(180deg, #FFB81E 0%, #FF585A 100%)',
        color: theme.palette.neutral.ink,
        transition: 'opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1)',

        '&:hover': {
            opacity: 0.7,
        },

        '& .MuiButton-label': {
            textTransform: 'uppercase',
            letterSpacing: 1,
            fontSize: 20,
            fontFamily: 'Ahkio'
        },

        '&.Mui-disabled': {
            background: theme.palette.neutral[300],

            '& .MuiButton-label': {
                color: theme.palette.neutral[500],
            }
        }
    },
    totalRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        color: theme.palette.neutral[900],
        borderTop: `1px solid ${theme.palette.neutral[500]}`,
        minHeight: 48,
        margin: '0 24px'
    },

    iframeLoader: {
        position: 'absolute',
        width: 42,
        height: 42,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
}))

export default function ExtraAddonsDialog({ handleClose, open, order }) {
    const classes = useStyles();

    const { dispatch } = useAppState();

    const [step, setStep] = useState(0);
    const [selectedAddons, setSelectedAddons] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [error, setError] = useState('');
    
    const paymentIntentId = useRef(null);
    const threeDSSuccessPayload = useRef(null);
    const [iframeUrl, setIframeUrl] = useState(null);
    const [iframeLoading, setIframeLoading] = useState(true);

    const calcTotal = () => {
        let total = 0;

        selectedAddons.forEach(addon => {
            if (addon.items && addon.items.length > 0) {
                addon.items.forEach(item => {
                    total += item.qty * addon.price
                })
            }
        })

        return total;
    }

    useEffect(() => {
        if (open === true) {
            setStep(0);
            setSelectedPaymentMethod(null);
        }
    }, [open])

    const continueDisabled = () => {
        let invalid = false;

        // no addons selected --> no price --> invalid input
        if (calcTotal() === 0) {
            invalid = true;
        }

        // if addon needs address && address field is empty --> invalid input
        selectedAddons.forEach(addon => {
            if (addon.type !== 'design') {
                if (addon.items && addon.items.length && addon.items.length > 0) {
                    addon.items.forEach(item => {
                        if (item.qty > 0 && !item.address_id) {
                            invalid = true
                        }
                    })
                }
            }
        });

        return invalid
    }

    const handleNext = () => {
        setStep(1);
        setError('');
    }

    // cleanup 3ds success handler
    useEffect(() => {
        return () => {
            window.removeEventListener('message', on3dsComplete);
        }
    }, []);

    const on3dsComplete = (ev) => {
        console.log(ev, ev.data);
        if (ev.data === '3DS-authentication-complete') {

            console.log('[on3dsComplete] got the success message back');
            console.log('[on3dsComplete] payload: ', threeDSSuccessPayload.current);
            console.log('[on3dsComplete] Should hit the API again now, with payment intent id');

            // TODO: Extract and reuse this logic internally
            // TODO2: Extract any purchase logic to be reused accross components

            DataController
                .purchaseAddons(order.id, threeDSSuccessPayload.current)
                .then(res => {
                    if (res && res.success) {
                        dispatch({
                            type: actions.SET_CURRENT_ORDER,
                            payload: res.data
                        });
                        setStep(3);
                    }  else if (res && res.errors && res.errors.order) {
                        setError(res.errors.order[0]);
                        setStep(0);
                    } else if (res && res.errors && res.errors.payment) {
                        setError(res.errors.payment[0]);
                        setStep(1);
                    } else {
                        setError(res.message || "Whoops... There was an error. Please try again.");
                        setStep(0);
                    }
                })
        }
    }

    const handlePlaceOrder = () => {

        let extraAddonsOrder = [];

        selectedAddons.forEach(addon => {
            if (addon.type === 'design') {
                if (addon.items && addon.items[0] && addon.items[0].qty > 0) {
                    extraAddonsOrder.push({
                        type: 'design',
                        qty: 1,
                    })
                }
            } else {
                if (addon.items && addon.items.length && addon.items.length > 0) {
                    addon.items.forEach(item => {
                        extraAddonsOrder.push({...item, type: addon.type});
                    })
                }
            }
        });

        const payload = {
            paymentmethod: selectedPaymentMethod,
            addons: extraAddonsOrder
        }

        setStep(2);
        setError('');

        DataController
            .purchaseAddons(order.id, payload)
            .then(res => {
                if (res && res.success) {
                    dispatch({
                        type: actions.SET_CURRENT_ORDER,
                        payload: res.data
                    });
                    setStep(3);
                } else if (res && res['3ds']) {
                    threeDSSuccessPayload.current = {...payload, paymentintent: res['3ds'].paymentintent_id}
                    setIframeUrl(res['3ds'].next_action.redirect_to_url.url);
                    window.addEventListener('message', on3dsComplete);
                    setIframeLoading(true);
                    setStep(4);
                } else if (res && res.errors && res.errors.order) {
                    setError(res.errors.order[0]);
                    setStep(0);
                } else if (res && res.errors && res.errors.payment) {
                    setError(res.errors.payment[0]);
                    setStep(1);
                } else {
                    setError(res.message || "Whoops... There was an error. Please try again.");
                    setStep(0);
                }
            })
    }

    return (
        <Dialog
            className={classes.dialog}
            onClose={() => {
                handleClose();
            }}
            open={open}
            disableEnforceFocus={true}
            disableScrollLock={true}
        >
            <Box className={classes.dialogTitleCloseButton}>
                <DialogTitle className={classes.title} disableTypography>
                    <span>Buy Extra Add-ons</span>
                </DialogTitle>
                <IconButton
                    className={classes.xCloseButton}
                    onClick={handleClose}
                    size="small"
                    aria-label="close"
                    color="secondary"
                >
                    <CloseDialogIcon/>
                </IconButton>
            </Box>
            <DialogContent>
                <OrderDetailsHeader order={order} dialog={true} />
                {step === 0 && <>
                        {error && <Box p={3}>
                                <Alert
                                    icon={<AlertIcon/>}
                                    className={classes.alert}
                                    severity={'error'}>
                                    {error}
                                </Alert>
                            </Box>
                        }
                        <OrderExtraAddonsSelect
                            addons={order.available_addons}
                            selected={selectedAddons}
                            setSelected={setSelectedAddons}
                        />
                    </>
                }
                {step === 1 && <>
                        {error && <Box p={3}>
                                <Alert
                                    icon={<AlertIcon/>}
                                    className={classes.alert}
                                    severity={'error'}>
                                    {error}
                                </Alert>
                            </Box>
                        }
                        <OrderExtraAddonsPayment
                            selected={selectedPaymentMethod}
                            setSelected={setSelectedPaymentMethod}
                        />
                    </>
                }
                {[0, 1].includes(step) &&
                    <Box className={classes.totalRow} px={1}>
                        <span>total extra add-ons</span>
                        <span>{formatPrice(calcTotal())}</span>
                    </Box>
                }

                {step === 2 &&
                    <OrderExtraAddonsProcessing />
                }

                {step === 3 &&
                    <OrderExtraAddonsSuccess handleClose={handleClose} text="Your payment is complete."/>
                }

                {step === 4 &&
                    <Box>
                        {iframeLoading &&
                            <Box className={classes.iframeLoader}>
                                <CircularProgress color="secondary" />
                            </Box>
                        }
                        <iframe src={iframeUrl} width="100%" height="400" frameBorder="0" onLoad={() => {
                            setIframeLoading(false)
                        }}></iframe>
                    </Box>
                }

            </DialogContent>

            {[0, 1].includes(step) &&
                <DialogActions>
                    {step === 0 &&
                        <Button
                            onClick={handleNext}
                            fullWidth
                            variant="contained"
                            className={classes.cta}
                            disabled={continueDisabled()}
                        >Continue to Payment</Button>
                    }
                    {step === 1 &&
                        <Button
                            onClick={handlePlaceOrder}
                            fullWidth
                            variant="contained"
                            className={classes.cta}
                            disabled={!selectedPaymentMethod}
                        >Place Order</Button>
                    }
                </DialogActions>
            }

        </Dialog>
    )
}
