import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    customTooltip: {
        backgroundColor: theme.palette.primary.main,
        padding: '3px 15px',
        borderRadius: 4,
    },
    label: {
        fontSize: 15,
        alignItems: 'center',
        color: theme.palette.white,
    }
}))

export default function CustomTooltip ({ active, payload }) {
    const classes = useStyles();

    if (active && payload && payload.length) {
        return (
            <div className={classes.customTooltip}>
                <span className={classes.label}>{`${payload[0].value.toLocaleString()}`}</span>
            </div>
        );
    }

    return null;
};
