import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import Box from '@material-ui/core/Box';

import { Dropdown } from 'components/elements/common';
import useAppState from 'lib/hooks/useAppState';
import { Switch, ButtonGroup, Button } from '@material-ui/core';
import { ReactComponent as GridViewIcon } from 'images/gridView.svg';
import { ReactComponent as ListViewIcon } from 'images/listView.svg';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',

        [theme.breakpoints.up('tb')]: {
            flexWrap: 'nowrap',            
        }
    },

    dropdown: {
        width: 'calc(50% - 8px)',

        '&:first-child': {
            marginRight: 16,
        },
        
        [theme.breakpoints.up('tb')]: {
            marginRight: 16,
            maxWidth: 250,
        }
    },

    switchWrapper: {
        display: 'flex',
        marginTop: 22,
        alignItems: 'center',
        marginRight: 16,

        '& label': {
            marginRight: 8,
            cursor: 'pointer',
            fontSize: 15,
            whiteSpace: 'nowrap'
        }
    },

    buttonGroupWrap: {
        marginTop: 24,
        marginLeft: 'auto'
    },

    viewSelectButton: {
        width: 52,
        height: 46,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& svg': {
            marginRight: 0,

            '& path': {
                stroke: theme.palette.primary.main
            }
        },

        '&:not(:last-child)': {
            borderRight: 'none'
        }
    },

    selected: {
        '& svg path': {
            stroke: theme.palette.white
        }
    }
}));

function FilesFilter({ filters, setFilters, view, setView }) {

    const classes = useStyles();

    const { state } = useAppState();

    const handleChange = key => e => {
        setFilters(prevFilter => ({
            ...prevFilter,
            [key]: e.target.value,
            page: 1,
            prevent: false
        }));
    };

    const handleSwitchChange = (e, key) => {
        setFilters(prevFilter => ({
            ...prevFilter,
            [key]: e.target.checked ? true : false,
            page: 1,
            prevent: false
        }))
    }

    const handleSetView = (selected) => {
        setView(selected);
        localStorage.setItem('ad_files_view', selected);
    }

    if (!state.files.isLoaded) return '';

    return (
        <Box className={classes.root}>
            {state.files.meta.options.map(({ id, name, options, type }, i) => (
                <Fragment key={id}>
                    {type === 'checkbox' 
                        ? <div className={classes.switchWrapper}>
                            <label htmlFor={`filter-${id}`}>{name}</label>
                            <Switch id={`filter-${id}`} onChange={(e) => { handleSwitchChange(e, id) }} checked={filters[id] === true} />
                        </div>
                        : <Dropdown
                            id={`filter-${id}`}
                            label={name}
                            onChange={handleChange(id)}
                            options={options}
                            value={filters[id]}
                            className={classes.dropdown}
                        />
                    }
                </Fragment>
            ))}
            <Box className={classes.buttonGroupWrap}>
                <ButtonGroup aria-label="" disableElevation>
                    <Button 
                        variant={view === 'list' ? 'contained' : 'outlined'} 
                        color="primary" 
                        className={classNames(classes.viewSelectButton, view === 'list' ? classes.selected : '')} onClick={() => handleSetView('list')}
                    >
                        <ListViewIcon />
                    </Button>
                    <Button 
                        variant={view === 'grid' ? 'contained' : 'outlined'} 
                        color="primary" 
                        className={classNames(classes.viewSelectButton, view === 'grid' ? classes.selected : '')} onClick={() => handleSetView('grid')}
                    >
                        <GridViewIcon />
                    </Button>
                </ButtonGroup>  
            </Box>
        </Box>
    );
}

FilesFilter.propTypes = {
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired
};

export default FilesFilter;
