import { useCallback } from "react"
import { makeStyles, Button } from '@material-ui/core';
import { findZipcode } from 'lib/abdmUtils';

import { ReactComponent as CloseCircleIcon } from 'images/xCircle.svg';
import { ReactComponent as ArrowIcon } from 'images/abdmArrow.svg';
import { ReactComponent as CalendarIcon } from 'images/calendar.svg';

import Statistics from './Statistics'
import MapSummary from "./MapSummary"
import { ABDM_MIN_ADDRESSES } from "lib/config";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },

        body: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0
    },

    noZipsContainer: {
        borderBottom: '1px solid #DFDFDF',
        height: '100%'
    },

    noZipsTitle: {
        textAlign: 'center',
        fontWeight: 700,
        marginTop: 4
    },

    noZipsText: {
        margin: '15rem 60px 0 60px'
    },

    fewAddressesContainer: {
        display: 'flex',
        marginBottom: 42,
        justifyContent: 'center'
    },

    fewAddressesIcon: {
        transform: 'translateY(-35px) rotate(90deg)'
    },

    fewAddressesText: {
        textAlign: 'center'
    },

    zipsList: {
        flexGrow: 1,
        overflowY: 'auto'
    },

    zipItem: {
        display: 'flex',
        background: '#FDFDFD',
        border: '1px solid #957EFE',
        borderRadius: 20,
        padding: '8px 12px',
        marginBottom: 8
    },

    zipItemZip: {
        fontWeight: 700,
        marginRight: 4,
    },

    zipItemPrice: {
        flexGrow: 1,
        textAlign: 'right',
        color: theme.palette.primary.main,
        marginRight: 8
    },

    zipItemRemoveIcon: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },

    footer: {
        textAlign: 'center',
        marginTop: 16,
    },
}))

export default function SelectedZipcodesList({
    selectedZipcodes,
    quote,
    onSelectZipcode,
    onSelectDates
}) {
    const classes = useStyles();

    const onRowClick = useCallback(e => {
        const zipcode = findZipcode(e)
        if (zipcode) {
            onSelectZipcode(zipcode, false)
        }
    }, [onSelectZipcode])

    const totalAddresses = Object.values(selectedZipcodes).reduce((res, demogr) => res + demogr.total_households, 0)

    return (
        <div className={classes.root}>
            <div className={classes.body}>
                {Object.keys(selectedZipcodes).length === 0 && (
                    <div className={classes.noZipsContainer}>
                        <div className={classes.noZipsTitle}>No Zips Selected</div>
                        <div className={classes.noZipsText}>
                            Select a zip code to choose a neighborhood and get started!
                        </div>
                        <ArrowIcon />
                    </div>
                )}
                {Object.keys(selectedZipcodes).length > 0 && (<>
                    <Statistics quote={quote}/>
                    <div className={classes.zipsList}>
                        {Object.keys(selectedZipcodes).map(zipcode => (
                            <div
                                data-zipcode={selectedZipcodes[zipcode].zip}
                                key={selectedZipcodes[zipcode].zip}
                                className={classes.zipItem}
                            >
                                <div className={classes.zipItemZip}>
                                    Zip Code {selectedZipcodes[zipcode].zip}
                                </div>
                                <div className={classes.zipItemAddress}>
                                    ({selectedZipcodes[zipcode].total_households} Addresses)
                                </div>
                                <div className={classes.zipItemPrice}>
                                    {selectedZipcodes[zipcode].price_formatted}
                                </div>
                                <div className={classes.zipItemRemoveIcon} onClick={onRowClick}>
                                    <CloseCircleIcon/>
                                </div>
                            </div>
                        ))}
                    </div>
                    {totalAddresses < ABDM_MIN_ADDRESSES && (
                        <div className={classes.fewAddressesContainer}>
                            <ArrowIcon className={classes.fewAddressesIcon}/>
                            <div className={classes.fewAddressesText}>
                                Selected {totalAddresses}/{ABDM_MIN_ADDRESSES} addresses<br/>minimum needed.
                            </div>
                        </div>
                    )}
                    <MapSummary quote={quote}/>
                </>)}
            </div>
            <div className={classes.footer}>
                <Button
                    variant="contained"
                    fullWidth
                    disabled={!quote.result}
                    onClick={onSelectDates}
                    endIcon={<CalendarIcon />}
                >Pick Your Delivery Date</Button>
            </div>
        </div>
    )
}