import React, {useEffect, useState} from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import DataController from 'lib/controllers/DataController.js';
import { useSnackbar } from 'notistack';
import EmailTextarea from '../TeamPage/EmailTextarea';
import LoadingButton from '../common/LoadingButton';
import actions from "../../../store/actions";
import useAppState from "../../../lib/hooks/useAppState";

const useStyles = makeStyles((theme) => ({
    wrap: {
        maxWidth: 800,
    },
    label: {
        color: theme.palette.neutral[900],
        fontSize: 17,
        fontWeight: 'bold',
        lineHeight: '24px',
        marginBottom: 4,
        marginTop: 20,
    },
    note: {
        marginBottom: 5,
    }
}));

export default function AdDueNotificationsForm({ companyId, materialReminders }) {
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    useEffect(() => {
      if(materialReminders?.length) {
        setEmails(materialReminders);
      }
    }, [materialReminders])

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        DataController
            .postCompanyNotifications(companyId, {material_reminder: emails})
            .then(({ data, errors, message, success }) => {
                if (success) {
                    enqueueSnackbar('Ad due notification settings updated successfully!');
                } else {
                    enqueueSnackbar(message, { variant: 'error' });
                }
            })
            .finally(() => {
              setLoading(false);
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box className={classes.wrap}>
                <Typography variant="h4">
                    Ad Due Notifications
               </Typography>
                <div className={classes.label}>
                   Add the email addresses of any team members who should be notified when your ad materials are due.
                </div>
                <div className={classes.note}>
                   (Note: the team member who placed the original order will always receive the notification emails as well.)
                </div>
                <EmailTextarea items={emails} setItems={setEmails} />
            </Box>
            <LoadingButton variant="contained" color="default" type="submit" loading={loading}>
                Save
            </LoadingButton>
        </form>
    );
}
