import { createMuiTheme } from '@material-ui/core/styles';
import palette from './palette.js';
import { fade } from '@material-ui/core/styles/colorManipulator';
import NeutrifStudioRegularTtf from 'assets/fonts/Neutrif-Studio-Regular.ttf';
import NeutrifStudioMediumTtf from 'assets/fonts/Neutrif-Studio-Medium.ttf';
import NeutrifStudioSemiBoldTtf from 'assets/fonts/Neutrif-Studio-SemiBold.ttf';
import NeutrifStudioBoldTtf from 'assets/fonts/Neutrif-Studio-Bold.ttf';
import NeutrifStudioExtraBoldTtf from 'assets/fonts/Neutrif-Studio-ExtraBold.ttf';
import AhkioBoldWoff from 'assets/fonts/Ahkio-Bold.woff';
import { ReactComponent as CheckboxIcon } from 'images/checkbox.svg';
import { ReactComponent as CheckboxSelectedIcon } from 'images/checkboxSelected.svg';
import { ReactComponent as SelectChevronDown } from 'images/selectChevronDown.svg';
import { ReactComponent as AlertErrorIcon } from 'images/alertError.svg';
import React from 'react';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const NeutrifStudioRegular = {
    fontFamily: 'Neutrif Studio',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `
    url(${NeutrifStudioRegularTtf}) format('truetype')
  `
};

const NeutrifStudioMedium = {
    fontFamily: 'Neutrif Studio',
    fontStyle: 'normal',
    fontWeight: 500,
    src: `
    url(${NeutrifStudioMediumTtf}) format('truetype')
  `
};

const NeutrifStudioSemiBold = {
    fontFamily: 'Neutrif Studio',
    fontStyle: 'normal',
    fontWeight: 600,
    src: `
    url(${NeutrifStudioSemiBoldTtf}) format('truetype')
  `
};

const NeutrifStudioBold = {
    fontFamily: 'Neutrif Studio',
    fontStyle: 'normal',
    fontWeight: 700,
    src: `
    url(${NeutrifStudioBoldTtf}) format('truetype')
  `
};

const NeutrifStudioExtraBold = {
    fontFamily: 'Neutrif Studio',
    fontStyle: 'normal',
    fontWeight: 800,
    src: `
    url(${NeutrifStudioExtraBoldTtf}) format('truetype')
  `
};

const AhkioBold = {
    fontFamily: 'Ahkio',
    fontStyle: 'normal',
    fontWeight: 700,
    src: `
    url(${AhkioBoldWoff}) format('woff')
  `
};

const breakpoints = createBreakpoints({
    values: {
        xs: 0,
        sm: 600,
        tb: 769,
        md: 1024,
        lg: 1200,
        xl: 1440,
        xxl: 1920
    }
});

const theme = createMuiTheme({

    // Spacing default: 8
    // spacing: 4,

    palette,

    breakpoints,

    // typography: {
    // All typography variants
    // h1
    // h2
    // h3
    // h4
    // h5
    // h6
    // subtitle1
    // subtitle2
    // body1
    // body2
    // button
    // caption
    // overline
    // Examples:
    // subtitle1: {
    //   fontSize: 12
    // },
    // body1: {
    //   fontWeight: 500
    // },
    // button: {
    //   fontStyle: 'italic'
    // }
    // },

    typography: {
        fontFamily: "'Neutrif Studio', sans-serif;",
        h1: {
            fontWeight: 600
        },
        h2: {
            fontWeight: 600
        },
        h3: {
            fontWeight: 600
        },
        h4: {
            fontWeight: 600
        },
        h5: {
            fontWeight: 600
        },
        h6: {
            fontWeight: 400
        }
    },

    // overrides: {
    //   // Style sheet name
    //   MuiButton: {
    //     // Name of the rule (or variant)
    //     text: {
    //       // Some CSS
    //       color: 'white',
    //     },
    //   },
    // },

    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [
                    NeutrifStudioRegular,
                    NeutrifStudioMedium,
                    NeutrifStudioSemiBold,
                    NeutrifStudioBold,
                    NeutrifStudioExtraBold,
                    AhkioBold
                ]
            }
        },
        MuiDivider: {
            root: {
                backgroundColor: palette.common.base,
                margin: '24px 0',
                opacity: 0.1
            }
        },
        MuiInputBase: {
            root: {
                fontSize: '1.2rem'
            }
        },
        MuiButton: {
            root: {
                borderRadius: 10,
                lineHeight: '24px',
                minWidth: 102,
                padding: '12px 24px',

                '& svg': {
                    marginRight: 8
                },

                '&:hover': {
                    backgroundColor: 'transparent'
                }
            },
            label: {
                fontSize: 17,
                fontWeight: 800,
                textTransform: 'none',
                whiteSpace: 'nowrap',

                '&$disabled': {
                    color: palette.neutral[500]
                }
            },
            text: {
                padding: 0,
                textTransform: 'none',
                color: palette.primary.main,
                minWidth: 0,

                '& .MuiTouchRipple-root': {
                    display: 'none'
                },

                '&:hover': {
                    textDecoration: 'underline'
                },

                '&$disabled': {
                    '& $label': {
                        color: palette.neutral[500]
                    }
                }
            },
            outlined: {
                padding: '12px 24px',
                borderWidth: 2,

                '&$disabled': {
                    backgroundColor: palette.neutral[300],

                    '& $label': {
                        color: palette.neutral[500]
                    }
                },

                '&:hover': {
                    backgroundColor: palette.neutral[300]
                }
            },
            outlinedSecondary: {
                borderColor: palette.neutral.paper
            },
            contained: {
                backgroundColor: palette.neutral[900],
                color: palette.common.offWhite,
                boxShadow: 'none',

                '&$disabled': {
                    backgroundColor: palette.neutral[300],

                    '& $label': {
                        color: palette.neutral[500]
                    }
                },

                '&:hover': {
                    backgroundColor: palette.neutral[800],
                    boxShadow: 'none'
                }
            },
            containedSecondary: {
                backgroundColor: palette.neutral.paper,
                color: palette.neutral[900],

                '&:hover': {
                    backgroundColor: palette.neutral[400]
                }
            },
            containedPrimary:{
                '&$disabled': {
                    backgroundColor: palette.neutral[800],

                    '& $label': {
                        color: palette.neutral[600]
                    }
                },
            },
            sizeSmall: {
                height: 36,

                '& .MuiButton-label': {
                    fontSize: 15,
                    lineHeight: '20px'
                }
            },
            outlinedSizeSmall: {
                borderColor: palette.neutral[900],
                borderWidth: 1
            }
        },
        MuiTextField: {},
        MuiInputLabel: {
            outlined: {
                color: palette.neutral[700],
                fontSize: 15,
                fontWeight: 400,
                lineHeight: '20px',
                position: 'static',
                transform: 'none',
                transition: 'none',

                '&$focused': {
                    color: palette.neutral[700]
                },

                '&$error': {
                    color: palette.error.main
                },

                '&$disabled': {
                    color: palette.neutral[500]
                },

                '&[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder': {
                    opacity: '1 !important'
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 10,
                marginTop: 4,
                padding: '6px 12px',
                backgroundColor: palette.white,

                '& $notchedOutline': {
                    borderColor: palette.neutral[400],

                    '& span': {
                        display: 'none'
                    }
                },

                '&$focused': {
                    '& $notchedOutline': {
                        border: `1px solid ${palette.active.main}`
                    }
                },

                '&$error': {
                    background: palette.error.light,
                    animationName: 'shakeX',
                    animationDuration: '.75s',
                    animationRepeat: 1,

                    '& $notchedOutline': {
                        borderColor: `${palette.error.main} !important`
                    }
                },

                '&$disabled': {
                    background: palette.neutral[100],
                    pointerEvents: 'none',

                    '& $input': {
                        color: palette.neutral[500]
                    },

                    '& $notchedOutline': {
                        borderColor: palette.neutral[400]
                    }
                },

                '&:hover': {
                    '& $notchedOutline': {
                        borderColor: palette.neutral[400]
                    }
                }
            },
            focused: {},
            disabled: {},
            input: {
                padding: '6px 0 7px',

                '&::placeholder': {
                    color: palette.neutral[500]
                }
            }
        },
        MuiInputAdornment: {
            root: {
                '& .MuiTypography-root': {
                    color: palette.neutral[500],
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    lineHeight: 'inherit'
                }
            }
        },
        MuiAutocomplete: {
            root: {
                '&[aria-expanded="true"]': {
                    '& .MuiInputBase-root': {
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0
                    },

                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: palette.neutral[400]
                    }
                }
            },
            inputRoot: {
                '&[class*="MuiOutlinedInput-root"]': {
                    padding: '6px 12px'
                }
            },
            input: {
                padding: '6px 0 7px !important'
            },
            option: {
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 12,
                paddingBottom: 12
            },
            paper: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                margin: 0,
                boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.08)',
                border: `1px solid ${palette.neutral[400]}`,
                borderTop: 0
            },
            listbox: {
                padding: 0
            }
        },
        MuiDialog: {
            root: {
                zIndex: '1700 !important'
            },
            paper: {
                borderRadius: 10,
                backgroundColor: palette.common.offWhite,
                border: `1px solid ${palette.neutral[400]}`,
                // TODO: Try reusing shadows/elevation from MUI
                boxShadow: `0px 3px 5px 0px rgba(0, 0, 0, 0.2),
                            0px 5px 8px 0px rgba(0, 0, 0, 0.14),
                            0px 1px 14px 0px rgba(0, 0, 0, 0.12)`,
                padding: 24,
                width: '100%',
                width: '-moz-available',
                width: '-webkit-fill-available',
                width: 'fill-available',
            }
        },
        MuiDialogTitle: {
            root: {
                padding: 0
            }
        },
        MuiDialogContent: {
            root: {
                padding: 0,
                color: palette.neutral[600]
            }
        },
        MuiDialogActions: {
            root: {
                display: 'block',
                padding: 0
            }
        },
        MuiPopover: {
            root: {
                zIndex: '1800 !important'
            }
        },
        MuiBackdrop: {
            root: {
                backgroundColor: fade(palette.neutral.ink, 0.5)
            }
        },
        MuiFormControlLabel: {
            label: {
                color: palette.neutral[700]
            }
        },
        MuiCard: {
            root: {
                borderRadius: 10,
                boxShadow: `0px 5px 20px rgba(0, 0, 0, 0.08)`,
                padding: 16,

                [breakpoints.up('sm')]: {
                    padding: 24
                },

                [breakpoints.up('md')]: {
                    padding: 32
                }
            }
        },
        MuiChip: {
            root: {
                backgroundColor: palette.accent.marketMint[700],
                borderRadius: 14,
                height: 28
            },
            label: {
                color: palette.common.offWhite,
                fontSize: 13,
                lineHeight: '18px'
            }
        },
        MuiTableCell: {
            root: {
                fontSize: 15,
                lineHeight: '20px',
                padding: '13px 10px',
                borderBottom: `1px solid ${palette.neutral[300]}`
            },
            body: {
                color: palette.neutral[800]
            },
            head: {
                color: palette.neutral[900],
                background: palette.neutral[100],
                fontWeight: 800,
                padding: '8px 10px'
            }
        },
        MuiMenu: {
            paper: {
                borderWidth: 1,
                borderColor: palette.neutral[500],
                borderStyle: 'solid',
                borderRadius: 10,
                boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.08)'
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: 17,
                color: palette.active.main,
                minWidth: 240,
                paddingLeft: 24,
                paddingRight: 24,
                paddingTop: 10,
                paddingBottom: 10,

                '&.Mui-disabled': {
                    color: palette.neutral[800]
                }
            }
        },
        MuiTab: {
            root: {
                minWidth: '0 !important',
                padding: '13px 16px',

                '&$selected': {
                    '& svg path': {
                        stroke: `${palette.primary.main} !important`
                    }
                }
            },
            wrapper: {
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',

                '& p': {
                    fontSize: 15,
                    lineHeight: '20px',
                    textTransform: 'none'
                },

                '& svg': {
                    height: 16,
                    marginRight: 8,
                    width: 16,

                    '& path': {
                        stroke: palette.neutral[500]
                    },

                    [breakpoints.up('lg')]: {
                        width: 20,
                        height: 20
                    }
                }
            }
        },
        MuiTabs: {
            root: {
                borderBottom: `1px solid ${palette.neutral[300]}`
            },
            indicator: {
                height: 4
            }
        },
        MuiIconButton: {
            colorSecondary: {
                '&:not(.MuiCheckbox-root)': {
                    backgroundColor: palette.secondary.light,

                    '&:hover': {
                        backgroundColor: palette.neutral[700]
                    }
                }
            },
            sizeSmall: {
                width: 28,
                height: 28
            }
        },
        MuiPaginationItem: {
            root: {
                color: palette.primary.main,
                fontSize: 17
            }
        },
        MuiSelect: {
            icon: {
                top: 'calc(50% - 4px)'
            },
            iconOutlined: {
                right: 16
            },
            outlined: {
                padding: '12px 12px 13px 12px'
            }
        },
        MuiTimeline: {
            root: {
                padding: '16px 0'
            }
        },
        MuiTimelineContent: {
            root: {
                padding: '0 0 30px 20px'
            }
        },
        MuiTimelineDot: {
            root: {
                marginTop: '0',
                marginBottom: '0',
            },
            defaultSecondary: {
                backgroundColor: palette.accent.customerCoral[500],
                borderColor: palette.white,
            },
            outlinedSecondary: {
                borderColor: palette.neutral[900],
            }
        },
        MuiTimelineConnector: {
            root: {
                border: `1px dashed ${palette.neutral[500]}`,
                backgroundColor: 'transparent'
            }
        },
        MuiTimelineItem: {
            missingOppositeContent: {
                '&::before': {
                    display: 'none'
                }
            }
        },
        MuiAlert: {
            root: {
                padding: '8px 16px',

                '& a': {
                    textDecoration: 'underline'
                }
            },
            filledSuccess: {
                backgroundColor: palette.primary.main,
                color: palette.common.offWhite
            },
            filledInfo: {},
            filledError: {
                backgroundColor: palette.accent.customerCoral[600],

                '& a': {
                    color: palette.accent.subscriberSunshine[500]
                }
            },
            filledWarning: {},
            message: {
                fontSize: 17,
                lineHeight: '24px',
                color: palette.common.offWhite,
                fontWeight: 'normal',
                padding: '5px 0',
            },
            icon: {
                '& + .MuiAlert-message': {
                    padding: '8px 0'
                }
            },
            action: {
                '& .MuiButton-root': {
                    minWidth: 140
                }
            }
        },
        MuiStep: {
            horizontal: {
                paddingLeft: 16,
                paddingRight: 16,

                [breakpoints.down('xs')]: {
                    paddingLeft: 8,
                    paddingRight: 8,
                },
            }
        },
        MuiStepIcon: {
            root: {
                color: palette.neutral[100],
                border: `1px solid ${palette.accent.marketMint[600]}`,
                borderRadius: 12,

                '&.MuiStepIcon-active': {
                    color: palette.neutral[900],
                    border: 0,
                }
            },
            active: {},
            text: {
                fill: palette.neutral[600]
            }
        },
        MuiStepLabel: {
            root: {
                [breakpoints.down('xs')]: {
                    flexDirection: 'column',
                    '& .MuiStepLabel-iconContainer': {
                        paddingRight: 0,
                    },
                },
            },
            label: {
                color: palette.neutral[600],

                '&.MuiStepLabel-active': {
                    color: palette.neutral[900]
                }
            },
            active: {}
        },
        MuiAccordion: {
            root: {
                background: 'transparent',
                boxShadow: 'none',
                borderTop: `1px solid ${palette.neutral[700]}`,

                '&:first-child': {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0
                },

                '&.Mui-expanded': {
                    marginTop: 0
                }
            }
        },
        MuiAccordionSummary: {
            root: {
                minHeight: 64,
                padding: '0 10px',
                fontWeight: 900,
                borderBottom: `1px solid transparent`,

                '&.Mui-expanded': {
                    borderBottomColor: palette.neutral[300]
                },

                '& .MuiIconButton-label': {
                    width: 24,
                    height: 24
                }
            },
            expandIcon: {
                '& path': {
                    stroke: palette.primary.main
                }
            }
        },
        MuiAccordionDetails: {
            root: {
                padding: '32px 0'
            }
        },
        MuiSwitch: {
            root: {
                width: 64,
                height: 32,
                padding: 0
            },
            switchBase: {
                padding: 1,
                '&$checked': {
                    transform: 'translateX(32px)',
                    color: palette.common.offWhite,
                    '& + $track': {
                        opacity: 1,
                        border: 'none'
                    },
                    '& $thumb': {
                        background: palette.common.offWhite
                    }
                }
            },
            thumb: {
                width: 24,
                height: 24,
                margin: 3,
                backgroundColor: palette.neutral[900],
                boxShadow: 'none'
            },
            track: {
                borderRadius: 32 / 2,
                border: `1px solid ${palette.neutral[900]}`,
                backgroundColor: palette.common.offWhite,
                opacity: 1
            },
            checked: {}
        },
        MuiRadio: {
            root: {
                '&.MuiIconButton-root': {
                    backgroundColor: 'transparent',

                    '&:hover': {
                        backgroundColor: palette.neutral[100]
                    }
                }
            },
            colorPrimary: {
                color: palette.neutral[400],

                '&$checked': {
                    color: palette.primary.main
                }
            }
        },
        MuiFormHelperText: {
            contained: {
                marginLeft: 0
            }
        },
        MuiSkeleton: {
            root: {
                backgroundColor: palette.neutral[300]
            }
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: palette.primary[500],
                fontSize: 15,
                lineHeight: '20px',
                color: palette.common.offWhite
            },
            arrow: {
                color: palette.primary[500],
            },

        }
    },

    // props: {
    //   // Name of the component
    //   MuiButtonBase: {
    //     // The default props to change
    //     disableRipple: true, // No more ripple, on the whole application 💣!
    //   },
    // },

    props: {
        MuiAppBar: {
            elevation: 0
        },
        MuiTextField: {
            fullWidth: true,
            InputLabelProps: { shrink: false },
            variant: 'outlined'
        },
        MuiButton: {},
        MuiCheckbox: {
            icon: <CheckboxIcon/>,
            checkedIcon: <CheckboxSelectedIcon/>
        },
        MuiContainer: {
            maxWidth: false,
            disableGutters: true
        },
        MuiSlider: {
            variant: 'outlined'
        },
        MuiSelect: {
            IconComponent: SelectChevronDown
        },
        MuiPagination: {
            color: 'primary',
            shape: 'rounded'
        },
        MuiAlert: {
            severity: 'error',
            variant: 'filled',
            icon: <AlertErrorIcon/>
        },
        MuiAccordion: {
            square: true
        }
    },
});

export default theme;
