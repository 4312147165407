import { useCallback } from "react"
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames'

import { findZipcode } from 'lib/abdmUtils';

const useStyles = makeStyles(theme => ({
    leftTableHeader: {
        background: '#F8F8F8',
        borderBottom: '1px solid #DFDFDF',
        display: 'flex',
        height: 48,
        alignItems: 'center',
        padding: '14px 27px 14px 12px'
    },

    leftHeaderItem: {
        flexGrow: 1,
        fontWeight: 'bold',
        textAlign: 'center',
        flexBasis: 0,
    },

    leftTable: {
        flexGrow: 1,
        overflowY: 'auto'
    },

    leftTableRow: {
        display: 'flex',
        padding: '8px 0px',
        margin: '0px 12px',
        border: '1px solid transparent',
        cursor: 'pointer',
        borderRadius: 5,
    },

    leftTableHoveredRow: {
        border: '1px solid #957EFE',
    },

    leftTableItem: {
        flexGrow: 1,
        textAlign: 'center',
        flexBasis: 0,
    },

    leftTableChecbox: {
        marginRight: 8
    },
}))

export default function ZipcodesTable({
    demographics,
    selectedZipcodes, // Record<zipcode, Demographics>
    hoveredZipcode,
    onHoverZipcode,
    onSelectZipcode
}) {
    const classes = useStyles();
    const onRowClick = useCallback(e => {
        const zipcode = findZipcode(e)
        if (zipcode) {
            onSelectZipcode(zipcode)
        }
    }, [onSelectZipcode])

    const onRowMouseEnter = useCallback(e => {
        const zipcode = findZipcode(e)
        if (zipcode) {
            onHoverZipcode(zipcode)
        }
    }, [onHoverZipcode])

    const onRowMouseLeave = useCallback(e => {
        const zipcode = findZipcode(e)
        if (zipcode) {
            onHoverZipcode(null)
        }
    }, [onHoverZipcode])

    return (<>
        <div className={classes.leftTableHeader}>
            <div className={classes.leftHeaderItem}>Zip Codes</div>
            <div className={classes.leftHeaderItem}>Households</div>
            <div className={classes.leftHeaderItem}>Price</div>
        </div>
        <div className={classes.leftTable}>
            {demographics.map(zipcode => (
                <div
                    data-zipcode={zipcode.zip}
                    key={zipcode.zip}
                    className={classNames(
                        classes.leftTableRow, 
                        hoveredZipcode === zipcode.zip && classes.leftTableHoveredRow
                    )}
                    onClick={onRowClick}
                    onMouseEnter={onRowMouseEnter}
                    onMouseLeave={onRowMouseLeave}
                >
                    <div className={classes.leftTableItem}>
                        <input
                            type="checkbox"
                            readOnly
                            className={classes.leftTableChecbox}
                            checked={!!selectedZipcodes[zipcode.zip]}
                        />
                        {zipcode.zip}
                    </div>
                    <div className={classes.leftTableItem}>{zipcode.total_households}</div>
                    <div className={classes.leftTableItem}>{zipcode.price_formatted}</div>
                </div>
            ))}
        </div>
    </>)
}