import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { Avatar } from 'components/elements/common';
import { ReactComponent as EditIcon } from 'images/edit.svg';
import AvatarDialog from './AvatarDialog';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
    container: {
        alignItems: 'center',
        display: 'flex',
    },
}));

export default function AvatarContainer({ icon, src, uploadUrl, badge }) {

    const [avatarOpen, setAvatarOpen] = useState(false);

    const classes = useStyles();

    return (
        <>
            <Box className={classes.container}>
                <Avatar
                    icon={icon}
                    src={src}
                    badge={badge}
                />
                <Button
                    color="secondary"
                    onClick={() => setAvatarOpen(true)}
                    variant="outlined"
                >
                    <EditIcon />
                    <span>Edit Image</span>
                </Button>
            </Box>

            <AvatarDialog
                handleClose={() => setAvatarOpen(false)}
                open={avatarOpen}
                uploadUrl={uploadUrl}
            />
        </>
    );
}
