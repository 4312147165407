import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {ReactComponent as InvoicePrinter} from "../../../images/invoicePrinter.svg";
import {ReactComponent as InvoiceFileText} from "../../../images/invoiceFileText.svg";
import {ReactComponent as InvoiceMail} from "../../../images/invoiceMail.svg";
import DataController from '../../../lib/controllers/DataController.js';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useSnackbar } from "notistack";

const useStyles = makeStyles(theme => ({
  invoicePageWrapper: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1141,
    alignItems: 'center',
    paddingBottom: 30,
    marginTop: 30,

    [theme.breakpoints.up('xs')]: {
      width: '95%',
    },
  },
  invoiceNumberWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {

    },

    [theme.breakpoints.up('lg')]: {

    },
  },
  invoiceInfoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 50,
  },
  amountInfoRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 0 50px 0',
  },
  amountInfoWrapper: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  amountInfoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 350,
    padding: 15,
    borderBottom: `1px solid ${theme.palette.neutral[300]}`,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },

    '& :first-child': {
      fontSize: 15,
      lineHeight: '20px',
      color: theme.palette.neutral[600],
    },
    '& :last-child': {
      fontSize: 15,
      lineHeight: '20px',
      color: theme.palette.neutral[800],
    },
  },
  amountDuoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 350,
    padding: 15,
    borderBottom: `1px solid ${theme.palette.neutral[300]}`,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },

    '& div': {
      fontWeight: 800,
      fontSize: 20,
      lineHeight: '25px',
      color: theme.palette.neutral[900],
    }
  },
  invoiceN: {
    fontSize: 20,
    lineHeight: '25px',
    color: theme.palette.neutral[900],
  },
  invoiceD: {
    fontSize: 15,
    lineHeight: '25px',
    color: theme.palette.neutral[900],
    textAlign: 'right',
  },
  logo: {
    width: 80,
    height: 44,
    marginRight: 5,
  },
  button: {

  },
  chiplabel: {
    borderColor: theme.palette.text.primary,

    '& span': {
      color: theme.palette.text.primary
    }
  },
  chipOutlined: {
    background: theme.palette.common.white,
    borderColor: theme.palette.accent.subscriberSunshine[500],

    '& span': {
      color: theme.palette.accent.subscriberSunshine[500]
    }
  },
  chipPrimary: {
    borderColor: theme.palette.accent.buyerBlue[600],

    '& span': {
      color: theme.palette.accent.buyerBlue[600]
    }
  },
  chipSecondary: {
    borderColor: theme.palette.accent.customerCoral[500],

    '& span': {
      color: theme.palette.accent.customerCoral[500]
    }
  },
  chipDisabled: {
    borderColor: theme.palette.neutral[600],

    '& span': {
      color: theme.palette.neutral[600]
    }
  },
  infoWrapper: {
    display: 'flex',
  },
  el: {
    width: 150,
    textAlign: 'left',
    marginRight: 20,
  },
  title: {
    fontWeight: 800,
    fontSize: 15,
    lineHeight: '20px',
    color: theme.palette.neutral[800]
  },
  text: {

  },
  transferType: {
    fontSize: 25,
  },
  bankInfoItem: {
    marginTop: 15,
    '& :first-child': {
      fontSize: 17,
      fontWeight: 800,
      lineHeight: '24px',
      color: theme.palette.neutral[800],
    },
    '& :last-child': {
      fontSize: 11,
      fontWeight: 400,
      lineHeight: '13px',
      color: theme.palette.neutral[800],
    },
  },
  note: {
    marginTop: 15
  },
  dialog: {
    '& .MuiDialog-paper': {
      margin: 0,
      padding: 0,
      maxWidth: 1143,

      [theme.breakpoints.down('xs')]: {
        width: '95%',
      },

      [theme.breakpoints.down('sm')]: {
        width: '95%',
      },
    },
  },
  top: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FFE5BB',
    borderRadius: 10,
    marginBottom: 20,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  info: {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: '25px',

    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    },
  },
  viewAllButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 20
    },
  },
  header: {
    height: 72,
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    marginRight: 20,
    cursor: 'pointer'
  },
  summaryTitle: {
    color: theme.palette.neutral[900],
    fontSize: 20,
    lineHeight: '25px',
    marginBottom: 4,
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  summaryText: {
    color: theme.palette.neutral[800],
    fontSize: 13,
    overflow: 'hidden',
    lineHeight: '18px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
}));

export default function HeaderActions({ handlePayThisInvoice, setIsOpen, cartId, orderId, handlePrintClick, isPaid }) {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { enqueueSnackbar } = useSnackbar();

  const downloadPdf = async (type) => {
    if (!cartId || !orderId) {
      return;
    }
    handleClose();

    const fileName = type === 'addy' ? `addy-invoice-${orderId}.pdf` : `invoice-${orderId}.pdf`;

    DataController
      .downloadInvoicePdf({type, cartId, orderId })
      .then((body) => {
        return body.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob)
      })
      .then(href => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = href;
        a.style = 'display: none';
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(href);
      })
      .catch(() => {
        enqueueSnackbar('Error while downloading', {
          variant: 'error'
        });
      });
  };

  return (
    <div className={classes.right}>
      <InvoicePrinter className={classes.icon} onClick={handlePrintClick} />
      <InvoiceFileText className={classes.icon} onClick={handleClick}/>
      <InvoiceMail className={classes.icon} onClick={() => setIsOpen({
        isOpen: true,
        currentPopup: 'sendEmails',
      })}/>
      <Button variant="contained" onClick={handlePayThisInvoice} disabled={isPaid}>
        Pay This Order
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => downloadPdf('addy')}>Your Addy Invoice PDF</MenuItem>
        <MenuItem onClick={() => downloadPdf('agency')}>Client-Facing Gross Invoice PDF</MenuItem>
      </Menu>
    </div>
  );
}
