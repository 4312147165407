import { useCallback, useEffect, useState } from 'react';
import { actions } from '../../store/store.js';
import DataController from '../controllers/DataController.js';
import useAppState from './useAppState.js';
import { compareMixed } from '../functions.js';
import useMemoCompare from './useMemoCompare.js';
import useIsWindowVisible from './useIsWindowVisible.js';

let cachedQueryId = 0;
let suppressLoadingState = false;

/**
 * Create unique query id
 *
 * @param query
 * @returns {string}
 */
const getQueryId = query => {
    return JSON.stringify(query);
};

/**
 * Use data hook
 *
 * @param queries array of 'query names', or ['query name', [params...]]
 * @param cbBefore callback before
 * @param cbAfter callback after
 * @param cbError callback error
 * @param runOnce run once, or always on re-render
 * @param setLoadingState set the isLoading state flag
 * @param pollingTimeout set a polling timeout for these queries
 * @param prevent prevent a call
 * @returns {*}
 */
const useData = ({
                     queries,
                     cbBefore,
                     cbAfter,
                     cbError,
                     runOnce = true,
                     setLoadingState = false,
                     pollingTimeout,
                     prevent = false,
                 }) => {

    const isWindowVisible = useIsWindowVisible();
    const [queriesDone, setQueriesDone] = useState({});
    const [localQueryId, setLocalQueryId] = useState(0);
    const [pollingTimeoutId, setPollingTimeoutId] = useState(0);
    const { dispatch, state } = useAppState();

    const queriesCached = useMemoCompare(queries, (prevQueries) => {
        return prevQueries && queries && compareMixed(queries, prevQueries);
    });

    const supportedQueries = [
        // TODO: add all queries that should be supported by useData
        /*
            name: '', name used in the queries parameter when calling useData
            funcName: '', name of the promise in DataController
            onDone: (data, success, message, meta, queryParams) => {} processing when the query runs
            runOnceCondition: (state) => {} when true, the data has been fetched
         */

        /**
         * Get current user data
         */
        {
            name: 'getUser',
            funcName: 'getUser',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_USER_PROFILE,
                        payload: data
                    });
                }
            },
            runOnceCondition: state => state.userData && state.userData.last_login
        },
        /**
         * Get current user team
         */
        {
            name: 'getMyTeam',
            funcName: 'getMyTeam',
            onDone: (data, success, message, meta) => {
                if (success) {
                    dispatch({
                        type: actions.SET_MY_TEAM,
                        payload: { data, meta }
                    });
                }
            },
            // Quick solution, find a better one
            //runOnceCondition: state => state.myTeam && state.myTeam.isLoaded
        },
        /**
         * Get my clients
         */
        {
            name: 'getMyClients',
            funcName: 'getMyClients',
            onDone: (data, success, message, meta) => {
                data.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    return 1;
                });
                if (success) {
                    dispatch({
                        type: actions.SET_MY_CLIENTS,
                        payload: { data, meta }
                    });
                }
            },
            runOnceCondition: state => state.myClients && state.myClients.isLoaded
        },
        /**
         * Get current client
         */
        {
            name: 'getCurrentClient',
            funcName: 'getCurrentClient',
            onDone: (data, success, message, meta) => {
                if (success) {
                    dispatch({
                        type: actions.SET_CURRENT_CLIENT,
                        payload: { data, meta }
                    });
                }
            },
            // Quick solution, find a better one
            /*runOnceCondition: (state, queryParams) => {
                if (state.myClients && state.myClients.current && queryParams && queryParams[0]) {
                    if (state.myClients.current.id.toString() === queryParams[0]) {
                        return true;
                    }
                }
                return false;
            }*/
        },
        /**
         * Get my promo codes
         */
        {
            name: 'getMyPromocodes',
            funcName: 'getMyPromocodes',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_MY_PROMOCODES,
                        payload: data
                    });
                }
            },
            runOnceCondition: state => state.promocodes && state.promocodes.isLoaded
        },
        /**
         * Get my payment methods
         */
        {
            name: 'getMyPaymentMethods',
            funcName: 'getMyPaymentMethods',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_MY_PAYMENT_METHODS,
                        payload: data
                    });
                }
            },
        },
        /**
         * Get my company
         */
        {
            name: 'getMyCompany',
            funcName: 'getMyCompany',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_MY_COMPANY,
                        payload: data
                    });
                }
            },
            // Quick solution, find a better one
            //runOnceCondition: state => state.myCompany && state.myCompany.isLoaded
        },
        /**
         * Get team member
         */
        {
            name: 'getTeamMember',
            funcName: 'getTeamMember',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.UPDATE_ROW,
                        payload: {
                            location: 'myTeam.contacts',
                            data: data
                        }
                    });
                }
            },
            runOnceCondition: (state, queryParams) => {
                if (state.myTeam && state.myTeam.contacts && queryParams && queryParams[0]) {
                    const contact = state.myTeam.contacts.find(el => el.id.toString() === queryParams[0]);
                    if (contact && contact.clients && Array.isArray(contact.clients)) {
                        return true;
                    }
                }
                return false;
            }
        },
        /**
         * Get my addresses
         */
        {
            name: 'getMyAddresses',
            funcName: 'getMyAddresses',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_ADDRESSES,
                        payload: data
                    });
                }
            },
            runOnceCondition: state => state.addresses && state.addresses.isLoaded
        },
        /**
         * Get billing
         */
        {
            name: 'getBilling',
            funcName: 'getBilling',
            onDone: (data, success, message, meta) => {
                if (success) {
                    dispatch({
                        type: actions.SET_BILLING,
                        payload: { data, meta }
                    });
                }
            },
            runOnceCondition: (state, queryParams) => {

                if (state.billing?.data && queryParams.length) {
                    return false;
                }

                return state.billing?.isLoaded;
            }
        },
        /**
         * Get my files
         */
         {
            name: 'getFiles',
            funcName: 'getFiles',
            onDone: (data, success, message, meta) => {
                if (success) {
                    dispatch({
                        type: actions.SET_FILES,
                        payload: { data, meta }
                    });
                }
            },
            runOnceCondition: (state, queryParams) => {

                if (state.files?.data && queryParams.length) {
                    return false;
                }

                return state.files?.isLoaded;
            }
        },
        /**
         * Get single file
         */
         {
            name: 'getFile',
            funcName: 'getFile',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_FILE,
                        payload: data
                    });
                }
            },
            runOnceCondition: (state, queryParams) => {
                if (state.files && state.files.current && queryParams && queryParams[0]) {
                    if (state.files.current.id.toString() === queryParams[0]) {
                        return true;
                    }
                }
                return false;
            }
        },
        /**
         * Get orders
         */
        {
            name: 'getOrders',
            funcName: 'getOrders',
            onDone: (data, success, message, meta) => {
                if (success) {
                    dispatch({
                        type: actions.SET_ORDERS,
                        payload: { data, meta }
                    });
                }
            },
            runOnceCondition: (state, queryParams) => {

                if (state.orders?.data && queryParams.length) {
                    return false;
                }

                return state.orders?.isLoaded;
            }
        },
        /**
         * Get current order
         */
        {
            name: 'getOrder',
            funcName: 'getOrder',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_CURRENT_ORDER,
                        payload: data
                    });
                }
            },
            runOnceCondition: (state, queryParams) => {
                if (state.orders && state.orders.current && queryParams && queryParams[0]) {
                    if (state.orders.current.id.toString() === queryParams[0]) {
                        return true;
                    }
                }
                return false;
            }
        },
        /**
         * Get current timeline
         */
        {
            name: 'getTimeline',
            funcName: 'getTimeline',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_CURRENT_TIMELINE,
                        payload: data
                    });
                }
            },
            runOnceCondition: (state, queryParams) => {
                if (state.orders && state.orders.current && queryParams && queryParams[0]) {
                    if (state.orders.current.id.toString() === queryParams[0]) {
                        return true;
                    }
                }
                return false;
            }
        },
        /**
         * Get order stats
         */
        {
            name: 'getOrdersStats',
            funcName: 'getOrdersStats',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_ORDERS_STATS,
                        payload: data
                    });
                }
            },
            runOnceCondition: (state, queryParams) => {
                if (state.orderMetrics && queryParams && queryParams[0]) {
                    if (state.orderMetrics.orderId.toString() === queryParams[0]) {
                        return true;
                    }
                }
                return false;
            }
        },
        /**
         * Get order metrics
         */
        {
            name: 'getOrderMetrics',
            funcName: 'getOrderMetrics',
            onDone: (data, success, message, meta, queryParams) => {
                if (success) {
                    dispatch({
                        type: actions.SET_ORDER_METRICS,
                        payload: {
                            data,
                            orderId: queryParams[0]
                        }
                    });
                }
            },
            runOnceCondition: (state, queryParams) => {
                return false;
            }
        },
        {
            name: 'getInvoice',
            funcName: 'getInvoice',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_INVOICE,
                        payload: data
                    });
                }
            },
        },
    ];

    useEffect(() => {

        if (prevent) {
            return;
        }

        let didCancel = false;

        suppressLoadingState = false;
        if (cachedQueryId !== localQueryId) {
            cachedQueryId = localQueryId;
            suppressLoadingState = true;
        }

        setQueriesDone({});

        if (typeof cbBefore === 'function') cbBefore();
        if (setLoadingState && !suppressLoadingState) {
            dispatch({
                type: actions.SET_IS_LOADING,
                payload: true
            });
        }

        console.log('[useData] re-rendering');

        if (queriesCached && queriesCached.length > 0) {
            for (let i = 0; i < queriesCached.length; i++) {

                let queryName = queriesCached[i];
                let queryParams = [];
                if (Array.isArray(queriesCached[i])) {
                    queryName = queriesCached[i][0];
                    queryParams = queriesCached[i][1];
                }
                const queryId = getQueryId(queriesCached[i]);

                const setDone = () => {
                    queriesDone[queryId] = true;
                    setQueriesDone({ ...queriesDone });
                };

                const supportedQuery = supportedQueries.find(query => query.name === queryName);
                if (!supportedQuery) {
                    setDone();
                    console.error('[useData] query not found', queryName);
                    continue;
                }

                if (runOnce && typeof supportedQuery.runOnceCondition === 'function' && supportedQuery.runOnceCondition(state, queryParams)) {
                    setDone();
                    console.log('[useData] run once condition is true', queryName);
                    continue;
                }

                DataController[supportedQuery.funcName](...queryParams).then(({ data, success, message, meta }) => {
                    if (didCancel) return;
                    console.log('[useData] DataController', supportedQuery.funcName, data, queriesDone);
                    setDone();
                    if (!success && typeof cbError === 'function') {
                        cbError({ data, success, message, query: supportedQuery.name });
                    }
                    if (typeof supportedQuery.onDone === 'function') {
                        supportedQuery.onDone(data, success, message, meta, queryParams);
                    }
                });
            }
        }

        return () => {
            didCancel = true;
        };

    }, [queriesCached, state.queriesId, localQueryId]);

    // const handleVisibilityChange = useCallback(() => {
    //     console.log('handleVisibilityChange');
    //     if (document.visibilityState === 'hidden' && pollingTimeoutId) {
    //         console.log('[useData] polling pausing because window blurred');
    //         window.clearTimeout(pollingTimeoutId);
    //         setPollingTimeoutId(0);
    //     }
    // }, [pollingTimeoutId]);

    // clear polling timeout
    useEffect(() => {
        return () => {
            if (pollingTimeoutId) window.clearTimeout(pollingTimeoutId);
        };
    }, []);

    useEffect(() => {
        if (pollingTimeout && !isNaN(pollingTimeout) && !pollingTimeoutId) {
            if (!isWindowVisible) {
                console.log('[useData] polling stopped');
                return;
            }
            const newTimeoutId = window.setTimeout(() => {
                setQueriesDone({});
                setLocalQueryId(localQueryId + 1);
                setPollingTimeoutId(0);
            }, pollingTimeout);
            console.log('[useData] polling starting', newTimeoutId);
            setPollingTimeoutId(newTimeoutId);
        }
    }, [isWindowVisible, queriesDone]);

    // check if all queries done
    useEffect(() => {

        let allDone = true;
        for (let i = 0; i < queriesCached.length; i++) {
            const queryId = getQueryId(queriesCached[i]);
            if (!queriesDone[queryId]) {
                allDone = false;
                break;
            }
        }

        if (allDone) {
            console.log('[useData] queries complete', queriesDone);
            if (typeof cbAfter === 'function') cbAfter();
            if (setLoadingState && !suppressLoadingState) {
                dispatch({
                    type: actions.SET_IS_LOADING,
                    payload: false
                });

            }
        }
    }, [queriesDone, queriesCached.length]);

    return state;
};

export default useData;
