import React, {useState} from "react";
import Box from '@material-ui/core/Box';
import {Button, makeStyles} from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import {ReactComponent as ExternalLink} from "../../../images/editPrimery.svg";
import DataController from "../../../lib/controllers/DataController";
import actions from "../../../store/actions";
import useAppState from "../../../lib/hooks/useAppState";
import {useSnackbar} from "notistack";
import LoadingButton from "../common/LoadingButton";

const useStyles = makeStyles((theme) => ({
    root: {},
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    title: {
        fontSize: 20,
        lineHeight: '25px'
    },
    edit: {
        cursor: 'pointer',
        display: 'hidden'
    },
    text: {
        fontSize: 17,
        lineHeight: '24px',
        textDecoration: 'underline'
    },
    cancelBtn: {
        marginRight: 12,

        '& span': {
            fontWeight: "400"
        }
    }
}));

function OrderViewURL({ order }) {
    const [isEditMode, setIsEditMode] = useState(false);
    const [text, setText] = useState(order.filenotes.campaign_url || '');
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const { dispatch } = useAppState();
    const { enqueueSnackbar } = useSnackbar();

    async function handleDoneClick() {
        setLoading(true);
        try {
            const { message, success } = await DataController.updateCampaignUrl({orderId: order.id, link: text});
            if (success) {
                dispatch({
                    type: actions.UPDATE_CAMPAIGN_URL,
                    payload: text
                });
                enqueueSnackbar('Please allow up to 24 hours for the new url to sync with your campaign.');
            } else {
                enqueueSnackbar(message, {
                    variant: 'error',
                });
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            setIsEditMode(false);
        }
    }

    if (!order?.filenotes?.campaign_url) return '';

    return (
        <Box className={classes.root}>
            <Box mb={1} className={classes.titleContainer}>
                <span className={classes.title}>Campaign URL</span>
                <div>
                    {!isEditMode ? (
                        order.is_editable_url && <ExternalLink className={classes.edit} onClick={() => {setIsEditMode(!isEditMode)}} />
                    ) : (
                        <>
                            <Button
                                className={classes.cancelBtn}
                                size='small'
                                onClick={() => setIsEditMode(false)}
                            >
                                cancel
                            </Button>
                            <LoadingButton
                                variant="outlined"
                                disabled={!text}
                                onClick={handleDoneClick}
                                size='small'
                                loading={loading}
                            >
                                Save
                            </LoadingButton>
                        </>
                    )}
                </div>
            </Box>
            {isEditMode ? (
                <TextField
                    placeholder="https://"
                    value={text}
                    onChange={(e) => setText(e.target.value)
                }
              />
            ) : (
                <a
                    href={order.filenotes.campaign_url}
                    target="_blank"
                    rel="noopener"
                    className={classes.text}>
                    {order.filenotes.campaign_url}
                </a>
            )}
        </Box>
    );
}

export default OrderViewURL;
