import { useLocation, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { scrollTo } from '../../../lib/functions.js';
import useAppState from '../../../lib/hooks/useAppState.js';
import actions from '../../../store/actions.js';

let scrollToInvtl;
let scrollToInvtlCount = 0;

function _scrollTo(targetEl, yOffset = 0){
    const y = targetEl.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
}

function HashListener() {

    const location = useLocation();
    const history = useHistory();
    const { dispatch } = useAppState();

    useEffect(() => {
        if (location.hash) {
            if (scrollToInvtl) window.clearTimeout(scrollToInvtl);

            const clearHash = () => {
                const state = history.location.state;
                history.replace(`${location.pathname}${location.search}`);
                if (state && state.from === 'timeline') {
                    history.go(-1);
                }
            };

            if (location.hash.startsWith('#/')) {
                // if hash starts with #/ they are used to dispatch an action which can load a view
                dispatch({
                    type: actions.HASH_VIEW,
                    payload: location.hash
                });
                clearHash();
                return;
            }

            scrollToInvtl = window.setInterval(() => {
                scrollToInvtlCount++;
                const targetEl = document.querySelector(location.hash);
                if (targetEl) {
                    window.clearTimeout(scrollToInvtl);
                    //scrollTo(targetEl, 300); //TODO not working as expected, need to be checked
                    _scrollTo(targetEl, -100); //TODO temp fix
                    clearHash();
                }
                if (scrollToInvtlCount > 50) {
                    console.warn('[ScrollToAnchor] given hash was not found in DOM');
                    window.clearTimeout(scrollToInvtl);
                }
            }, 100);

        }
    }, [location]);

    return '';
}

export default HashListener;
