import React, { useState } from 'react';
import { Box, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import MoreMenu from '../common/MoreMenu';
import DataController from 'lib/controllers/DataController';
import { actions } from 'store/store.js';
import useAppState from 'lib/hooks/useAppState';
import useData from 'lib/hooks/useData';
import { useSnackbar } from "notistack";

const useStyles = makeStyles(theme => ({
    wrap: {
        maxWidth: 792,  // TODO - this is repeatable maxWidth (FieldGroup) - maybe set globally
    },
    
    listItem: {
        paddingLeft: 0,
        paddingRight: 0,
        color: theme.palette.neutral[800],
        borderBottom: `1px solid ${theme.palette.neutral[300]}`,
    },
    
    actionWrapper: {
        right: 0,
        display: 'flex',
        alignItems: 'center',
    }
}));


export default function ClientList({ teamMember }) {
    const { dispatch } = useAppState();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [loading, setLoading] = useState(false)

    useData({
        queries: ['getMyClients'],
        setLoadingState: true
    });

    const handleRemoveClient = (companyId) => {
        if (loading) return 
        setLoading(companyId);

        DataController
            .removeClientsFromMember({ memberId: teamMember.id, companyIds: companyId })
            .then(({ data, errors, message, success }) => {
                if (success) {
                    dispatch({
                        type: actions.UPDATE_ROW,
                        payload: {
                            location: 'myTeam.contacts',
                            data: data
                        }
                    });
                    enqueueSnackbar('Client successfully removed!');

                } else {
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                }
                setLoading(false);
            });
    }

    const handleUpdateUserRole = (role, companyId) => {
        if (loading) return

        setLoading(true);

        const contactId = teamMember.id;

        DataController
            .postTeamMemberRole({ role, companyId, contactId })
            .then(({ data, errors, message, success }) => {
                if (success) {
                    dispatch({
                        type: actions.UPDATE_ROW,
                        payload: {
                            location: 'myTeam.contacts',
                            data: data
                        }
                    });
                    enqueueSnackbar('Contact successfully updated!');

                } else {
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                }
                setLoading(false);
            });
    }

    return (
        <Box className={classes.wrap}>
            <List>
                {teamMember && teamMember.clients && teamMember.clients.map(client => {
                    const items = [];

                    if (client.is_admin) {
                        items.push(...[
                            { label: 'Downgrade to client member', onClick: () => { handleUpdateUserRole('member', client.id) } },
                            { label: 'Remove from client', onClick: () => { handleRemoveClient(client.id) } },
                        ])
                    } else if (client.is_member) {
                        items.push(...[
                            { label: 'Upgrade to client admin', onClick: () => { handleUpdateUserRole('admin', client.id) } },
                            { label: 'Remove from client', onClick: () => { handleRemoveClient(client.id) } },
                        ])
                    }

                    return (
                        <ListItem key={client.id} className={classes.listItem}>
                            <ListItemText
                                primary={client.name}
                            />
                            <ListItemSecondaryAction className={classes.actionWrapper}>
                                {client.is_editable && items.length > 0 &&
                                    <MoreMenu 
                                        items={items}
                                        loading={ loading === client.id } 
                                    />
                                }
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}   
            </List>
        </Box>
    )
}