import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from "@material-ui/core/Button";
import PlaidButton from "../form/PlaidButton";
import {ReactComponent as TrashIcon} from "../../../images/trash.svg";
import FieldGroup from '../../elements/form/FieldGroup';
import StripeInputWrap from "../../elements/form/StripeInputWrap";
import {ReactComponent as CardIcon} from "../../../images/card.svg";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from '@stripe/react-stripe-js';
import { ReactComponent as CreditCardIcon } from 'images/credit-card.svg';
import { ReactComponent as BankIcon } from 'images/bank.svg';
import {TextField} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
  },
  formWrap: {
    padding: 20,
  },
  creditCardBtn: {
    backgroundColor: theme.palette.neutral[900],
    border: '1px solid #3F3F3F',
    padding: "12px 20px",

    '& .MuiButton-label': {
      color: theme.palette.white,
      fontSize: 17,
      lineHeight: '24px',
      fontWeight: 'normal'
    }
  },
  bankAccountBtn: {
    border: '1px solid #3F3F3F',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    paddingRight: 10,

    '& .MuiButton-label': {
      fontFamily: 'Neutrif Studio',
      color: theme.palette.neutral[900],
      fontSize: 17,
      lineHeight: '24px',
      fontWeight: 'normal',
      marginLeft: 10,
    }
  },
  bankIcon: {
    '& path': {
      fill: theme.palette.primary.main,
      stroke: theme.palette.primary.main
    },
  },
  bankName: {
    fontWeight: 800,
    fontSize: 17,
    marginTop: 25,
    lineHeight: '24px',
    color: theme.palette.neutral[900],
  },
  bankAccount: {
    fontSize: 15,
    marginTop: 10,
    lineHeight: '20px',
    color: theme.palette.neutral[700],
    marginBottom: 10
  },
  editAccount: {
    '& span': {
      fontSize: 15,
      color: theme.palette.primary.main,
    }
  },
}));

export default function FirstTimeBuyerPayment({ handlePayClick, orderId }) {

  const classes = useStyles();
  const [paymentType, setPaymentType] = useState('stripe');
  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardCvcComplete, setCardCvcComplete] = useState(false);
  const [nickname, setNickname] = useState('');
  const [plaidData, setPlaidData] = useState({
    publicToken: '',
    accountId: '',
    institutionName: '',
    mask: ''
  });
  const elements = useElements();
  const stripe = useStripe();

  const paymentIsReady = paymentType === 'stripe'
    ? cardNumberComplete && cardExpiryComplete && cardCvcComplete
    : plaidData.publicToken && plaidData.accountId

  function handlePaymentMethodAdded({metadata}) {
    setPlaidData({
      publicToken: metadata.public_token,
      accountId: metadata.account_id,
      mask: metadata.account.mask,
      institutionName: metadata.institution.name,
    });
    setPaymentType('ach_debit');
  }

  function handleDeleteBankInfo() {
    setPlaidData({
      publicToken: '',
      accountId: '',
      institutionName: '',
      mask: ''
    });
    setPaymentType('stripe');
    setCardNumberComplete(false);
    setCardExpiryComplete(false);
    setCardCvcComplete(false);
  }

  const handlePay = async () => {
    if (paymentType === 'stripe') {
      if (!stripe || !elements) {
        return;
      }
      const cardNumberElement = elements.getElement(CardNumberElement);
      const {error, token} = await stripe.createToken(cardNumberElement);
      if (error) {
        return;
      }
      handleCreditCardPay(token)
    } else {
      handleBankPay()
    }
  }

  function handleBankPay() {
    // Pay with Bank account
    handlePayClick({
      token: plaidData.publicToken,
      accountId: plaidData.accountId,
      tokenType: 'ach'
    });
  }

  function handleCreditCardPay(token) {
    // Pay with Credit card
    handlePayClick({
      token: token.id,
      tokenType: 'card',
      nickname
    });
  }

  function handleChange({complete, elementType}) {
    if (elementType === 'cardNumber') {
      setCardNumberComplete(complete);
      return;
    }
    if (elementType === 'cardExpiry') {
      setCardExpiryComplete(complete);
      return;
    }
    if (elementType === 'cardCvc') {
      setCardCvcComplete(complete);
    }
  }

  return (
    <Box className={classes.formWrap}>
      <Button onClick={handlePay} id="firstBuyerSubmitBtn" />
      <Box>
        <ButtonGroup variant="outlined">
          <Button className={classes.creditCardBtn} onClick={handleDeleteBankInfo}>
            <CreditCardIcon />
            Add a Credit Card
          </Button>
          <PlaidButton
            successCallback={handlePaymentMethodAdded}
            triggerAchTokenOnly={true}
            orderId={orderId}
            ButtonComponent={Button}
            ButtonComponentProps={{
              children: <Box display="flex"><BankIcon className={classes.bankIcon}/><span>Add a Bank Account</span></Box>,
              className: classes.bankAccountBtn,
            }}
          />
        </ButtonGroup>
      </Box>
      {paymentType === 'stripe' ? (
        <>
          <FieldGroup>
            <TextField
              value={nickname}
              label="Card nickname"
              onChange={(e) => setNickname(e.target.value)}
            />
          </FieldGroup>
          <FieldGroup>
            <StripeInputWrap
              label="Card number"
              Component={CardNumberElement}
              endAdornment={<CardIcon/>}
              onChangeCallback={handleChange}
            />
          </FieldGroup>
          <FieldGroup half>
            <StripeInputWrap
              label="Expiry date"
              Component={CardExpiryElement}
              onChangeCallback={handleChange}
            />
            <StripeInputWrap
              label="CVC"
              Component={CardCvcElement}
              onChangeCallback={handleChange}
            />
          </FieldGroup>
        </>
      ) : (
        <>
          <div className={classes.bankName}>{plaidData.institutionName}</div>
          <div className={classes.bankAccount}>Account: ****{plaidData.mask}</div>
          <Button
            variant="text"
            className={classes.editAccount}
            onClick={handleDeleteBankInfo}
          >
            <TrashIcon/> Delete bank information
          </Button>
        </>
      )}
    </Box>
  );
}
