let ZipsLayerClass = null

export const ZIP_LAYER_MIN_ZOOM = 11

//The class in created at the run time because it requires Leaflet library to be loaded (it expends "L.Class.")
export function getLeafletZipsLayerClass(params) {
    if (!ZipsLayerClass) {
        ZipsLayerClass = window.L.Class.extend({
            initialize: function (options) {
                var that = this

                this._hoveredFeature = null

                this._mvtSource = new window.L.TileLayer.MVTSource(
                {
                    minZoom: ZIP_LAYER_MIN_ZOOM,
                    url: 'https://api.mapbox.com/v4/' + params.mapId + '/{z}/{x}/{y}.mvt?access_token=' + params.mapboxAccessToken,
                    clickableLayers: [params.layerId],
                    getIDForLayerFeature: function (feature) {
                        return feature.properties.zip
                    },
                    style: function (feature) {
                        if (
                            that._hoveredFeature &&
                            that._hoveredFeature.id === feature.id
                        ) {
                            return ZipsLayerClass.HOVERED_STYLE
                        } else {
                            return ZipsLayerClass.STYLE
                        }
                    },

                    //this iterator is just uptimized way (without new array creation) of array sorting
                    //sorting rule is the following: first unhovered and unselected features,
                    //then selected features and then hovered feature
                    featureIteratorFactory: function (features) {
                        var state = 'main' //main, selected, hovered, done
                        var selectedFeatures = []
                        var idx = 0
                        var f = null
                        return function next() {
                            switch (state) {
                            case 'main':
                                if (idx === features.length) {
                                    state = 'selected'
                                    idx = 0
                                    return next()
                                }
                                if (features[idx] === that._hoveredFeature) {
                                    idx++
                                    return next()
                                }
                                if (features[idx].selected) {
                                    selectedFeatures.push(features[idx++])
                                    return next()
                                }

                                return features[idx++]
                            case 'selected':
                                if (idx === selectedFeatures.length) {
                                    state = 'done'
                                    for (var i = 0; i < features.length; i++) {
                                        if (features[i] === that._hoveredFeature) {
                                            return that._hoveredFeature
                                        }
                                    }
                                } else {
                                    return selectedFeatures[idx++]
                                }
                            }
                        }
                    },
                    ...options
                })
            },

            updateSelectedFeatures: function (selectedFeatures) {
                var layer = this._mvtSource.layers[params.layerId]
                if (!layer) {
                    return
                }
                for (var idx in layer.features) {
                    var f = layer.features[idx]
                    if (f.selected && !selectedFeatures[idx]) {
                        f.deselect()
                    } else if (!f.selected && selectedFeatures[idx]) {
                        f.select()
                    }
                }
            },

            forEachFeature(callback) {
                var layer = this._mvtSource.layers[params.layerId]
                if (!layer) {
                    return
                }
                for (var idx in layer.features) {
                    callback(layer.features[idx])
                }
            },

            setHoveredFeature: function (id) {
                var layer = this._mvtSource.layers[params.layerId]

                if (!layer) {
                    return
                }

                var f = id ? layer.features[id] : null
                var prevFeature = this._hoveredFeature

                this._hoveredFeature = f

                if (prevFeature && (!f || prevFeature.id !== f.id)) {
                    prevFeature.redraw(ZipsLayerClass.STYLE)
                }

                if (f && (!prevFeature || prevFeature.id !== f.id)) {
                    f.redraw(ZipsLayerClass.HOVERED_STYLE)
                }
            },

            onAdd: function (map) {
                map.addLayer(this._mvtSource)
            },

            onRemove: function (map) {
                map.removeLayer(this._mvtSource)
            },

            updateOptions: function(options) {
                window.L.Util.setOptions(this._mvtSource, options)
            },

            setFilter: function(filter) {
                this._mvtSource.setFilter(filter)
                setTimeout(() => {
                    this._mvtSource.redraw()
                }, 0)
            },
        })

        ZipsLayerClass.SELECTED_STYLE = {
            color: 'rgba(251,153,85,0.2)',
            outline: {
                color: 'rgba(251,153,85, 1)',
                size: 2,
            },
        }

        ZipsLayerClass.HOVERED_SELECTED_STYLE = {
            color: 'rgba(251,153,85,0.2)',
            outline: {
                color: 'rgba(149, 126, 254, 1)',
                size: 2,
            },
        }

        

        ZipsLayerClass.STYLE = {
            color: 'rgba(149, 126, 254, 0.0)',
            outline: {
                color: 'rgba(149, 126, 254, 0.2)',
                size: 2,
            },
            selected: ZipsLayerClass.SELECTED_STYLE,
        }

        ZipsLayerClass.HOVERED_STYLE = {
            color: 'rgba(149, 126, 254, 0.0)',
            outline: {
                color: 'rgba(149, 126, 254, 1)',
                size: 2,
            },
            selected: ZipsLayerClass.HOVERED_SELECTED_STYLE,
        }
    }

    return ZipsLayerClass
}