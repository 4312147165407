import { makeStyles, Button } from '@material-ui/core';
import { ReactComponent as MailIcon } from 'images/mail.svg';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.common.white,
        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.08)',
        borderRadius: 10,
        padding: 20,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: 16,
    },

    titleRow: {
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
    },
    title: {
        fontFamily: 'Ahkio',
        fontSize: 21,
        marginRight: 16,
    },
    icon: {
        width: 33,
        height: 33,
        backgroundColor: '#3E729C',
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    priceRow: {
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
    },
    price: {
        fontWeight: 800,
        fontSize: 15,
        width: 80
    }
}))

export default function CampaignSummaryMobile({
    title,
    priceFormatted,
    onClick
}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.titleRow}>
                <div className={classes.title}>{title}</div>
                <div className={classes.icon}>
                    <MailIcon />
                </div>
            </div>
            <div className={classes.priceRow}>
                <div className={classes.price}>{priceFormatted}</div>
                <Button variant="contained" onClick={onClick}>Customize Order</Button>
            </div>
        </div>
    )
}