import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button, Stepper, StepButton, Step } from '@material-ui/core';
import BackArrow from "components/elements/common/BackArrow";
import { useHistory } from "react-router";
import StepperComponent from '../common/Stepper.js';
import { ReactComponent as StepError } from 'images/stepError.svg';
import CheckoutWIP from './CheckoutWIP';
import CheckoutList from './CheckoutList';
import CheckoutPayment from './CheckoutPayment.js';
import { ReactComponent as EditIcon } from 'images/editPrimery.svg';
import { ReactComponent as EmptyCart } from 'images/emptyCart.svg';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '33px 0',
        margin: 0
    },
    stepper: {
        padding: 8,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    stepItem: {
      "& .MuiStepIcon-active": {
        color: "red"
      },
    },
    stepperWrapper: {
      padding: '36px 0px',
      margin: '0 33px',
    },
    errorIconWrapper: {
      position: 'absolute',
      top: 12,
      left: 32
    },
    infoWrapper: {
      paddingLeft: '36px',
      fontWeight: 400,
      letterSpacing: '-0.5px',
      color: theme.palette.neutral[900],
      fontSize: 55
    },
    messageWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "100px 0 100px 0",

      "& span": {
        fontSize: 20,
        color: theme.palette.neutral[800],
        marginTop: 16
      }
    },
    headerWrapper: {
        display: 'flex',
        width: '100%',
        padding: '0 33px',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.neutral[300]}`,

        '& .MuiBox-root': {
            borderBottom: 0,
            marginBottom: 0
        }
    },
    reviewWrapper: {
        display:"flex",
        justifyContent: "space-between",
        marginTop: 50,
        padding: '0 24px',
        fontSize: 15,
        lineHeight: '25px',
        '& p': {
          fontSize: 21,
          color: theme.palette.neutral[900],
          fontWeight: 400,
        },
        [theme.breakpoints.down('sm')]: {
          padding: '0 16px',
        }
    },
    title: {
      fontSize: 55,
      fontWeight: 400,
      lineHeight: '100%',
      margin: '0 24px',

      [theme.breakpoints.down('md')]: {
        fontSize: 34,
      }
    },
    editOrder: {
      '& .MuiButton-label': {
        fontWeight: 'normal'
      }
    },
    editIcon: {
      marginLeft: 6.5
    },
}));

function CheckoutBody({
  items,
  updateItem,
  steps,
  currStep,
  switchStep,
  handleAddonChange,
  getFieldProps,
  formData,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  errors,
  cart,
  isInvoicePayment,
  isCartProcessed,
  deleteCartItem,
  cartErrors,
  dateErrors,
  handlePayClick
}) {
  const classes = useStyles();
  const history = useHistory();
  const defaulTitle = 'Do I need my ad ready today?';
  const ccTitle = "Your next steps";
  const ccDescription =
    "Just make sure you upload your ad(s) before the artwork deadline. \
                     Not ready yet? No problem, you can access these next steps anytime \
                     from Your Orders. And if you need help or have questions, we’re here for you.";
  const defaultDescription =
    "Nope. You purchase your space now and we’ll \
                     remind you to upload your ad before the artwork deadline.";
  const wipTitle = isCartProcessed && !isInvoicePayment ? ccTitle : defaulTitle;
  const wipDescription =
    isCartProcessed && !isInvoicePayment ? ccDescription : defaultDescription;
  const disableUpdate = currStep.title !== steps[0].title;
  const hideAddon = currStep.title !== steps[1].title;
  const showAddonPricing =
    currStep.title === steps[2].title || currStep.title === steps[3].title;
  const showCTA = currStep.title === "All Set";

  const showCalloutText = currStep.title === steps[0].title || currStep.title === steps[3].title;
  const disableDelete = currStep.title === steps[3].title;

  const goToCart = () => {
    switchStep(steps[0]);
  };

  const getInvoiceInfo = () => {
    if (!cart?.is_invoiceable) {
      return null;
    } else {
      return {
        id: "invoice",
        title: "Invoice me",
        payment_terms: cart?.payment_terms ? `Your ${cart?.payment_terms} payment terms are pre-approved.` : '',
      };
    }
  };

  const invoiceInfo = getInvoiceInfo();
  const isDateError = !!Object.keys(dateErrors).length;
  const isCartError = !!Object.keys(cartErrors).length;
  const label = ['https://qa.addy.co/', 'https://addy.co/', ''].includes(document.referrer) ? 'Keep Shopping' : 'Back'

  const handleBack = () => {
    if (['https://qa.addy.co/', 'https://addy.co/'].includes(document.referrer)) {
      window.open(`${document.referrer}shop`, '_self');
      return;
    }
    if (!document.referrer) {
      const url = window.location.host === 'checkout-qa.addy.co' ? 'https://qa.addy.co/shop' : 'https://addy.co/shop';
      window.open(url, '_self');
      return;
    }
    history.goBack();
  }

  const handleClick = (step) => {
    if (currStep.index === 3) {
      return;
    }
    switchStep(step)
  }

  if (!cart) {
    return (
      <Box className={classes.root}>
        <div className={classes.headerWrapper}>
          <BackArrow
            className={classes.backArrow}
            label={label}
            onClick={handleBack}
          />
        </div>
        <Box className={classes.stepperWrapper}>
          <StepperComponent
            id="checkout_page"
            className={classes.stepper}
            fileSteps={steps}
          />
          <Box className={classes.infoWrapper}>
            Shopping Cart
          </Box>
          <Box className={classes.messageWrapper}>
            <EmptyCart />
            <span>Sorry, this cart is not available.</span>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <div className={classes.headerWrapper}>
        <BackArrow
          className={classes.backArrow}
          label={label}
          onClick={handleBack}
        />
      </div>
      <Box className={classes.stepperWrapper}>
        <Stepper
          id="checkout_page"
          className={classes.stepper}
          activeStep={currStep.index}
        >
          {steps.map((step, index) => {
            const showStepError = ((isDateError && index === 0) || (isCartError && index === 1));
            return (
              <Step
                key={index}
                active={index <= currStep.index}
                completed={false}
                className={showStepError && classes.stepItem}
              >
                <StepButton onClick={() => handleClick(step)}>
                  {step.title}
                  {showStepError && (
                    <div className={classes.errorIconWrapper}>
                      <StepError />
                    </div>
                  )}
                </StepButton>
              </Step>
            )
          })}
        </Stepper>
      </Box>
      <Box>
        <Box>
          <Typography className={classes.title}>{currStep.label}</Typography>
        </Box>

        {showCalloutText && <CheckoutWIP wipTitle={wipTitle} wipDescription={wipDescription}/> }

        {currStep.title === "Payment" && (
          <CheckoutPayment
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            getFieldProps={getFieldProps}
            formData={formData}
            errors={errors}
            cart={cart}
            invoiceInfo={invoiceInfo}
            handlePayClick={handlePayClick}
          />
        )}

        {currStep.title === "Payment" && (
          <Box className={classes.reviewWrapper}>
            <Typography>Review your order</Typography>
            <Button onClick={goToCart} className={classes.editOrder}>
              edit order <EditIcon className={classes.editIcon} />
            </Button>
          </Box>
        )}
        <CheckoutList
          items={items}
          updateItem={updateItem}
          disableUpdate={disableUpdate}
          hideAddon={hideAddon}
          handleAddonChange={handleAddonChange}
          showAddonPricing={showAddonPricing}
          showCTA={showCTA}
          isInvoicePayment={isInvoicePayment}
          deleteCartItem={deleteCartItem}
          disableDelete={disableDelete}
          cartErrors={cartErrors}
          dateErrors={dateErrors}
        />
      </Box>
    </Box>
  );
}

export default CheckoutBody;
