import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import { ReactComponent as PlusIcon } from 'images/plus.svg';
import { ReactComponent as ChevronIcon } from 'images/chevron-down.svg';
import { ReactComponent as CreditCardIcon } from 'images/credit-card.svg';
import { ReactComponent as BankAccountIcon } from 'images/bank-account.svg';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import { useHistory } from 'react-router';
import PlaidButton from '../form/PlaidButton.js';
import AccountSectionHeader from '../common/AccountSectionHeader.js';
import { becomesIconButtonResponsive } from '../../../theme/mixins.js';

const useStyles = makeStyles(theme => ({
    cta: {
        height: 48,
        width: 280,
        ...becomesIconButtonResponsive(theme)
    },
    menu: {
        transform: 'translateY(2px)',

        '& .MuiPopover-paper': {
            backgroundColor: theme.palette.neutral[900]
        },

        '& .MuiListItem-root': {
            color: theme.palette.common.offWhite,
            minWidth: 196,

            '&:hover': {
                backgroundColor: theme.palette.neutral[800]
            }
        }
    },
    menuItemContent: {
        display: 'flex'
    },

    icon: {
        width: 32,

        '& .creditCardIcon path': {
            stroke: theme.palette.common.offWhite
        }
    }
}));

function PaymentMethodsHeader() {

    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAddCC = e => {
        e.preventDefault();
        history.push('/account/paymentmethods/create/card');
    };

    return <AccountSectionHeader
        title="Payment Methods"
        rightSide={<>
            <Button
                className={classes.cta}
                size="small"
                variant="contained"
                startIcon={<PlusIcon/>}
                endIcon={<ChevronIcon/>}
                onClick={handleOpenMenu}
            >
                <Hidden smDown>Add Payment Method</Hidden>
            </Button>
            <Menu
                disableEnforceFocus
                className={classes.menu}
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <MenuItem onClick={handleAddCC}>
                    <div className={classes.menuItemContent}><span
                        className={classes.icon}><CreditCardIcon
                        className={'creditCardIcon'}/></span><span>Credit Card</span></div>
                </MenuItem>
                <PlaidButton
                    onButtonComponentClick={handleClose}
                    ButtonComponent={MenuItem}
                    ButtonComponentProps={{
                        children: <div className={classes.menuItemContent}><span
                                       className={classes.icon}><BankAccountIcon/></span><span>Bank Account</span>
                                  </div>
                             }}/>
            </Menu>
        </>}
        hasFilter/>;
}

export default PaymentMethodsHeader;
