import React, { useEffect, useState } from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, Divider, DialogActions, Button, Box } from '@material-ui/core';
import ClientSelect from '../common/ClientSelect';
import useAppState from 'lib/hooks/useAppState';
import { useSnackbar } from 'notistack';
import DataController from 'lib/controllers/DataController';
import actions from 'store/actions';
import LoadingButton from '../common/LoadingButton';

export default function AssignClientDialog({ handleClose, open, teamMember }) {

    const { dispatch } = useAppState();
    const { enqueueSnackbar } = useSnackbar();
    
    const [loading, setLoading] = useState(false);
    const [assignedClients, setAssignedClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);

    useEffect(() => {
        setAssignedClients(teamMember && teamMember.clients ? teamMember.clients.map(client => client.id) : []);
        setSelectedClients(teamMember && teamMember.clients ? teamMember.clients.map(client => client.id) : []);
    }, [teamMember]);


    const handleAssignClients = async (e) => {
        e.preventDefault();
        setLoading(true);

        const toRemove = assignedClients.filter(id => !selectedClients.includes(id));
        const toAssign = selectedClients.filter(id => !assignedClients.includes(id));

        let finalUserData;
        let error = false;

        if (toAssign.length > 0 && !error) {
            let companyIds = toAssign.join(',');
            const { data, errors, message, success } = await DataController.addClientsToMember({ memberId: teamMember.id, companyIds })

            if (success) {
                finalUserData = data;
            } else {
                error = message;
            }
        }

        if (toRemove.length > 0 && !error) {
            let companyIds = toRemove.join(',');
            const { data, errors, message, success } = await DataController.removeClientsFromMember({ memberId: teamMember.id, companyIds })

            if (success) {
                finalUserData = data;
            } else {
                error = message;
            }
        }

        if (error) {
            enqueueSnackbar(error, {
                variant: 'error',
            });
        } else if (toRemove.length === 0 && toAssign.length === 0) {
            enqueueSnackbar('Nothing to update!');
            handleClose();
            setLoading(false);
        } else {
            dispatch({
                type: actions.UPDATE_ROW,
                payload: {
                    location: 'myTeam.contacts',
                    data: finalUserData
                }
            });
            enqueueSnackbar('Clients updated successfully!');
            handleClose();
            setLoading(false);
        }
    };

    return (
        <Dialog
            onClose={() => {
                handleClose();
            }}
            open={open}
        >
            <DialogTitle disableTypography>
                <Typography color="textSecondary">Assign to Clients</Typography>
                <Typography variant="h4">{teamMember ? teamMember.fullname : ''}</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Box mb={3}>
                    <Typography>You can assign more than one client to a team member.</Typography>
                </Box>
                <ClientSelect 
                    selectedClients={selectedClients}
                    setSelectedClients={setSelectedClients}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={false}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    color="default"
                    type="submit"
                    disabled={false}
                    onClick={handleAssignClients}
                    loading={loading}
                >
                    Assign
                </LoadingButton>
            </DialogActions>

        </Dialog>
    )
}
