import Drawer from '@material-ui/core/Drawer';
import DataController from '../../../lib/controllers/DataController.js';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import useUIMeasurements from '../../../lib/hooks/useUIMeasurements.js';
import Box from '@material-ui/core/Box';
import { ReactComponent as CloseCircleIcon } from '../../../images/closeCircle.svg';
import { ReactComponent as CheckmarkButtonIcon } from '../../../images/checkmarkButton.svg';
import { ReactComponent as MagnifierSquareIcon } from '../../../images/magnifierSquare.svg';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DataTable from '../common/DataTable.js';
import Radio from '@material-ui/core/Radio';
import { format, parseISO } from 'date-fns';
import FilePreviewDialog from '../common/FilePreviewDialog.js';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1
    },
    paper: {
        width: '100%',
        top: props => props.headerHeight,
        bottom: 0,
        height: 'auto',

        [theme.breakpoints.up('md')]: {
            width: 650
        }
    },
    header: {
        width: '100%',
        marginTop: 0,
        marginBottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: 68,
        background: theme.palette.neutral[900],
        padding: '0 0 0 16px'
    },
    headerClose: {
        marginRight: 0,
        marginLeft: 'auto'
    },
    headerText: {
        color: theme.palette.common.offWhite,
        fontSize: 21,
        lineHeight: '120%'
    },
    content: {
        width: '100%',
        marginBottom: 'auto',
        marginTop: 0,
        flex: 1,
        overflow: 'hidden'
    },
    contentScrollable: {
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    footer: {
        padding: 16,
        width: '100%',
        marginBottom: 0,
        marginTop: 'auto',
        background: theme.palette.neutral[900]
    },
    loader: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)'
    },
    filePreview: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    fileRadio: {
        padding: 4
    },
    fileThumbWrap: {
        position: 'relative',
        marginLeft: 10,
        marginRight: 24,

        '& svg': {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            opacity: 0,
            transition: `opacity 250ms ease`
        }
    },
    fileThumb: {
        display: 'block',
        maxWidth: 80,
        maxHeight: 100,
        objectFit: 'contain',
        objectPosition: 'center',
        boxShadow: '0 5px 10px rgba(0, 0, 0, 0.08)'
    },
    fileText: {},
    fileTitle: {
        color: theme.palette.primary.main,
        fontSize: 20,
        lineHeight: '25px',
        marginBottom: 4
    },
    fileDetails: {
        fontSize: 13,
        lineHeight: '18px',
        color: theme.palette.neutral[600]
    },
    fileDate: {
        fontSize: 15,
        lineHeight: '20px',
        color: theme.palette.neutral[800]
    },
    defaultRow: {
        cursor: 'pointer',

        '&:hover': {
            background: theme.palette.neutral[300],

            '& $fileThumbWrap svg': {
                opacity: .89
            }
        }
    },
    rightCell: {
        width: 125,
        maxWidth: 125
    }
}));

function OrderFileDrawer({ isOpen, onClose, onSelect, order, spec, value }) {

    const measurements = useUIMeasurements();
    const classes = useStyles({
        headerHeight: measurements.headerHeight
    });
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState({});
    const [viewingFile, setViewingFile] = useState(null);
    const [fileViewerOpen, setFileViewerOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            if (files.length > 0) return;
            DataController.getOrderSpecFiles(order, spec.id).then(res => {
                setFiles(res.data);
                if (value && value.id) {
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].id === value.id) {
                            setSelectedFile(res.data[i]);
                        }
                    }
                }
            });
        } else {
            window.setTimeout(() => {
                setFiles([]);
                setSelectedFile({});
            }, 300);
        }
    }, [isOpen]);

    const isLoading = files.length === 0;

    const headings = [
        {
            id: 'left',
            label: `You have ${files.length} ads in Addy that can be used for this order.`
        },
        {
            id: 'right',
            label: 'Uploaded on',
            breakpoint: 'sm'
        }
    ];

    const cellPropsGenerator = (heading, row) => {
        switch (heading.id) {
            case 'right':
                return { className: classes.rightCell };
        }
    };

    const rowPropsGenerator = row => {
        return { className: classes.defaultRow };
    };

    const cellRenderer = (heading, row) => {
        switch (heading.id) {
            case 'left':
                return <Box
                    className={classes.filePreview}>
                    <Radio
                        className={classes.fileRadio}
                        size="small"
                        color="primary"
                        checked={!!selectedFile && row.id === selectedFile.id}/>
                    {row.thumbnail ? <Box
                        className={classes.fileThumbWrap}
                        onClick={e => handleThumbClick(e, row)}>
                        <img
                            className={classes.fileThumb}
                            src={row.thumbnail}
                            alt="thumb"/>
                        <MagnifierSquareIcon/>
                    </Box> : ''}
                    <Box className={classes.fileText}>
                        <Box className={classes.fileTitle}>{row.filename}</Box>
                        <Box className={classes.fileDetails}>
                            {row.summary_title}<br/>
                            {row.summary_line_1}<br/>
                            {row.summary_line_2}
                        </Box>
                    </Box>
                </Box>;
            case 'right':
                return <Box className={classes.fileDate}>{
                    row.uploaded ? format(parseISO(row.uploaded), 'MMM d, yyyy') : '--'
                }</Box>;
        }
    };

    const handleThumbClick = (e, row) => {
        e.preventDefault();
        e.stopPropagation();
        setViewingFile(row);
        setFileViewerOpen(true);
    };

    const handleViewFileDialogClose = () => {
        setFileViewerOpen(false);
        window.setTimeout(() => {
            setViewingFile(null);
        }, 300);
    };

    const handleClickRow = (e, row) => {
        if (selectedFile && row.id === selectedFile.id) {
            setSelectedFile({});
            return;
        }
        setSelectedFile(row);
    };

    const handleSubmit = e => {
        e.preventDefault();
        onSelect(selectedFile, order, spec);
        onClose();
    };

    const handleDialogApplyFile = file => {
        onSelect(file, order, spec);
        onClose();
    };

    const hasSelectedFile = Object.keys(selectedFile).length > 0;

    return <>
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
            PaperProps={{ className: classes.paper }}>
            <Box className={classes.header}>
                <Box className={classes.headerText}>Your Addy Ads</Box>
                <IconButton
                    className={classes.headerClose}
                    onClick={onClose}>
                    <CloseCircleIcon/>
                </IconButton>
            </Box>
            {isLoading ? <CircularProgress
                    size={28}
                    color="secondary"
                    className={classes.loader}/> :
                <Box className={classes.content}>
                    <Box className={classes.contentScrollable}>
                        <DataTable
                            headings={headings}
                            rowPropsGenerator={rowPropsGenerator}
                            cellPropsGenerator={cellPropsGenerator}
                            cellRenderer={cellRenderer}
                            onRowClick={handleClickRow}
                            rows={files}
                        />
                    </Box>
                </Box>}
            <Box className={classes.footer}>
                <Button
                    onClick={handleSubmit}
                    disabled={!hasSelectedFile}
                    fullWidth
                    color="primary"
                    variant="contained">
                    <CheckmarkButtonIcon/> Apply
                </Button>
            </Box>
        </Drawer>
        <FilePreviewDialog
            onClose={handleViewFileDialogClose}
            onApply={handleDialogApplyFile}
            file={viewingFile}
            isOpen={fileViewerOpen}
            hasApplyButton
        />
    </>;
}

export default OrderFileDrawer;
