import React from 'react';
import OrdersList from '../../../elements/OrdersPage/OrdersList.js';

function OrdersHistoryPage() {
    return (
        <OrdersList status="completed" title="History"/>
    );
}

export default OrdersHistoryPage;
