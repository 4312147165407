import React, { useEffect, useState } from 'react';
import useData from "lib/hooks/useData";
import OrderExtraAddonsPayment from 'components/elements/OrderPage/OrderSidebar/OrderExtraAddonsPayment';
import { Box, TextField, Typography, makeStyles } from '@material-ui/core';
import FieldGroup from 'components/elements/form/FieldGroup';
import CheckboxField from '../form/CheckboxField';
import StripeProvider from '../form/StripeProvider';
import FirstTimeBuyerPayment from './FirstTimeBuyerPayment';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 24px',
        marginTop: 24,
        [theme.breakpoints.down('md')]: {
            padding: '0 16px',
        }
    },
    form: {
        maxWidth: 428,

        [theme.breakpoints.up('tb')]: {
            maxWidth: 384,
        }
    },
    title: {
        fontSize: 55,
        fontWeight: 400,
        lineHeight: '100%',
        marginTop: 55,
        [theme.breakpoints.down('md')]: {
          fontSize: 34,
        }
    },
    ccBtn: {
        borderRadius: '10px 0 0 10px',
        height: '46px',
        padding: 12,
        '& svg': {
            marginRight: 0,
            '& path': {
                stroke: theme.palette.common.offWhite
            }
        },
        '& .MuiButton-label': {
            fontWeight: 'normal',
            lineHeight: '24px',
            fontSize: 17,
            [theme.breakpoints.down('md')]: {
                fontSize: 15,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 13,
            }
        }
    },
    bankBtn: {
        borderRadius: '0 10px 10px 0',
        height: '46px',
        padding: 12,
        '& svg': {
            marginRight: 0,
            '& path': {
                stroke: theme.palette.primary.main
            }
        },
        '& .MuiButton-label': {
            lineHeight: '24px',
            fontWeight: 'normal',
            fontSize: 17,
            [theme.breakpoints.down('md')]: {
                fontSize: 15,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 13,
            }
        }
    },
    bankIcon: {
        width: 20,
        height: 20
    },
    ccIcon: {
        width: 20,
        height: 20
    }
}));

function CheckoutPayment({getFieldProps, formData, selectedPaymentMethod, setSelectedPaymentMethod, errors, invoiceInfo, handlePayClick, cart}) {

    const classes = useStyles();
    const state = useData({
        queries: ['getMyPaymentMethods'],
        setLoadingState: true
    });
    const [copyAddress, setCopyAddress] = useState(true);

    const handleCopyAddress = () => {
        setCopyAddress(!copyAddress);
    }

    useEffect(() => {
        formData.billing_same = copyAddress ? 1 : 0;
    }, [copyAddress])

    const isFirstTimeBuyer = () => {
        const hasCards = state.paymentMethods.cards.length > 0;
        const hasBankInfo = state.paymentMethods.banks.length > 0;
        return !(!!invoiceInfo || hasCards || hasBankInfo);
    };

    const [firstTimeBuyer, setFirstTimeBuyer] = useState(isFirstTimeBuyer());

    React.useEffect(() => {
        setFirstTimeBuyer(isFirstTimeBuyer())
    }, [state])

    return (
        <Box>
            {firstTimeBuyer &&
              <StripeProvider>
                 <FirstTimeBuyerPayment handlePayClick={handlePayClick}/>
              </StripeProvider>
            }
            {!firstTimeBuyer &&
                <OrderExtraAddonsPayment
                    selected={selectedPaymentMethod}
                    setSelected={setSelectedPaymentMethod}
                    invoiceInfo={invoiceInfo}
                    halfWidth={true}
                />
            }
            <Box className={classes.root}>
                <Typography className={classes.title}>Company Address</Typography>
                <form
                    autoComplete="off"
                    className={classes.form}
                    noValidate
                >
                    <FieldGroup>
                        <TextField
                            label="Company name"
                            {...getFieldProps('company_name')}
                            error={!!errors.company_name}
                            helperText={errors.company_name}
                            required
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <TextField
                            label="Address line1"
                            {...getFieldProps('company_street')}
                            error={!!errors.company_street}
                            helperText={errors.company_street}
                            required
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <TextField
                            label="City"
                            {...getFieldProps('company_city')}
                            error={!!errors.company_city}
                            helperText={errors.company_city}
                            required
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <TextField
                            label="State/Province"
                            {...getFieldProps('company_state')}
                            error={!!errors.company_state}
                            helperText={errors.company_state}
                            required
                        />
                        <TextField
                            label="Postal code"
                            {...getFieldProps('company_zip')}
                            error={!!errors.company_zip}
                            helperText={errors.company_zip}
                            required
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <TextField
                            label="Phone number"
                            {...getFieldProps('company_phone')}
                            error={!!errors.company_phone}
                            helperText={errors.company_phone}
                            required
                        />
                    </FieldGroup>

                </form>

                {
                    cart?.requires_billing_contact &&
                    <Box>
                        <Typography className={classes.title}>Billing Information</Typography>
                        <form
                            autoComplete="off"
                            className={classes.form}
                            noValidate
                        >
                            <FieldGroup>
                                <TextField
                                    label="Billing contact"
                                    {...getFieldProps('billing_contact_name')}
                                    required
                                    error={!!errors.billing_contact_name}
                                    helperText={errors.billing_contact_name}
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <TextField
                                    label="Billing email"
                                    {...getFieldProps('billing_contact_email')}
                                    required
                                    error={!!errors.billing_contact_email}
                                    helperText={errors.billing_contact_email}
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <TextField
                                    label="Billing phone number"
                                    {...getFieldProps('billing_contact_phone')}
                                    required
                                    error={!!errors.billing_contact_phone}
                                    helperText={errors.billing_contact_phone}
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <CheckboxField
                                    label="address same as company's"
                                    checked={copyAddress}
                                    onChange={handleCopyAddress}
                                />
                            </FieldGroup>

                            {
                                !copyAddress &&
                                <Box>
                                    <FieldGroup>
                                        <TextField
                                            label="Address line1"
                                            {...getFieldProps('billing_street')}
                                            error={!!errors.billing_street}
                                            helperText={errors.billing_street}
                                        />
                                    </FieldGroup>
                                    <FieldGroup>
                                        <TextField
                                            label="City"
                                            {...getFieldProps('billing_city')}
                                            error={!!errors.billing_city}
                                            helperText={errors.billing_city}
                                        />
                                        <TextField
                                            label="State/Province"
                                            {...getFieldProps('billing_state')}
                                            error={!!errors.billing_state}
                                            helperText={errors.billing_state}
                                        />
                                        <TextField
                                            label="Postal code"
                                            {...getFieldProps('billing_zip')}
                                            error={!!errors.billing_zip}
                                            helperText={errors.billing_zip}
                                        />
                                    </FieldGroup>
                                </Box>
                            }

                        </form>
                    </Box>
                }
            </Box>

        </Box>
    )
}

export default CheckoutPayment;
