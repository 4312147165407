import { useState, useMemo } from "react"
import { makeStyles } from '@material-ui/core';

import { ZIP_LAYER_MIN_ZOOM } from 'lib/LeafletZipsLayer'
import MarketSelect from './MarketSelect'
import ZipcodesTable from './ZipcodesTable'
import ZipcodeInfo from "./ZipcodeInfo";
import Map from "./Map"
import SelectedZipcodesList from './SelectedZipcodesList'
import CloseIconButton from './CloseIconButton'

import 'external/leaflet.css'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex'
    },

    leftPanel: {
        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.08)',
        width: 290,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    leftPanelHeader: {
        padding: 8,
    },

    leftPanelMarkerLabel: {
        marginBottom: 4
    },

    zoomOutInfo: {
        textAlign: 'center'
    },

    mapPanel: {
        flexGrow: 1,
        position: 'relative',
    },

    hoverInfoPanelWrapper: {
        position: 'absolute',
        pointerEvents: 'none',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        zIndex: 1,
        top: 12,
    },

    hoverInfoPanel: {
        pointerEvents: 'initial',
        margin: 'auto',
        background: theme.palette.common.offWhite,
        border: '1px solid #957EFE',
        borderRadius: 10,
        width: 380,
        padding: 20
    },

    rightPanel: {
        width: 390,
        padding: '20px 24px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
    },

    rightPanelTitle: {
        fontFamily: 'Ahkio',
        fontWeight: 400,
        fontSize: 55,
        textTransform: 'uppercase'
    },

    closeIcon: {
        position: 'absolute',
        top: 20,
        right: 32,
    },

    rightPanelBody: {
        flexGrow: 1,
        minHeight: 0
    },
}))

export default function ZipcodesSelectionWidget({
    title,
    markets,
    selectedMarket,
    selectedZipcodes, // Record<zipcode, Demographics>
    demographics,
    quote, // {isFetching: boolean, result: API response}
    bbox,
    onSelectMarket,
    onSelectFeature, //(zipcode: number, isSelected: boolean | undefined) => void ("isSelected === undefined" means toggle)
    onClose, // () => void
    onSelectDates // () => void
}) {
    const classes = useStyles();

    const [hoveredZipcode, setHoveredZipcode] = useState(null)
    const [zoom, setZoom] = useState(null)

    const demographicsById = useMemo(
        () => demographics.reduce((res, demogr) => {
            res[demogr.zip] = demogr
            return res
        }, {}),
        [demographics]
    )

    return (
        <div className={classes.root}>
            <div className={classes.leftPanel}>
                <div className={classes.leftPanelHeader}>
                    <div className={classes.leftPanelMarkerLabel}>Select Market</div>
                    <MarketSelect
                        small
                        markets={markets}
                        prodcode={selectedMarket}
                        onChange={onSelectMarket}
                    />
                </div>
                {zoom === null || zoom >= ZIP_LAYER_MIN_ZOOM
                    ?  <ZipcodesTable
                        demographics={demographics}
                        selectedZipcodes={selectedZipcodes}
                        hoveredZipcode={hoveredZipcode}
                        onHoverZipcode={setHoveredZipcode}
                        onSelectZipcode={onSelectFeature}
                    />
                    : <div className={classes.zoomOutInfo}>
                        Please zoom in or select another market
                    </div>
                }
            </div>
            <div className={classes.mapPanel} >
                <Map
                    mode="hover"
                    selectedZipcodes={selectedZipcodes}
                    demographics={demographics}
                    bbox={bbox}
                    hoveredZipcode={hoveredZipcode}
                    onHoverZipcode={setHoveredZipcode}
                    onSelectZipcode={onSelectFeature}
                    onZoomChange={setZoom}
                />
                {hoveredZipcode && (
                    <div className={classes.hoverInfoPanelWrapper}>
                        <div className={classes.hoverInfoPanel}>
                            <ZipcodeInfo
                                variant="lg"
                                demographics={demographicsById[hoveredZipcode]}
                                zipcodeTitle={demographicsById[hoveredZipcode].zip}
                                cost={demographicsById[hoveredZipcode].price_formatted}
                                addresses={demographicsById[hoveredZipcode].total_households}
                                isAdded={!!selectedZipcodes[hoveredZipcode]}
                                onToggle={() => {onSelectFeature(hoveredZipcode)}}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={classes.rightPanel}>
                <CloseIconButton className={classes.closeIcon} onClick={onClose} />
                <div className={classes.rightPanelTitle}>{title}</div>
                <div className={classes.rightPanelBody}>
                    <SelectedZipcodesList
                        selectedZipcodes={selectedZipcodes}
                        quote={quote}
                        onSelectZipcode={onSelectFeature}
                        onSelectDates={onSelectDates}
                    />
                </div>
            </div>
        </div>
    )
}