import { Box, Button, makeStyles } from '@material-ui/core';
import { ReactComponent as ExternalLink } from 'images/externalLink.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '15px 0',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
    },
    ctaWrapper: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    btn: {
        marginRight: 24, 
    },
    spec: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 26,
            width: '50%',
            marginLeft: '25%'
        },

        '& .MuiButton-label': {
            fontWeight: 'normal'
        }
    }
}));

function CheckoutCTA({order, isInvoicePayment}) {
    const classes = useStyles();

    return (
        <Box className={classes.root} >
            <Box className={classes.ctaWrapper}>
                <Button
                    href={order?.upload_url}
                    target="_blank"
                    variant="outlined"
                    className={classes.btn}
                >
                    Upload ad
                </Button>
                
                {isInvoicePayment && 
                    <Button
                        href={order?.invoice_url}
                        target="_blank"
                        variant="outlined"
                        className={classes.btn}
                    >
                        Pay Invoice
                    </Button>
                }
                {!isInvoicePayment &&
                    <Button
                        href={order?.invoice_url}
                        target="_blank"
                        variant="outlined"
                        className={classes.btn}
                    >
                        View Receipt
                    </Button>
                }
            </Box>
            <Button 
                href={order?.media_specs?.cta_url}
                target="_blank"
                className={classes.spec}
            >
                <ExternalLink /> {order?.media_specs?.cta_label}
            </Button>
        </Box>
    )
}

export default CheckoutCTA;
