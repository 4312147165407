import React from 'react';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { ReactComponent as PlusIcon } from 'images/plus.svg';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import { useHistory } from 'react-router';
// import useData from 'lib/hooks/useData.js';
import useAppState from 'lib/hooks/useAppState.js';
import AccountSectionHeader from '../common/AccountSectionHeader.js';
import { becomesIconButtonResponsive } from '../../../theme/mixins.js';

const useStyles = makeStyles(theme => ({
    cta: {
        height: 48,
        width: 178,
        ...becomesIconButtonResponsive(theme)
    }
}));

function TeamHeader() {

    // the main app header calls getUser
    // useData({
    //     queries: ['getUser'],
    //     setLoadingState: true
    // });

    const classes = useStyles();
    const history = useHistory();
    const { state } = useAppState();

    const handleAddMember = e => {
        e.preventDefault();
        history.push('/team/invite');
    };

    return <AccountSectionHeader
        title="Team Members"
        rightSide={state.userData && state.userData.is_admin &&
        <Button onClick={handleAddMember} className={classes.cta} size="small" variant="contained"
                startIcon={<PlusIcon/>}><Hidden smDown>Add Member</Hidden></Button>}
        hasFilter/>;
}

export default TeamHeader;
