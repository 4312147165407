import React from 'react';
import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router";
import CheckoutReceiptLine from "components/elements/CheckoutPage/CheckoutReceiptLine";
import {ReactComponent as Discount} from "images/discount.svg";
import {ReactComponent as Phone} from 'images/phone.svg';
import classNames from 'classnames';
import LoadingButton from "../common/LoadingButton";
import useIsAuthenticated from 'lib/hooks/useIsAuthenticated';
import AuthenticateDialog from 'components/elements/CheckoutPage/AuthenticateDialog';
import {useHistory} from 'react-router-dom';
import OrderSummary from './OrderSummary';
import {useSnackbar} from "notistack";
import useIntersection from "../../../lib/hooks/useIntersection";
import palette from '../../../theme/palette';
import { ReactComponent as LoaderIcon } from 'images/loader.svg';
import { ReactComponent as WarningIcon } from 'images/warningPromo.svg';
import { ReactComponent as TrashIcon } from 'images/trash.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    border: `1px solid ${theme.palette.neutral[400]}`,
    boxSizing: "border-box",
    borderRadius: '0 10px 10px 0px',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0 0 10px 10px',
    }
  },
  summary: {
    fontSize: 15,
    padding: 30,
    background: `${theme.palette.neutral[200]}`,
    borderRadius: '0 10px 0 0',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0 0 10px 10px',
    },

    [theme.breakpoints.up("lg")]: {
      minWidth: "450px",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "290px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 10,
    },
    lineHeight: "15px",
  },
  caption: {
    fontSize: 20,
    minHeight: 80,
    fontWeight: 800,
    lineHeight: '28px'
  },
  container: {
    minHeight: "60px",
    padding: "10px 0px",
    borderBottom: `2px solid ${theme.palette.neutral[300]}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center'
  },
  promoCodeWrapper: {
    display: 'flex',
    '& span': {
      marginRight: 20,
      marginTop: 2,
    }
  },
  trashIcon: {
    cursor: 'pointer'
  },
  loaderImg: {
    animation: 'rotation 2s infinite linear',
  },
  formControl: {
    padding: '10px 0',
    marginBottom: 26,
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.up("lg")]: {
        width: "360px",
      },
      [theme.breakpoints.down("md")]: {
        width: "220px",
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "75vw",
      },
      marginTop: '-10px',
    }
  },
  input: {
    display: "flex",
    alignItems: "start"
  },
  promoCode: {
    maxWidth: "50%",
      borderRadius: "10px",
      border: props => props.promoCodeError ? '1px solid red' : '',
      fontSize: 15,
      color: theme.palette.neutral[900],

      '& fieldset': {
        top: 0,
    },

    [theme.breakpoints.down('md')]: {
      width: '50%',
    }
  },
  cross: {
    '& path': {
      stroke: theme.palette.primary.main,
      transition: 'stroke 0.3s ease-in-out',
    },

    '&:hover': {
      cursor: 'pointer',

      '& path': {
        stroke: theme.palette.primary[500]
      }
    }
  },
  link: {
    color: theme.palette.neutral[100],
    width: '100%'
  },
  ctaBtn: {
    marginTop: 48,

    "& button": {
      [theme.breakpoints.down('md')]: {
        position: 'fixed',
        bottom: 20,
        zIndex: 1000,
        transition: 'bottom 0.5s',
        width: '90%',
        margin: 'auto',
        left: 0,
        right: 0
      },
      [theme.breakpoints.up(1200)]: {
        bottom: '5px!important',
      }
    },
  },
  help: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 32px 32px',
    background: `${theme.palette.neutral[300]}`,
    height: '100%',
    borderRadius: '0 0 10px 0',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0 0 10px 10px',
    },
  },
  helpText: {
    fontSize: 17,
    margin: '10px 40px',
    minWidth: 230,
    color: `${theme.palette.neutral[900]}`,
    fontWeight: 800
  },
  dialog: {
    width: 450,
    margin: 'auto',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 350,
    }
  },
  paymentType: {
    width: '100%',
    height: "60px",
    padding: "10px 0px",
    borderBottom: `2px solid ${theme.palette.neutral[300]}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center'
  }
}));

function CheckoutSummary({
                           cart,
                           updateCartPaymentType,
                           addPromoCode,
                           switchStep,
                           steps,
                           currStep,
                           disablePlaceOrder,
                           handlePlaceOrder,
                           claimCart,
                           validateCart,
                           validateDates,
                           setCart,
                           loadingPromoCode,
                           promoCodeError,
                           removePromoCode,
                           isInvoicePayment,
                           loadingPlaceOrder
                         }) {
  const classes = useStyles({promoCodeError});
  const {cartId} = useParams();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const [promoCode, setPromoCode] = React.useState(cart?.promocode);
  const [open, setOpen] = React.useState(false);
  const {isAuthenticated} = useIsAuthenticated();

  const ref = document.getElementById('footer');
  const inViewport = useIntersection(ref, '-50px');
  const el = document.getElementById("confirmAdButton");
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

  if (el) {
    let bottomWidth = "5px";
    if (inViewport && vw <= 1200) {
      bottomWidth = (vw <= 769) ? "250px" : "140px"
    }
    document.getElementById("confirmAdButton").style.bottom = bottomWidth;
  }

  const handleChange = (event) => {
    const paymentType = event.target.value;
    updateCartPaymentType(cartId, paymentType);
  };

  const prepayLabel = () => {
    return (
      <Box className={classes.paymentType}>
        <Box><Typography color="secondary">pay in advance</Typography>
        </Box>
        <Box><Typography><Discount/>{"media price"}</Typography>
        </Box>
      </Box>
    );
  };

  const futureLabel = () => {
    return (
      <Box className={classes.paymentType}>
        <Typography color="secondary">pay at deadline</Typography>
        <Typography></Typography>
      </Box>
    );
  };

  const paymentOptions = [
    {
      label: prepayLabel,
      value: "prepay"
    },
    {
      label: futureLabel,
      value: "future"
    },
  ];

  const getLabel = (option) => {
    return (
      <Box>
        {<option.label className={classes.container}/>}
        {option.value === cart?.payment_type && (
          <CheckoutReceiptLine receiptLines={cart?.receipt_lines}/>
        )}
      </Box>
    );
  };

  const getCartTotal = () => {
    return cart?.receipt_lines.filter(r => r.is_total)[0]?.value;
  }

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      addPromoCode(cartId, promoCode);
    }
  }

  const handlePromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  }

  const handlePromoCodeRemove = () => {
    removePromoCode();
    setPromoCode()
  }

  const onClose = () => {
    setOpen(false);
  }

  const onDone = () => {
    onClose();
    switchStep(steps[1]);
    claimCart();
  }

  function scrollToTop(top = 0) {
    window.scrollTo({top, behavior: 'smooth'});
  }

  function redirectToInvoicePage() {
    window.open(cart.invoice_url, '_blank')
  }

  const handleCheckout = async () => {
    if (currStep.title === steps[0].title) {
      if (!isAuthenticated) {
        setOpen(true);
      } else {
        // proceed to next step?
        const isDatesValidated = await validateDates();
        if (isDatesValidated) {
          switchStep(steps[1]);
          scrollToTop();
          claimCart();
        }
      }
    } else if (currStep.title === steps[1].title) {
      const isValidated = await validateCart();
      if (isValidated) {
        switchStep(steps[2]);
        scrollToTop();
      }
    } else if (currStep.title === steps[2].title) {
      const btn = document.getElementById("firstBuyerSubmitBtn");
      if (btn) {
        btn.click();
      } else {
        handlePlaceOrder()
      }

    } else {
      if (isInvoicePayment) {
        redirectToInvoicePage()
        return
      }
      switchStep(steps[3]);
      scrollToTop();
    }
  }

  if (!cart) {
    return (
      <Box className={classes.root}>
        <Box className={classes.help}>
          <Typography className={classes.helpText}>Have questions? Need help?</Typography>
          <Button className={classes.helpText} fullWidth variant="outlined"><Phone/> (646) 595-1155</Button>
        </Box>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.summary}>
        {currStep.title === steps[3].title &&
          <Button variant='contained' fullWidth onClick={() => history.push('/orders/all')}>
            Go to Your Orders
          </Button>
        }
        <Typography className={classNames(classes.container, classes.caption)}>
          {"Summary"}
        </Typography>

        <Box className={classes.container}>
          <Typography color="textSecondary"> {"Shipping"} </Typography>
          <Typography> {"free"} </Typography>
        </Box>

        {currStep.title === steps[3].title &&
          <OrderSummary cart={cart}/>
        }

        {currStep.title !== steps[3].title &&
          <Box>
            <Box className={classes.container}>
              <Typography color="textSecondary"> {"promo code"} </Typography>
              {cart?.promocode ? (
                <Box className={classes.promoCodeWrapper}>
                  <span>{cart?.promocode}</span>
                  <TrashIcon onClick={handlePromoCodeRemove} className={classes.trashIcon}/>
                </Box>
              ) : (
                <OutlinedInput
                  fullWidth
                  className={classes.promoCode}
                  onChange={handlePromoCodeChange}
                  onKeyPress={handleEnter}
                  value={promoCode}
                  endAdornment={
                    <InputAdornment position="end">
                      {loadingPromoCode && <LoaderIcon className={classes.loaderImg}/>}
                      {promoCodeError && <WarningIcon />}
                    </InputAdornment>
                  }
                />
              )}
            </Box>

            {cart.is_future_billable &&
              <Box>
                <FormControl className={classes.formControl}>
                  <RadioGroup name="payment-type" value={cart?.payment_type || ""}>
                    {paymentOptions.map((option, i) => (
                      <FormControlLabel
                        key={i}
                        value={option.value}
                        control={<Radio/>}
                        label={getLabel(option)}
                        onChange={handleChange}
                        className={classes.input}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            }

            {!cart.is_future_billable &&
              <CheckoutReceiptLine receiptLines={cart.receipt_lines}/>
            }

            <Typography className={classNames(classes.container, classes.caption)}>
              <span>
                {`total due today`}
              </span>
              <span>{getCartTotal()} {currStep.hideBtn}
                </span>
            </Typography>
          </Box>
        }

        <Box>
          <CheckoutReceiptLine receiptLines={cart.billing_lines}/>
        </Box>

        {!currStep.hideBtn &&
          <LoadingButton
            fullWidth={true}
            variant="contained"
            id="confirmAdButton"
            onClick={handleCheckout}
            className={classes.ctaBtn}
            loading={loadingPlaceOrder}
            style={{backgroundColor: palette.neutral[900]}} //Temp fix, safary bug https://github.com/mui/material-ui/issues/26251 TODO need better solution
          >
            {currStep.btnLabel}
          </LoadingButton>
        }
        <Dialog
          open={open}
          onClose={onClose}
          className={classes.dialog}
        >
          <AuthenticateDialog onDone={onDone} onClose={onClose}/>
        </Dialog>
      </Box>
      <Box className={classes.help}>
        <Typography className={classes.helpText}>Have questions? Need help?</Typography>
        <Button className={classes.helpText} fullWidth variant="outlined"><Phone/> (646) 595-1155</Button>
      </Box>
    </Box>
  );
}

export default CheckoutSummary;
