import React from 'react';
import { Box, Divider, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import useData from 'lib/hooks/useData';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: 15,
        fontWeight: 800,
        color: theme.palette.neutral[900],
        marginBottom: 16
    },

    thumbnail: {
        width: '100%',
        height: 'auto',
        marginBottom: 20
    },

    historyIdLink: {
        display: 'block',
        fontWeight: 800,
        marginBottom: 8,
    },
    historyTitle: {
        color: theme.palette.neutral[900],
    },

    filePropertiesItem: {
        display: 'flex',
        marginBottom: 8
    },

    filePropertiesLabel: {
        width: 80,
        marginRight: 8,
        color: theme.palette.neutral[600]
    },

    filePropertiesText: {
        flex: 1,
        color: theme.palette.neutral[800]
    },

    video: {
        maxWidth: '100%',
    }
}));

export default function FileSummary({selectedFile, onFilePage = false}) {
    const classes = useStyles();

    const state = useData({
        queries: [['getFile', [selectedFile.id]]],
        cbError: () => {
            // TODO: 404
        },
        setLoadingState: true
    });

    console.log(state.files.current)

    return (
        <Box>
            {state.isLoading 
                ? <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress /> 
                </Box>
                : <>
                    {!onFilePage && (
                        state.files.current && state.files.current.filespec.media_type === 'tv'
                            ? <video controls className={classes.video} src={state.files.current.url} />
                            : <img src={selectedFile.thumbnail} className={classes.thumbnail} width="100%" height="auto" />
                    )
                    }

                    {state.files.current && state.files.current.history && state.files.current.history.length > 0 && 
                        <>
                            <Typography className={classes.title}>File history</Typography>
                            {state.files.current.history.map(historyItem => <div>
                                <Link
                                    className={classes.historyIdLink}
                                    to={{
                                        pathname: `/order/${historyItem.id}`,
                                    }}
                                >#{historyItem.id}</Link>
                                <div className={classes.historyTitle}>{historyItem.summary_title}</div>
                                <div>{historyItem.summary_line_1}</div>
                                <div>{historyItem.summary_line_2}</div>
                                <Divider />
                            </div>)}
                        </>
                    }

                    <Typography className={classes.title}>File properties</Typography>
                        {state.files.current && state.files.current.file_property_lines.map((item) => 
                            <div className={classes.filePropertiesItem} key={item.description}>
                                <label className={classes.filePropertiesLabel}>{item.description}</label>
                                <div className={classes.filePropertiesText}>{item.value}</div>
                            </div>
                        )}
                </>
            }
        </Box>
    )
}
