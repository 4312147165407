import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { formatErrorObj } from 'lib/functions';
import DataController from 'lib/controllers/DataController.js';
import Divider from '@material-ui/core/Divider';
import FieldGroup from 'components/elements/form/FieldGroup';
import TextField from '@material-ui/core/TextField';
import useFormControl from '../../../lib/hooks/useFormControl.js';
import { useSnackbar } from "notistack";

const defaultForm = {
    password: '',
    confirmPassword: '',
};

const defaultFieldProps = {
    password: { required: true },
    confirmPassword: { required: true },
};

export default function PasswordForm() {

    const { enqueueSnackbar } = useSnackbar();

    const {
        getFieldProps,
        setErrors,
        formData,
        renderSubmitButton,
        setIsWorking,
        resetForm
    } = useFormControl({
        defaultForm,
        defaultFieldProps,
        SubmitButtonProps: {
            label: 'Change Password'
        }
    });

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        console.log(e)

        setIsWorking(true);
        setErrors({});

        DataController
            .changePassword(formData)
            .then(({ errors, message, success }) => {
                if (success) {
                    enqueueSnackbar('Password has been updated successfully.');
                    resetForm();
                } else {
                    if (Object.keys(errors).length > 0) {
                        const errorObject = formatErrorObj(errors);
                        setErrors(errorObject);
                    } else {
                        enqueueSnackbar(message, {
                            variant: 'error',
                        });
                    }
                }
                setIsWorking(false);
            });
    };

    return (
        <form
            autoComplete="off"
            noValidate
            onSubmit={handlePasswordSubmit}
        >
            <Divider />

            <Typography variant="h3">
                Change Password
            </Typography>

            <FieldGroup vertical>
                <TextField
                    label="Password"
                    type="password"
                    {...getFieldProps('password')}
                />
            </FieldGroup>
            <FieldGroup>
                <TextField
                    label="Confirm new password"
                    type="password"
                    {...getFieldProps('confirmPassword')}
                />
            </FieldGroup>

            <Box mt={3}>
                {/* <Button
                    variant="contained"
                    type="submit"
                    disabled={false}
                >
                    Change Password
                </Button> */}
                {renderSubmitButton}
            </Box>
        </form>
    );
}
