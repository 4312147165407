import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles, Dialog } from '@material-ui/core';
import geojsonBbox from 'lib/geojsonBbox'

import useBoolean from 'lib/hooks/useBoolean'
import DataController from 'lib/controllers/DataController'
import ZipcodesSelectionWidget from 'components/elements/ABDMPage/ZipcodesSelectionWidget';
import ABDMBanner from 'components/elements/ABDMPage/ABDMBanner'
import CampaignOverview from 'components/elements/ABDMPage/CampaignOverview'
import CampaignSummaryMobile from 'components/elements/ABDMPage/CampaignSummaryMobile'
import { ReactComponent as MailIcon } from 'images/mail.svg';
import MobileZipcodeSelection from 'components/elements/ABDMPage/MobileZipcodeSelection';

const MEDIACODE = 'abdmart'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 70,
        position: 'relative',
        minHeight: 'calc(100vh - 70px)',
        backgroundSize: 'cover',
        overflow: 'hidden'
    },

    backgroundImg: {
        width: '100%',
    },

    mainPanel: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
        position: 'absolute',
        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.08)',
        borderRadius: 10,
        top: 40,
        right: 85,
        width: 400,
        height: 'calc(100vh - 60px - 80px)',
        backgroundColor: theme.palette.common.white,
        padding: 24,
        display: 'flex',
        flexDirection: 'column'
    },

    panelHeader: {
        display: 'flex'
    },

    panelTitle: {
        fontFamily: 'Ahkio',
        fontWeight: 400,
        fontSize: 55,
        letterSpacing: -1,
        textTransform: 'uppercase',
        lineHeight: '100%',
        flexGrow: 1
    },

    panelHeaderIcon: {
        width: 53,
        height: 53,
        backgroundColor: '#3E729C',
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    panelBody: {
        flexGrow: 1
    },

    mapDialog: {
        margin: '40px 85px',
    },

    mobileMapDialog: {
        margin: '0 12px',
    },

    mapDialogPaper: {
        borderRadius: 10,
        padding: 0
    },

    mobilePanelBody: {
        position: 'absolute',
        bottom: 12,
        maxWidth: 500,
        left: 12,
        right: 12,
        margin: 'auto',
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
    }
}))

const EMPTY_QUOTE = {isFetching: false, isError: false, result: null}
const FETCHING_QUOTE = {isFetching: true, isError: false, result: null}
const ERROR_QUOTE = {isFetching: false, isError: true, result: null}

export default () => {
    const classes = useStyles();
    const [mediaInfo, setMediaInfo] = useState(null)
    const [mapOpen, openMap, closeMap] = useBoolean(false);
    const [isMobileFlowOpen, openMobileFlow, closeMobileFlow] = useBoolean(false);
    const [calendarOpen, openCalendar, , , setCalendarOpen] = useBoolean(false);
    const [demographics, setDemographics] = useState({}) // Record<Prodcode, {isLoaded: boolean, result: Demographics[]}>
    const [prodcode, setProdcode] = useState(null)
    const [selectedZipcodes, setSelectedZipcodes] = useState({}) //Record<zipcode, Demographic>
    const [quote, setQuote] = useState(EMPTY_QUOTE) //{isFetching: boolean, isError: boolean result: API response}
    const [selectedDates, setSelectedDates] = useState(null)
    const [marketBboxes, setMarketBboxes] = useState({}) // Record<Prodcode, {isLoaded: boolean, result: [[lat, lng], [lat, lng]]}>

    useEffect(() => {
        DataController.getMedia(MEDIACODE).then(result => {
            setMediaInfo(result.data)
            setProdcode(result.data.markets[0].prodcode)
            setSelectedDates(result.data.availability[0])
        })
    }, [])

    useEffect(() => {
        const zipcodes = Object.keys(selectedZipcodes)
        if (!zipcodes.length) {
            setQuote(EMPTY_QUOTE)
            return
        }

        let isCancelled = false

        setQuote(FETCHING_QUOTE)

        DataController.getQuote(MEDIACODE, zipcodes).then(response => {
            if (isCancelled) {
                return
            }
            if (response.success) {
                setQuote({isFetching: false, isError: false, result: response.data})
            } else {
                setQuote(ERROR_QUOTE)
            }
        }).catch(() => {
            setQuote(ERROR_QUOTE)
        })

        return () => {
            isCancelled = true
        }
    }, [selectedZipcodes])

    useEffect(() => {
        if (!prodcode || demographics[prodcode]) {
            return
        }

        setDemographics({
            ...demographics,
            [prodcode]: {
                isLoaded: false,
                result: null
            }
        })
        DataController.getABDMDemographic(mediaInfo.code, prodcode)
            .then(result => setDemographics({...demographics, [prodcode]: {isLoaded: true, result: result.data.demographics}}))
            .catch(() => setDemographics({...demographics, [prodcode]: {isLoaded: true, result: null}}))
    }, [mediaInfo, prodcode, demographics])

    useEffect(() => {
        if (!prodcode || marketBboxes[prodcode]) {
            return
        }

        setMarketBboxes({
            ...marketBboxes,
            [prodcode]: {
                isLoaded: false,
                result: null
            }
        })
        DataController.getMarketBoundary(prodcode)
            .then(result => setMarketBboxes({
                ...marketBboxes,
                [prodcode]: {isLoaded: true, result: geojsonBbox(result.data.geo)}
            }))
            .catch(() => setMarketBboxes({
                ...marketBboxes,
                [prodcode]: {isLoaded: true, result: null}
            }))
    }, [prodcode, marketBboxes])

    const onSelectMarket = useCallback(e => {
        setProdcode(e.target.value)
    }, [])

    const onSelectFeature = useCallback((zipcode, isSelected) => {
        setSelectedZipcodes(selectedZipcodes => {
            const newZipcodes = {...selectedZipcodes}
            const isCurrentlySelected = !!newZipcodes[zipcode]
            if (isSelected === undefined) {
                isSelected = !isCurrentlySelected
            }
            if (isCurrentlySelected && !isSelected) {
                delete newZipcodes[zipcode]
            } else if (!isCurrentlySelected && isSelected) {
                const demogr = demographics[prodcode].result.find(demographic => demographic.zip === zipcode)
                if (!demogr) {
                    return selectedZipcodes
                } else {
                    newZipcodes[zipcode] = demogr
                }
            } else {
                return selectedZipcodes
            }
            return newZipcodes
        })
    }, [demographics[prodcode]?.result])

    const onSelectDates = useCallback(() => {
        closeMap()
        openCalendar()
    }, [closeMap])

    const onAddToCart = useCallback(() => {
        DataController.createCampaign(MEDIACODE, Object.keys(selectedZipcodes), selectedDates.date_start)
    }, [selectedZipcodes, selectedDates])

    if (!mediaInfo) {
        return null
    }

    return (<>
        <div
            className={classes.root}
            style={{backgroundImage: `url(https://d2ai543m1eawbf.cloudfront.net/${mediaInfo.image_hero})`}}
        >
            <ABDMBanner />
            <div className={classes.mainPanel}>
                <div className={classes.panelHeader}>
                    <div className={classes.panelTitle}>{mediaInfo.title}</div>
                    <div className={classes.panelHeaderIcon}><MailIcon/></div>
                </div>
                <div className={classes.panelBody}>
                    <CampaignOverview
                        mediaInfo={mediaInfo}
                        prodcode={prodcode}
                        quote={quote}
                        selectedZipcodesCount={Object.keys(selectedZipcodes).length}
                        selectedDates={selectedDates}
                        isCalendarOpen={calendarOpen}
                        setCalendarOpen={setCalendarOpen}
                        onSelectMarket={onSelectMarket}
                        onShowMap={openMap}
                        onSelectDates={setSelectedDates}
                        onAddToCart={onAddToCart}
                    />
                </div>
            </div>
            <Dialog
                className={classes.mapDialog}
                open={mapOpen}
                onClose={closeMap}
                PaperProps={{className: classes.mapDialogPaper}}
                fullScreen
            >
                <ZipcodesSelectionWidget
                    title={mediaInfo.title}
                    markets={mediaInfo.markets}
                    selectedMarket={prodcode}
                    selectedZipcodes={selectedZipcodes}
                    demographics={demographics[prodcode]?.result ?? []}
                    onSelectMarket={onSelectMarket}
                    onClose={closeMap}
                    onSelectFeature={onSelectFeature}
                    quote={quote}
                    onSelectDates={onSelectDates}
                    bbox={marketBboxes[prodcode]?.result}
                />
            </Dialog>
            <Dialog
                className={classes.mobileMapDialog}
                open={isMobileFlowOpen}
                onClose={closeMobileFlow}
                PaperProps={{className: classes.mapDialogPaper}}
                fullScreen
            >
                <MobileZipcodeSelection
                    mediaInfo={mediaInfo}
                    prodcode={prodcode}
                    quote={quote}
                    selectedZipcodes={selectedZipcodes}
                    demographics={demographics[prodcode]?.result ?? []}
                    bbox={marketBboxes[prodcode]?.result}
                    selectedDates={selectedDates}
                    onClose={closeMobileFlow}
                    onChangeProdcode={onSelectMarket}
                    onSelectZipcode={onSelectFeature}
                    onSelectDates={setSelectedDates}
                    onAddToCart={onAddToCart}
                />
            </Dialog>
        </div>
        <div className={classes.mobilePanelBody}>
            <CampaignSummaryMobile
                title={mediaInfo.title}
                priceFormatted={quote.result?.price_formatted ?? '$0'}
                onClick={openMobileFlow}
            />
        </div>
    </>)
}