import React, {useState} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import classNames from "classnames";
import { ReactComponent as CheckmarkIcon } from 'images/checkmark.svg';
import {isSameDay} from "date-fns";

const useStyles = makeStyles(theme => ({
  issuesContainer: {
    maxHeight: 300,
    minWidth: 300,
    overflowY: 'auto',
    padding: 10
  },
  issueItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: '10px 15px',
    fontWeight: 400,
    fontSize: 17,
    lineHeight: '24px',
    color: theme.palette.primary.main,
    cursor: "pointer"
  },
  selectedIssue: {
    color: theme.palette.common.offWhite,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
  },
  icon: {
    '& path': {
      stroke: theme.palette.common.offWhite,
    }
  }
}));

function MagazineDateSelector({ handleDateChange, itemDates, value }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const classes = useStyles();

  React.useEffect(() => {
    const itemDateIndex = itemDates.findIndex((itemDate) => {
      const newDate = itemDate?.date_start?.replaceAll("-", "/");
      return isSameDay(value, new Date(newDate));
    });
    setSelectedItem(itemDateIndex);
  }, [itemDates, value]);

  function handleClick(item, index) {
    const newDate = item?.date_start?.replaceAll("-", "/")
    handleDateChange(new Date(newDate));
    setSelectedItem(index);
  }

  return (
    <Box className={classes.issuesContainer}>
      {itemDates.map((item, index) => {
        const isSelected = index === selectedItem;
        return (
          <div
            key={index}
            className={classNames(classes.issueItem, isSelected && classes.selectedIssue)}
            onClick={() => handleClick(item, index)}
          >
            {item.issue_formatted}
            {isSelected && <CheckmarkIcon className={classes.icon}/>}
          </div>
        )
      })}
    </Box>
  )
}

export default MagazineDateSelector;
