import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from '../common/DataTable.js';
import { ReactComponent as CloseDialogIcon } from "../../../images/closeDialog.svg";
import OrderExtraAddonsPayment from "../OrderPage/OrderSidebar/OrderExtraAddonsPayment";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  IconButton,
  Chip,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    paddingTop: '0!important'
  },
  button: {
    width: '100%',
  },
  footer: {
    backgroundColor: theme.palette.neutral[200],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 10,
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 15,
    padding: '10px 15px',
    fontWeight: 800,
    color: theme.palette.neutral[900],
  },
  invoiceNumberWrapper: {
    padding: '0 10px',

    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
    }
  },
  xCloseButton: {
    marginLeft: 16,
    marginRight: 0,
    marginTop: 2
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,

    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    }
  },
  dialogTitle: {
    fontSize: 34,
    lineHeight: '34px',
    letterSpacing: '-0.45px',
    color: theme.palette.neutral[900],

    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    }
  },
  formWrap: {
    padding: 16,
  },
  formTitle: {
    color: theme.palette.neutral[900],
    fontSize: 21,
  },
  creditCardBtn: {
    backgroundColor: theme.palette.neutral[900],
    border: '1px solid #3F3F3F',
    '& .MuiButton-label': {
      color: theme.palette.white,
      fontSize: 17,
      lineHeight: '24px',
      fontWeight: 'normal'
    }
  },
  bankAccountBtn: {
    border: '1px solid #3F3F3F',
    '& .MuiButton-label': {
      fontFamily: 'Neutrif Studio',
      color: theme.palette.neutral[900],
      fontSize: 17,
      lineHeight: '24px',
      fontWeight: 'normal'
    }
  },
  invoices: {

  },
  chipOutlined: {
    background: theme.palette.common.white,
    borderColor: theme.palette.accent.subscriberSunshine[500],

    '& span': {
      color: theme.palette.accent.subscriberSunshine[500]
    }
  },
  chipPrimary: {
    borderColor: theme.palette.accent.buyerBlue[600],

    '& span': {
      color: theme.palette.accent.buyerBlue[600]
    }
  },
  chipSecondary: {
    borderColor: theme.palette.accent.customerCoral[500],

    '& span': {
      color: theme.palette.accent.customerCoral[500]
    }
  },
  chipDisabled: {
    borderColor: theme.palette.neutral[600],

    '& span': {
      color: theme.palette.neutral[600]
    }
  },
  chiplabel: {
    borderColor: theme.palette.text.primary,

    '& span': {
      color: theme.palette.text.primary
    }
  },
  textCell: {
    maxWidth: 430,
    minWidth: 400
  },
  orderCell: {
    maxWidth: 170,
    paddingLeft: 25
  },
  orderDateCell: {
    maxWidth: 260,
  },
  amountDueCell: {
    justifyContent: 'flex-end',
    paddingRight: 25,
  },
  adDescription: {
    color: theme.palette.neutral[900],
    fontSize: 20,
    lineHeight: '25px',
    marginBottom: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  adQty: {
    color: theme.palette.neutral[800],
    fontSize: 13,
    overflow: 'hidden',
    lineHeight: '18px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  adRun: {
    color: theme.palette.neutral[800],
    fontSize: 13,
    overflow: 'hidden',
    lineHeight: '18px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
}));

const headings = [
  { label: 'Order', id: 'order', key: 'order' },
  { label: 'Order date', id: 'orderDate', key: 'orderDate' },
  { label: 'Item', id: 'item', key: 'item' },
  { label: 'Due', id: 'due', key: 'due' },
  { label: 'Amount due', id: 'amountDue', key: 'amountDue' },
];

const statusMap = {
  open: 'default',
  overdue: 'default',
  paid: 'primary'
};

export default function PaymentMethod({ onClose, handlePayClick, invoices }) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const classes = useStyles();

  const total = invoices.length ? invoices.map(el => el.totals.amount_due_float).reduce((accumulator, a) => accumulator + a) : 0;
  const formattedTotal = total.toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits: 2 });

  const renderCell = ({ key }, row) => {
    switch (key) {
      case 'order':
        return (
          <Box>
            <div>{`#${row.id}`}</div>
            <Chip
              classes={{
                disabled: classes.chipDisabled,
                label: classes.chiplabel,
                outlined: classes.chipOutlined,
                outlinedPrimary: classes.chipPrimary,
                outlinedSecondary: classes.chipSecondary
              }}
              color={statusMap[row.status]}
              disabled={row.status === 'canceled'}
              label={row.status}
              variant="outlined"
            />
          </Box>
        );
      case 'orderDate':
        return (
          <Typography>
            {row.order_date}
          </Typography>
        );
      case 'item':
        return (
          <Box maxWidth={340}>
            <Typography className={classes.adDescription}>
              {row.summary.summary_title}
            </Typography>
            <Typography className={classes.adRun}>
              Client: {row.summary.summary_line_1}
            </Typography>
            <Typography className={classes.adQty}>
              {row.summary.summary_line_2}
            </Typography>
            <Typography className={classes.adQty}>
              {row.addons.join(', ')}
            </Typography>
          </Box>
        );
      case 'due':
        return (
          <Typography>
            {row.due_date}
          </Typography>
        );
      case 'amountDue':
        return (
          <Typography>
            {row.totals.amount_due}
          </Typography>
        );
      default:
        return row[key] || '-';
    }
  };

  const cellPropsGenerator = (heading, row) => {
    switch (heading.id) {
      case 'order':
        return { className: classes.orderCell };
      case 'orderDate':
        return { className: classes.orderDateCell };
      case 'item':
        return { className: classes.textCell };
      case 'amountDue':
        return { className: classes.amountDueCell };
    }
  };

  return (
    <>
      <DialogTitle disableTypography>
        <Box className={classes.headerContainer}>
          <div className={classes.dialogTitle}>
            Payment Method
          </div>
          <IconButton
            className={classes.xCloseButton}
            onClick={onClose}
            size="small"
            aria-label="close"
            color="secondary"
          >
            <CloseDialogIcon/>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <OrderExtraAddonsPayment
          selected={selectedPaymentMethod}
          setSelected={setSelectedPaymentMethod}
        />
        <Box className={classes.invoiceNumberWrapper}>
          <Box className={classes.total}>
            <div>total</div>
            <div>${formattedTotal}</div>
          </Box>
        </Box>
        <Box className={classes.invoices}>
          <DataTable
            /*breakpointPaddings={{ xsUp: 16, smUp: 24, mdUp: 32 }}*/
            cellPropsGenerator={cellPropsGenerator}
            headings={headings}
            rows={invoices}
            cellRenderer={renderCell}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box className={classes.footer}>
          <Button
            variant="contained"
            className={classes.button}
            size="small"
            disabled={!selectedPaymentMethod}
            onClick={() => handlePayClick({payment_id: selectedPaymentMethod})}>
            Pay Now
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}
