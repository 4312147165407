import {useState} from "react";
import {Avatar, Box, FormControl, InputLabel, makeStyles, MenuItem, Paper, Select, Typography} from '@material-ui/core';
import {ReactComponent as FileCircle} from 'images/fileCircle.svg';
import useAppState from 'lib/hooks/useAppState.js';
import theme from 'theme/theme';
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 80,
    width: '100%',
    padding: 16,
    borderRadius: 10,

    [theme.breakpoints.up('sm')]: {
      marginBottom: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
      marginTop: 60,
    },
    [theme.breakpoints.down('xs')]: {
      height: 45,
      padding: 0
    }
  },
  icon: {
    padding: 5,
      [theme.breakpoints.down('xs')]: {
          display: 'none'
      },
  },
  agencyName: {
    marginLeft: 16,
    fontWeight: 800,
  },
  container: {
    width: '100%',
    height: 48,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: 'relative',
  },
  content: {
    display: "flex",
    alignItems: "center",
    height: 40,
  },
  selectWrapper: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  agencyNameWrapper: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
        top: -30,
        left: -10
    },
  },
  agencyWrapper: {
    fontSize: 20,
    lineHeight: '25px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 15
    },
  },
  clientListDropdown: {
    height: 40,
    width: 442,
    '& .MuiOutlinedInput-input': {
      height: 40,
      display: 'flex',
      alignContents: 'center',
      alignItems: 'center',
      padding: 0,
      backgroundColor: 'transparent',
    },
    backgroundColor: 'transparent',

    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  avatarIcon: {
    height: 24,
    width: 24
  },
  clientName: {
    height: 24,
    marginLeft: 8
  },
  clientItem: {
    height: 40,
  },
  input: {
    margin: "-6.5px 24px",
  },
  formControl: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  }
}));

function AgencyHeader({companyId, updateCheckoutCompany}) {
  const classes = useStyles();
  const {state} = useAppState();
  const [client, setClient] = useState(companyId);

  const handleChange = (e) => {
    setClient(e.target.value)
    updateCheckoutCompany(e.target.value)
  }

  const AvatarIcon = ({src}) => {
    return (
      <Avatar
        className={classes.avatarIcon}
        src={src}
        border={theme.palette.neutral[900]}
        badge='Client'
      />
    )
  }

  return (
    <Paper className={classes.root}>
      <Box className={classes.container}>
        <Box className={classNames(classes.content, classes.agencyNameWrapper)}>
          <FileCircle className={classes.icon}/>
          <Typography className={classes.agencyWrapper}>
            <span className={classes.agencyName}>{state.userData.company ?? "[Agency name]"}</span> buying for the client:
          </Typography>
        </Box>
        <Box className={classNames(classes.content, classes.selectWrapper)}>
          <FormControl className={classes.formControl}>
            <InputLabel className={classes.input}><span hidden={client}>Select a Client...</span></InputLabel>
            <Select
              value={client}
              className={classes.clientListDropdown}
              onChange={handleChange}
              variant="outlined"
            >
              {state.userData.companies.map(client => (
                <MenuItem className={classes.clientItem} key={client.id} value={client.id}>
                  <AvatarIcon src={client.avatar}/>
                  <Typography className={classes.clientName} noWrap={true}>{client.name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Paper>
  )
}

export default AgencyHeader;
