import React, { useState } from 'react';
import { Box, Button, Typography  } from '@material-ui/core';
import AssignClientDialog from './AssignClientDialog.js';
import ClientList from './ClientList.js';

export default function ClientsSection({ teamMember }) {

    const [clientsDialogOpen, setClientsDialogOpen] = useState(false)

    return (
        <>
            <Typography variant="h4">
                Clients
            </Typography>

            <ClientList teamMember={teamMember} />

            <Box mt={3}>
                <Button
                    color="secondary"
                    onClick={() => {setClientsDialogOpen(true)}}
                    variant="outlined"
                >
                    Assign Client
                </Button>
            </Box>

            <AssignClientDialog
                teamMember={teamMember ? teamMember : null}
                userName={teamMember ? `${teamMember.firstname} ${teamMember.lastname}` : null}
                handleClose={() => setClientsDialogOpen(false)}
                open={clientsDialogOpen}
            />
        </>
    )
}
