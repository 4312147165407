import React, { useEffect, useState } from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, Divider, DialogActions, Button } from '@material-ui/core';
import ClientSelect from '../../common/ClientSelect';
import useAppState from 'lib/hooks/useAppState';
import { useSnackbar } from 'notistack';
import DataController from 'lib/controllers/DataController';
import actions from 'store/actions';
import LoadingButton from '../../common/LoadingButton';

export default function OrderEditClientDialog({ handleClose, open, order }) {

    const { dispatch } = useAppState();
    const { enqueueSnackbar } = useSnackbar();
    
    const [loading, setLoading] = useState(false);
    const [selectedClients, setSelectedClients] = useState([]);

    useEffect(() => {
        setSelectedClients(order && order.company && order.company.id ? [order.company.id] : []);
    }, [order])

    const handleUpdateClient = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!selectedClients || !selectedClients[0]) return

        DataController
            .updateOrder(order.id, { company: selectedClients[0] })
            .then(({ data, errors, message, success }) => {
                if (success) {
                    dispatch({
                        type: actions.SET_CURRENT_ORDER,
                        payload: data
                    });
                    enqueueSnackbar('Client updated successfully!');
                    handleClose();
                    setLoading(false);
                } else {
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                    setLoading(false);
                }
            })
    };

    return (
        <Dialog
            onClose={() => {
                handleClose();
            }}
            open={open}
        >
            <DialogTitle disableTypography>
                <Typography variant="h4">Edit Client</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <ClientSelect 
                    allowAddingNewClient={false}
                    allowMultiselect={false}
                    selectedClients={selectedClients}
                    setSelectedClients={setSelectedClients}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={false}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    color="default"
                    type="submit"
                    disabled={false}
                    onClick={handleUpdateClient}
                    loading={loading}
                >
                    Save
                </LoadingButton>
            </DialogActions>

        </Dialog>
    )
}
