import React from 'react';
import AccountSectionHeader from 'components/elements/common/AccountSectionHeader.js';

function OrdersHeader({ title, rightSide, leftSide }) {
    return (
        <AccountSectionHeader
            title={title || 'Orders'}
            rightSide={rightSide}
            leftSide={leftSide}
        />
    );
}

export default OrdersHeader;
