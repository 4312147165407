import React, { useState } from 'react';
import useData from '../../../lib/hooks/useData.js';
import Box from '@material-ui/core/Box';
import PaymentDataTableCards from '../../elements/PaymentMethodsPage/PaymentDataTableCards.js';
import PaymentDataTableAccounts from '../../elements/PaymentMethodsPage/PaymentDataTableAccounts.js';
import DataController from '../../../lib/controllers/DataController.js';
import { actions } from '../../../store/store.js';
import useAppState from '../../../lib/hooks/useAppState.js';
import PaymentMethodNicknameDialog from '../../elements/PaymentMethodsPage/PaymentMethodNicknameDialog.js';
import { useSnackbar } from "notistack";

function PaymentPage() {

    const { dispatch } = useAppState();
    const { enqueueSnackbar } = useSnackbar();
    const [editRow, setEditRow] = useState(null);

    useData({
        queries: ['getMyPaymentMethods'],
        setLoadingState: true
    });

    const editNicknameDialog = (e, row) => {
        e.preventDefault();
        console.log(row);
        setEditRow(row);
    };

    const setDefaultPaymentMethod = (e, id) => {
        e.preventDefault();
        DataController.paymentMethodEdit(id, { is_default: 1 }).then(res => {
            console.log('[paymentMethodEdit]', res);
            if (res && res.success) {
                dispatch({
                    type: actions.UPDATE_ROW,
                    payload: {
                        location: res.data.type === 'stripe' ? 'paymentMethods.cards' : 'paymentMethods.banks',
                        data: res.data,
                        mutateTargets: ['paymentMethods.cards', 'paymentMethods.banks'],
                        mutateFunction: item => {
                            item.is_default = 0;
                            return item;
                        }
                    }
                });
                enqueueSnackbar('Default payment method has been set.');
            } else {
                enqueueSnackbar(res.message || 'Can not set this payment method as default.', {
                    variant: 'error',
                });
            }
        });
    };

    const deletePaymentMethod = (e, id, type) => {
        e.preventDefault();
        DataController.paymentMethodDelete(id).then(res => {
            console.log('[paymentMethodDelete]', res);
            if (res && res.success) {
                dispatch({
                    type: actions.UPDATE_ROW,
                    payload: {
                        location: type === 'stripe' ? 'paymentMethods.cards' : 'paymentMethods.banks',
                        deleteId: id
                    }
                });
                enqueueSnackbar('Contact successfully updated!');

            } else {
                enqueueSnackbar(res.message || 'Your payment method could not be deleted at this time.', {
                    variant: 'error',
                });
            }
        });
    };

    return (
        <Box>
            <PaymentDataTableCards
                onEditNickname={editNicknameDialog}
                onSetDefault={setDefaultPaymentMethod}
                onDelete={deletePaymentMethod}/>
            <PaymentDataTableAccounts
                onEditNickname={editNicknameDialog}
                onSetDefault={setDefaultPaymentMethod}
                onDelete={deletePaymentMethod}/>
            <PaymentMethodNicknameDialog
                openRow={editRow}
                setOpenRow={setEditRow}/>
        </Box>
    );
}

export default PaymentPage;