import React, { useState } from "react";
import { useParams } from "react-router";
import { Box, LinearProgress, Divider } from "@material-ui/core";
import CommonHeader from "components/elements/common/CommonHeader";
import BackArrow from "components/elements/common/BackArrow";
import useData from "lib/hooks/useData";
import useAppState from "lib/hooks/useAppState";
import AvatarContainer from "components/elements/common/AvatarContainer";
import { ReactComponent as CompanyIcon } from 'images/company.svg';
import AddressForm from "components/elements/CompanyPage/AddressForm";
import BillingForm from "components/elements/CompanyPage/BillingForm";
import MoreMenu from "components/elements/common/MoreMenu";
// import UserRoleChip from "components/elements/TeamPage/UserRoleChip";
import DataController from "lib/controllers/DataController";
import actions from "store/actions";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import TeamSection from "components/elements/ClientsPage/TeamSection";
import AdDueNotificationsForm from "../../../elements/CompanyPage/AdDueNotificationsForm";

export default function ClientEditPage() {

    const { id } = useParams();
    const { state, dispatch } = useAppState();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const isAgency = state.userData.is_agency;
    const companyId = state.myClients?.current?.id;
    const materialReminders = state.myClients?.current?.company?.material_reminder;

    useData({
        queries: [ 'getMyTeam', ['getCurrentClient', [id]] ],
        cbError: () => {
            // TODO: 404
        }
    });

    const handleDelete = e => {
        e.preventDefault();

        setLoading(true);

        DataController
            .deleteCompany(id)
            .then(({data, success, error, message}) => {
                if (success) {
                    dispatch({
                        type: actions.UPDATE_ROW,
                        payload: {
                            deleteId: id,
                            location: 'myClients.clients'
                        }
                    });
                    history.push('/clients');
                } else {
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                }
                setLoading(false);
            });
    }

    return (
        <Box>
            {state.myClients.current
                ? (
                    <>
                        <CommonHeader
                            heading={state.myClients.current.name}
                            right={
                                <Box display="flex" alignItems="center">
                                    {/* <UserRoleChip role={state.myClients.current.role} /> */}
                                    <MoreMenu
                                        items={[ { label: 'Delete', onClick: (e) => { handleDelete(e) }, disabled: !state.myClients.current.is_editable } ]}
                                        loading={loading}
                                    />
                                </Box>
                            }
                        />

                        <BackArrow path="/clients" />

                        <AvatarContainer
                            icon={<CompanyIcon/>}
                            src={state.myClients.current.avatar}
                            uploadUrl={`/company/${state.myClients.current.id}/avatar`}
                        />

                        <Divider/>

                        <AddressForm
                            data={state.myClients.current.company}
                            isClientPage={true}
                        />

                        <Divider/>

                        <BillingForm
                            data={state.myClients.current.company}
                            isClientPage={true}
                        />

                        <Divider/>

                        <TeamSection client={state.myClients.current} meta={state.myClients.currentMeta}/>

                        <Divider/>
                        {isAgency && (
                            <AdDueNotificationsForm
                                companyId={companyId}
                                materialReminders={materialReminders}
                            />
                        )}
                    </>
                ) : (
                    <Box display="flex" alignItems="center">
                        <LinearProgress />
                    </Box>
                )
            }
        </Box>
    )
}
