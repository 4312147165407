import { Box, makeStyles } from "@material-ui/core";
import mediaTypeDigitalOOH from 'images/mediaTypeDigitalOOH.svg';
import mediaTypeDirectMail from 'images/mediaTypeDirectMail.svg';
import mediaTypeMagazine from 'images/mediaTypeMagazine.svg';
import mediaTypeGoogle from 'images/mediaTypeGoogle.svg';
import mediaTypeGt from 'images/mediaTypeGt.svg';
import mediaTypeTV from 'images/mediaTypeTV.svg';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    mediaTypeText: {
        fontSize: 13,
        lineHeight: '18px'
    },
    mediaTypeText_printmag: {
        color: theme.palette.accent.customerCoral[400]
    },
    mediaTypeText_tv: {
        color: theme.palette.accent.TV[500]
    },
    mediaTypeText_dooh: {
        color: theme.palette.active.main
    },
    mediaTypeText_google: {
        color: theme.palette.accent.buyerBlue[500]
    },
    mediaTypeText_gt: {
        color: theme.palette.accent.online[500]
    },
    mediaTypeText_eddm: {
        color: theme.palette.accent.subscriberSunshine[500]
    },
    mediaTypeSvgWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& img': {
            height: props => props.size,
            width: props => props.size
        },
    }
}));

export default function MediaTypeIcon({ mediaType, iconType, inline = false, iconOnly = false, size = 24 }) {

    const classes = useStyles({size});
    let mediaTypeText = mediaType;
    let mediaTypeSVG = '';

    if (iconType === 'printmag') {
        mediaTypeText = 'Magazine';
        mediaTypeSVG = mediaTypeMagazine;
    }

    if (iconType === 'tv') {
        mediaTypeText = 'TV';
        mediaTypeSVG = mediaTypeTV;
    }

    if (iconType === 'dooh') {
        mediaTypeText = <span>Digital Out<br/>of Home</span>;
        mediaTypeSVG = mediaTypeDigitalOOH;
    }

    if (iconType === 'google') {
        mediaTypeText = 'Online';
        mediaTypeSVG = mediaTypeGoogle;
    }

    if (iconType === 'gt') {
        mediaTypeText = 'Online';
        mediaTypeSVG = mediaTypeGt;
    }

    if (iconType === 'eddm') {
        mediaTypeText = 'Direct Mail';
        mediaTypeSVG = mediaTypeDirectMail;
    }

    return <Box display="inline-flex" flexDirection={inline ? "row" : "column"} alignItems="center" justifyContent={inline ? "flex-start" : "center"}>
        <Box className={classes.mediaTypeSvgWrapper} mr={inline ? 1 : 0}>
            <img src={mediaTypeSVG} alt={mediaTypeText} />
        </Box>
        {!iconOnly && 
            <Box className={classNames(
                classes.mediaTypeText,
                classes[`mediaTypeText_${iconType}`]
            )}>
                {mediaTypeText}
            </Box>
        }
    </Box>;
}
