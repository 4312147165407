import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        '& hr': {
            margin: '16px 0'
        }
    },
    rootBlock: {
        '& + $rootBlock': {
            marginTop: 22
        }
    },
    skel: {
        '& + $skel': {
            marginTop: 10
        }
    },
    skelAuto: {
        marginTop: 'auto !important',
        marginBottom: 'auto !important',
        marginLeft: 16
    },
    skelSq: {
        marginRight: 8,

        '& +  $skel': {
            marginLeft: '0 !important'
        }
    },
    profile: {
        flex: 1
    },
    stepper: {
        '& hr': {
            flex: '0 0 10%',
            marginTop: 'auto',
            marginBottom: 'auto',
            marginLeft: 16
        },

        '& > $skel': {
            marginTop: 'auto',
            marginBottom: 'auto'
        },

        '& > *': {
            marginLeft: 16
        }
    },
    upload: {
        border: `1px dashed ${theme.palette.neutral[500]}`,
        borderRadius: 4,
        height: 300
    },
    line: {
        height: 24,
        width: 1,
        backgroundColor: theme.palette.neutral[500]
    },
    textarea: {
        height: 96,
        border: `1px solid ${theme.palette.neutral[400]}`,
        borderRadius: 10,
        marginBottom: 24
    }
}));

function SkeletonOrderMain() {

    const classes = useStyles();

    return <div className={classes.root}>
        <Skeleton className={classes.skel} animation="wave" variant="rect" height={55}/>
        <Box display="flex" alignItems="center" justifyContent="flex-start" mt={2} mb={2}>
            <Skeleton className={classes.skel} animation="wave" variant="rect" width={100} height={100}/>
            <Box flex={1} ml={3}>
                <Box flex={1} mb={.5}>
                    <Skeleton className={classes.skel} animation="wave" variant="rect" width="60%" height={25}/>
                </Box>
                <Box flex={1} mb={.5}>
                    <Skeleton className={classes.skel} animation="wave" variant="rect" width="80%" height={16}/>
                </Box>
                <Box flex={1}>
                    <Skeleton className={classes.skel} animation="wave" variant="rect" width="45%" height={16}/>
                </Box>
            </Box>
        </Box>
        <Divider/>
        <Box className={classes.stepper} display="flex" alignItems="center" justifyContent="center" mt={4} mb={4}>
            <Skeleton className={classNames(
                classes.skel,
                classes.skelSq
            )} animation="wave" variant="rect" width={24} height={24}/>
            <Skeleton className={classes.skel} animation="wave" variant="rect" width="10%" height={16}/>
            <Divider/>
            <Skeleton className={classNames(
                classes.skel,
                classes.skelSq
            )} animation="wave" variant="rect" width={24} height={24}/>
            <Skeleton className={classes.skel} animation="wave" variant="rect" width="10%" height={16}/>
            <Divider/>
            <Skeleton className={classNames(
                classes.skel,
                classes.skelSq
            )} animation="wave" variant="rect" width={24} height={24}/>
            <Skeleton className={classes.skel} animation="wave" variant="rect" width="10%" height={16}/>
        </Box>
        <Box className={classes.upload}/>
        <Box className={classes.stepper} display="flex" alignItems="center" justifyContent="center" mt={4} mb={4}>
            <Skeleton className={classNames(
                classes.skel,
                classes.skelSq
            )} animation="wave" variant="rect" width={24} height={24}/>
            <Skeleton className={classes.skel} animation="wave" variant="rect" width="20%" height={16}/>
            <div className={classes.line}/>
            <Skeleton className={classNames(
                classes.skel,
                classes.skelSq
            )} animation="wave" variant="rect" width={24} height={24}/>
            <Skeleton className={classes.skel} animation="wave" variant="rect" width="20%" height={16}/>
        </Box>
        <div className={classes.textarea}/>
        <Box mt={3} mb={3}>
            <Skeleton className={classes.skel} animation="wave" variant="rect" height={48}/>
        </Box>
        <Divider/>
        <Box mt={3} display="flex" alignItems="center" justifyContent="space-between">
            <Skeleton className={classes.skel} animation="wave" variant="rect" width="30%" height={48}/>
            <Box mt="auto" mb="auto" width="40%" display="flex" alignItems="center" justifyContent="flex-end">
                <Skeleton className={classes.skel} animation="wave" variant="rect" width={24} height={24}/>
                <Skeleton className={classNames(classes.skel, classes.skelAuto)} animation="wave" variant="rect"
                          width="100%" height={16}/>
            </Box>
        </Box>
    </div>;
}

export default SkeletonOrderMain;