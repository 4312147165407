import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'images/x.svg';

const useStyles = makeStyles(theme => ({
    closeIcon: {
        padding: 8,
        backgroundColor: theme.palette.secondary.light,
        cursor: 'pointer',
        borderRadius: '100%',
        display: 'flex',
        transition: 'background 0.1s ease-out',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
}))

export default function CloseIconButton({onClick, className, ...rest}) {
    const classes = useStyles();
    return (
        <div
            className={classNames(classes.closeIcon, className)}
            onClick={onClick}
            {...rest}
        >
            <CloseIcon />
        </div>
    )
}