import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function ({
                             xs = false,
                             sm = false,
                             tb = false,
                             md = false,
                             lg = false,
                             up = false,
                             down = false
                         }) {

    const theme = useTheme();

    const xsDown = theme.breakpoints.down('xs');
    const xsUp = theme.breakpoints.up('xs');
    const smDown = theme.breakpoints.down('sm');
    const smUp = theme.breakpoints.up('sm');
    const tbDown = theme.breakpoints.down('tb');
    const tbUp = theme.breakpoints.up('tb');
    const mdDown = theme.breakpoints.down('md');
    const mdUp = theme.breakpoints.up('md');
    const lgDown = theme.breakpoints.down('lg');
    const lgUp = theme.breakpoints.up('lg');

    const ret = {};

    if (xs) {
        ret.xs = {
            xsDown,
            xsUp
        };
        if (up) ret.xs.up = useMediaQuery(xsUp);
        if (down) ret.xs.down = useMediaQuery(xsDown);
    }

    if (sm) {
        ret.sm = {
            smDown,
            smUp
        };
        if (up) ret.sm.up = useMediaQuery(smUp);
        if (down) ret.sm.down = useMediaQuery(smDown);
    }

    if (tb) {
        ret.tb = {
            tbDown,
            tbUp
        };
        if (up) ret.tb.up = useMediaQuery(tbUp);
        if (down) ret.tb.down = useMediaQuery(tbDown);
    }

    if (md) {
        ret.md = {
            mdDown,
            mdUp
        };
        if (up) ret.md.up = useMediaQuery(mdUp);
        if (down) ret.md.down = useMediaQuery(mdDown);
    }

    if (lg) {
        ret.lg = {
            lgDown,
            lgUp
        };
        if (up) ret.lg.up = useMediaQuery(lgUp);
        if (down) ret.lg.down = useMediaQuery(lgDown);
    }

    return ret;
}