import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import DataTable from '../common/DataTable.js';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {ReactComponent as CloseDialogIcon} from "../../../images/closeDialog.svg";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {DialogContent, DialogTitle, DialogActions, Divider, Typography} from "@material-ui/core";
import PlaidButton from "../form/PlaidButton";
import Chip from "@material-ui/core/Chip";
import {ReactComponent as TrashIcon} from "../../../images/trash.svg";
import FieldGroup from '../../elements/form/FieldGroup';
import StripeInputWrap from "../../elements/form/StripeInputWrap";
import {ReactComponent as CardIcon} from "../../../images/card.svg";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from '@stripe/react-stripe-js';

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
  },
  footer: {
    backgroundColor: theme.palette.neutral[200],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 10,
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 25px',
    fontWeight: 800,
    fontSize: 15,
    lineHeight: '20px',
    marginTop: 25,
    color: theme.palette.neutral[900],
  },
  invoiceNumberWrapper: {
    padding: '0 10px',
  },
  xCloseButton: {
    marginLeft: 16,
    marginRight: 0,
    marginTop: 2
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20
  },
  dialogTitle: {
    fontSize: 34,
    lineHeight: '34px',
    letterSpacing: '-0.45px',
    color: theme.palette.neutral[900],

    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
  },
  formWrap: {
    padding: 20,
    marginTop: 50,
  },
  signInBtnWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  formTitle: {
    color: theme.palette.neutral[900],
    fontSize: 21,
    lineHeight: '25px',

  [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
  },
  },
  addPaymentBtn: {
    paddingLeft: 10,
    paddingBottom: 10,

    '& span': {
      fontSize: 21,
      lineHeight: '25px',
      fontWeight: 400,

        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
            lineHeight: '22px',
            marginTop: 5,
        },
    }
  },
  accountText: {
    fontSize: 21,
    lineHeight: '25px',
    color: theme.palette.neutral[700],

    [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        lineHeight: '22px',
    },
  },
  creditCardBtn: {
    backgroundColor: theme.palette.neutral[900],
    border: '1px solid #3F3F3F',

    '& .MuiButton-label': {
      color: theme.palette.white,
      fontSize: 17,
      lineHeight: '24px',
      fontWeight: 'normal'
    }
  },
  bankAccountBtn: {
    border: '1px solid #3F3F3F',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    paddingRight: 20,
    '& .MuiButton-label': {
      fontFamily: 'Neutrif Studio',
      color: theme.palette.neutral[900],
      fontSize: 17,
      lineHeight: '24px',
      fontWeight: 'normal',
      marginLeft: 10,
    }
  },
  chipOutlined: {
    background: theme.palette.common.white,
    borderColor: theme.palette.accent.subscriberSunshine[500],

    '& span': {
      color: theme.palette.accent.subscriberSunshine[500]
    }
  },
  chipPrimary: {
    borderColor: theme.palette.accent.buyerBlue[600],

    '& span': {
      color: theme.palette.accent.buyerBlue[600]
    }
  },
  chipSecondary: {
    borderColor: theme.palette.accent.customerCoral[500],

    '& span': {
      color: theme.palette.accent.customerCoral[500]
    }
  },
  chipDisabled: {
    borderColor: theme.palette.neutral[600],

    '& span': {
      color: theme.palette.neutral[600]
    }
  },
  chiplabel: {
    borderColor: theme.palette.text.primary,

    '& span': {
      color: theme.palette.text.primary
    }
  },
  textCell: {
    maxWidth: 430,
    minWidth: 400
  },
  orderCell: {
    maxWidth: 170,
    paddingLeft: 20
  },
  orderDateCell: {
    maxWidth: 260,
  },
  amountDueCell: {
    justifyContent: 'flex-end',
    paddingRight: 25
  },
  bankName: {
    fontWeight: 800,
    fontSize: 17,
    marginTop: 25,
    lineHeight: '24px',
    color: theme.palette.neutral[900],
  },
  bankAccount: {
    fontSize: 15,
    marginTop: 10,
    lineHeight: '20px',
    color: theme.palette.neutral[700],
    marginBottom: 10
  },
  editAccount: {
    '& span': {
      fontSize: 15,
      color: theme.palette.primary.main,
    }
  },
  buttonPayNow: {
    width: '100%'
  },
  creditCardWrapper: {
    width: '100%',

    '& button': {
      width: '100%',
    }
  },
  adDescription: {
    color: theme.palette.neutral[900],
    fontSize: 20,
    lineHeight: '25px',
    marginBottom: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  adQty: {
    color: theme.palette.neutral[800],
    fontSize: 13,
    overflow: 'hidden',
    lineHeight: '18px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  adRun: {
    color: theme.palette.neutral[800],
    fontSize: 13,
    overflow: 'hidden',
    lineHeight: '18px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
}));

const headings = [
  {label: 'Order', id: 'order', key: 'order'},
  {label: 'Order date', id: 'orderDate', key: 'orderDate'},
  {label: 'Item', id: 'item', key: 'item'},
  {label: 'Due', id: 'due', key: 'due'},
  {label: 'Amount due', id: 'amountDue', key: 'amountDue'},
];

const statusMap = {
  open: 'default',
  overdue: 'default',
  paid: 'primary'
};

export default function Invoices({onClose, handlePayClick, handleSignInClick, userData, invoices, orderId}) {

  const classes = useStyles();
  const [paymentType, setPaymentType] = useState('stripe');
  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardCvcComplete, setCardCvcComplete] = useState(false);
  const [plaidData, setPlaidData] = useState({
    publicToken: '',
    accountId: '',
    institutionName: '',
    mask: ''
  });
  const elements = useElements();
  const stripe = useStripe();

  const paymentIsReady = paymentType === 'stripe'
    ? cardNumberComplete && cardExpiryComplete && cardCvcComplete
    : plaidData.publicToken && plaidData.accountId

  const renderCell = ({key}, row) => {
    switch (key) {
      case 'order':
        return (
          <Box>
            <div>{`#${row.id}`}</div>
            <Chip
              classes={{
                disabled: classes.chipDisabled,
                label: classes.chiplabel,
                outlined: classes.chipOutlined,
                outlinedPrimary: classes.chipPrimary,
                outlinedSecondary: classes.chipSecondary
              }}
              color={statusMap[row.status]}
              disabled={row.status === 'canceled'}
              label={row.status}
              variant="outlined"
            />
          </Box>
        );
      case 'orderDate':
        return (
          <Typography>
            {row.order_date}
          </Typography>
        );
      case 'item':
        return (
          <Box maxWidth={340}>
            <Typography className={classes.adDescription}>
              {row.summary.summary_title}
            </Typography>
            <Typography className={classes.adRun}>
              Client: {row.summary.summary_line_1}
            </Typography>
            <Typography className={classes.adQty}>
              {row.summary.summary_line_2}
            </Typography>
            <Typography className={classes.adQty}>
              {row.addons.join(', ')}
            </Typography>
          </Box>
        );
      case 'due':
        return (
          <Typography>
            {row.due_date}
          </Typography>
        );
      case 'amountDue':
        return (
          <Typography>
            {row.totals.amount_due}
          </Typography>
        );
      default:
        return row[key] || '-';
    }
  };

  function handlePaymentMethodAdded({metadata}) {
    setPlaidData({
      publicToken: metadata.public_token,
      accountId: metadata.account_id,
      mask: metadata.account.mask,
      institutionName: metadata.institution.name,
    });
    setPaymentType('ach_debit');
  }

  function handleDeleteBankInfo() {
    setPlaidData({
      publicToken: '',
      accountId: '',
      institutionName: '',
      mask: ''
    });
    setPaymentType('stripe');
    setCardNumberComplete(false);
    setCardExpiryComplete(false);
    setCardCvcComplete(false);
  }

  const handlePay = async () => {
    if (paymentType === 'stripe') {
      if (!stripe || !elements) {
        return;
      }
      const cardNumberElement = elements.getElement(CardNumberElement);
      const {error, token} = await stripe.createToken(cardNumberElement);
      if (error) {
        return;
      }
      handleCreditCardPay(token)
    } else {
      handleBankPay()
    }
  }

  function handleBankPay() {
    // Pay with Bank account
    handlePayClick({
      token: plaidData.publicToken,
      account_id: plaidData.accountId,
      token_type: 'ach'
    });
  }

  function handleCreditCardPay(token) {
    // Pay with Credit card
    handlePayClick({
      token: token.id,
      token_type: 'card',
    });
  }

  function handleChange({complete, elementType}) {
    if (elementType === 'cardNumber') {
      setCardNumberComplete(complete);
      return;
    }
    if (elementType === 'cardExpiry') {
      setCardExpiryComplete(complete);
      return;
    }
    if (elementType === 'cardCvc') {
      setCardCvcComplete(complete);
    }
  }

  const cellPropsGenerator = (heading, row) => {
    switch (heading.id) {
      case 'order':
        return {className: classes.orderCell};
      case 'orderDate':
        return {className: classes.orderDateCell};
      case 'item':
        return {className: classes.textCell};
      case 'amountDue':
        return {className: classes.amountDueCell};
    }
  };

  const total = invoices.length ? invoices.map(el => el?.totals?.amount_due_float).reduce((accumulator, a) => accumulator + a) : 0;
  const formattedTotal = total?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
  const triggerAchTokenOnly = !userData.id;

  return (
    <>
      <DialogTitle disableTypography>
        <Box className={classes.headerContainer}>
          <div className={classes.dialogTitle}>
            Pay Orders
          </div>
          <IconButton
            className={classes.xCloseButton}
            onClick={onClose}
            size="small"
            aria-label="close"
            color="secondary"
          >
            <CloseDialogIcon/>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DataTable
          cellPropsGenerator={cellPropsGenerator}
          headings={headings}
          rows={invoices}
          cellRenderer={renderCell}
        />
        <Box className={classes.total}>
          <div>total</div>
          <div>${formattedTotal}</div>
        </Box>
        <Box className={classes.formWrap}>
          <Box className={classes.signInBtnWrapper}>
            <div className={classes.formTitle}>Enter payment method</div>
            <div>
              {!userData.id && (
                <>
                  <span className={classes.accountText}>Do you have an account?</span>
                  <Button className={classes.addPaymentBtn} onClick={handleSignInClick}>Sign in</Button>
                </>
              )}
            </div>
          </Box>
          <Divider/>
          <Box>
            <ButtonGroup variant="outlined">
              <Button className={classes.creditCardBtn} onClick={handleDeleteBankInfo}>Credit Card</Button>
              <PlaidButton
                successCallback={handlePaymentMethodAdded}
                triggerAchTokenOnly={triggerAchTokenOnly}
                orderId={orderId}
                ButtonComponent={Button}
                ButtonComponentProps={{
                  children: <span>Bank Account</span>,
                  className: classes.bankAccountBtn,
                }}
              />
            </ButtonGroup>
          </Box>
          {paymentType === 'stripe' ? (
            <>
              <FieldGroup>
                <StripeInputWrap
                  label="Card number"
                  Component={CardNumberElement}
                  endAdornment={<CardIcon/>}
                  onChangeCallback={handleChange}
                />
              </FieldGroup>
              <FieldGroup half>
                <StripeInputWrap
                  label="Expiry date"
                  Component={CardExpiryElement}
                  onChangeCallback={handleChange}
                />
                <StripeInputWrap
                  label="CVC"
                  Component={CardCvcElement}
                  onChangeCallback={handleChange}
                />
              </FieldGroup>
            </>
          ) : (
            <>
              <div className={classes.bankName}>{plaidData.institutionName}</div>
              <div className={classes.bankAccount}>Account: ****{plaidData.mask}</div>
              <Button
                variant="text"
                className={classes.editAccount}
                onClick={handleDeleteBankInfo}
              >
                <TrashIcon/> Delete bank information
              </Button>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box className={classes.footer}>
          <Divider/>
          <Button
            variant="contained"
            className={classes.buttonPayNow}
            onClick={handlePay}
            disabled={!paymentIsReady}
          >
            Pay Now
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}
