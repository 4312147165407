import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

function IntercomBoot() {
  const { boot } = useIntercom();

  useEffect(() => {
    boot();
  }, []);

  return null;
}

export default IntercomBoot;
