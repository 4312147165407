import React from 'react';
import useAppState from '../../../lib/hooks/useAppState.js';
import AuthFooter from './AuthFooter';
import NonAuthFooter from './NonAuthFooter';

const Footer = () => {
    const { state } = useAppState();
    const authenticated = !!state.token;

    return authenticated ? <AuthFooter /> : <NonAuthFooter />
};

export default Footer;
