import React, { useEffect, useMemo, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { actions } from 'store/store.js';
import DataController from 'lib/controllers/DataController.js';
import { ReactComponent as UploadIcon } from 'images/upload.svg';
import useAppState from '../../../lib/hooks/useAppState.js';
import { useSnackbar } from "notistack";
import CustomDialog from './CustomDialog.js';
import ImageEditor from '../ProfilePage/ImageEditor';
import { getCroppedImg } from '../../../lib/functions.js';
import useData from 'lib/hooks/useData.js';
import DropFile from './DropFile.js';

const useStyles = makeStyles((theme) => ({
    uploadImage: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContents: 'center',
        height: 340,
        overflow: 'visible',

        '& > button': {
            borderRadius: 10,
            width: '100%'
        },

        '& hr': {
            width: '100%'
        }
    },

    dropArea: {
        height: 340,
        marginBottom: 26,
        width: 300,
        color: theme.palette.neutral[900],

        '& *': {
            fontSize: 17,
            textAlign: 'center'
        },

        '& svg': {
            marginBottom: 12
        }
    },

    body: {
        overflow: 'visible'
    }
}));

export default function AvatarDialog({ handleClose, open, uploadUrl }) {

    const { enqueueSnackbar } = useSnackbar();

    const { state, dispatch } = useAppState();
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const classes = useStyles();
    const src = useMemo(() => file ? URL.createObjectURL(file) : '', [file]);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    useData({
        queries: ['getMyCompany'],
        cbError: () => {
            // TODO
        }
    });

    useEffect(() => {
        if (open) {
            setUploadProgress(0);
        }
    }, [open]);

    const uploadFile = () => {
        getCroppedImg(src, croppedAreaPixels).then(imgB64 => {
            DataController
                .uploadAvatar(imgB64, uploadUrl, progress => {
                    console.warn('progress', progress);
                    setUploadProgress(progress);
                })
                .then((res) => {
                    const data = res.data;
                    if (data) {
                        enqueueSnackbar('Uploaded successfully!');
                        onHandleClose();
                        if (uploadUrl.startsWith('/my')) {
                            dispatch({
                                type: actions.SET_USER_AVATAR,
                                payload: data.avatar
                            });
                        } else if (uploadUrl.startsWith('/company')) {
                            if (state.myCompany && state.myCompany.isLoaded && state.myCompany.data.id === data.id) {
                                // if is MY_COMPANY
                                dispatch({
                                    type: actions.SET_MY_COMPANY,
                                    payload: data
                                });
                            } else {
                                dispatch({
                                    type: actions.CLEAR_DATA,
                                    payload: {
                                        action: actions.SET_MY_CLIENTS
                                    }
                                });
                                dispatch({
                                    type: actions.CLEAR_DATA,
                                    payload: {
                                        action: actions.SET_CURRENT_CLIENT
                                    }
                                });
                            }
                        } else if (uploadUrl.startsWith('/team')) {
                            dispatch({
                                type: actions.UPDATE_ROW,
                                payload: {
                                    location: 'myTeam.contacts',
                                    data: data
                                }
                            });
                        }

                    } else {
                        enqueueSnackbar(res && res.message ? res.message : 'Your file could not be uploaded. Please try again.', {
                            variant: 'error'
                        });
                        setUploadProgress(0);
                    }
                });
        });
    };

    const handleFileSelect = newFile => {
        setFile(newFile);
    };

    const onHandleClose = () => {
        handleClose();
        setFile(undefined);
    };

    const onHandleUpload = () => {
        uploadFile();
    };

    return <CustomDialog
        onClose={onHandleClose}
        scroll="body"
        title="Edit Image"
        maxWidth="xs"
        dialogContentClass={classes.body}
        open={open}
        actions={[
            {
                label: 'Cancel',
                fullWidth: false,
                variant: 'contained',
                color: 'secondary',
                style: { marginRight: '20px' },
                onClick: handleClose
            },
            {
                label: 'Upload Image',
                fullWidth: false,
                onClick: onHandleUpload,
                progress: uploadProgress,
                disabled: !src
            }
        ]}
        hasXClose>
        <Box className={classes.uploadImage}>
            {!file ? (
                <>
                    <DropFile
                        isEditableFiles={true}
                        id="profile-image-upload"
                        label="select an image"
                        onSelect={handleFileSelect}
                        className={classes.dropArea}>
                        <UploadIcon/>
                        <Typography>Drop an image here or</Typography>
                    </DropFile>
                    <Typography align="center">
                        JPG, GIF or PNG
                    </Typography>
                </>
            ) : (
                <ImageEditor
                    croppedAreaPixels={croppedAreaPixels}
                    setCroppedAreaPixels={setCroppedAreaPixels}
                    src={src}
                />
            )}
        </Box>
    </CustomDialog>;
};
