import { Box, makeStyles } from "@material-ui/core";
import { ReactComponent as PointingGuysGraphic } from 'images/pointingguys.svg';
import { ReactComponent as WhoopsGraphic } from 'images/whoops.svg';

const useStyles = makeStyles(theme => ({
    wrap: {
        width: '100%',
        marginTop: '5%',
        fontSize: 20,
        color: theme.palette.neutral[800],
    },
    whoops: {
        maxWidth: 190,
    },
    graphic: {
        maxWidth: 226,
    }
}));

export default function NoDataFound({ hideWoops, children }) {
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center" flexDirection="column" className={classes.wrap}>
            {hideWoops ? '' : <Box m={2} className={classes.whoops}>
                <WhoopsGraphic width="226" margin="auto" />
            </Box>}
            <Box width="100%">
                {children ? children : 'No data found.'}
            </Box>
            <Box m={2} className={classes.graphic}>
                <PointingGuysGraphic width="100%" height="400" />
            </Box>
        </Box>
    )
}