const actions = {
    SET_TOKEN: 'SET_TOKEN',
    SET_USER_DATA: 'SET_USER_DATA',
    SET_USER_PROFILE: 'SET_USER_PROFILE',
    SET_USER_AVATAR: 'SET_USER_AVATAR',
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_MY_TEAM: 'SET_MY_TEAM',
    SET_MY_CLIENTS: 'SET_MY_CLIENTS',
    SET_CURRENT_CLIENT: 'SET_CURRENT_CLIENT',
    SET_ADDED_CLIENT: 'SET_ADDED_CLIENT',
    SET_MY_PROMOCODES: 'SET_MY_PROMOCODES',
    SET_ADDRESSES: 'SET_ADDRESSES',
    SET_MY_PAYMENT_METHODS: 'SET_MY_PAYMENT_METHODS',
    SET_MY_COMPANY: 'SET_MY_COMPANY',
    SET_TEMPORARY: 'SET_TEMPORARY',
    SET_BILLING: 'SET_BILLING',
    SET_FILES: 'SET_FILES',
    SET_FILE: 'SET_FILE',
    SET_ORDERS: 'SET_ORDERS',
    SET_CURRENT_ORDER: 'SET_CURRENT_ORDER',
    SET_CURRENT_TIMELINE: 'SET_CURRENT_TIMELINE',
    SET_ORDERS_STATS: 'SET_ORDERS_STATS',
    SET_ORDER_METRICS: 'SET_ORDER_METRICS',
    UPDATE_ROW: 'UPDATE_ROW',
    HASH_VIEW: 'HASH_VIEW',
    CLEAR_DATA: 'CLEAR_DATA',
    SET_INVOICE: 'SET_INVOICE',
    UPDATE_CAMPAIGN_URL: 'UPDATE_CAMPAIGN_URL',
    // UPDATE_QUERY_ID: 'UPDATE_QUERY_ID',
    LOGOUT: 'LOGOUT'
};

export default actions;
