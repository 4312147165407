import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useIsAuthenticated from './useIsAuthenticated.js';

let authCallbackTimer = null;

const useCallbackAuthenticated = (cbIsAuth, cbIsUnknown, cbBefore) => {
    const { isAuthenticated, isCheckingCookie } = useIsAuthenticated();
    const history = useHistory();
    const [timer, setTimer] = useState(900);

    useEffect(() => {
        if (!authCallbackTimer) {
            if (typeof cbBefore === 'function') cbBefore();
            authCallbackTimer = window.setTimeout(() => {
                setTimer(0);
            }, 100);
        }
    });

    useEffect(() => {
        if (timer === 0) {
            if (isAuthenticated) {
                if (typeof cbIsAuth === 'function') cbIsAuth();
            } else {
                if (!isCheckingCookie) {
                    if (typeof cbIsUnknown === 'function') cbIsUnknown();
                }
            }
        }
    }, [timer, isAuthenticated, isCheckingCookie, history, cbIsAuth, cbIsUnknown]);
}

export default useCallbackAuthenticated;