import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns'

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.primary[100],
        padding: 12,
        fontSize: 13
    },
    row: {
        display: 'flex',
        margin: '4px 0'
    },
    rowImportant: {
        fontWeight: 700
    },
    leftColumn: {
        flexGrow: 1
    },
}))

const DATE_FORMAT = 'MM/dd/yyyy'

export default function MainSummary({
    quote, // {isFetching: boolean, result: API response}
    dates
}) {
    const classes = useStyles()
    if (!dates) {
        return null
    }

    const dateStart = parseISO(dates.date_start)
    const dateEnd = parseISO(dates.date_end)
    const dateClose = parseISO(dates.date_close)
    const dateMaterials = parseISO(dates.date_materials)

    return (
        <div className={classes.root}>
            {quote.result && (<>
                <div className={classes.row}>
                    <div className={classes.leftColumn}>{quote.result.qty_formatted} Addressess</div>
                    <div>{quote.result.price_without_discount_formatted}</div>
                </div>
                <div className={classes.row}>
                    <div className={classes.leftColumn}>Volume Discount</div>
                    <div>{quote.result.total_discount_formatted}</div>
                </div>
            </>)}
            <div className={classes.row}>
                <div className={classes.leftColumn}>Week Selected</div>
                <div>{format(dateStart, DATE_FORMAT)} - {format(dateEnd, DATE_FORMAT)}</div>
            </div>
            <div className={classNames(classes.row, classes.rowImportant)}>
                <div className={classes.leftColumn}>Last Day to Purchase</div>
                <div>{format(dateClose, DATE_FORMAT)}</div>
            </div>
            <div className={classNames(classes.row, classes.rowImportant)}>
                <div className={classes.leftColumn}>Materials Due</div>
                <div>{format(dateMaterials, DATE_FORMAT)}</div>
            </div>
        </div>
    )
}
