import { Box, makeStyles } from "@material-ui/core";
import OrderThumbnail from "../OrdersPage/OrderThumbnail";
import MediaTypeIcon from "../common/MediaTypeIcon";
import PropTypes from 'prop-types';
import OrderSummaryText from './OrderSummaryText.js';
import React from 'react';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    wrap: props => ({
        backgroundColor: props.dialog ? theme.palette.accent.marketMint[100] : '',
        padding: props.dialog ? '16px 24px' : 0,
        marginTop: props.dialog ? 0 : 16,
        marginBottom: props.dialog ? 0 : 16,
        borderTop: props.dialog ? `1px solid ${theme.palette.neutral[300]}` : 'none',
        borderBottom: props.dialog ? `1px solid ${theme.palette.neutral[300]}` : 'none'
    }),
    thumb: {
        display: 'none',

        [theme.breakpoints.up('tb')]: {
            display: 'block',
            marginRight: 16
        }
    },
    description: {
        flex: 1
    },
    textTitle: {
        color: theme.palette.neutral[900],
        fontSize: 20,
        lineHeight: '25px',
        fontWeight: 400,
        marginBottom: 4
    },
    textRow: {
        color: theme.palette.neutral[800],
        fontSize: 11,
        lineHeight: '13px'
    },
    mediaType: {
        width: 100,
        marginLeft: 28,
        textAlign: 'center'
    },
    canceled: {
        opacity: .3
    }
}));

export default function OrderDetailsHeader({ order, ...props }) {
    const classes = useStyles(props);

    return <Box display="flex" mt={2} mb={2} alignItems="center" className={classes.wrap}>
        {order?.media?.image_thumbnail ?
            <Box className={classNames(
                classes.thumb,
                order.is_canceled ? classes.canceled : ''
            )}>
                <OrderThumbnail src={'https://d2ai543m1eawbf.cloudfront.net/' + order.media.image_thumbnail}/>
            </Box> : ''}
        <Box className={classNames(
            classes.description,
            order.is_canceled ? classes.canceled : ''
        )}>
            {order?.media?.title ?
                <Box className={classes.textTitle}>{order.media.title}</Box> : ''}
            <OrderSummaryText order={order}/>
        </Box>
        <Box className={classes.mediaType}>
            <MediaTypeIcon mediaType={order.media_type} iconType={order.icon_type}/>
        </Box>
    </Box>;
}

OrderDetailsHeader.propTypes = {
    dialog: PropTypes.bool
};

OrderDetailsHeader.defaultProps = {
    dialog: false
};
