import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { actions } from 'store/store.js';
import { formatErrorObj } from 'lib/functions';
import DataController from 'lib/controllers/DataController.js';
import FieldGroup from 'components/elements/form/FieldGroup';
import TextField from '@material-ui/core/TextField';
import useAppState from 'lib/hooks/useAppState.js';
import useFormControl from '../../../lib/hooks/useFormControl.js';
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
    form: {
        maxWidth: 428,

        [theme.breakpoints.up('tb')]: {
            maxWidth: 384,
        }
    },
}));

const defaultForm = {
    billing_contact_email: '',
    billing_contact_name: '',
    billing_contact_phone: '',
    material_reminder: '',
};

export default function BillingForm({ data, isClientPage }) {

    const { dispatch } = useAppState();

    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles();

    const {
        getFieldProps,
        setErrors,
        formData,
        renderSubmitButton,
        setIsWorking
    } = useFormControl({
        loadValues: Object.keys(data).length && data,
        defaultForm,
        SubmitButtonProps: {
            label: 'Update Billing Info'
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        setIsWorking(true);
        setErrors({});

        DataController
            .postCompanyBilling(data.id, formData)
            .then(({ data, errors, message, success }) => {
                if (success) {
                    if (!isClientPage) {
                        dispatch({
                            type: actions.SET_MY_COMPANY,
                            payload: data,
                        });
                    } else {
                        dispatch({
                            type: actions.CLEAR_DATA,
                            payload: {
                                action: actions.SET_MY_CLIENTS
                            }
                        });
                        dispatch({
                            type: actions.CLEAR_DATA,
                            payload: {
                                action: actions.SET_CURRENT_CLIENT
                            }
                        });
                    }
                    enqueueSnackbar('Billing info updated successfully!');
                } else {
                    if (Object.keys(errors).length > 0) {
                        const errorObject = formatErrorObj(errors);
                        setErrors(errorObject);
                    } else {
                        enqueueSnackbar(message, {
                            variant: 'error',
                        });
                    }
                }
                setIsWorking(false);
            });
    };

    return (
        <form
            autoComplete="off"
            className={classes.form}
            noValidate
            onSubmit={handleSubmit}
        >
            <FieldGroup>
                <TextField
                    label="Billing contact"
                    {...getFieldProps('billing_contact_email')}
                />
            </FieldGroup>

            <FieldGroup>
                <TextField
                    label="Email"
                    {...getFieldProps('billing_contact_name')}
                />
            </FieldGroup>

            <FieldGroup>
                <TextField
                    label="Phone number"
                    {...getFieldProps('billing_contact_phone')}
                />
            </FieldGroup>

            <Box mt={3}>
                {renderSubmitButton}
            </Box>
        </form>
    );
}
