import React, { useState } from 'react';
import DataTable from '../../elements/common/DataTable.js';
import { Box } from '@material-ui/core';
import useAppState from '../../../lib/hooks/useAppState.js';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import UserRoleChip from 'components/elements/TeamPage/UserRoleChip.js';
import useData from '../../../lib/hooks/useData.js';
import TeamMoreMenu from 'components/elements/TeamPage/TeamMoreMenu.js';
import { objContainsStr } from '../../../lib/functions.js';
import NoDataFound from 'components/elements/common/NoDataFound.js';
import TeamHeader from 'components/elements/TeamPage/TeamHeader.js';
import SkeletonTable from '../../elements/common/SkeletonTable.js';
import useLinkHandler from '../../../lib/hooks/useLinkHandler';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    fullname: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
    fullnamedark: {
        color: theme.palette.neutral[800],
    },
    avatarCell: {
        width: 44,
        paddingTop: 10,
        paddingBottom: 10
    },
    actionsCell: {
        width: 44,
        padding: 0
    },
    ownerCell: {
        width: 71,
        paddingRight: 0,
        paddingTop: 8,
        paddingBottom: 8
    },
    subtitle: {
        marginBottom: 24,
        fontSize: 34,
        lineHeight: '100%',
        [theme.breakpoints.down('sm')]: {
            fontSize: 21,
            lineHeight: '120%',
        }
    },
    avatar: {
        width: 24,
        height: 24
    },
    tabsMenu: {
        marginTop: 10,
        '& .MuiTabs-root': {
            borderBottom: `1px solid ${theme.palette.neutral[300]}`,
        },
    },
    tabLabel: {
        textTransform: "unset",
        fontSize: 15,
    },
    text: {
        textAlign: 'center',
    },
}));

const headings = [
    { id: 'avatar' },
    { label: 'Name', id: 'name' },
    { label: 'Email', id: 'email' },
    { label: 'Phone number', id: 'phone' },
    { label: 'Date added', id: 'date_added' },
    { id: 'owner' }
    // { id: 'actions' },  // add conditionally: if userData.is_admin
];

export default function TeamPage() {

    const classes = useStyles();
    const { state } = useAppState();
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const { getLinkAnchorProps } = useLinkHandler();
    const [value, setValue] = useState(0);
    const meta = state.myTeam.meta;
    const history = useHistory();

    const handleTabClick = (e, index) => {
        e.preventDefault();
        setValue(index)
    };

    useData({
        queries: ['getMyTeam', 'getMyCompany', 'getUser'],
        setLoadingState: true,
        cbAfter: () => {
            setSkeletonLoading(false);
        },
        cbBefore: () => {
            setSkeletonLoading(true);
        }
    });

    if (state.userData && state.userData.is_admin && headings.findIndex(heading => heading.id === 'actions') === -1) {
        headings.push({ id: 'actions' });
    }

    const cellRenderer = (heading, row) => {
        switch (heading.id) {
            case 'avatar':
                if (row.avatar) {
                    return <Avatar src={row.avatar} className={classes.avatar} />;
                } else {
                    return <Avatar
                        className={classes.avatar}>{row.fullname && row.fullname.trim().substring(0, 1).toUpperCase()}</Avatar>;
                }
            case 'name':
                if(row.is_editable) return <a className={classes.fullname} {...getLinkAnchorProps({ url: `/team/${row.id}/edit` })}>{row.fullname}</a>;
                else return <span className={classes.fullnamedark}>{row.fullname}</span>;
            case 'email':
                return row.email;
            case 'phone':
                return row.phone;
            case 'date_added':
                return row.created;
            case 'owner':
                let acc_role = row.role ? 'account ' + row.role : null;
                return <UserRoleChip role={acc_role} description={meta[row.role]} />;
            case 'actions':
                return <TeamMoreMenu contact={row} company={state.myCompany.data} />;
            default:
                return '';
        }
    };

    const cellPropsGenerator = (heading, row) => {
        switch (heading.id) {
            case 'avatar':
                return { className: classes.avatarCell };
            case 'actions':
                return { className: classes.actionsCell };
            case 'owner':
                return { className: classes.ownerCell };
            default:
                return {};
        }
    };

    const filterFunction = rows => {
        if (!state.temporary.filter) return rows;
        return rows.filter(row => objContainsStr(row, ['fullname', 'email', 'phone'], state.temporary.filter));
    };

    return (
        <Box>
            {skeletonLoading ?
                <SkeletonTable /> :
                <>
                    <TeamHeader />
                    {!state.myTeam.contacts || state.myTeam.contacts.length === 0 ?
                        (state.myTeam.isLoaded ?
                            <NoDataFound hideWoops>
                                <Box className={classes.text}>You don’t have any team members in Addy yet.</Box>
                            </NoDataFound>
                            : '') :
                        <Box mt={5}>
                            <DataTable
                                breakpointPaddings={{ xsUp: 16, smUp: 24, mdUp: 32 }}
                                headings={headings}
                                rows={state.myTeam.contacts}
                                filterFunction={filterFunction}
                                cellPropsGenerator={cellPropsGenerator}
                                cellRenderer={cellRenderer}
                            />
                        </Box>
                    }
                </>
            }
        </Box>
    );
}
