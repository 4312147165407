import React, { useCallback, useEffect, useRef, useState } from "react"
import { makeStyles } from '@material-ui/core';

import useLoadScript from 'lib/hooks/useLoadScript';
import { getLeafletZipsLayerClass, ZIP_LAYER_MIN_ZOOM } from 'lib/LeafletZipsLayer'

const MAP_ID = 'addygroundtruth.maponics_zips'
const MAPBOX_LAYER_ID = 'Maponics ZIP Boundaries'
const MAPBOX_ACCESS_TOKEN = 'sk.eyJ1IjoiYWRkeWdyb3VuZHRydXRoIiwiYSI6ImNrdnRyMm55cjBvZnQyb28yeXppbnRzbTgifQ.rnpMqXgtFZcdkp0twWk48A'

const useStyles = makeStyles(theme => ({
    map: {
        width: '100%',
        height: '100%'
    }
}))

export default function Map({
    mode, // 'touch' or 'hover'
    selectedZipcodes, // Record<zipcode, Demographics>
    demographics, // Demographics[]
    bbox, //[[number, number], [number, number]]
    hoveredZipcode,
    onHoverZipcode,
    onSelectZipcode, //(zipcode: number, isSelected: boolean | undefined) => void ("isSelected === undefined" means toggle)
    onZoomChange
}) {
    const classes = useStyles()
    const [isLeafletLoaded] = useLoadScript('leaflet.js')
    const [isVectorTileLoaded] = useLoadScript('Leaflet.MapboxVectorTile.min.js', !isLeafletLoaded)

    const [zipsLayer, setZipsLayer] = useState(null)
    const [map, setMap] = useState(null)

    const zoomChangeHandler = useCallback(() => {
        onZoomChange && onZoomChange(map.getZoom())
    }, [onZoomChange, map])

    useEffect(() => {
        if (!isLeafletLoaded || !isVectorTileLoaded) {
            return
        }

        const map = window.L.map(leafletRef.current, {
            maxZoom: 17,
            minZoom: 3,
            maxBounds: [
                [72.35, -173.0],
                [16.91, -62.22],
            ],
        })

        map.zoomControl.setPosition('bottomleft')

        setMap(map)

        /* Basemap */
        window.L.tileLayer(
            `https://api.mapbox.com/styles/v1/addygroundtruth/ckwqnhmpaa6zj14oc4ncuzn0k/tiles/256/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`,
            {
                maxZoom: 18,
                attribution: false,
            }
        ).addTo(map)

        return () => {
            map.remove()
        }

    }, [isLeafletLoaded, isVectorTileLoaded])

    useEffect(() => {
        if (map) {
            map.on('zoomend', zoomChangeHandler)
            return () => {
                map.off('zoomend', zoomChangeHandler)
            }
        }
    }, [map, zoomChangeHandler])

    useEffect(() => {
        if (!map || !bbox) {
            return
        }

        // this is a workaround to set minZoom for "map.fitBounds()"
        const prevMinZoom = map.options.minZoom
        map.options.minZoom = ZIP_LAYER_MIN_ZOOM
        map.fitBounds(bbox)
        map.options.minZoom = prevMinZoom
    }, [map, bbox])

    useEffect(() => {
        if (!map || !demographics.length) {
            return
        }

        const zips = {}
        demographics.forEach(zipcode => {
            zips[zipcode.zip] = true
        })

        const ZipsLayerClass = getLeafletZipsLayerClass({
            mapId: MAP_ID,
            layerId: MAPBOX_LAYER_ID,
            mapboxAccessToken: MAPBOX_ACCESS_TOKEN
        })

        const zipsLayer = new ZipsLayerClass({
            hoveredLayers: mode === 'hover' ? null : [],
            onHover: function (event) {
                if (mode === 'touch') {
                    return
                }
                var f = event.feature
                if (f) {
                    zipsLayer.setHoveredFeature(f.properties.zip)
                    onHoverZipcode(f.properties.zip)
                } else {
                    zipsLayer.setHoveredFeature(null)
                    onHoverZipcode(null)
                }
            },
            filter: feature => !!zips[feature.properties.zip],
        })
        map.addLayer(zipsLayer)
        setZipsLayer(zipsLayer)

        return () => {
            map.removeLayer(zipsLayer)
        }
    }, [map, demographics, mode])

    useEffect(() => {
        zipsLayer && zipsLayer.updateOptions({
            onClick: function (event) {
                const zipcode = event.feature?.properties.zip
                if (mode === 'hover') {
                    zipcode && onSelectZipcode(zipcode)
                } else {
                    onHoverZipcode(zipcode)
                }
            }
        })
    }, [zipsLayer, onSelectZipcode, mode, selectedZipcodes])

    useEffect(() => {
        if (!zipsLayer) {
            return
        }

        const update = () => {
            zipsLayer.forEachFeature(f => {
                f.toggleEnabled = false
            })
            zipsLayer.setHoveredFeature(hoveredZipcode)
            zipsLayer.updateSelectedFeatures(
                Object.keys(selectedZipcodes).reduce((res, key) => {
                    res[key] = true
                    return res
                }, {})
            )
        }

        zipsLayer.updateOptions({onTilesLoaded: update})
        update()

    }, [zipsLayer, selectedZipcodes, hoveredZipcode])

    const leafletRef = useRef()

    return (
        <div
            ref={leafletRef}
            className={classes.map}
            onMouseLeave={() => {
                mode === 'hover' && hoveredZipcode && onHoverZipcode(null)
            }}
        ></div>
    )
}