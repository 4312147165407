import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        paddingTop: 60,

        [theme.breakpoints.up('md')]: {
            paddingTop: 72
        }
    }
}));

const Page = props => {

    const classes = useStyles();

    return <Box {...props} className={classNames(
        classes.root,
        props.className ? props.className : ''
    )}/>;
};

export default Page;
