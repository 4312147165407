import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'grid',
        fontSize: 13
    },

    rootLg: {
        gridTemplateColumns: '50% 30% 20%',
    },
    rootMd: {
        gridTemplateColumns: '50% 30% 20%',
    },
    rootSm: {
        gridTemplateColumns: '1fr 1fr 50px',
    },

    statsValue: {
        display: 'inline',
        fontWeight: 700
    },

    statsItem: {
        height: 22
    },

    chartItem: {
        height: 4,
        borderRadius: 2,
        alignSelf: 'center'
    },

    buttonCell: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },

    buttonCellSm: {
        gridRow: '1 / 5',
        gridColumn: '3'
    },

    buttonCellMd: {
        gridRow: '1 / 4',
        gridColumn: '4'
    },

    button: {
        width: 32,
        height: 32,
        cursor: 'pointer',
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 24
    },

    buttonAdd: {
        color: theme.palette.common.offWhite,
        background: theme.palette.neutral[900],
        '&:hover': {
            background: theme.palette.neutral.ink
        },
    },

    buttonRemove: {
        color: theme.palette.common.black,
        background: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.neutral[100]
        },
        border: '1px solid black'
    }
}))

export default function ZipcodeInfo({
    demographics,
    zipcodeTitle,
    cost,
    addresses,
    isAdded,
    onToggle,
    variant = 'lg' //sm, md, lg
}) {
    const classes = useStyles();

    const maxAvgGender = Math.max(demographics?.gender_female, demographics?.gender_male, demographics?.gender_other)

    const renderCell = (label, value) => (
        <div className={classes.statsItem}>
            {label}: <div className={classes.statsValue}>{value}</div>
        </div>
    )

    const renderLine = (key, background) => (
        <div
            className={classes.chartItem}
            style={{
                width: demographics
                    ? `${demographics[key] / maxAvgGender * 100}%`
                    : 0,
                background
            }}
        />
    )

    const addressesCell = renderCell('Addresses', addresses)
    const costCell = renderCell('Cost', cost)

    const zipcode = renderCell('Zip Code', zipcodeTitle)
    const avgIncome = renderCell('Avg. Income', demographics ? `$${Math.round(demographics.avg_hhi / 1000)}K` : '')
    const avgAge = renderCell('Avg. Age', demographics ? Math.round(demographics.avg_age) : '')

    const female = renderCell('Female', demographics ? `${demographics.gender_female.toFixed(1)}%` : '')
    const male = renderCell('Male', demographics ? `${demographics.gender_male.toFixed(1)}%` : '')
    const other = renderCell('Other', demographics ? `${demographics.gender_other.toFixed(1)}%` : '')

    const femaleLine = renderLine('gender_female', '#957EFE')
    const maleLine = renderLine('gender_male', '#8ACCFF')
    const otherLine = renderLine('gender_other', '#FFA71E')

    const button = (
        <div
            className={classNames(
                classes.buttonCell,
                variant === 'sm' && classes.buttonCellSm,
                variant === 'md' && classes.buttonCellMd,
            )}
        >
            <div className={classNames(
                classes.button,
                isAdded ? classes.buttonRemove : classes.buttonAdd
            )} onClick={onToggle}>
                {isAdded ? '\uFF0D' : '\uFF0B'}
            </div>
            {isAdded ? 'Remove' : 'Add'}
        </div>
    )

    return (
        <div className={classNames(
            classes.root,
            variant === 'lg' && classes.rootLg,
            variant === 'md' && classes.rootMd,
            variant === 'sm' && classes.rootSm,
        )}>
            {variant === 'lg' && (<>
                {zipcode}
                {female}
                {femaleLine}

                {avgIncome}
                {male}
                {maleLine}

                {avgAge}
                {other}
                {otherLine}
            </>)}
            {variant === 'sm' && (<>
                {zipcode}
                {addressesCell}
                {button}

                {avgIncome}
                {female}

                {avgAge}
                {male}

                {costCell}
                {other}
            </>)}
        </div>
    )
}