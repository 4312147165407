import React from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import { useSnackbar } from 'notistack';

import { ReactComponent as DownloadIcon } from 'images/download.svg';
import { becomesIconButtonResponsive } from 'theme/mixins.js';
import DataController from 'lib/controllers/DataController.js';
import AccountSectionHeader from 'components/elements/common/AccountSectionHeader.js';

const useStyles = makeStyles(theme => ({
    cta: {
        ...becomesIconButtonResponsive(theme),
        height: 48,
        minWidth: '237px !important',
        marginTop: 4,
        textTransform: 'initial'
    }
}));

function BillingHeader() {

    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const { search } = useLocation();

    const handleDownloadClick = e => {
        const url = `/billing${search}&download=1`;
        DataController
            .downloadBilling(url)
            .then((body) => body.blob())
            .then((blob) => URL.createObjectURL(blob))
            .then(href => {
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.href = href;
                a.style = 'display: none';
                a.download = 'billing.csv';
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(href);
            })
            .catch(() => {
                enqueueSnackbar('Error while downloading', {
                    variant: 'error'
                });
            });
    }

    return (
        <AccountSectionHeader
            rightSide={(
                <Button
                    onClick={handleDownloadClick}
                    className={classes.cta}
                    size="small"
                    variant="contained"
                    startIcon={<DownloadIcon />}
                >
                    Download current view
                </Button>
            )}
            title="Billing"
        />
    );
}

export default BillingHeader;
