import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import theme from 'theme/theme';

import { ReactComponent as AvatarIcon } from 'images/avatar.svg';

const useStyles = makeStyles(theme => ({
    wrap: {
        position: 'relative',
        marginRight: ({marginRight}) => marginRight,
        marginBottom: ({badge}) => badge ? 10 : 0,
    },
    root: {
        alignItems: 'center',
        background: theme.palette.accent.marketMint[200],
        border: ({ border, width }) => border && width > 100 && `6px solid ${border}`,
        borderRadius: 60,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        height: ({ height }) => height,
        overflow: 'hidden',
        width: ({ width }) => width,

        [theme.breakpoints.down('xs')]: {
            height: '80px!important',
            width: '80px!important',
        },

        '& img': {
            height: '100%',
        }

    },

    badge: {
        position: 'absolute',
        bottom: -9,
        transform: 'translateX("-50%")',
        border: `1px solid ${theme.palette.accent.marketMint[700]}`,
        borderRadius: 100,
        background: theme.palette.common.offWhite,
        display: 'flex',
        height: 30,
        padding: '0 10px',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 11,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.accent.marketMint[700],

    }
}));

function Avatar({
    border,
    height = 120,
    icon = <AvatarIcon/>,
    src,
    width = 120,
    badge,
    marginRight = 16,
    ...rest
}) {

    const classes = useStyles({
        border,
        height,
        width,
        marginRight,
        badge
    });

    return (
        <Box className={classes.wrap}>
            <Box
                className={`avatar ${classes.root}`}
                {...rest}
            >
                {src ? (
                    <img
                        alt="avatar"
                        src={src}
                    />
                ) : (
                    icon
                )}
            </Box>
        </Box>
    );
}

Avatar.propTypes = {
    border: PropTypes.string,
    height: PropTypes.number,
    icon: PropTypes.element,
    src: PropTypes.string,
    width: PropTypes.number,
};

Avatar.defaultProps = {
    border: theme.palette.accent.marketMint[600]
}

export default Avatar;
