import React from 'react';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    infoItemWrapper: {
        display: 'flex',
        marginTop: 15,
        justifyContent: 'flex-start',
    },
    circle: {
        backgroundColor: theme.palette.accent.subscriberSunshine[500],
        height: 20,
        width: 20,
        borderRadius: 10,
        marginRight: 16,
    },
    label: {
        fontFamily: 'Neutrif Studio',
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: 11,
        lineHeight: '13px',
        alignItems: 'center',
        textTransform: 'uppercase',
        color: theme.palette.neutral[900],
    },
    value: {
        fontFamily: 'Neutrif Studio',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 13,
        lineHeight: '18px',
        alignItems: 'center',
        textTransform: 'uppercase',
        color: theme.palette.neutral[700],
        marginTop: 5
    }
}))

export default function InfoItem({ label, value, color, percent }) {
    const classes = useStyles();
    const val = percent ? `${value} (${percent})%` : value;
    return (
        <div className={classes.infoItemWrapper}>
            <div className={classes.circle} style={{background: color}}/>
            <div>
                <div className={classes.label}>{label}</div>
                <div className={classes.value}>{val}</div>
            </div>
        </div>
    )
}
