import React, { useState } from "react";
import { Box, List, ListItem, ListItemText, ListItemSecondaryAction, makeStyles } from "@material-ui/core";
import UserRoleChip from "../TeamPage/UserRoleChip";
import MoreMenu from "../common/MoreMenu";
import DataController from "lib/controllers/DataController";
import useAppState from "lib/hooks/useAppState";
import actions from "store/actions";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(theme => ({
    wrap: {
        maxWidth: 792,  // TODO - this is repeatable maxWidth (FieldGroup) - maybe set globally
    },

    listItem: {
        paddingLeft: 0,
        paddingRight: 0,
        color: theme.palette.neutral[800],
        borderBottom: `1px solid ${theme.palette.neutral[300]}`,
    },

    actionWrapper: {
        right: 0,
        display: 'flex',
        alignItems: 'center',
    }
}));

export default function AssignedTeamMembers({ client, meta }) {

    const { dispatch } = useAppState();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleRemoveClient = (teamMemberId) => {
        if (loading) return
        setLoading(teamMemberId);

        DataController
            .removeMembersFromClient({ memberIds: teamMemberId, companyId: client.id })
            .then(({ data, errors, message, success }) => {
                if (success) {
                    enqueueSnackbar('Team member successfully removed!');

                    dispatch({
                        type: actions.SET_CURRENT_CLIENT,
                        payload: data
                    });

                    // re-fetch my team to fetch single member changes
                    dispatch({
                        type: actions.CLEAR_DATA,
                        payload: {
                            action: actions.SET_MY_TEAM
                        }
                    });
                } else {
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                }
                setLoading(false);
            });
    }

    const handleUpdateUserRole = (role, teamMemberId) => {
        if (loading) return

        setLoading(true);

        const contactId = teamMemberId;
        const companyId = client.id;

        DataController
            .postTeamMemberRole({ role, companyId, contactId })
            .then(({ data, errors, message, success }) => {
                if (success) {
                    // set response values in myTeam array
                    dispatch({
                        type: actions.UPDATE_ROW,
                        payload: {
                            location: 'myTeam.contacts',
                            data: data
                        }
                    });
                    // re-fetch current client to update team member roles
                    dispatch({
                        type: actions.CLEAR_DATA,
                        payload: {
                            action: actions.SET_CURRENT_CLIENT
                        }
                    });
                    enqueueSnackbar('Contact successfully updated!');

                } else {
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                }
                setLoading(false);
            });
    }

    return (
        <Box className={classes.wrap}>
            <List>
                {client.team.map(teamMember => {
                    const items = [];

                    if (teamMember.is_admin) {
                        items.push(...[
                            { label: 'Downgrade to client member', onClick: () => { handleUpdateUserRole('member', teamMember.id) } },
                            { label: 'Remove from client', onClick: () => { handleRemoveClient(teamMember.id) } },
                        ])
                    } else if (teamMember.is_member) {
                        items.push(...[
                            { label: 'Upgrade to client admin', onClick: () => { handleUpdateUserRole('admin', teamMember.id) } },
                            { label: 'Remove from client', onClick: () => { handleRemoveClient(teamMember.id) } },
                        ])
                    } else {
                        items.push(...[
                            { label: 'Downgrade to client member', onClick: () => { handleUpdateUserRole('member', teamMember.id) } },
                            { label: 'Upgrade to client admin', onClick: () => { handleUpdateUserRole('admin', teamMember.id) } },
                            { label: 'Remove from client', onClick: () => { handleRemoveClient(teamMember.id) } },
                        ])
                    }
                    return (
                        <ListItem key={teamMember.id} className={classes.listItem}>
                            <ListItemText
                                primary={teamMember.fullname}
                            />
                            <ListItemSecondaryAction className={classes.actionWrapper}>
                                <UserRoleChip role={ teamMember.role? `client ${teamMember.role}` : null } description={meta[teamMember.role]}/>

                                {teamMember.is_editable && items.length > 0 &&
                                    <MoreMenu
                                        items={items}
                                        loading={ loading === teamMember.id }
                                    />
                                }
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
}
