import React from 'react';
import useAppState from '../../../lib/hooks/useAppState.js';
import AccountSectionHeader from '../common/AccountSectionHeader.js';

function ProfileHeader() {
    const { state } = useAppState();
    return <AccountSectionHeader
        title={state.userData.fullname || 'Unknown'}
        isLoaded={!state.isLoading}/>;
}

export default ProfileHeader;