import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ReactComponent as Logo } from 'images/addy-logo.svg';
import useLinkHandler from '../../../lib/hooks/useLinkHandler';
import { wwwUrl } from 'lib/config.js';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 154,
        padding: '20px 20px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    groundTruth: {
        fontWeight: 'bold',
        marginLeft: 4,
    },
    firstBox: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 15,
        borderBottom: `1px solid ${theme.palette.neutral[300]}`,

        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column'
        },
    },
    logoWrapper: {
        fontFamily: 'Neutrif Studio',
        fontSize: 13,
        lineHeight: '18px',
        color: theme.palette.neutral[800],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 20,

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'space-between',
            marginRight: 0,
        },
    },
    secondBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        },
    },
    copyrightWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse'
        },
    },
    logo: {
        width: 40,
        height: 22,
        marginRight: 5,
    },
    divider: {
        padding: 0,
        margin: 0
    },
    infoLinks: {
        '& a': {
            marginRight: 15,
            display: 'inline-block',
            padding: '5px 0',
        },

        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            padding: '10px 0',
            maxWidth: 250,
        },
    },
    adLinks: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px 0'
        },
        '& a': {
            marginLeft: 20,
            color: theme.palette.primary[500],
            fontWeight: 800,
            fontSize: 13,
            lineHeight: '18px',

            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
            },
        }
    },
    copyright: {
        fontSize: 11,
        lineHeight: '13px',
        textAlign: 'right',
        color: theme.palette.neutral[600],

        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            marginTop: 20,
            width: 260
        },
    },
    contact: {
        fontSize: 13,
        lineHeight: '18px',
        color: theme.palette.neutral[600],
        '& span': {
            color: theme.palette.primary[500]
        }
    }
}));

const AD_LINKS = [
    { url: wwwUrl + '/shop/magazines', name: 'Magazines' },
    { url: wwwUrl + '/shop/websites', name: 'Online' },
    { url: wwwUrl + '/shop/tv', name: 'TV' },
    { url: wwwUrl + '/shop/out-of-home', name: 'Out of Home' },
    { url: wwwUrl + '/direct-mail', name: 'Direct Mail' },
];

const INFO_LINKS = [
    { url: wwwUrl + '/about-us', name: 'about us' },
    { url: wwwUrl + '/how-it-works', name: 'how it works' },
    { url: wwwUrl + '/info/resourses', name: 'resources' },
    { url: wwwUrl + '/specs', name: 'specs' },
    { url: wwwUrl + '/assets/pdf/adverator-terms.pdf', name: 'legal' },
    { url: wwwUrl + '/assets/pdf/adverator-privacy.pdf', name: 'privacy' },
];

const Footer = () => {
    const classes = useStyles();
    const { getLinkAnchorProps } = useLinkHandler();

    return (
        <Box className={classes.root} id="footer">
            <div className={classes.firstBox}>
                <div className={classes.logoWrapper}>
                    <Logo className={classes.logo} />
                    <span>A division of<span className={classes.groundTruth}>GroundTruth</span></span>
                </div>
                <div className={classes.adLinks}>
                    {AD_LINKS.map((el, index) => {
                        return (
                            <a key={index} {...getLinkAnchorProps({url: el.url, target: '_self'})}>{el.name}</a>
                        )
                    })}
                </div>
            </div>
            <div className={classes.secondBox}>
                <div className={classes.infoLinks}>
                    {INFO_LINKS.map((el, index) => {
                        return (
                            <a key={index} {...getLinkAnchorProps({url: el.url, target: '_self'})}>{el.name}</a>
                        )
                    })}
                </div>
            </div>
            <div className={classes.copyrightWrapper}>
                <div className={classes.copyright}>
                    Addy is a registered trademark of xAd, Inc. ©2021. All rights reserved.&nbsp;
                    <a {...getLinkAnchorProps({url: wwwUrl + '/assets/pdf/adverator-privacy.pdf#page=4', target: '_self'})}>Adchoices.</a>
                </div>
                <div className={classes.contact}>
                    Get in touch: <span>(646) 595-1155</span> or <span>info@addy.co</span>
                </div>
            </div>
        </Box>
    );
};

export default Footer;
