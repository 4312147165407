import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {},
    skel: {},
    head: {
        marginBottom: 40,
        flexDirection: 'column',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            marginBottom: 46
        },

        [theme.breakpoints.up('md')]: {
            marginBottom: 56
        },

        '& $skel': {
            flexGrow: 1,
            height: 40,

            [theme.breakpoints.up('md')]: {
                height: 60
            }
        },

        '& $skel + $skel': {
            marginTop: 24,

            [theme.breakpoints.up('sm')]: {
                marginLeft: 24,
                marginTop: 0
            }
        },

        '& $skel:last-of-type': {
            [theme.breakpoints.up('sm')]: {

                maxWidth: 170,
                display: 'block'
            },

            [theme.breakpoints.up('md')]: {
                maxWidth: 250
            }
        }
    },
    table: {
        margin: '16px -16px',

        [theme.breakpoints.up('sm')]: {
            margin: '24px -24px'
        },

        [theme.breakpoints.up('md')]: {
            margin: '32px -32px'
        },

        '& $skel': {
            width: '100%'
        },

        '& $skel + $skel': {
            marginTop: 2
        },

        '& $skel:not(:first-of-type)': {
            backgroundColor: theme.palette.neutral[100]
        }
    }
}));

function SkeletonTable({ disableHead = false }) {

    const classes = useStyles();

    return <div>
        {!disableHead && (
            <Box display="flex" className={classes.head}>
                <Skeleton className={classes.skel} animation="wave" variant="rect"/>
                <Skeleton className={classes.skel} animation="wave" variant="rect"/>
            </Box>
        )}
        <Box display="flex" flexDirection="column" className={classes.table}>
            <Skeleton className={classes.skel} animation="wave" variant="rect" height={36}/>
            {[...Array(9)].map((e, i) =>
                <Skeleton key={i} className={classes.skel} animation="wave" variant="rect" height={36}/>)}
        </Box>
    </div>;
}

export default SkeletonTable;
