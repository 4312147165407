import DataController from './DataController.js';

class S3UploadController {

    constructor() {
        this.s3SDK = 'https://sdk.amazonaws.com/js/aws-sdk-2.1.24.min.js';
        this.isLoading = false;
        this.isScriptLoaded = false;
        this.uploadConfig = null;
        this.s3 = null;
    }

    // async init() {
    //     if (this.isLoading) return;
    //     this.isLoading = true;
    //
    //     await this.getUploadConfig();
    //     await this.loadS3Script();
    //
    //     window.AWS.config.update({
    //         region: this.uploadConfig.region,
    //         accessKeyId: this.uploadConfig.key,
    //         secretAccessKey: this.uploadConfig.secret
    //     });
    //
    //     this.s3 = new window.AWS.S3({
    //         apiVersion: '2006-03-01',
    //         params: { Bucket: this.uploadConfig.bucket }
    //     });
    //
    //     this.isLoading = false;
    //     console.log('[S3UploadController] init complete', this);
    // }

    getUploadConfig(fileName) {
        return new Promise(resolve => {
            if (this.uploadConfig && !fileName) {
                resolve(true);
                return;
            }
            DataController.getFileUploadConfig(fileName).then(res => {
                this.uploadConfig = res.data;
                resolve(true);
            });
        });
    }

    // loadS3Script() {
    //     return new Promise(resolve => {
    //
    //         const scriptReadyCallback = () => {
    //             this.isScriptLoaded = true;
    //             resolve(true);
    //         };
    //
    //         if (this.isScriptLoaded || window.AWS) {
    //             scriptReadyCallback();
    //             return;
    //         }
    //         const script = document.createElement('script');
    //         script.id = 'script-s3SDK';
    //         script.src = this.s3SDK;
    //         script.async = true;
    //         script.onload = () => {
    //             scriptReadyCallback();
    //         };
    //         document.body.append(script);
    //     });
    // }

    async putUploadFile({
                            file,
                            path,
                            onProgress
                        }) {

        const fileName = file.name;
        const filePath = `${path ? `${path}/` : ''}${new Date().getTime()}/${fileName}`;

        const uploadConfig = await this.getUploadConfig(filePath);

        console.log('uploadConfig', uploadConfig, this);

        // const formData = new FormData();
        // formData.append('file', file);

        const opts = {
            method: 'PUT',
            body: file,
            raw: true
        };

        await DataController.uploadProgressAuth(
            this.uploadConfig.presigned_url,
            opts,
            perc => onProgress(perc),
            false
        );

        return this.uploadConfig.presigned_url.split('?')[0];
    }

    // uploadFile({
    //                file,
    //                path,
    //                onProgress
    //            }) {
    //
    //     return new Promise((resolve, reject) => {
    //         if (!this.isScriptLoaded || !this.uploadConfig || !this.s3) {
    //             reject();
    //             return;
    //         }
    //
    //         const fileName = file.name;
    //         const filePath = `${path ? `${path}/` : ''}${new Date().getTime()}/${fileName}`;
    //         // const fileURL = `https://${this.uploadConfig.region}.amazonaws.com/${this.uploadConfig.bucket ? `${this.uploadConfig.bucket}/` : ''}${filePath}`;
    //
    //         this.s3.upload({
    //             Key: filePath,
    //             Body: file,
    //             ACL: 'public-read'
    //         }, (err, data) => {
    //             if (err) {
    //                 console.log('[S3UploadController] onError', err);
    //                 reject(err);
    //             } else {
    //                 console.log('[S3UploadController] onSuccess', data);
    //                 resolve(data);
    //             }
    //         }).on('httpUploadProgress', (progress) => {
    //             console.log('[S3UploadController] onProgress', progress);
    //             if (onProgress) onProgress((progress.loaded * 100) / progress.total);
    //         });
    //     });
    // }
}

export default new S3UploadController();