import React from 'react';
import OrdersList from '../../../elements/OrdersPage/OrdersList.js';

function OrdersInProcessPage() {
    return (
        <OrdersList status="inflight" title="In Process"/>
    );
}

export default OrdersInProcessPage;
