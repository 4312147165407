import { useState, useEffect } from "react";

const cachedScripts = {};

export default function (src, postponeLoading = false) {
    const [state, setState] = useState(cachedScripts[src] || {
        loaded: false,
        error: false
    });

    useEffect(() => {
        if (cachedScripts[src]) {
            setState(cachedScripts[src]);
        } else {
            if (postponeLoading) {
                return
            }

            cachedScripts[src] = state;

            const script = document.createElement('script');
            script.src = src;
            script.async = true;

            const onScriptLoad = () => {
                cachedScripts[src] = {
                    loaded: true,
                    error: false
                }
                setState(cachedScripts[src]);
            };

            const onScriptError = () => {
                cachedScripts[src] = {
                    loaded: true,
                    error: true
                }

                setState(cachedScripts[src]);
            };

            script.addEventListener('load', onScriptLoad);
            script.addEventListener('error', onScriptError);

            document.body.appendChild(script);

            return () => {
                script.removeEventListener('load', onScriptLoad);
                script.removeEventListener('error', onScriptError);
            };
        }
    }, [src, postponeLoading]);

    return [state.loaded, state.error];
}