import { makeStyles } from '@material-ui/core';
import ABDMCircleURL from 'images/abdmCircle.png'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        width: 600,
        height: 600,
        left: 0,
        top: 0,
        background: 'linear-gradient(135deg, rgba(23, 23, 23, 0.5) 0%, rgba(23, 23, 23, 0) 50%)',
        [theme.breakpoints.down('md')]: {
            width: 400,
            height: 400,
        },
    },
    
    spiral: {
        width: '60%'
    },

    text: {
        color: theme.palette.common.white,
        fontFamily: 'Ahkio',
        fontWeight: 700,
        fontSize: 20,
        lineHeight: '20px',
        letterSpacing: '0.555556px',
        textTransform: 'uppercase',
        position: 'absolute',
        top: '11%',
        left: '14%',
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
    }
}))

export default function ABDMBanner() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <img className={classes.spiral} src={ABDMCircleURL}/>
            <div className={classes.text}>
                just in the AUDIENCE<br />you want to reach.
            </div>
        </div>
    )
}