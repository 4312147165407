import React, { useState, useEffect } from "react";
import useData from "lib/hooks/useData";
import { Dropdown } from "../../common";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { ReactComponent as PlusIcon } from 'images/plusCircle.svg';
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    addressItem: {
        position: 'relative',
        color: theme.palette.neutral[700],
        fontSize: 15,

        [theme.breakpoints.down('sm')]: {
            fontSize: 13
        },

        '&::after': {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 1,
            background: theme.palette.accent.marketMint[600],
        },
    },

    btn: {
        width: '100%',
        textAlign: 'center',
        height: 52,

        '& .MuiButton-label': {
            fontSize: 13,
            textTransform: 'lowercase',
            fontWeight: 'normal'
        },
    },
    select: (props) => ({
        flex: 1,
        '& > *': {
            width: '100%',
        },

        // HACK: when value is selected, hide the address row, show only 1st row
        '& .addressRow': {
            display: 'none',
        }
    }),

    selectOpen: {
        '& .MuiInputBase-root': {
            borderRadius: '10px 10px 0 0',
        },

        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.primary[500]} !important`
        }
    },

    menu: {
        '& .MuiMenu-paper': {
            border: `1px solid ${theme.palette.primary[500]}`,
            borderTop: 'none'
        },

        '& .MuiMenuItem-root': {
            padding: '8px 11px'
        },

        '& .addressRow': {
            whiteSpace: 'normal'
        }
    },
    placeholder: (props) => ({
        fontSize: 15,
        color: theme.palette.neutral[500],

        [theme.breakpoints.down('sm')]: {
            fontSize: 13
        }
    })
}));

export default function AddressSelect({ value, onChange, setAddressFormOpen }) {
    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const state = useData({
        queries: ['getMyAddresses'],
        setLoadingState: true
    });

    const handleAddAddress = () => {
        setAddressFormOpen();
        handleClose();
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        let options = state.addresses.addresses ? state.addresses.addresses.map(address => ({
            text: <Typography
                className={classes.addressItem}
                variant="inherit"
                noWrap
                dangerouslySetInnerHTML={{ __html: `${address.address_name}<br><span class="addressRow">${address.street} ${address.street2 ? address.street2 : ''} ${address.city}, ${address.state} ${address.zip}</span>` }}>
            </Typography>,
            value: address.id,
        })) : [];

        options.push({
            text: <Button
                className={classes.btn}
                onClick={e => { e.stopPropagation(); handleAddAddress(); } }
                size="small"
                startIcon={<PlusIcon/>}>add new address</Button>,
            value: 'add',
            unstyled: true,
        })

        setOptions(options);
    }, [state])

    const handleChange = (e) => {
        onChange(e.target.value);
    }

    return <Dropdown
        className={classNames(classes.select, open ? classes.selectOpen : '')}
        menuClassName={classNames(classes.menu, open ? classes.menuOpen : '')}
        id="address"
        label={false}
        small={true}
        separator={true}
        onChange={handleChange}
        options={options}
        value={value}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        renderValue={value ? '' : () => <div className={classes.placeholder}>Select address…</div>}
    />
}
