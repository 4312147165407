export function findZipcode(e) {
    let elem = e.target

    while(elem) {
        if (elem.dataset.zipcode) {
            return elem.dataset.zipcode
        }
        elem = elem.parentNode
    }
    return null
}
