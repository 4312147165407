const palette = {
    background: {
        start: '#c5ede9',
        end: '#fef3e1'
    },
    common: {
        base: '#202020',
        offWhite: '#fdfdfd'
    },
    primary: {
        main: '#957eFe',
        100: '#DBD4FF',
        200: '#D0C4FF',
        400: '#AA98FE',
        500: '#957efe',
        700: '#6858B2',
        900: '#3C3266',
    },
    secondary: {
        main: '#3f3f3f', // neutral[900]
        light: '#C7C7CC'
    },
    active: {
        main: '#957eFe'
    },
    neutral: {
        100: '#f8f8f8',
        200: '#f6f6f6',
        300: '#ededed',
        400: '#dfdfdf',
        500: '#d1d1d1',
        600: '#b1b1b1',
        700: '#939393',
        800: '#737373',
        900: '#3f3f3f',
        ink: '#171717',
        paper: '#ebebeb',
        audienceAsphalt: '#37373d'
    },
    error: {
        light: '#fffbf5',
        main: '#fe585a'
    },
    accent: {
        buyerBlue: {
            500: '#8accff',
            600: '#73B3E6'
        },
        customerCoral: {
            400: '#fe585a',
            500: '#FE585A',
            600: '#D84B4C'
        },
        marketMint: {
            100: '#EEFAF8',
            200: '#e8f8f6',
            300: '#dcf4f2',
            400: '#d1f1ed',
            600: '#aed1ce',
            700: '#8aa6a3',
            900: '#4f5f5d'
        },
        subscriberSunshine: {
            100: '#ffe5bb',
            500: '#ffa71e',
            700: '#B37515',
        },
        publicPink: {
            400: '#f1d0d1',
            500: '#edc4c6',
            600: '#c9a7a8'
        },
        TV: {
            500: '#82E7DD'
        },
        online: {
            500: '#AEF205'
        },
    },
    text: {
        1: '#212529',
        primary: '#3f3f3f', // neutral[900]
        secondary: '#b1b1b1' // neutral[600]
    },
    white: '#fff'
};

export default palette;