import React, { createContext, useReducer } from 'react';
import PersistentStorage from '../lib/controllers/PersistentStorage.js';
import initialState from './initialState.js';
import actions from './actions.js';
import reducer from './reducer.js';

const _initialState = Object.assign(initialState, PersistentStorage.getAll());

const store = createContext(_initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { actions, store, StateProvider };
