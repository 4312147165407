import React, { useState, useRef, useEffect } from 'react';
import { makeStyles, Popover } from '@material-ui/core';
import { ReactComponent as InfoIcon } from 'images/info.svg';
import useBoolean from 'lib/hooks/useBoolean';
import { ABDM_MIN_ADDRESSES } from 'lib/config';
import classNames from 'classnames'
import DataController from 'lib/controllers/DataController';

const useStyles = makeStyles(theme => ({
    root: {
        height: 90,
        marginTop: 4,
        borderTop: '1px solid #DFDFDF',
        flexShrink: 0
    },
    rootError: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    rowAddresses: {
        display: 'flex',
        marginBottom: 4
    },
    rowDiscount: {
        display: 'flex'
    },
    leftColumn: {
        flexGrow: 1
    },
    info: {
        cursor: 'pointer',
        display: 'flex',
        '& > svg': {
            width: 20,
            height: 20
        },
        marginRight: 4
    },
    nextDiscount: {
        fontSize: 12,
        marginLeft: 20
    },
    rowTotal: {
        display: 'flex',
        fontWeight: 700,
        marginTop: 4
    },

    popover: {
        transform: 'translate(0, -8px)'
    },

    infoPopover: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.offWhite,
        padding: 8,
    },

    infoPopupHeader: {
        marginBottom: 8
    }
}))

const MapSummary = React.memo(({
    quote, // {isFetching: boolean, result: API response}
}) => {
    const classes = useStyles()

    const [infoOpen, openInfo, closeInfo] = useBoolean(false)
    const infoAnchorEl = useRef()

    const [rates, setRates] = useState(null)

    useEffect(() => {
        if (infoOpen && !rates) {
            DataController.getABDMRates().then(response => setRates(response.data))
        }
    }, [infoOpen, rates])

    if (!quote.result) {
        return (
            <div className={classes.root} />
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.rowAddresses}>
                <div className={classes.leftColumn}>
                    {quote.result.qty_formatted} Addresses
                </div>
                <div>{quote.result.price_without_discount_formatted}</div>
            </div>
            <div className={classes.rowDiscount}>
                <div ref={infoAnchorEl} className={classes.info} onClick={openInfo}>
                    <InfoIcon />
                </div>
                <div className={classes.leftColumn}>
                    Volume Discount
                </div>
                <div>{quote.result.total_discount_formatted}</div>
            </div>
            <div className={classes.nextDiscount}>
                +{quote.result.qty_next_discount_formatted} more addresses until next discount
            </div>
            <div className={classes.rowTotal}>
                <div className={classes.leftColumn}>
                    Total Cost
                </div>
                <div>{quote.result.price_formatted}</div>
            </div>
            <Popover
                className={classes.popover}
                open={infoOpen && !!rates}
                anchorEl={infoAnchorEl.current}
                onClose={closeInfo}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                {rates && (
                    <div className={classes.infoPopover}>
                        <div className={classes.infoPopupHeader}>
                            As you increase the number of<br/>homes you mail to, your rate drops.
                        </div>
                        <div>
                            Minimum {Math.min(...rates.map(rate => rate.min))} number of addresses
                        </div>
                        {rates.map(rate => (
                            <div>
                                {rate.max > 1000000
                                    ? `Over ${rate.min} addresses ${rate.rate_formatted}`
                                    : `Up to ${rate.max} addresses ${rate.rate_formatted}`
                                }
                            </div>
                        ))}
                    </div>
                )}
            </Popover>
        </div>
    )
})

export default MapSummary