import React, { useState } from 'react';
import { Box, Divider, makeStyles } from '@material-ui/core';
import { Tabs } from '../../common';
import PieChart from './PieChart';
import BarChart from './BarChart';
import AreaChart from './AreaChart';
import InfoItem from './InfoItem';
import palette from '../../../../theme/palette';
import useData from "lib/hooks/useData";
import OrderFileSpecs from "../OrderFileSpecs";
import OrderDesignHelp from "../OrderDesignHelp";
import classNames from "classnames";

const colors = [
     palette.accent.subscriberSunshine[500],
     palette.accent.customerCoral[500],
     palette.primary[500],
     palette.background.start,
];

const useStyles = makeStyles(theme => ({
    endedCampaignWrapper: {
        display: 'flex',
        flexDirection: 'row',
        padding: '15px 0',
        margin: '40px 0 30px 0',
        background: theme.palette.accent.marketMint[100],
        border: `1px solid ${theme.palette.accent.marketMint[300]}`,
        boxSizing: 'border-box',
        borderRadius: 5,
        position: 'relative'
    },
    cellWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cellTitle: {
        color: theme.palette.neutral[600],
        fontSize: 11,
        lineHeight: '18px',
    },
    cellValue: {
        fontSize: 34,
        lineHeight: '100%',
        letterSpacing: -0.45,
    },
    liveCampaignBadge: {
        position: 'absolute',
        top: -17,
        borderRadius: 14,
        padding: '4px 12px 6px',
        fontSize: 13,
        lineHeight: '18px',
        color: theme.palette.white
    },
    liveCampaignBadgeColor: {
        background: theme.palette.accent.subscriberSunshine[500],
    },
    endedCampaignBadgeColor: {
        background: theme.palette.accent.marketMint[700],
    },
    metricSectionWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 45,
        marginBottom: 55,

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    infoSectionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 50,

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
            marginLeft: 0,
            flexFlow: 'wrap'
        }
    },
    tabsMenu: {
        '& .MuiTabs-root': {
            borderBottom: `1px solid ${theme.palette.neutral[700]}`,
        },
        position: 'relative',
    },
    divider: {
        marginTop: 100,
        backgroundColor: theme.palette.neutral[700],
        opacity: 1
    },
    tabDate: {
        paddingTop: 30,
        fontSize: 13,
        lineHeight: '18px',
        color: theme.palette.neutral[600],
    },
    adsWrapper: {
        marginTop: 30
    },
    tabLabel: {
        textTransform: "unset"
    },
    borders: {
        borderLeft: `1px solid ${theme.palette.background.start}`,
        borderRight: `1px solid ${theme.palette.background.start}`
    }
}))

export default function OrderCharts({ order, id }) {
    const classes = useStyles();
    const [value, setValue] = useState(0)

    const handleTabClick = (e, index) => {
        e.preventDefault();
        setValue(index)
    };

    const state = useData({
        queries: [['getOrderMetrics', [id]]],
        cbError: () => {
            // TODO: 404
        }
    });

    const metrics = state.orderMetrics?.data

    const status = order.is_inflight ? 'live campaign' : 'ended campaign';
    const className = order.is_inflight ? classes.liveCampaignBadgeColor : classes.endedCampaignBadgeColor;
    const totalClicks = metrics.totals && metrics.totals[0].clicks_formatted;
    const totalImpressions = metrics.totals && metrics.totals[0].impressions_formatted;
    const CTR = metrics.totals && metrics.totals[0].ctr_formatted;
    const dataKey = value ? 'impressions' : 'clicks';
    const dataKeyFormatted = `${dataKey}_formatted`;
    const percentFormatted = `${dataKey}_percent_formatted`;
    const tabDate = metrics.rollup?.length ? metrics.rollup[metrics.rollup.length - 1].date_formatted : '';

    return (
        <Box id="metrics">
            <div className={classes.endedCampaignWrapper}>
                <div className={classes.cellWrapper}>
                    <div className={classes.cellTitle}>TOTAL CLICKS</div>
                    <div className={classes.cellValue}>{totalClicks}</div>
                </div>
                <div />
                <div className={classNames(classes.cellWrapper, classes.borders)}>
                    <div className={`${classes.liveCampaignBadge} ${className}`}>{status}</div>
                    <div className={classes.cellTitle}>IMPRESSIONS</div>
                    <div className={classes.cellValue}>{totalImpressions}</div>
                </div>
                <div className={classes.cellWrapper}>
                    <div className={classes.cellTitle}>CTR</div>
                    <div className={classes.cellValue}>{`${CTR}%`}</div>
                </div>
            </div>
            <div className={classes.tabsMenu}>
                <Tabs
                    value={value}
                    onChange={handleTabClick}
                >
                    <Tabs.Label className={classes.tabLabel} label="Clicks" />
                    <Tabs.Label className={classes.tabLabel} label="Impressions" />
                    <Tabs.Label className={classes.tabLabel} label="Ads" />
                </Tabs>
            </div>
            {[0,1].includes(value) && (
                <>
                    {tabDate && (
                        <div className={classes.tabDate}>{`as of ${tabDate}`}</div>
                    )}
                    {!!metrics.rollup?.length && (
                        <>
                            <div className={classes.metricSectionWrapper}>
                                <AreaChart
                                    data={metrics.rollup}
                                    title={value ? "Total Impressions" : "Total Clicks"}
                                    dataKey={dataKey}
                                    nameKey="date_formatted"
                                    areaColor={order.is_flight ? palette.primary[500] : palette.accent.customerCoral[500]}
                                />
                            </div>
                            <Divider className={classes.divider}/>
                        </>
                    )}
                    {!!metrics.device?.length && (
                        <>
                            <div className={classes.metricSectionWrapper}>
                                <PieChart
                                    data={metrics.device}
                                    title="By Devices"
                                    colors={colors}
                                    dataKey={dataKey}
                                    nameKey="device"
                                />
                                <div className={classes.infoSectionWrapper}>
                                    {metrics.device.map((device, index) => {
                                        return (
                                            <InfoItem
                                                key={index}
                                                label={device.device}
                                                value={device[dataKeyFormatted]}
                                                percent={device[percentFormatted]}
                                                color={colors[index % colors.length]}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                            <Divider className={classes.divider}/>
                        </>
                    )}
                    {!!metrics.gender?.length && (
                        <>
                            <div className={classes.metricSectionWrapper}>
                                <PieChart
                                    data={metrics.gender}
                                    title="By Gender"
                                    colors={colors}
                                    dataKey={dataKey}
                                    nameKey="gender"
                                />
                                <div className={classes.infoSectionWrapper}>
                                    {metrics.gender.map((gender, index) => {
                                        return (
                                            <InfoItem
                                                key={index}
                                                label={gender.gender}
                                                value={gender[dataKeyFormatted]}
                                                percent={gender[percentFormatted]}
                                                color={colors[index % colors.length]}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                            <Divider className={classes.divider}/>
                        </>
                    )}
                    {!!metrics.age?.length && (
                        <>
                            <div className={classes.metricSectionWrapper}>
                                <BarChart
                                    data={metrics.age}
                                    dataKey={dataKey}
                                    nameKey="age"
                                />
                            </div>
                        </>
                    )}
              </>
            )}
            {value === 2 && (
                <>
                    <div className={classes.adsWrapper}>
                        <OrderFileSpecs order={order} />
                    </div>
                    <OrderDesignHelp order={state.orders.current}/>
                </>
            )}
        </Box>
    )
}
