import { Box, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getImageDimensions, gifAnimationCheck, humanFileSize } from '../../../lib/functions.js';
import PDFViewer from './PDFViewer.js';

const ERRORS = {
    FILE_SIZE: (maxSize) => `Please select a file up to ${humanFileSize(maxSize)}.`,
    FILE_TYPE: 'Incorrect file type.',
    FILE_DIMENSIONS: (width, height) => `The dimensions required for this file are ${width}x${height} pixels.`,
    FILE_GIF_SIZE: 'GIF animations must be 30 seconds or less and cannot loop continuously.'
};

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        alignItems: 'center',
        border: `1px dashed ${theme.palette.neutral[600]}`,
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    rootFilled: {
        borderRadius: 0,
        borderColor: 'transparent'
    },
    rootIsDragging: {
        borderColor: theme.palette.neutral[800]
    },
    label: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontWeight: 800
    },
    input: {
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        display: 'block',
        width: '100%',
        height: '100%',
        opacity: 0,
        cursor: 'pointer'
    },
    inputBehind: {
        zIndex: -1
    },
    selected: {},
    selectedImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        maxWidth: '100%',
        height: '100%'
    },
    selectedPDF: {
        width: '100%',
        height: 'auto',

        '& + $selectedPDF': {
            marginTop: 24
        }
    },
    selectedVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 2
    }
}));

function DropFile({
                      onSelect,
                      onError,
                      onWarning,
                      label,
                      className,
                      id = 'file-drop',
                      template,
                      inputAccept = 'image/png,image/jpg,image/gif,image/jpeg',
                      children,
                      zoomButton,
                      clearButton,
                      startOverButton,
                      toggleTrimlinesButton,
                      width,
                      height,
                      maxSize,
                      trimlines,
                      hasSelectedFile,
                      hasSelectedFileShowTrimlines,
                      isEditableFiles,
                      isError,
                      measurements,
                      value
                  }) {

    const classes = useStyles();
    const [isDragging, setIsDragging] = useState(false);
    const [firstPreviewLoaded, setFirstPreviewLoaded] = useState(false);

    const setStartDrag = () => setIsDragging(true);
    const setEndDrag = () => setIsDragging(false);

    const handleDragOver = e => {
        e.preventDefault();
        setStartDrag();
    };

    const showTrimlinesBtn = hasSelectedFile && trimlines && trimlines.length && !isError;

    const checkFileBeforeSelect = async file => {
        if (!isEditableFiles) return;
        if (maxSize) {
            if (file.size > maxSize) {
                onError(ERRORS.FILE_SIZE(maxSize), file);
                return;
            }
        }
        if (inputAccept) {
            const inputAcceptArr = inputAccept.split(',');
            if (!inputAcceptArr.includes(file.type)) {
                onError(ERRORS.FILE_TYPE, file);
                return;
            }
        }
        if (inputAccept.startsWith('image/')) {
            const dimensions = await getImageDimensions(URL.createObjectURL(file));
            if (width && width !== dimensions.width) {
                onError(ERRORS.FILE_DIMENSIONS(width, height), file);
                return;
            }
            if (height && height !== dimensions.height) {
                onError(ERRORS.FILE_DIMENSIONS(width, height), file);
                return;
            }
        }
        if (file.name.substring(file.name.length - 3) === 'gif') {
            const gifCheck = await gifAnimationCheck(file, 30);
            if (!gifCheck) {
                onError(ERRORS.FILE_GIF_SIZE, file);
                return;
            }
        }

        onSelect(file);
    };

    const handleFileDrop = async e => {
        e.preventDefault();
        await checkFileBeforeSelect(e.dataTransfer.files[0]);
        setEndDrag();
    };

    const handleFileSelect = async e => {
        e.preventDefault();
        await checkFileBeforeSelect(e.target.files[0]);
        setEndDrag();
    };

    const handleFileWarning = warning => {
        if (onWarning) onWarning(warning);
    };

    const PDFEnableZoom = !hasSelectedFileShowTrimlines && showTrimlinesBtn;

    const renderValue = value => {
        if (!value) return '';
        if (inputAccept.startsWith('image/')) {
            return (
                <img
                    className={classNames(classes.selected, classes.selectedImage)}
                    src={value}
                    alt="selected image"
                />
            );
        } else if (inputAccept.startsWith('application/')) {
            return (
                <>
                    <PDFViewer
                        enableZoom={PDFEnableZoom}
                        onWarning={handleFileWarning}
                        measurements={measurements}
                        trimlines={hasSelectedFileShowTrimlines || !hasSelectedFile ? trimlines : []}
                        className={classNames(classes.selected, classes.selectedPDF)}
                        onLoad={() => setFirstPreviewLoaded(true)}
                        src={value}
                    />
                    {template === 'eddm' && firstPreviewLoaded ?
                        <PDFViewer
                            enableZoom={PDFEnableZoom}
                            pageNumber={2}
                            measurements={measurements}
                            trimlines={hasSelectedFileShowTrimlines || !hasSelectedFile ? trimlines : []}
                            className={classNames(classes.selected, classes.selectedPDF)}
                            src={value}
                        />
                      : ''
                    }
                </>
            );
        } else if (inputAccept.startsWith('video/')) {
            return <video
                controls
                className={classNames(classes.selected, classes.selectedVideo)}
                src={value}
            />;
        }
        return '';
    };

    return <Box
        className={classNames(
            classes.root,
            isDragging ? classes.rootIsDragging : '',
            value ? classes.rootFilled : '',
            className
        )}
        onDragEnter={setStartDrag}
        onDragLeave={setEndDrag}
        onDragOver={handleDragOver}
        onDrop={handleFileDrop}
    >
        {!value ? children : ''}
        {isError ? startOverButton : ''}
        {showTrimlinesBtn ? toggleTrimlinesButton : ''}
        {value && clearButton && isEditableFiles && !isError ? clearButton : ''}
        {value && !isError && !isEditableFiles ? zoomButton : ''}
        {renderValue(value)}
        {label && <label className={classes.label} htmlFor={id}>
            {label}
        </label>}
        {isEditableFiles === false ? '' : <input
            className={classNames(
                classes.input,
                inputAccept.startsWith('application/') && PDFEnableZoom ? classes.inputBehind : ''
            )}
            accept={inputAccept}
            id={id}
            onChange={handleFileSelect}
            type="file"
        />}
    </Box>;
}

DropFile.propTypes = {
    id: PropTypes.string,
    inputAccept: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    onError: PropTypes.func,
    onWarning: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    maxSize: PropTypes.number
};

export default DropFile;
