import { Box, Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    body: {
        marginTop: '-10px'
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: "100%",
        height: '50px',
        margin: '10px 0px',
        borderBottom: `2px solid ${theme.palette.neutral[300]}`,
    },
    discount: {
        '& p': {
            color: theme.palette.accent.customerCoral[500],
            fontWeight: 800
        }
    }
}));

function CheckoutReceiptLine({receiptLines, skipTotal = false}) {
    const classes = useStyles();
    return (
        <Box className={classes.body}>
            {receiptLines.map((receiptLine, i) => (
                !receiptLine.is_total && !skipTotal && 
                <Box key={i} className={classNames(classes.container,
                    receiptLine.is_discount ? classes.discount : ''
                )}>
                    <Typography>{receiptLine.description}</Typography>
                    <Typography>{receiptLine.value}</Typography>
                </Box>
            ))}
        </Box>
    )
};

export default CheckoutReceiptLine;
