import React, {useEffect, useState, useRef} from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import DataTable from '../elements/common/DataTable.js';
import useAppState from '../../lib/hooks/useAppState.js';
import {ReactComponent as Logo} from "../../images/addy-logo.svg";
import Chip from "@material-ui/core/Chip";
import {Divider} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import SelectMultipleOrders from "../elements/InvoicePage/SelectMultipleOrders";
import PaymentStatus from "../elements/InvoicePage/PaymentStatus";
import PayInvoices from "../elements/InvoicePage/PayInvoices";
import Login from "../elements/InvoicePage/Login";
import SendEmails from "../elements/InvoicePage/SendEmails";
import PaymentMethods from "../elements/InvoicePage/PaymentMethods";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import {useParams, useLocation, useHistory} from "react-router-dom";
import DataController from '../../lib/controllers/DataController.js';
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import HeaderActions from "../elements/InvoicePage/HeaderActions";
import WireTransferInfo from '../../components/elements/InvoicePage/WireTransferInfo';
import classNames from 'classnames';
import {actions} from "../../store/store";
import SkeletonTable from "../elements/common/SkeletonTable";
import SkeletonRow from '../elements/InvoicePage/Skeleton'
import { CircularProgress } from '@material-ui/core';
import StripeProvider from "./../elements/form/StripeProvider";

const useStyles = makeStyles(theme => ({
  invoicePageWrapper: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1141,
    alignItems: 'center',
    paddingBottom: 30,
    marginTop: 30,

    [theme.breakpoints.up('xs')]: {
      width: '95%',
    },
  },
  invoiceNumberWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {},

    [theme.breakpoints.up('lg')]: {},
  },
  invoiceInfoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 50,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  amountInfoRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 0 50px 0',
  },
  amountInfoWrapper: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  amountInfoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 350,
    padding: '15px 0',
    borderBottom: `1px solid ${theme.palette.neutral[300]}`,
    fontSize: 15,
    lineHeight: '20px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  discountInfo: {
    color: theme.palette.accent.customerCoral[400],
  },
  totalInfo: {
    '& :first-child': {
      color: theme.palette.neutral[600],
    },
    '& :last-child': {
      color: theme.palette.neutral[900],
    },
  },
  amountDuoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 350,
    padding: '15px 0',
    borderBottom: `1px solid ${theme.palette.neutral[300]}`,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },

    '& div': {
      fontWeight: 800,
      fontSize: 20,
      lineHeight: '25px',
      color: theme.palette.neutral[900],
    }
  },
  invoiceN: {
    fontSize: 20,
    lineHeight: '25px',
    color: theme.palette.neutral[900],
  },
  invoiceD: {
    fontSize: 15,
    lineHeight: '25px',
    color: theme.palette.neutral[900],
    textAlign: 'right',
  },
  logo: {
    width: 80,
    height: 44,
    marginRight: 5,
  },
  skel: {
    height: 22,
    width: 189,
  },
  button: {},
  chiplabel: {
    borderColor: theme.palette.text.primary,

    '& span': {
      color: theme.palette.text.primary
    }
  },
  chipOutlined: {
    background: theme.palette.common.white,
    borderColor: theme.palette.accent.subscriberSunshine[500],

    '& span': {
      color: theme.palette.accent.subscriberSunshine[500]
    }
  },
  chipPrimary: {
    borderColor: theme.palette.accent.buyerBlue[600],

    '& span': {
      color: theme.palette.accent.buyerBlue[600]
    }
  },
  chipSecondary: {
    borderColor: theme.palette.accent.customerCoral[500],

    '& span': {
      color: theme.palette.accent.customerCoral[500]
    }
  },
  chipDisabled: {
    borderColor: theme.palette.neutral[600],

    '& span': {
      color: theme.palette.neutral[600]
    }
  },
  infoWrapper: {
    display: 'flex',
  },
  el: {
    width: 150,
    textAlign: 'left',
    marginRight: 20,
  },
  chipWrapper: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 15
    },
  },
  onFileTextWrapper: {
    border: `1px solid ${theme.palette.neutral[900]}`,
    padding: 16,
    borderRadius: 10,
    fontSize: 17,
    lineHeight: '24px',
    color: theme.palette.neutral[800]
  },
  boldText: {
    fontWeight: 800
  },
  submitPayment: {
    marginTop: 20
  },
  submitPaymentBtn: {
    marginBottom: 5,
    '& span': {
      fontWeight: 400
    }
  },
  title: {
    fontWeight: 800,
    fontSize: 15,
    lineHeight: '20px',
    color: theme.palette.neutral[800]
  },
  text: {
    fontSize: 15,
    lineHeight: '20px',
    color: theme.palette.neutral[800]
  },
  dialog: {
    '& .MuiDialog-paper': {
      margin: 0,
      padding: 0,
      maxWidth: 1143,
      width: '70%',
      overflow: 'hidden',
      border: 0,

      [theme.breakpoints.down('xs')]: {
        width: '95%',
      },

      [theme.breakpoints.down('sm')]: {
        width: '95%',
      },
    },
  },
  top: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FFE5BB',
    borderRadius: 10,
    marginBottom: 20,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },

    '@media print': {
      display: 'none'
    },
  },
  info: {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: '25px',

    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    },
  },
  viewAllButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 20
    },
  },
  header: {
    height: 72,
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',

    '@media print': {
      display: 'none'
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    marginRight: 20
  },
  summaryTitle: {
    color: theme.palette.neutral[900],
    fontSize: 20,
    lineHeight: '25px',
    marginBottom: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  summaryText: {
    color: theme.palette.neutral[800],
    fontSize: 13,
    overflow: 'hidden',
    lineHeight: '18px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  nameField: {
    '@media print': {
      maxWidth: '250px'
    },
  },
  totalDueField: {
    textAlign: 'right'
  },
  iframeLoader: {
    position: 'absolute',
    width: 42,
    height: 42,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

const headings = [
  {label: 'Order', id: 'order'},
  {label: 'Name', id: 'name'},
  {label: 'Status', id: 'status'},
  {label: 'Rate', id: 'due'},
];

const statusMap = {
  open: 'default',
  overdue: 'default',
  paid: 'primary'
};

export default function InvoicePage() {

  const classes = useStyles();
  const {state} = useAppState();
  const {enqueueSnackbar} = useSnackbar();
  const {cartId, orderId} = useParams();
  const { dispatch } = useAppState();
  const [{isOpen, currentPopup}, setIsOpen] = useState({
    isOpen: false,
    currentPopup: ''
  });
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const { hash, pathname } = useLocation();
  const history = useHistory();
  const {order, all, all_total} = state.invoice?.data;

  const paymentDataWithIntent = useRef(null);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [iframeLoading, setIframeLoading] = useState(false);

  // cleanup 3ds success handler
    useEffect(() => {
        return () => {
            window.removeEventListener('message', on3dsComplete);
        }
    }, []);

  useEffect(() => {
     if (hash === '#all' && all.length) {
       handleViewAllClick();
     }
  }, [all, hash])

  async function getInvoice() {
    setLoading(true);
    try {
      const { data, message, success } = await DataController.getInvoice(cartId, orderId);
      if (success) {
        dispatch({
          type: actions.SET_INVOICE,
          payload: data
        });
        return;
      }
      if (message) {
        enqueueSnackbar(message, {
          variant: 'error'
        });
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getInvoice()
  }, [cartId, orderId])

  const cellRenderer = (heading, row) => {
    switch (heading.id) {
      case 'order':
        return `#${row.id}`;
      case 'name':
        return (
          (order.id === row.id)
            ? (
              <Box maxWidth={340} className={classes.nameField}>
                <Typography className={classes.summaryTitle}>
                  {row.summary.summary_title}
                </Typography>
                <Typography className={classes.summaryText}>
                  {row.summary.summary_line_1}
                </Typography>
                <Typography className={classes.summaryText}>
                  {row.summary.summary_line_2}
                </Typography>
              </Box>
            ) : (
              <Box maxWidth={340} className={classes.nameField}>
                <Typography className={classes.summaryTitle}>
                  {row.summary.ad_description}
                </Typography>
                <Typography className={classes.summaryText}>
                  {row.summary.ad_qty}
                </Typography>
                <Typography className={classes.summaryText}>
                  {row.summary.ad_run}
                </Typography>
                {row.summary?.shipto?.oneline && (
                  <Typography className={classes.summaryText}>
                    {row.summary?.shipto?.oneline}
                  </Typography>
                )}
              </Box>
            )
        );
      case 'subtotal':
        return row.subtotal;
      case 'status':
        return row.status;
      case 'due':
        return (
          <div className={classes.totalDueField}>{row.subtotal}</div>
        );
      default:
        return '';
    }
  };

  const clearHash = () => {
    history.replace(`${pathname}`);
  };

  const addHash = () => {
    history.replace(`${pathname}#all`);
  };

  function onClose() {
    setIsOpen({isOpen: false, currentPopup: ''});
    clearHash()
  }

  function handleSuccessClose() {
    setStatus('');
    setSelectedInvoices([]);
    setIsOpen({isOpen: false, currentPopup: ''})
  }

  const on3dsComplete = (ev) => {
    if (ev.data === '3DS-authentication-complete') {
      payInvoice();
    }
  }

  async function payInvoice(paymentData) {
    setIsOpen({isOpen: true, currentPopup: 'paymentStatus'});
    setStatus('processing');
    const invoices = selectedInvoices.map(invoice => invoice.id);
    const payingMainInvoice = invoices.includes(order.id);

    if (paymentDataWithIntent.current) {
      paymentData = paymentDataWithIntent.current;
    }

    try {
      const res = await DataController.payInvoiceAnon({invoices, paymentData});
      const {message, success} = res;

      if (success) {
        const updatedInvoices = selectedInvoices.map(invoice => {
          return {
            ...invoice,
            status: 'paid'
          }
        })
        setIsOpen({isOpen: true, currentPopup: 'paymentStatus'});
        setStatus('success');
        setSelectedInvoices(updatedInvoices);
        if (payingMainInvoice) {
          getInvoice();
        }
        return;
      }

      if (res && res['3ds']) {
        // store payment data
        paymentDataWithIntent.current = { ...paymentData, paymentintent: res['3ds'].paymentintent_id };
        setIframeUrl(res['3ds'].next_action.redirect_to_url.url);
        window.addEventListener('message', on3dsComplete);
        setIframeLoading(true);
        setIsOpen({isOpen: true, currentPopup: 'payment3ds'})
        return;
      }

      setIsOpen({isOpen: false, currentPopup: ''});
      setStatus('error');

      if (message) {
        enqueueSnackbar(message, { variant: 'error' });
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  function renderContent() {
    if (currentPopup === 'invoices') {
      return (
        <SelectMultipleOrders
          onClose={onClose}
          invoices={all}
          onPayClick={handlePayClick}
        />
      )
    }
    if (currentPopup === 'payInvoices') {
      return (
        <StripeProvider>
          <PayInvoices
            onClose={onClose}
            userData={state.userData}
            invoices={selectedInvoices}
            handleSignInClick={() => setIsOpen({isOpen: true, currentPopup: 'login'})}
            handlePayClick={payInvoice}
            orderId={orderId}
          />
        </StripeProvider>
      )
    }
    if (currentPopup === 'paymentMethods') {
      return (
        <PaymentMethods
          onClose={onClose}
          handlePayClick={payInvoice}
          invoices={selectedInvoices}
        />
      )
    }
    if (currentPopup === 'payment3ds') {
      return (
        <>
          {iframeLoading &&
            <Box className={classes.iframeLoader}>
              <CircularProgress color="secondary" />
            </Box>
          }
          <iframe src={iframeUrl} width="100%" height="400" frameBorder="0" onLoad={() => {
            setIframeLoading(false)
          }}></iframe>
        </>
      )
    }
    if (currentPopup === 'login') {
      return (
        <Login
          onClose={onClose}
          onDone={() => setIsOpen({isOpen: true, currentPopup: 'paymentMethods'})}
        />
      )
    }
    if (currentPopup === 'paymentStatus') {
      return (
        <PaymentStatus
          status={status}
          onClose={onClose}
          handleSuccessClose={handleSuccessClose}
          invoices={selectedInvoices}
        />
      )
    }
    if (currentPopup === 'sendEmails') {
      return (
        <SendEmails
          onClose={onClose}
          cartId={cartId}
          orderId={orderId}
        />
      )
    }
  }

  const handleViewAllClick = () => {
    addHash()
    setIsOpen({
      isOpen: true,
      currentPopup: 'invoices'
    })
  }

  function handlePayClick(selectedInvoices) {
    setSelectedInvoices(selectedInvoices);
    clearHash();
    if (state.userData.id) {
      setIsOpen({
        isOpen: true,
        currentPopup: 'paymentMethods',
      })
      return;
    }

    setIsOpen({
      isOpen: true,
      currentPopup: 'payInvoices',
    })
  }

  const handlePayThisInvoice = () => {
    const item = all.find(el => el.id === order.id);
    setSelectedInvoices([item]);
    if (state.userData.id) {
      setIsOpen({
        isOpen: true,
        currentPopup: 'paymentMethods',
      })
      return;
    }
    setIsOpen({isOpen: true, currentPopup: 'payInvoices'});
  }

  function handlePrintClick() {
    window.print()
  }

  const isCreditCard = order.on_file_text && order.on_file_text.includes('Your card');
  const prefix = isCreditCard ? 'Your card' : 'Your bank account';

  return (
    <div className={classes.invoicePageWrapper}>
      <Box className={classes.header}>
        <Logo className={classes.logo}/>
        <HeaderActions
          setIsOpen={setIsOpen}
          handlePayThisInvoice={handlePayThisInvoice}
          cartId={cartId}
          orderId={orderId}
          handlePrintClick={handlePrintClick}
          isPaid={order.status === 'paid'}
        />
      </Box>
      <Container className={classes.wrapper} id="printContainer">
        {!!all?.length && all.length > 1 && (
          <Card className={classes.top}>
            <Box className={classes.info}>
              <div>{`${order.company} has ${all.length} open orders, ${all_total} in total`}</div>
            </Box>
            <Button className={classes.viewAllButton} variant="contained" size="small" onClick={handleViewAllClick}>
              View All
            </Button>
          </Card>
        )}
        <Card className={classes.content}>
          <Box className={classes.invoiceNumberWrapper}>
            <Logo className={classes.logo}/>
            {loading ? (
              <Box display='flex' flexDirection='column' justifyContent='flex-end' alignItems='end'>
                <SkeletonRow width={189} height={22}/>
                <SkeletonRow width={243} height={18}/>
              </Box>
            ) : (
              <div>
                <div className={classes.invoiceN}>{order.id ? `Order #${order.id}` : ''}</div>
                <div className={classes.invoiceD}>{order.asof_date}</div>
              </div>
            )}
            </Box>
          <Box className={classes.invoiceInfoWrapper}>
            <div className={classes.infoWrapper}>
              <div className={classes.el}>
                {loading ? <SkeletonRow width={84} height={33}/> : <div className={classes.title}>Billed to</div>}
                {loading ? <SkeletonRow width={108} height={18}/> : <div className={classes.text}>{order.billto_line_1}</div>}
                {loading ? <SkeletonRow width={60} height={18}/> :<div className={classes.text}>{order.billto_line_2}</div>}
                {loading ? <SkeletonRow width={60} height={18}/> :<div className={classes.text}>{order.billto_line_3}</div>}
              </div>
              <div className={classes.el}>
                {loading ? <SkeletonRow width={84} height={33}/> : <div className={classes.title}>Due</div>}
                {loading ? <SkeletonRow width={108} height={18}/> : <div className={classes.text}>{order.due_date}</div>}
                {loading && <SkeletonRow width={60} height={18}/>}
                {loading && <SkeletonRow width={60} height={18}/>}
              </div>
            </div>
            <div className={classes.chipWrapper}>
              {loading ? (
                <SkeletonRow width={84} height={32}/>
              ) : (
                <Chip
                  classes={{
                    disabled: classes.chipDisabled,
                    label: classes.chiplabel,
                    outlined: classes.chipOutlined,
                    outlinedPrimary: classes.chipPrimary,
                    outlinedSecondary: classes.chipSecondary
                  }}
                  color={statusMap[order.status]}
                  disabled={order.status === 'canceled'}
                  label={order.status}
                  variant="outlined"
                />
              )}
            </div>
          </Box>
          {order.on_file_text && (
            <Box mt={5} className={classes.onFileTextWrapper}>
              <div>
                {`${prefix}, `}
                <span className={classes.boldText}>{order.on_file_card}</span> will be charged automatically
                on <span className={classes.boldText}>{order.on_file_date}.</span>
              </div>
              <div className={classes.submitPayment}>
                If you would like to pay with a different method,
                please <Button onClick={handlePayThisInvoice} className={classes.submitPaymentBtn}>submit payment</Button> before
                the due date.
              </div>
            </Box>
          )}
          <Box mt={5}>
            {loading ? (
              <SkeletonTable disableHead/>
            ) : (
              <DataTable
                breakpointPaddings={{xsUp: 16, smUp: 24, mdUp: 32}}
                headings={headings}
                rows={order.items}
                cellRenderer={cellRenderer}
              />
            )}
          </Box>
          {!loading && (
            <>
              <Box className={classes.amountInfoRow}>
              <Box className={classes.amountInfoWrapper}>
                {!!order.totals?.discounts_float && (
                  <>
                    <div className={classNames(classes.amountInfoItem, classes.totalInfo)}>
                      <div>subtotal</div>
                      <div>{order.totals?.subtotal || '-'}</div>
                    </div>
                    <div className={classNames(classes.amountInfoItem, classes.discountInfo)}>
                      <div>discounts</div>
                      <div>{order.totals?.discounts || '-'}</div>
                    </div>
                  </>
                )}
                <div className={classNames(classes.amountInfoItem, classes.totalInfo)}>
                  <div>total</div>
                  <div>{order.totals?.total || '-'}</div>
                </div>
                <div className={classNames(classes.amountInfoItem, classes.totalInfo)}>
                  <div>amount paid</div>
                  <div>{order.totals?.amount_paid || '-'}</div>
                </div>
                <div className={classNames(classes.amountDuoItem)}>
                  <div>amount due</div>
                  <div>{order.totals?.amount_due || '-'}</div>
                </div>
              </Box>
            </Box>
              <Divider/>
              <WireTransferInfo/>
            </>
          )}
        </Card>
        <Dialog
          className={classes.dialog}
          onClose={onClose}
          open={isOpen}
          disableEnforceFocus={true}
        >
          {renderContent()}
        </Dialog>
      </Container>
    </div>
  );
}
