import React, { useState } from "react";
import { useHistory } from "react-router";
import MoreMenu from "../common/MoreMenu";
import DataController from "lib/controllers/DataController";
import actions from "store/actions";
import useAppState from "lib/hooks/useAppState";
import { useSnackbar } from "notistack";

export default function AddressMoreMenu({address}) {
    const [loading, setLoading] = useState(false);
    
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch } = useAppState();
    const history = useHistory();

    const items = [
        { label: 'Make default', onClick: () => { handleMakeDefault() }, disabled: !!address.is_default },
        { label: 'Edit', onClick: (e) => { handleEdit(e) } },
        { label: 'Delete', onClick: () => { handleDelete() }, disabled: !address.is_deleteable }
    ];

    const handleDelete = () => {
        if (!address.is_deleteable) return

        DataController
            .deleteAddress(address.id)
            .then(({data, error, success, message}) => {
                if (success) {
                    dispatch({
                        type: actions.UPDATE_ROW,
                        payload: {
                            location: 'addresses.addresses',
                            // delete item in location by id
                            deleteId: address.id
                        }
                    });
                    enqueueSnackbar('Address deleted successfully.');
                } else {
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                }
            })
    }

    const handleEdit = (e) => {
        e.preventDefault();
        history.push(`/account/addresses/${address.id}/edit`);
    }

    const handleMakeDefault = () => {
        setLoading(true);

        DataController
            .postDefaultAddress(address.id)
            .then(({data, error, message, success}) => {
                if (success) {
                    dispatch({
                        type: actions.UPDATE_ROW,
                        payload: {
                            mutateTargets: ['addresses.addresses'],
                            mutateFunction: item => {
                                item.is_default = 0;
                                return item;
                            },
                            location: 'addresses.addresses',
                            data: data
                        }
                    });
                } else {
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                }
                setLoading(false);
            })
    }

    return <MoreMenu id="addresses-menu" items={items} row={address} loading={loading} />;
}