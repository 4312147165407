import React, { useState, useEffect } from "react";
import { Box, Typography, Switch, Button, makeStyles } from "@material-ui/core";
import NumberInputGroup from "../../common/NumberInputGroup";
import ExtraAddonsDialog from "./ExtraAddonsDialog";
import classNames from "classnames";
import { formatPrice } from "lib/functions";

const useStyles = makeStyles(theme => ({
    title: {
        padding: '0 10px',
    },
    row: {
        minHeight: 48,
        padding: '4px 10px',
        borderBottom: `1px solid ${theme.palette.neutral[500]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.down('lg')]: {
            flexWrap: 'wrap',
        }
    },
    totalRow: {
        fontWeight: 'bolder',
        borderBottom: 'none',
    },
    right: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '45%',

        [theme.breakpoints.down('lg')]: {
            width: '100%',
            minHeight: 40,
        }
    },
    description: {
        color: theme.palette.neutral[700],

        [theme.breakpoints.down('lg')]: {
            minHeight: 40,
            display: 'flex',
            alignItems: 'center'
        }
    },
    value: {
        color: theme.palette.neutral[700],
    }
}))

export default function OrderExtraAddons({ addons, order }) {

    const classes = useStyles();

    const [total, setTotal] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [intAddons, setIntAddons] = useState([]);

    useEffect(() => {
        let arr = Array.from(addons);
        arr.forEach(addon => {
            if (addon.type !== 'design') {
                addon.qty = 0
            }
        })
        setIntAddons(arr);
    }, [addons])

    useEffect(() => {
        calcTotal();
    }, [intAddons])

    const handleQtyChange = (value, addonIndex) => {
        let addons = Array.from(intAddons);
        addons[addonIndex].qty = value;
        setIntAddons(addons);
    }

    const handleSwitchChange = (evt, addonIndex) => {
        let addons = Array.from(intAddons);
        addons[addonIndex].qty = evt.target.checked ? 1 : 0;
        setIntAddons(addons);
    }

    const calcTotal = () => {
        let total = 0;
        addons.forEach(addon => {
            if (addon.is_available && addon.qty && addon.price) {
                total += addon.qty * addon.price
            }
        });

        setTotal(total);
    }

    return <Box>
        <Typography variant="h5" className={classes.title}>Buy extra add-ons</Typography>
        {intAddons.length > 0 && <>
                <Box my={3}>
                    {intAddons.map((addon, addonIndex) => (
                        <Box className={classes.row} key={addonIndex}>
                            <Box className={classes.description}>{addon.description}</Box>
                            <Box className={classes.right}>
                                <Box className={classes.value}>{addon.price_formatted}</Box>
                                <Box>
                                    {addon.type === 'design' &&
                                        <Switch onChange={(e) => { handleSwitchChange(e, addonIndex) }} />
                                    }
                                    {addon.type !== 'design' &&
                                        <NumberInputGroup value={addon.qty} onChange={(value) => { handleQtyChange(value, addonIndex) }} />
                                    }
                                </Box>
                            </Box>
                        </Box>
                    ))}
        
                    <Box className={classNames(classes.row, classes.totalRow)}>
                        <span>Total extra add-ons</span>
                        <span>{formatPrice(total)}</span>
                    </Box>
                </Box>
                <Button variant="contained" fullWidth onClick={() => { setDialogOpen(true) }}>Add to cart</Button>
                <ExtraAddonsDialog
                    order={order}
                    open={dialogOpen}
                    handleClose={() => { setDialogOpen(false) }}
                />
            </>
        }
    </Box>
}
