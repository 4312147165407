import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import palette from '../../../theme/palette.js';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    rootFocus: {
        '& $inputWrap': {
            borderColor: theme.palette.primary.main
        }
    },
    rootError: {
        animationName: 'shakeX',
        animationDuration: '.75s',
        animationRepeat: 1,

        '& $inputWrap': {
            borderColor: theme.palette.error.main,
            background: theme.palette.error.light
        },

        '& $label': {
            color: theme.palette.error.main
        }
    },
    inputWrap: {
        display: 'flex',
        alignItems: 'center',
        padding: '6px 12px',
        position: 'relative',
        marginTop: 4,
        borderRadius: 10,
        border: `1px solid ${theme.palette.neutral[400]}`,
        background: theme.palette.common.white,

        '& .StripeElement': {
            flex: 1
        }
    },
    label: {}
}));

function StripeInputWrap({ Component, label, className, endAdornment, onChangeCallback }) {

    const classes = useStyles();
    const [isFocused, setIsFocused] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleChange = e => {
        console.log('CHANGE')
        setIsError(!!e.error);
        setErrorText(e.error && e.error.message);
        if (onChangeCallback && typeof onChangeCallback === 'function') {
            onChangeCallback(e);
        }
    };

    return <FormControl className={classNames(
        classes.root,
        isFocused ? classes.rootFocus : '',
        isError ? classes.rootError : '',
        className ? className : ''
    )}>
        {label && <InputLabel disableAnimation={true}
                              className={classes.label}
                              variant="outlined"
                              shrink={false}>
            {label}
        </InputLabel>}
        <div className={classes.inputWrap}>
            <Component onFocus={handleFocus}
                       onBlur={handleBlur}
                       onChange={handleChange}
                       options={{
                           style: {
                               base: {
                                   fontFamily: '"Neutrif Studio", sans-serif',
                                   fontSize: '19.2px',
                                   fontWeight: 'normal',
                                   lineHeight: '35.8px',
                                   color: palette.secondary.main,

                                   '::placeholder': {
                                       color: palette.neutral[500]
                                   }
                               },
                               invalid: {
                                   iconColor: palette.error.main,
                                   color: palette.error.main
                               }
                           }
                       }}/>
            {endAdornment}
        </div>
        {errorText && <FormHelperText variant="outlined" error>{errorText}</FormHelperText>}
    </FormControl>;
}

export default StripeInputWrap;
