import { useState, useLayoutEffect, useEffect } from 'react';

export default function () {

    const [measurements, setMeasurements] = useState({
        headerHeight: 0
    });

    const performCalculations = () => {
        const headerEl = document.querySelector('#header');
        setMeasurements({
            headerHeight: headerEl.clientHeight
        });
    };

    useLayoutEffect(() => {
        performCalculations();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', performCalculations);
        return () => {
            window.removeEventListener('resize', performCalculations);
        };
    }, []);

    return measurements;
}