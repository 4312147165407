import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CheckboxField = ({ label, ...rest }) => (
    <FormControlLabel
        control={
            <Checkbox />
        }
        label={label}
        {...rest}
    />
);

export default CheckboxField;
