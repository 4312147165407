import React from 'react';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { ReactComponent as PlusIcon } from 'images/plus.svg';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import { useHistory } from 'react-router';
import AccountSectionHeader from '../common/AccountSectionHeader.js';
import { becomesIconButtonResponsive } from '../../../theme/mixins.js';

const useStyles = makeStyles(theme => ({
    cta: {
        height: 48,
        minWidth: 237,
        ...becomesIconButtonResponsive(theme)
    }
}));

function MailingHeader() {

    const classes = useStyles();
    const history = useHistory();

    const handleAddMailingAddress = e => {
        e.preventDefault();
        history.push('/account/addresses/create');
    };

    return <AccountSectionHeader
        title="Mailing Addresses"
        rightSide={<Button
            onClick={handleAddMailingAddress}
            className={classes.cta}
            size="small"
            variant="contained"
            startIcon={<PlusIcon/>}><Hidden smDown>Add Mailing Address</Hidden></Button>}
        hasFilter/>;
}

export default MailingHeader;
