import React from 'react';
import useAppState from '../../../lib/hooks/useAppState.js';
import AccountSectionHeader from '../common/AccountSectionHeader.js';

function CompanyHeader() {
    const { state } = useAppState();
    return <AccountSectionHeader
        title={state.myCompany.data.name || 'Unknown'}
        isLoaded={!state.isLoading}/>;
}

export default CompanyHeader;