import React from 'react';
import { Typography, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import AddTeamMembersList from './AddTeamMembersList';

export default function AssignClientDialog({ handleClose, open, client }) {
    return (
        <Dialog
            onClose={() => {
                handleClose();
            }}
            open={open}
        >
            <DialogTitle disableTypography>
                <Typography color="textSecondary">Add Team Members</Typography>
                <Typography variant="h4">{client ? client.name : ''}</Typography>
            </DialogTitle>
            
            <DialogContent>
                <Typography>You can assignt more than one client to a team member.</Typography>
                {open && 
                    <AddTeamMembersList client={client} handleClose={handleClose} />
                }
            </DialogContent>

        </Dialog>
    )
} 