import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import actions from '../../../store/actions.js';
import useAppState from '../../../lib/hooks/useAppState.js';

export const customEvents = {
    APIError: 'APIError'
};

/**
 * Fire custom event
 *
 * @param e
 * @param data
 */
export const fireCustomEvent = (e, data) => {
    const event = new CustomEvent(`addy_${e}`, {
        detail: data
    });
    return window.dispatchEvent(event);
};

/**
 * Bind custom event
 *
 * @param e
 * @param func
 */
export const bindCustomEvent = (e, func) => {
    return window.addEventListener(`addy_${e}`, func);
};

/**
 * Unbind custom event
 *
 * @param e
 * @param func
 */
export const unbindCustomEvent = (e, func) => {
    return window.removeEventListener(`addy_${e}`, func);
};

function CustomEventHandler() {

    const { dispatch, state } = useAppState();
    const location = useLocation();
    const history = useHistory();

    const handleAPIError = (e) => {

        const unhandledAPIError = e => {
            console.warn('[CustomEventHandler] Unhandled API error', e.detail);
        };

        if (e.detail && e.detail.status) {

            switch (e.detail.status) {
                case 401:
                    if (e.detail.body &&
                        e.detail.body.message === 'Unauthenticated.' &&
                        !location.pathname.startsWith('/login')) {
                        dispatch({
                            type: actions.LOGOUT
                        });
                        history.push(`/login?goto=${location.pathname}`);
                    }
                    break;
                case 429:
                    if (state.temporary.isThrottled) return;
                    dispatch({
                        type: actions.SET_TEMPORARY,
                        payload: {
                            key: 'isThrottled',
                            val: true
                        }
                    });
                    break;
                default:
                    unhandledAPIError(e);
                    break;
            }

        } else {
            unhandledAPIError(e);
        }
    };

    useEffect(() => {

        bindCustomEvent(customEvents.APIError, handleAPIError);

        return () => {

            unbindCustomEvent(customEvents.APIError, handleAPIError);

        };

    }, []);

    return '';
}

export default CustomEventHandler;