import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import useData from "lib/hooks/useData";
import classNames from 'classnames';
import CheckoutReceiptLine from './CheckoutReceiptLine';

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: "60px",
        padding: "10px 0px",
        borderBottom: `2px solid ${theme.palette.neutral[300]}`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center'
    },
    wrapper: {
        marginTop: 36
    }
}));

function OrderSummary({cart}) {
    const classes = useStyles();

    const state = useData({
        queries: ['getMyPaymentMethods'],
        setLoadingState: true
    });
    
    const getSelectedPaymentMethod = () => {
        if (cart.is_invoice) 
            return "Invoice";
        else {
            const card = state.paymentMethods.cards.filter(p => p.id === cart.paymentmethod_id);
            const bank = state.paymentMethods.cards.filter(p => p.id === cart.paymentmethod_id);

            if (card.length) {
                return card[0]?.label;
            } else {
                return bank[0]?.label;
            }
        }
    }

    const selectedPaymentMethod = getSelectedPaymentMethod();

    return (
        <Box>
            <Box className={classes.container}>
                <Typography color="textSecondary">promo code</Typography>
                <Typography>{cart?.promocode}</Typography>
            </Box>
            <Box className={classNames(classes.container)}>
                <Typography color="textSecondary">payment method</Typography>
                <Typography>{selectedPaymentMethod}</Typography>
            </Box>

            <Box className={classNames(classes.container, classes.wrapper)}>
                <Typography>payment</Typography>
            </Box>

            <CheckoutReceiptLine receiptLines={cart?.receipt_lines} />

        </Box>
    )
}

export default OrderSummary;
