import Box from '@material-ui/core/Box';
import DataTable from '../common/DataTable.js';
import useAppState from '../../../lib/hooks/useAppState.js';
import { makeStyles } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns';
import Chip from '@material-ui/core/Chip';
import PaymentTableTitle from './PaymentTableTitle.js';
import MoreMenu from '../common/MoreMenu.js';
import { objContainsStr } from '../../../lib/functions.js';
import { ReactComponent as BankIcon } from '../../../images/bank.svg';
import NoDataFound from '../common/NoDataFound.js';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 64
    },
    name: {
        color: theme.palette.primary.main
    },
    nameNew: {
        color: theme.palette.error.main
    },
    logoCell: {
        width: 39,
        paddingRight: 0,

        '& svg': {
            display: 'inline-block'
        }
    },
    actionsCell: {
        width: 44,
        padding: 0
    },
    defaultCell: {
        width: 77,
        paddingRight: 0,
        paddingTop: 10,
        paddingBottom: 10
    },
    verificationChip: {
        backgroundColor: theme.palette.error.main,
        marginLeft: 6
    }
}));

const headings = [
    { id: 'logo' },
    { label: 'Name', id: 'name' },
    { label: 'Account', id: 'account' },
    { label: 'Date added', id: 'date_added' },
    { id: 'default' },
    { id: 'actions' }
];

function PaymentDataTableCards({ onSetDefault, onDelete, onEditNickname }) {

    const classes = useStyles();
    const { state } = useAppState();

    const cellPropsGenerator = (heading, row) => {
        switch (heading.id) {
            case 'logo':
                return { className: classes.logoCell };
            case 'actions':
                return { className: classes.actionsCell };
            case 'default':
                return { className: classes.defaultCell };
            default:
                return {};
        }
    };

    const cellRenderer = (heading, row) => {
        switch (heading.id) {
            case 'logo':
                return <BankIcon/>;
            case 'account':
                return <span
                    className={row.ba_status === 'new' ? classes.nameNew : classes.name}
                >
                    {row.ba_status === 'new' ? <Chip className={classes.verificationChip} label="pending verification"/> : ''} {row.brand}
                </span>;
            case 'name':
                return <span className={row.ba_status === 'new' ? classes.nameNew : classes.name}>{`${row.name} ${row.number}`}</span>;
            case 'type':
                return row.ba_type;
            case 'date_added':
                return !row.created ? '--' : format(parseISO(row.created), 'MM/dd/yyyy');
            case 'default':
                if (row.is_default) return <Chip label="default"/>;
                else return '';
            case 'actions':
                const items = [
                    {
                        label: 'Make default', disabled: row.ba_status === 'new', onClick: (e, row) => {
                            onSetDefault(e, row.id);
                        }
                    },
                    {
                        label: 'Edit nickname', onClick: (e, row) => {
                            onEditNickname(e, row);
                        }
                    },
                    {
                        label: 'Delete', onClick: (e, row) => {
                            onDelete(e, row.id, row.type);
                        }, disabled: row.is_deletable
                    }
                ];
                return <MoreMenu id="bank-menu" items={items} row={row}/>;
            default:
                return '';
        }
    };

    const filterFunction = rows => {
        if (!state.temporary.filter) return rows;
        return rows.filter(row => objContainsStr(row, ['ba_name', 'ba_number', 'ba_type'], state.temporary.filter));
    };

    return <Box className={classes.root}>
        <PaymentTableTitle>Bank accounts</PaymentTableTitle>
        {!state.paymentMethods.banks || state.paymentMethods.banks.length === 0 ?
            (state.paymentMethods.isLoaded ?
                <NoDataFound>No bank accounts in this account.</NoDataFound> : '') :
            <DataTable
                breakpointPaddings={{
                    xsUp: 16,
                    smUp: 24,
                    mdUp: 32
                }}
                headings={headings}
                rows={state.paymentMethods.banks}
                filterFunction={filterFunction}
                cellPropsGenerator={cellPropsGenerator}
                cellRenderer={cellRenderer}
            />}
    </Box>;
}

export default PaymentDataTableCards;
