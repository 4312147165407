import React, { useState } from 'react';
import { Box, TextField, Button, makeStyles } from '@material-ui/core';
import { ReactComponent as PlusIcon } from 'images/plus.svg';
import DataController from 'lib/controllers/DataController';
import actions from 'store/actions';
import useAppState from 'lib/hooks/useAppState';
import { useSnackbar } from "notistack";
import LoadingButton from '../common/LoadingButton';

const useStyles = makeStyles(theme => ({
    wrap: {
        width: '100%',
        borderTop: `1px solid ${theme.palette.neutral[300]}`,
        background: theme.palette.common.offWhite,
        flexGrow: 0,
    },

    inputWrap: {
        maxHeight: 0,
        overflow: 'hidden',
        marginBottom: 8,
        opacity: 0,
        transition: 'all 0.3s ease-in-out',
    },

    addIcon: {
        transition: 'all 0.3s ease-in-out',

        '& path': {
            stroke: theme.palette.primary.main
        }
    },

    textInput: {
        marginTop: 1
    }
}))

export default function AssigNewClient({ onClientAdded }) {
    const [open, setOpen] = useState(false);
    const [clientName, setClientName] = useState('');
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const { dispatch } = useAppState();

    const handleAddClient = (e) => {
        e.preventDefault();
        setLoading(true);

        const name = clientName;

        DataController
            .createCompany({ name })
            .then(({ data, errors, message, success }) => {
                if (success) {
                    dispatch({
                        type: actions.SET_ADDED_CLIENT,
                        payload: data,
                    });
                    enqueueSnackbar('Client added successfully!');

                    if (onClientAdded && typeof onClientAdded === 'function') {
                        onClientAdded(data.id);
                    }

                    setOpen(false);
                    setClientName('');
                } else {
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                }
                setLoading(false);
            });
    }

    const handleInputChange = (e) => {
        setClientName(e.target.value);
    }

    const handleKeyDown = evt => {
        if ("Enter" === evt.key) {
            evt.preventDefault();
            handleAddClient(evt);
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignContent="center" className={classes.wrap} p={2} pt={1}>
            <Box className={classes.inputWrap} style={open ? { transform: 'scaleY(1)', maxHeight: 100, opacity: 1 } : {}}>
                <TextField
                    className={classes.textInput}
                    onChange={e => handleInputChange(e)}
                    onKeyDown={e => handleKeyDown(e)}
                    value={clientName}
                    placeholder="Client Name"
                    InputProps={{
                        endAdornment: <LoadingButton loading={loading} variant="text" color="primary" onClick={e => handleAddClient(e)}>
                            Add
                        </LoadingButton>
                    }}/>
            </Box>
            <Box display="flex" justifyContent="center">
                <Button
                    variant="text"
                    color="secondary"
                    startIcon={
                        <PlusIcon
                            className={classes.addIcon}
                            style={open ? { transform: 'rotate(225deg)' } : {}}/>
                    }
                    onClick={() => { setOpen(!open) }}>
                    New Client
                </Button>
            </Box>
        </Box>
    )
}
