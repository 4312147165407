import { useState, useCallback } from 'react'

export default function useBoolean(initialValue) {
    const [value, setValue] = useState(initialValue)

    const setTrue = useCallback(() => setValue(true), [])
    const setFalse = useCallback(() => setValue(false), [])
    const toggle = useCallback(() => setValue(value => !value), [])

    return [value, setTrue, setFalse, toggle, setValue]
}