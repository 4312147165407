import React, { useCallback, useMemo } from 'react'
import { Dropdown } from 'components/elements/common';
import { makeStyles } from '@material-ui/core';

import { ReactComponent as LocationIcon } from 'images/location.svg';

const useStyles = makeStyles(theme => ({
    marketValue: {
        display: 'flex',
        alignItems: 'center'
    },

    marketValueIconMd: {
        marginRight: 12,
        width: 24,
        height: 24,
    },
    marketValueIconSm: {
        marginRight: 12,
        width: 20,
        height: 20,
    },
}))

const MarketSelect = React.memo(({markets, prodcode, small, onChange}) => {

    const classes = useStyles()

    const dropdownOptions = useMemo(() => markets.map(market => ({
        value: market.prodcode,
        text: market.market,
    })), [markets])

    const renderValue = useCallback(value => (
        <div className={classes.marketValue}>
            <LocationIcon className={small ? classes.marketValueIconSm  : classes.marketValueIconMd}/>
            {markets.find(market => market.prodcode === value)?.market ?? ''}
        </div>
    ), [small])

    return (
        <Dropdown
            small={small}
            label="Select market"
            onChange={onChange}
            options={dropdownOptions}
            value={prodcode}
            renderValue={renderValue}
        />
    )
})

export default MarketSelect