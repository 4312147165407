import { makeStyles, Typography, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    summary: {
        marginLeft: 10,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            width: 180,
        }
    },
    summaryTitle: {
        fontWeight: 800,
        fontSize: '20px',
        lineHeight: '25px',
        color: '#3F3F3F'
    },
    summaryBody: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '18px',
        color: `${theme.palette.neutral[700]}`
    }
}));

function ItemSummary({item}) {
    const classes = useStyles();

    return <Box className={classes.summary}>
        {item?.order?.id &&
            <Typography className={classes.summaryBody}>
                #{item?.order?.id}
            </Typography>
        }
        <Typography className={classes.summaryTitle}>
            {item.summary.summary_title}
        </Typography>
        <Typography className={classes.summaryBody}>
            {item.summary.summary_line_2}
        </Typography>
        <Typography className={classes.summaryBody}>
            {item.summary.summary_line_1}
        </Typography>
    </Box>;
}

export default ItemSummary;
