import React, { useState, useMemo } from 'react'
import classNames from 'classnames';
import { makeStyles, Button } from '@material-ui/core';

import useBoolean from 'lib/hooks/useBoolean'
import { ReactComponent as CalendarIcon } from 'images/calendar.svg';
import { ReactComponent as MenuIcon } from 'images/menu.svg';
import { ReactComponent as MapIcon } from 'images/map.svg';

import CloseIconButton from './CloseIconButton';
import MarketSelect from './MarketSelect';
import Map from './Map';
import ZipcodeInfo from "./ZipcodeInfo";
import { Count } from '../common';
import SelectedZipcodesList from './SelectedZipcodesList'
import CampaignOverview from './CampaignOverview'
import { ABDM_MIN_ADDRESSES } from 'lib/config';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    title: {
        fontFamily: 'Ahkio',
        fontWeight: 400,
        fontSize: 24,
        textTransform: 'uppercase',
        margin: '12px 0px 4px 20px'
    },
    closeIcon: {
        position: 'absolute',
        top: 14,
        right: 20,
    },

    mapHeader: {
        margin: '0 20px 8px 20px'
    },

    marketSelectLabel: {
        fontSize: '15px',
        fontWeight: 800
    },

    map: {
        flexGrow: 1,
        position: 'relative'
    },

    hoverInfoPanelWrapper: {
        position: 'absolute',
        pointerEvents: 'none',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        zIndex: 1,
        top: 8,
    },

    hoverInfoPanel: {
        pointerEvents: 'initial',
        margin: 'auto',
        background: theme.palette.common.offWhite,
        border: '1px solid #957EFE',
        borderRadius: 10,
        width: 320,
        padding: 12
    },

    footer: {
        padding: '12px 20px'
    },

    separator: {
        height: 1,
        backgroundColor: theme.palette.neutral[400],
    },

    mapSeparator: {
        marginBottom: 4,
        marginTop: 4
    },

    listSeparator: {
        margin: '4px 20px'
    },

    infoContainer: {
        display: 'flex',
        alignItems: 'center',
        height: 40
    },

    infoMain: {
        flexGrow: 1
    },

    infoMainText: {
        fontWeight: 800
    },

    hideMinAddressesInfo: {
        visibility: 'hidden'
    },

    zipcodesCount: {
        marginRight: 4,
        backgroundColor: theme.palette.secondary.main,
    },

    infoDatesButtonWrapper: {
        marginTop: 8,
        textAlign: 'center'
    },

    infoDatesButton: {
        width: '100%',
        maxWidth: 380
    },

    listHeader: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 20px'
    },

    listHeaderText: {
        fontWeight: 800,
        flexGrow: 1
    },

    listZipcodesWrapper: {
        flexGrow: 1,
        padding: '12px 20px'
    },

    linkButton: {
        '& .MuiButton-label': {
            fontWeight: 400
        }
    }
}));

export default function MobileZipcodeSelection({
    mediaInfo,
    prodcode,
    quote,
    selectedZipcodes,
    demographics,
    bbox,
    selectedDates,
    onClose,
    onChangeProdcode,
    onSelectZipcode,
    onSelectDates,
    onAddToCart
}) {
    const classes = useStyles()
    const [view, setView] = useState('map') // map, list, campaign
    const [hoveredZipcode, setHoveredZipcode] = useState(null)
    const [calendarOpen, openCalendar, , , setCalendarOpen] = useBoolean(false);

    const demographicsById = useMemo(
        () => demographics.reduce((res, demogr) => {
            res[demogr.zip] = demogr
            return res
        }, {}),
        [demographics]
    )

    const totalAddresses = Object.values(selectedZipcodes).reduce((res, demogr) => res + demogr.total_households, 0)
    const totalZipcodes = Object.keys(selectedZipcodes).length

    return (
        <div className={classes.root}>
            <div className={classes.title}>{mediaInfo.title}</div>
            <CloseIconButton className={classes.closeIcon} onClick={onClose} />
            {view === 'map' && (<>
                <div className={classes.mapHeader}>
                    <div className={classes.marketSelectLabel}>Select Market</div>
                    <MarketSelect
                        small
                        markets={mediaInfo.markets}
                        prodcode={prodcode}
                        onChange={onChangeProdcode}
                    />
                </div>
                <div className={classes.map}>
                    <Map
                        mode="touch"
                        selectedZipcodes={selectedZipcodes}
                        demographics={demographics}
                        bbox={bbox}
                        hoveredZipcode={hoveredZipcode}
                        onHoverZipcode={setHoveredZipcode}
                        onSelectZipcode={onSelectZipcode}
                    />
                    {hoveredZipcode && (
                        <div className={classes.hoverInfoPanelWrapper}>
                            <div className={classes.hoverInfoPanel}>
                                <ZipcodeInfo
                                    variant="sm"
                                    demographics={demographicsById[hoveredZipcode]}
                                    zipcodeTitle={demographicsById[hoveredZipcode].zip}
                                    cost={demographicsById[hoveredZipcode].price_formatted}
                                    addresses={demographicsById[hoveredZipcode].total_households}
                                    isAdded={!!selectedZipcodes[hoveredZipcode]}
                                    onToggle={() => {onSelectZipcode(hoveredZipcode)}}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className={classes.footer}>
                    <div className={classes.infoContainer}>
                        {!quote.isFetching && Object.keys(selectedZipcodes).length === 0 && (
                            <div className={classes.infoMainText}>No Zips Selected</div>
                        )}
                        {totalZipcodes > 0 && (<>
                            <Count value={totalZipcodes} className={classes.zipcodesCount}/>
                            <div className={classes.infoMain}>
                                <div>
                                    <span className={classes.infoMainText}>Zips Selected</span> {quote.result?.price_formatted ?? ''}
                                </div>
                                <div>({totalAddresses} Addresses)</div>
                            </div>
                            <Button
                                className={classes.linkButton}
                                size="small"
                                variant="text"
                                endIcon={<MenuIcon />}
                                onClick={() => {setView('list')}}
                            >View List</Button>
                        </>)}
                    </div>
                    <div className={classNames(classes.separator, classes.mapSeparator)} />
                    <div className={
                        classNames({
                            [classes.hideMinAddressesInfo]: totalAddresses >= ABDM_MIN_ADDRESSES || Object.keys(selectedZipcodes).length === 0
                        })
                    }>
                        {ABDM_MIN_ADDRESSES} addresses minimum needed.
                    </div>
                    <div className={classes.infoDatesButtonWrapper}>
                        <Button
                            variant="contained"
                            disabled={!quote.result}
                            onClick={() => {setView('campaign'); openCalendar()}}
                            endIcon={<CalendarIcon />}
                            className={classes.infoDatesButton}
                        >Pick Your Delivery Date</Button>
                    </div>
                </div>
            </>)}
            {view === 'list' && (<>
                <div className={classes.listHeader}>
                    <Count value={totalZipcodes} className={classes.zipcodesCount}/>
                    <div className={classes.listHeaderText}>Zips Selected</div>
                    <Button
                        className={classes.linkButton}
                        size="small"
                        variant="text"
                        endIcon={<MapIcon />}
                        onClick={() => setView('map')}
                    >View Map</Button>
                </div>
                <div className={classNames(classes.separator, classes.listSeparator)} />
                <div className={classes.listZipcodesWrapper}>
                    <SelectedZipcodesList
                        selectedZipcodes={selectedZipcodes}
                        quote={quote}
                        onSelectZipcode={onSelectZipcode}
                        onSelectDates={() => {setView('campaign'); openCalendar()}}
                    />
                </div>
            </>)}
            {view === 'campaign' && (
                <div className={classes.listZipcodesWrapper}>
                    <CampaignOverview
                        mediaInfo={mediaInfo}
                        prodcode={prodcode}
                        quote={quote}
                        selectedZipcodesCount={Object.keys(selectedZipcodes).length}
                        selectedDates={selectedDates}
                        isCalendarOpen={calendarOpen}
                        setCalendarOpen={setCalendarOpen}
                        onSelectMarket={onChangeProdcode}
                        onShowMap={() => setView('map')}
                        onSelectDates={onSelectDates}
                        onAddToCart={onAddToCart}
                    />
                </div>
            )}
        </div>
    )
}