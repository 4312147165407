import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { makeStyles, TextField, InputAdornment, Popover } from '@material-ui/core';
import { format, parseISO, isWithinInterval, isSameDay } from 'date-fns'
import { StaticDatePicker, PickersDay } from '@material-ui/pickers';

import {ReactComponent as CalendarIcon} from 'images/calendar.svg';
import {ReactComponent as ChevronDown} from 'images/chevron-down-line.svg';
import {ReactComponent as ChevronUp} from 'images/chevron-up.svg';

const DATE_FORMAT = 'MM/dd/yyyy'

const useStyles = makeStyles(theme => ({
    dateField: {
        cursor: 'pointer',
        '& input[type="button"]': {
            fontSize: 15,
            textAlign: 'left',
            lineHeight: '100%',
            cursor: 'pointer'
        },
        '& label': {
            color: theme.palette.neutral[900]
        },
    },

    icon: {
        height: 20,
        width: 20,
    },

    startIcon: {
        color: theme.palette.primary.main
    },

    popover: {
        marginTop: 16
    },

    daySelected: {
        '&:hover': {
            backgroundColor: 'rgb(219 212 255 / 50%)',
        },
        '.MuiPickersCalendar-week:hover &': {
            borderTop: '1px solid #957EFE',
            borderBottom: '1px solid #957EFE'
        },
        borderTop: '1px solid transparent',
        borderBottom: '1px solid transparent',
        backgroundColor: 'rgb(219 212 255 / 50%)',
        borderRadius: 0,
    },
    daySelectedFirst: {
        '.MuiPickersCalendar-week:hover &': {
            borderLeft: '1px solid #957EFE',
        },
        borderLeft: '1px solid transparent',
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
    },
    daySelectedLast: {
        '.MuiPickersCalendar-week:hover &': {
            borderRight: '1px solid #957EFE',
        },
        borderRight: '1px solid transparent',
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
    },

    selectable: {
        borderTop: '1px solid transparent',
        borderBottom: '1px solid transparent',
        '.MuiPickersCalendar-week:hover &': {
            borderTop: '1px solid #957EFE',
            borderBottom: '1px solid #957EFE',
        },
        borderRadius: 0,
    },
    firstSelectable: {
        '.MuiPickersCalendar-week:hover &': {
            borderLeft: '1px solid #957EFE',
        },
        borderLeft: '1px solid transparent',
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
    },
    lastSelectable: {
        '.MuiPickersCalendar-week:hover &': {
            borderRight: '1px solid #957EFE',
        },
        borderRight: '1px solid transparent',
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
    },
}))

export default function ABDMCalendar({
    selectedDates,
    availableDates,
    open,
    onOpen,
    onClose,
    onChange
}) {
    const classes = useStyles();

    // the problem happens when this component is rendered first time with "open=true"
    // during the first rendering "anchorEl" is null, and we can't open the Popover
    // So, we trigger the second rendering using the code below
    // It will trigger rerendering only once and only if "open=true" during the initial rendering
    const [, forceRerender] = useState(!open)
    useEffect(() => {
        open && forceRerender(true)
    }, [open])

    const parsedAvailableDates = useMemo(() =>
        availableDates?.map(dates => ({
            original: dates,
            parsed: {
                start: parseISO(dates.date_start),
                end: parseISO(dates.date_end),
            }
        })),
        [availableDates]
    )

    const parsedSelectedDates = useMemo(() => ({
        original: selectedDates,
        parsed: {
            start: parseISO(selectedDates.date_start),
            end: parseISO(selectedDates.date_end),
        }
    }), [selectedDates])

    const renderWeekPickerDay = useCallback((date, _, DayComponentProps) => {
        const isSelected = isWithinInterval(date, parsedSelectedDates.parsed)
        const isFirstSelected = isSameDay(date, parsedSelectedDates.parsed.start)
        const isLastSelected = isSameDay(date, parsedSelectedDates.parsed.end)

        const datesInterval = parsedAvailableDates.find(dates => isWithinInterval(date, dates.parsed))
        const isFirstInInterval = datesInterval && isSameDay(date, datesInterval.parsed.start)
        const isLastInInterval = datesInterval && isSameDay(date, datesInterval.parsed.end)
        return (
            <PickersDay
                {...DayComponentProps}
                disableMargin={true}
                today={false}
                selected={false}
                disabled={!datesInterval}
                className={classNames(
                    isSelected && classes.daySelected,
                    isFirstSelected && classes.daySelectedFirst,
                    isLastSelected && classes.daySelectedLast,
                    datesInterval && classes.selectable,
                    isFirstInInterval && classes.firstSelectable,
                    isLastInInterval && classes.lastSelectable,
                )}
            />
        );
    }, [selectedDates])

    const onDateSelected = useCallback(date => {
        if (isWithinInterval(date, parsedSelectedDates.parsed)) {
            return
        }

        const newSelection = parsedAvailableDates.find(dates => isWithinInterval(date, dates.parsed))
        if (newSelection) {
            onClose()
            onChange(newSelection.original)
        }
    }, [parsedAvailableDates, parsedSelectedDates, onChange, onClose])

    const anchorEl = useRef()

    return (<>
        <TextField
            ref={anchorEl}
            type="button"
            value={[
                format(parsedSelectedDates.parsed.start, DATE_FORMAT),
                format(parsedSelectedDates.parsed.end, DATE_FORMAT)
            ].join(' - ')}
            onClick={onOpen}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <CalendarIcon className={classes.startIcon}/>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                    {open ? <ChevronUp className={classes.icon}/> : < ChevronDown className={classes.icon}/>}
                    </InputAdornment>
                ),
                variant: 'outlined'
            }}
            className={classes.dateField}
        />
        <Popover
            open={!!anchorEl.current && open}
            anchorEl={anchorEl.current}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            className={classes.popover}
        >
            <StaticDatePicker
                value={parsedSelectedDates.parsed.start}
                displayStaticWrapperAs="desktop"
                showDaysOutsideCurrentMonth={true}
                renderDay={renderWeekPickerDay}
                onChange={onDateSelected}
            />
        </Popover>
    </>)
}