import {Box, makeStyles, Typography} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  header: {
      fontSize: props => props.fontSize === 'small' ? 34 : 55,
      lineHeight: '100%',
      [theme.breakpoints.down('sm')]: {
        fontSize: 34,
        lineHeight: '120%',
      }
  }
}));

function CommonHeader({ heading, right, fontSize = 'normal' }) {
    const classes = useStyles({fontSize});

    return (
        <Box display="flex">
            <Box ml={0} mr="auto" mb={4.5}>
                <Typography variant="h2" className={classes.header}>
                    { heading }
                </Typography>
            </Box>
            {right
                ?   <Box>
                        { right }
                    </Box>
                :   ''
            }
        </Box>
    );
}

export default CommonHeader;
