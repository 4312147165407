import React from 'react';
import Box from '@material-ui/core/Box';
import useData from '../../../lib/hooks/useData.js';
import useAppState from 'lib/hooks/useAppState.js';
import DataTable from '../../elements/common/DataTable.js';
import { objContainsStr } from '../../../lib/functions.js';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import NoDataFound from 'components/elements/common/NoDataFound.js';

import AddressMoreMenu from 'components/elements/AddressesPage/AddressMoreMenu.js';

const useStyles = makeStyles(theme => ({
    name: {
        color: theme.palette.primary.main
    },
    actionsCell: {
        width: 44,
        padding: 0
    },
    defaultCell: {
        width: 77,
        paddingRight: 0,
        paddingTop: 10,
        paddingBottom: 10
    }
}));

const headings = [
    { label: 'Name', id: 'address_name' },
    { label: 'Attention', id: 'attention' },
    { label: 'Address', id: 'address' },
    { id: 'default' },
    { id: 'actions' }
];

export default function MailingPage() {

    const classes = useStyles();
    useData({
        queries: ['getMyAddresses']
    });
    const { state } = useAppState();

    const cellPropsGenerator = (heading, row) => {
        switch (heading.id) {
            case 'actions':
                return { className: classes.actionsCell };
            case 'default':
                return { className: classes.defaultCell };
            default:
                return {};
        }
    };

    const cellRenderer = (heading, row) => {
        switch (heading.id) {
            case 'address_name':
                return <span className={classes.name}>{row.address_name}</span>;
            case 'attention':
                return row.attention;
            case 'address':
                return `${row.street}${row.street2 ? ` ${row.street2}` : ''}${row.street3 ? ` ${row.street3}` : ''}, ${row.state} ${row.zip}`;
            case 'default':
                if (row.is_default) return <Chip label="default"/>;
                else return '';
            case 'actions':
                return <AddressMoreMenu address={row}/>;
            default:
                return '';
        }
    };

    const filterFunction = rows => {
        if (!state.temporary.filter) return rows;
        return rows.filter(row => objContainsStr(row, ['addressee', 'attention', 'city', 'country', 'label', 'street', 'street2', 'street3'], state.temporary.filter));
    };

    return <Box>
        {!state.addresses.addresses || state.addresses.addresses.length === 0 ?
            (state.addresses.isLoaded ?
                <NoDataFound>No addresses in this account.</NoDataFound>: '') :
            <DataTable
                breakpointPaddings={{
                    xsUp: 16,
                    smUp: 24,
                    mdUp: 32
                }}
                headings={headings}
                rows={state.addresses.addresses}
                filterFunction={filterFunction}
                cellPropsGenerator={cellPropsGenerator}
                cellRenderer={cellRenderer}/>}
    </Box>;
}
