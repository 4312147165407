import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import CheckboxField from '../elements/form/CheckboxField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSnackbar } from 'notistack';
import LoadingButton from 'components/elements/common/LoadingButton';
import { makeStyles, Switch, Paper } from '@material-ui/core';
import DropFile from '../elements/common/DropFile.js';
import S3UploadController from '../../lib/controllers/S3UploadController.js';
import TransitionBounce from 'components/elements/common/TransitionBounce';
import MagnifyImage from '../elements/common/MagnifyImage.js';

const useStyles = makeStyles(theme => ({
    testClass: {
        marginRight: 20
    },
    dropFile: {
        width: 300,
        height: 300
    },
    wrapper: {
        height: 200,
        position: 'relative'
    }
}));

function TestPage() {

    const classes = useStyles();
    const [causeError, setCauseError] = useState('');
    const [loading, setLoading] = useState(false);
    const [slideShowing, setSlideShowing] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const triggerToast = (variant, persist) => {
        enqueueSnackbar('I love hooks', {
            variant: variant,
            persist: persist ? persist : false
        });
    };

    const handleFileChange = async e => {
        console.log(e.target.files[0]);
        // e.target.files[0]
        const fileUpload = await S3UploadController.putUploadFile({
            file: e.target.files[0],
            path: '123123',
            onProgress: perc => {
                console.log('onProgress', perc);
            }
        });

        console.log('result', fileUpload);
    };

    const handleSlideIn = () => {
        setSlideShowing(!slideShowing);
    };

    return <Box mt={20}>
        <Box>
            <div style={{ width: '300px', height: '250px' }}>
                <MagnifyImage
                    width={300}
                    height={250}
                    src="https://placekitten.com/300/250"
                />
            </div>
        </Box>
        <Box>
            <div className={classes.wrapper}>
                <FormControlLabel
                    control={<Switch checked={slideShowing} onChange={handleSlideIn}/>}
                    label="Show"
                />
                <TransitionBounce
                    direction="up"
                    in={slideShowing}
                    mountOnEnter
                    unmountOnExit
                    timeout={{ enter: 600, exit: 200 }}
                >
                    <Paper elevation={4} className={classes.paper}>
                        <svg className={classes.svg}>
                            <polygon points="0,100 50,00, 100,100" className={classes.polygon}/>
                        </svg>
                    </Paper>
                </TransitionBounce>
            </div>
        </Box>
        <Box p={4}>
            <input type="file" onChange={handleFileChange}/>
        </Box>
        <Box p={4}>
            <LoadingButton onClick={() => {
                setLoading(true);
            }} loading={loading} className={classes.testClass}>
                Start Loading
            </LoadingButton>
            <LoadingButton color="primary" onClick={() => {
                setLoading(true);
            }} loading={loading} className={classes.testClass}>
                Start Loading
            </LoadingButton>
            <LoadingButton color="secondary" onClick={() => {
                setLoading(true);
            }} loading={loading} className={classes.testClass}>
                Start Loading
            </LoadingButton><br/>
            <LoadingButton variant="outlined" onClick={() => {
                setLoading(true);
            }} loading={loading} className={classes.testClass}>
                Start Loading
            </LoadingButton>
            <LoadingButton variant="outlined" color="primary" onClick={() => {
                setLoading(true);
            }} loading={loading} className={classes.testClass}>
                Start Loading
            </LoadingButton>
            <LoadingButton variant="outlined" color="secondary" onClick={() => {
                setLoading(true);
            }} loading={loading} className={classes.testClass}>
                Start Loading
            </LoadingButton><br/>
            <Button variant="text" color="default" onClick={() => {
                setLoading(false);
            }}>
                Stop Loading
            </Button>
        </Box>
        <Box p={4}>
            <Button onClick={() => triggerToast()}>
                Trigger Success Toast
            </Button><br/>
            <Button onClick={() => triggerToast('error')}>
                Trigger Error Toast
            </Button><br/>
            <Button onClick={() => triggerToast('', true)}>
                Trigger Persistent Toast
            </Button><br/>
        </Box>
        <Box p={4}>
            <Button variant="text">A text button</Button>
            <br/>
            <Button variant="text" disabled>A text button</Button>
            <br/>
            {/*<StyledButton variant="text">A text button</StyledButton>*/}
        </Box>
        <Box p={4}>
            <Button variant="contained">A contained button</Button>
            <br/>
            <Button variant="contained" disabled>A contained button</Button>
            <br/>
            {/*<StyledButton variant="contained">A contained button</StyledButton>*/}
        </Box>
        <Box p={4}>
            <Button variant="outlined">An outlined button</Button>
            <br/>
            <Button variant="outlined" disabled>An outlined button</Button>
            <br/>
            {/*<StyledButton variant="outlined">An outlined button</StyledButton>*/}
        </Box>
        <Box p={4}>
            <TextField label="Password" variant="outlined" placeholder="placeholder"/>
            <br/>
        </Box>
        <Box p={4}>
            <TextField label={causeError || 'Password'} variant="outlined" error={!!causeError}/>
            <br/>
            <br/>
            <br/>
            <Button onClick={() => setCauseError('there is an error')} variant="contained">Cause an error</Button>
        </Box>
        <Box p={4}>
            <TextField value="disabled" label="Password" variant="outlined" disabled/>
            <br/>
        </Box>
        <Box p={4}>
            <CheckboxField label="Remember me"/>
            <br/>
            <FormControlLabel
                control={
                    <Checkbox/>
                }
                label="Remember me"
            />
        </Box>
        <Box p={4}>
            <DropFile
                className={classes.dropFile}
                onSelect={file => console.warn(file)}>
                Test uploader here.
            </DropFile>
        </Box>
        <Box p={4}>
            <DropFile
                id="a-drop-file-id"
                className={classes.dropFile}
                onSelect={file => console.warn(file)}>
                Test uploader here.
            </DropFile>
            <Box mt={2}><label htmlFor="a-drop-file-id">test with external label, when clicked will open file
                selector!</label></Box>
        </Box>
    </Box>;
}

export default TestPage;
