import React, { useCallback } from 'react'
import { makeStyles, Button } from '@material-ui/core';

import MarketSelect from './MarketSelect'
import MainSummary from './MainSummary'
import ABDMCalendar from './ABDMCalendar'

import { ReactComponent as CartIcon } from 'images/shopping-cart.svg';
import { Count } from '../common';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },

    sectionHeader: {
        fontFamily: 'Neutrif Studio',
        color: theme.palette.text.primary,
        fontWeight: 700,
        fontSize: 17,
        lineHeight: '24px',
        paddingTop: 20
    },

    zipcodesWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 16
    },

    zipcodesCountWrapper: {
        width: '50%',
        display: 'flex',
        alignItems: 'center'
    },

    zipcodesCount: {
        marginRight: 4,
        backgroundColor: theme.palette.secondary.main,
    },

    zipcodesButtonWrapper: {
        flexGrow: 1
    },

    dateHelpText: {
        fontSize: 11
    },

    summaryWrapper: {
        flexGrow: 1,
        marginTop: 16,
    },

    footer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 8
    },

    footerAmount: {
        fontSize: 20,
        fontWeight: 700,
        width: 100
    },

    addToCartWrapper: {
        flexGrow: 1,
        textAlign: 'right'
    },
}))

export default function CampaignOverview({
    mediaInfo,
    prodcode,
    quote,
    selectedZipcodesCount,
    selectedDates,
    isCalendarOpen,
    setCalendarOpen,
    onSelectMarket,
    onShowMap,
    onSelectDates,
    onAddToCart
}) {
    const classes = useStyles();

    const openCalendar = useCallback(() => setCalendarOpen(true), [])
    const closeCalendar = useCallback(() => setCalendarOpen(false), [])

    return (
        <div className={classes.root}>
            <div className={classes.sectionHeader}>Select Market</div>
            <MarketSelect
                markets={mediaInfo.markets}
                prodcode={prodcode}
                onChange={onSelectMarket}
            />
            <div className={classes.zipcodesWrapper}>
                {quote.result && (
                    <div className={classes.zipcodesCountWrapper}>
                        <Count value={selectedZipcodesCount} className={classes.zipcodesCount}/>
                        Zips Selected
                    </div>
                )}
                <div className={classes.zipcodesButtonWrapper}>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={onShowMap}
                    >
                        {quote.result ? 'Edit Zip Codes' : 'Select Zip Codes'}
                    </Button>
                </div>
            </div>

            <div className={classes.sectionHeader}>When To Send Your Mail</div>

            {selectedDates && (
                <ABDMCalendar
                    selectedDates={selectedDates}
                    availableDates={mediaInfo.availability}
                    open={isCalendarOpen}
                    onOpen={openCalendar}
                    onClose={closeCalendar}
                    onChange={onSelectDates}
                />
            )}

            <div className={classes.dateHelpText}>
                USPS will deliver your postcards within the week you choose.
            </div>

            <div className={classes.summaryWrapper}>
                <MainSummary quote={quote} dates={selectedDates} />
            </div>

            <div className={classes.footer}>
                <div className={classes.footerAmount}>{quote.result?.price_formatted || '$0'}</div>
                <div className={classes.addToCartWrapper}>
                    <Button
                        variant="contained"
                        fullWidth
                        endIcon={<CartIcon />}
                        disabled={!quote.result}
                        onClick={onAddToCart}
                    >Add To Cart</Button>
                </div>
            </div>
        </div>
    )
}