import actions from './actions.js';
import PersistentStorage from '../lib/controllers/PersistentStorage.js';
import { sessionCookieName } from 'lib/config.js';
import { dashboardCookieName } from 'lib/config.js';
import { wwwUrl } from 'lib/config.js';

const reducer = (state, action) => {
    switch (action.type) {
        /**
         * Set user data
         */
        case actions.SET_USER_DATA:
            let userData = action.payload;
            let token = action.payload.token;
            let tokenCreatedAt = new Date().getTime().toString();
            PersistentStorage.set('token', token);
            PersistentStorage.set('tokenCreatedAt', tokenCreatedAt);
            return { ...state, userData, token, tokenCreatedAt };
        /**
         * Set my team
         */
        case actions.SET_MY_TEAM:
            const contacts = action.payload.data;
            const meta = action.payload.meta;
            return { ...state, myTeam: { contacts, meta, isLoaded: true } };
        /**
         * Set temporary
         */
        case actions.SET_TEMPORARY:
            const key = action.payload.key;
            const val = action.payload.val;
            const newTemp = { ...state.temporary };
            newTemp[key] = val;
            return { ...state, temporary: newTemp };
        /**
         * Set my company
         */
        case actions.SET_MY_COMPANY:
            const data = action.payload;
            return { ...state, myCompany: { data, isLoaded: true } };
        /**
         * Set my clients
         */
        case actions.SET_MY_CLIENTS:
            return { ...state, myClients: { clients: action.payload.data, meta: action.payload.meta,  isLoaded: true } };
        /**
         * Set current client
         */
        case actions.SET_CURRENT_CLIENT:
            return { ...state, myClients: { ...state.myClients, current: action.payload.data, currentMeta: action.payload.meta } };
        /**
         * Set added client
         */
        case actions.SET_ADDED_CLIENT:
            const addedClient = action.payload;
            const updated = state.myClients.clients.slice();
            updated.push(addedClient);
            updated.sort((a, b) =>
                a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
            )
            return { ...state, myClients: { clients: updated, isLoaded: true } };

        /**
         * Set my promocodes
         */
        case actions.SET_MY_PROMOCODES:
            const codes = action.payload;
            return { ...state, promocodes: { codes, isLoaded: true } };
        /**
         * Set my addresses
         */
        case actions.SET_ADDRESSES:
            const addresses = action.payload;
            return { ...state, addresses: { addresses, isLoaded: true } };
        /**
         * Set my payment methods
         */
        case actions.SET_MY_PAYMENT_METHODS:
            const methods = action.payload;
            const cards = methods.filter(method => method.type === 'stripe');
            const banks = methods.filter(method => method.type !== 'stripe');
            return { ...state, paymentMethods: { cards, banks, isLoaded: true } };
        /**
         * Set billing
         */
        case actions.SET_BILLING:
            return {
                ...state,
                billing: {
                    data: action.payload.data,
                    meta: action.payload.meta,
                    isLoaded: true
                }
            };
        /**
         * Set files
         */
         case actions.SET_FILES:
            return {
                ...state,
                files: {
                    data: action.payload.data,
                    meta: action.payload.meta,
                    isLoaded: true
                }
            };
        /**
         * Set single file
         */
         /**
         * Set current order
         */
        case actions.SET_FILE:
            return { ...state, files: { ...state.files, current: action.payload } };
        /**
         * Set orders
         */
        case actions.SET_ORDERS:
            return {
                ...state,
                orders: {
                    data: action.payload.data,
                    meta: action.payload.meta,
                    isLoaded: true
                }
            };
        /**
         * Set orders stats
         */
        case actions.SET_ORDERS_STATS:
            return {
                ...state,
                ordersStats: {
                    data: action.payload,
                    isLoaded: true
                }
            };
      /**
       * Set order metrics
       */
        case actions.SET_ORDER_METRICS:
            return {
                ...state,
                orderMetrics: {
                    data: action.payload.data,
                    orderId: action.payload.orderId,
                    isLoaded: true
                }
            };
        /**
         * Set timeline
         */
        case actions.SET_CURRENT_TIMELINE:
            return {
                ...state,
                timeline: {
                    data: action.payload,
                    isLoaded: true
                }
            };
      /**
       * Set Invoice
       */
        case actions.SET_INVOICE:
            return {
                ...state,
                invoice: {
                    data: action.payload,
                    isLoaded: true
                }
            };
        /**
         * Set current order
         */
        case actions.SET_CURRENT_ORDER:
            return { ...state, orders: { ...state.orders, current: action.payload } };

        /**
         * Set current order
         */
        case actions.UPDATE_CAMPAIGN_URL: {
            const url = action.payload;
            let currentOrder = state.orders.current;
            currentOrder.filenotes.campaign_url = url;
            return { ...state, orders: { ...state.orders, current: {...currentOrder }} };
        }

        /**
         * Set user profile
         */
        case actions.SET_USER_PROFILE:
            const profile = action.payload;
            const newUserProfile = {
                ...state.userData,
                ...profile
            };
            return { ...state, userData: newUserProfile };
        /**
         * Set user avatar
         */
        case actions.SET_USER_AVATAR:
            const newAvatar = action.payload;
            const newUserAvatar = {
                ...state.userData,
                avatar: newAvatar
            };
            return { ...state, userData: newUserAvatar };
        // /**
        //  * Update query id, causing useData queries to re-run
        //  */
        // case actions.UPDATE_QUERY_ID:
        //     const newQueriesId = state.queriesId + 1;
        //     return { ...state, queriesId: newQueriesId };
        /**
         * Clear data set from one of the SET_... actions
         */
        case actions.CLEAR_DATA:
            const queriesId = state.queriesId + 1;
            switch (action.payload.action) {
                case actions.SET_MY_PAYMENT_METHODS:
                    return { ...state, queriesId, paymentMethods: { ...state.paymentMethods, isLoaded: false } };
                case actions.SET_ADDRESSES:
                    return { ...state, queriesId, addresses: { ...state.addresses, isLoaded: false } };
                case actions.SET_MY_PROMOCODES:
                    return { ...state, queriesId, promocodes: { ...state.promocodes, isLoaded: false } };
                case actions.SET_MY_TEAM:
                    return { ...state, queriesId, myTeam: { ...state.myTeam, isLoaded: false } };
                case actions.SET_USER_DATA:
                case actions.SET_USER_PROFILE:
                    return { ...state, queriesId, userData: {} };
                case actions.SET_MY_CLIENTS:
                    return { ...state, queriesId, myClients: { ...state.myClients, clients: [], isLoaded: false } };
                case actions.SET_CURRENT_CLIENT:
                    return { ...state, queriesId, myClients: { ...state.myClients, current: null } };
                default:
                    console.warn('[CLEAR_DATA] not found', action.payload.action);
                    return { ...state };
            }
        case actions.UPDATE_ROW:
            if (!action.payload.location) return state;

            // clone state
            const updatedState = { ...state };
            let statePosition; // reference to state obj used for data manipulation

            // bulk mutate objects (optional)
            if (action.payload.mutateTargets && Array.isArray(action.payload.mutateTargets) && typeof action.payload.mutateFunction === 'function') {
                for (let i = 0; i < action.payload.mutateTargets.length; i++) {
                    statePosition = updatedState; // reset state position
                    const mutateTargetsLocationArray = action.payload.mutateTargets[i].split('.');
                    for (let x = 0; x < mutateTargetsLocationArray.length; x++) {
                        statePosition = statePosition[mutateTargetsLocationArray[x]];
                    }
                    if (typeof statePosition === 'undefined' || !Array.isArray(statePosition)) {
                        console.warn('[UPDATE_ROW] could not mutate', action.payload.mutateTargets[i]);
                    } else {
                        for (let y = 0; y < statePosition.length; y++) {
                            statePosition[y] = action.payload.mutateFunction(statePosition[y]);
                        }
                    }
                }
            }

            // find update data location
            const locationArray = action.payload.location.split('.');
            statePosition = updatedState; // reset state position
            for (let i = 0; i < locationArray.length; i++) {
                statePosition = statePosition[locationArray[i]];
                if (typeof statePosition === 'undefined') {
                    console.error('[UPDATE_ROW] could not find location in state', action.payload.location);
                    return state;
                }
            }

            // perform actual data update
            if (typeof action.payload.data === 'object') {
                if (Array.isArray(statePosition)) {
                    if (!action.payload.data.id) {
                        console.error('[UPDATE_ROW] id not found in data', action.payload.data);
                        return state;
                    }
                    const objReplaceIndex = statePosition.findIndex(item => item.id === action.payload.data.id);
                    if (objReplaceIndex !== -1) {
                        console.log('[UPDATE_ROW] updating row in location');
                        if (action.payload.type && action.payload.type === 'merge') {
                            // sometimes the object returned is the fully hydrated object isntead of the tableview object
                            // in those situations, you cna use this to only update the props you need
                            // example: FilesTable - updateFavorite function
                            statePosition[objReplaceIndex] = {
                                ...statePosition[objReplaceIndex],
                                ...action.payload.data,
                                is_table_accented: { state: true, location: action.payload.location }
                            };
                        } else {
                            statePosition[objReplaceIndex] = {
                                ...action.payload.data,
                                is_table_accented: { state: true, location: action.payload.location }
                            };
                        }
                    } else {
                        console.log('[UPDATE_ROW] pushing row to location');
                        statePosition.push({
                            ...action.payload.data,
                            is_table_accented: { state: true, location: action.payload.location }
                        });
                    }
                } else if (typeof statePosition === 'object') {
                    statePosition = { ...statePosition, ...action.payload.data };
                    const stateUpd = {};
                    for (let i = locationArray.length - 1; i >= 0; i--) {
                        stateUpd[locationArray[i]] = { ...stateUpd };
                        if (i !== locationArray.length - 1) {
                            delete stateUpd[locationArray[i + 1]];
                        }
                    }
                    let stateUpdPos = { ...stateUpd };
                    for (let i = 0; i < locationArray.length; i++) {
                        if (locationArray.length - 1 === i) {
                            stateUpdPos[locationArray[i]] = { ...statePosition };
                        } else {
                            stateUpdPos = stateUpdPos[locationArray[i]];
                        }
                    }
                    return { ...state, ...stateUpd };
                } else {
                    console.error('[UPDATE_ROW] can not perform any data updates');
                    return state;
                }
            } else {
                if (action.payload.unsetAccentId) {
                    const unsetIndex = statePosition.findIndex(item => item.id === action.payload.unsetAccentId);
                    statePosition[unsetIndex] = { ...statePosition[unsetIndex], is_table_accented: null };
                } else if (action.payload.deleteId && !action.payload.data && Array.isArray(statePosition)) {
                    console.log('[UPDATE_ROW] delete item with', action.payload.deleteId);
                    const deleteIndex = statePosition.findIndex(item => item.id === action.payload.deleteId);
                    statePosition.splice(deleteIndex, 1);
                } else {
                    console.log('[UPDATE_ROW] updated primitive state');
                    statePosition = action.payload.data;
                }
            }

            return updatedState;
        /**
         * Handle hash view
         */
        case actions.HASH_VIEW:
            const hashPath = action.payload.split('/');
            console.warn('HASH_VIEW called using the following hash path', hashPath);
            return { ...state, hashPath };
        /**
         * Set is loading
         */
        case actions.SET_IS_LOADING:
            return { ...state, isLoading: action.payload };
        /**
         * Log out
         */
        case actions.LOGOUT:
            document.cookie = `${dashboardCookieName}=; Path=/; Domain=.addy.co; Max-Age=-99999999;`;
            document.cookie = `${sessionCookieName}=; Path=/; Domain=.addy.co; Max-Age=-99999999;`;
            PersistentStorage.delete('token');
            PersistentStorage.delete('tokenCreatedAt');
            PersistentStorage.delete('station');
            window.location = wwwUrl + '/logout' + window.location.search;
            break;
        default:
            return state;
    }
};

export default reducer;
